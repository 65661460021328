import { numberToNDigitString } from "../util";

export const MaxStateDistNumbers = {
  AL: {
    up: 35,
    lo: 105,
  },
  AK: {
    up: 20,
    lo: 40,
  },
  AZ: {
    up: 30,
    lo: 60,
  },
  AR: {
    up: 35,
    lo: 100,
  },
  CA: {
    up: 40,
    lo: 80,
  },
  CO: {
    up: 35,
    lo: 65,
  },
  CT: {
    up: 36,
    lo: 151,
  },
  DE: {
    up: 21,
    lo: 41,
  },
  FL: {
    up: 40,
    lo: 120,
  },
  GA: {
    up: 56,
    lo: 180,
  },
  HI: {
    up: 25,
    lo: 51,
  },
  ID: {
    up: 35,
    lo: 70,
  },
  IL: {
    up: 59,
    lo: 118,
  },
  IN: {
    up: 50,
    lo: 100,
  },
  IA: {
    up: 50,
    lo: 100,
  },
  KS: {
    up: 40,
    lo: 125,
  },
  KY: {
    up: 38,
    lo: 100,
  },
  LA: {
    up: 39,
    lo: 105,
  },
  ME: {
    up: 35,
    lo: 151,
  },
  MD: {
    up: 47,
    lo: 141,
  },
  MA: {
    up: 40,
    lo: 160,
  },
  MI: {
    up: 38,
    lo: 110,
  },
  MN: {
    up: 67,
    lo: 134,
  },
  MS: {
    up: 52,
    lo: 122,
  },
  MO: {
    up: 34,
    lo: 163,
  },
  MT: {
    up: 50,
    lo: 100,
  },
  NE: {
    up: 49,
    lo: 0,
  },
  NV: {
    up: 21,
    lo: 42,
  },
  NH: {
    up: 24,
    lo: 400,
  },
  NJ: {
    up: 40,
    lo: 80,
  },
  NM: {
    up: 42,
    lo: 70,
  },
  NY: {
    up: 63,
    lo: 150,
  },
  NC: {
    up: 50,
    lo: 120,
  },
  ND: {
    up: 47,
    lo: 94,
  },
  OH: {
    up: 33,
    lo: 99,
  },
  OK: {
    up: 48,
    lo: 101,
  },
  OR: {
    up: 30,
    lo: 60,
  },
  PA: {
    up: 50,
    lo: 203,
  },
  RI: {
    up: 38,
    lo: 75,
  },
  SC: {
    up: 46,
    lo: 124,
  },
  SD: {
    up: 35,
    lo: 70,
  },
  TN: {
    up: 33,
    lo: 99,
  },
  TX: {
    up: 31,
    lo: 150,
  },
  UT: {
    up: 29,
    lo: 75,
  },
  VT: {
    up: 30,
    lo: 150,
  },
  VA: {
    up: 40,
    lo: 100,
  },
  WA: {
    up: 49,
    lo: 98,
  },
  WV: {
    up: 34,
    lo: 100,
  },
  WI: {
    up: 33,
    lo: 99,
  },
  WY: {
    up: 30,
    lo: 60,
  },
};

export const isStateUpperDistrict = (str) => {
  return str.split("-")[1].length === 2;
};
export const isStateLowerDistrict = (str) => {
  return str.split("-")[1].length === 3;
};

function upperReducer(acc, [state, upLoMaxes]) {
  acc[state] = [...Array(upLoMaxes["up"]).keys()].map((i) =>
    numberToNDigitString(i + 1, 2)
  );
  return acc;
}

function lowerReducer(acc, [state, upLoMaxes]) {
  acc[state] = [...Array(upLoMaxes["lo"]).keys()].map((i) =>
    numberToNDigitString(i + 1, 3)
  );
  return acc;
}

export const StateUpperDistsByState = Object.entries(
  MaxStateDistNumbers
).reduce(upperReducer, {});

export const StateLowerDistsByState = Object.entries(
  MaxStateDistNumbers
).reduce(lowerReducer, {});
