import React, { useState, useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import { Button, Col, Form, Input, Row, Select, Card, Typography } from "antd";
import { LeftOutlined } from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";

import {
  createCampaign,
  getCampaign,
  getClients,
  isCampaignExported,
  updateCampaign,
} from "../../APIClient";
import { showSuccessMessage } from "../../utils";
import { useParams, useHistory } from "react-router-dom";

const spanSize = 6;
const applecartSolutionList = ["DMT", "ET"];
const mediaOptions = [
  { label: "Basis", value: "BASIS" },
  { label: "Optimal", value: "OPTIMAL" },
];
const campaignUsecaseOptions = [
  { label: "Legislative Affairs", value: "LEGISLATIVE_AFFAIRS" },
  { label: "Corporate Reputation", value: "CORPORATE_REPUTATION" },
  { label: "Investor Relations", value: "INVESTOR_RELATIONS" },
  { label: "Employee Engagement", value: "EMPLOYEE_ENGAGEMENT" },
  { label: "Marketing & Sales", value: "MARKETING_AND_SALES" },
  { label: "Political", value: "POLITICAL" },
];

const CreateEditCampaign = () => {
  const history = useHistory();
  const { hubspotCompanyId: hubspotCompanyIdFromUrlParams } = useParams();
  const { campaignId: selectedCampaignId } = useParams();

  const dispatch = useDispatch();

  const [clients, setClients] = useState([]);

  const [chosenMedia, setChosenMedia] = useState();
  const [chosenSolution, setChosenSolution] = useState([]);
  const [chosenUseCase, setChosenUseCase] = useState([]);
  const [campaignName, setCampaignName] = useState();
  const [endClient, setEndClient] = useState();
  const [isCampaignDSPExported, setIsCampaignDSPExported] = useState(false);

  const selectedHubspotCompanyId = useSelector(
    (state) => state.selectedHubspotCompanyId
  );

  const fetchClients = async () => {
    try {
      const data = await getClients();
      setClients(data);
    } catch (error) {
      alert(error);
    }
  };

  const fetchCampaign = async () => {
    try {
      const resp = await getCampaign(selectedCampaignId);
      setChosenMedia(resp.media);
      setChosenSolution(resp.solution);
      setChosenUseCase(resp.use_case);
      setCampaignName(resp.name);
      setEndClient(resp.end_client);
    } catch (error) {
      alert(error);
    }
  };

  const fetchIsCampaignExported = async () => {
    try {
      const response = await isCampaignExported(selectedCampaignId);
      setIsCampaignDSPExported(response.is_campaign_exported);
    } catch (error) {
      alert(error);
    }
  };

  const getBillingClientId = () => {
    const matchingClients = clients?.filter(
      (c) => c.hubspot_company_id === selectedHubspotCompanyId
    );

    if (matchingClients.length !== 1) {
      alert("Selected Account does not have match.");
      return;
    }
    return matchingClients?.[0].id;
  };

  const onCreate = async () => {
    try {
      const resp = await createCampaign(
        campaignName,
        chosenSolution,
        chosenUseCase,
        chosenMedia,
        endClient,
        getBillingClientId()
      );
      showSuccessMessage("Campaign Created");
      history.push(
        `/account-campaigns/${selectedHubspotCompanyId}/campaign/${resp.id}`
      );
    } catch (error) {
      alert(error);
    }
  };
  const onUpdate = async () => {
    console.log("will be called when button clicked");
    console.log(selectedHubspotCompanyId);
    try {
      await updateCampaign(
        selectedCampaignId,
        campaignName,
        chosenSolution,
        chosenUseCase,
        chosenMedia,
        endClient,
        getBillingClientId()
      );
      showSuccessMessage("Campaign Updated");
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  useEffect(() => {
    if (!!selectedCampaignId) {
      fetchCampaign();
      fetchIsCampaignExported();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCampaignId]);

  useEffect(() => {
    if (!!hubspotCompanyIdFromUrlParams) {
      dispatch({
        type: "set-selected-hubspot-company-id",
        payload: hubspotCompanyIdFromUrlParams,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHubspotCompanyId]);

  const isValidToCreate =
    !isCampaignDSPExported &&
    chosenSolution !== null &&
    chosenSolution?.length > 0 &&
    chosenUseCase !== null &&
    chosenUseCase?.length > 0 &&
    !!campaignName &&
    !!endClient;

  const endClientOptions = clients
    ?.filter((item) => !!item.hubspot_company_id)
    ?.map((item) => ({
      label: item.name,
      value: item.id,
    }));

  return (
    <Navbar contentClass="landing-page-content">
      <Row style={{ marginBottom: 16 }}>
        <Button
          type="primary"
          icon={<LeftOutlined />}
          onClick={() => {
            history.push(`/account-campaigns/${selectedHubspotCompanyId}`);
          }}
        >
          Campaigns
        </Button>
      </Row>
      <Card>
        <Typography.Title level={3}>
          {!selectedCampaignId ? `Create Campaign` : `Edit Campaign`}
        </Typography.Title>
        <Form layout="vertical" style={{ marginTop: "4%" }}>
          <Row>
            <Col span={spanSize}>
              <Form.Item
                label="Applecart Solution"
                required
                tooltip="Not editable after Liveramp export has occurred."
              ></Form.Item>
            </Col>
            <Col>
              <Select
                mode="multiple"
                allowClear
                style={{ width: "350px" }}
                placeholder="Please select"
                value={chosenSolution}
                onChange={(item) => {
                  setChosenSolution(item);
                }}
                options={applecartSolutionList.map((item) => {
                  return {
                    label: item,
                    value: item,
                  };
                })}
                disabled={isCampaignDSPExported}
              />
            </Col>
          </Row>

          <Row>
            <Col span={spanSize}>
              <Form.Item
                label="Campaign Use Case"
                required
                tooltip="Not editable after Liveramp export has occurred."
              ></Form.Item>
            </Col>
            <Col>
              <Select
                showSearch
                allowClear
                placeholder="Select"
                onSelect={(item) => {
                  setChosenUseCase(item);
                }}
                onClear={() => {
                  setChosenUseCase();
                }}
                value={chosenUseCase}
                options={campaignUsecaseOptions}
                style={{ width: 250, marginRight: 10 }}
                disabled={isCampaignDSPExported}
              />
            </Col>
          </Row>

          <Row>
            <Col span={spanSize}>
              <Form.Item
                label="Campaign Name"
                required
                tooltip="Not editable after Liveramp export has occurred."
              ></Form.Item>
            </Col>
            <Col>
              <Input
                allowClear
                style={{ width: "300px" }}
                placeholder="Type a campaign name"
                onChange={(e) => {
                  setCampaignName(e.target.value);
                }}
                value={campaignName}
                disabled={isCampaignDSPExported}
              />
            </Col>
          </Row>

          <Row>
            <Col span={spanSize}>
              <Form.Item
                label="Media"
                tooltip="Not editable after Liveramp export has occurred."
              ></Form.Item>
            </Col>
            <Col>
              <Select
                showSearch
                allowClear
                placeholder="Select"
                style={{ width: 150, marginRight: 10 }}
                value={chosenMedia}
                onSelect={(item) => {
                  setChosenMedia(item);
                }}
                onClear={() => {
                  setChosenMedia(null);
                }}
                options={mediaOptions}
                disabled={isCampaignDSPExported}
              />
            </Col>
          </Row>

          <Row>
            <Col span={spanSize}>
              <Form.Item
                label="End Customer"
                required
                tooltip="Not editable after Liveramp export has occurred."
              ></Form.Item>
            </Col>
            <Col>
              <Select
                showSearch
                allowClear
                placeholder="Select"
                optionFilterProp="label"
                style={{ width: 350, marginRight: 10 }}
                value={endClient}
                onSelect={(item) => {
                  setEndClient(item);
                }}
                onClear={() => {
                  setEndClient(null);
                }}
                options={endClientOptions}
                disabled={isCampaignDSPExported}
              />
            </Col>
          </Row>
          <Button
            type="primary"
            disabled={!isValidToCreate}
            onClick={() => {
              !selectedCampaignId ? onCreate() : onUpdate();
            }}
            style={{ float: "right" }}
          >
            {!selectedCampaignId ? `Create` : `Edit Campaign`}
          </Button>
        </Form>
      </Card>
    </Navbar>
  );
};

export default CreateEditCampaign;
