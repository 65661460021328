export const getCookie = (name) => {
  return document.cookie
    .split("; ")
    .find((row) => row.startsWith(name + "="))
    ?.split("=")[1];
};

export const decodeJWT = (token) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
};

export const cookieToObject = () => {
  return document.cookie.split("; ").reduce((prev, current) => {
    const [name, ...value] = current.split("=");
    prev[name] = value.join("=");
    return prev;
  }, {});
};

export const objectToCookie = (obj) => {
  return Object.entries(obj)
    .map(([key, value]) => `${key}=${value}`)
    .join("; ");
};

export const resetCookie = (name, domain) => {
  const cookies = cookieToObject();
  cookies[name] = `; Domain=${domain};`;
  document.cookie = objectToCookie(cookies);
};
