const reducer = (state = null, action) => {
  switch (action.type) {
    case "get-target":
      // fetch and return target info
      return action.payload;
    case "update-target":
      // fetch and return target info
      return action.payload;
    case "delete-target":
      // delete target and return null
      return null;
    case "set-target-null":
      return null;
    default:
      return state;
  }
};

export default reducer;
