import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { createStafferSegment } from "../../../APIClient";
import {
  showWarningMessage,
  showSuccessMessage,
  showDangerMessage,
} from "../../../utils";
import { SegmentDrawer } from "./SegmentDrawer";

export const StaffersDrawer = ({ isVisible, setIsVisible, refreshPage }) => {
  const { projectId } = useParams();
  const target = useSelector((state) => state.target);

  const createAudienceMembers = async (desiredNumber) => {
    try {
      const {
        audience_length: numberOfStaffers,
        matched_count: numberOfStaffersMatched,
      } = await createStafferSegment(
        target?.dm_id,
        desiredNumber,
        projectId,
        target.id
      );

      if (numberOfStaffersMatched === 0) {
        showDangerMessage(
          `Total of ${numberOfStaffers}  were found but we were unable to match any`
        );
      } else if (desiredNumber > numberOfStaffersMatched) {
        showWarningMessage(
          `Total of ${numberOfStaffersMatched} were found/matched and ${numberOfStaffers} staffers were added.`
        );
      } else {
        showSuccessMessage(
          `Total of ${numberOfStaffersMatched} were found/matched and ${numberOfStaffers} staffers were added.`
        );
      }
    } catch (error) {
      alert(error);
    }
  };

  return (
    <SegmentDrawer
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      refreshPage={refreshPage}
      createAudienceMembers={createAudienceMembers}
      message={"Create Staffers "}
    />
  );
};
