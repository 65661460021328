import React, { useEffect, useState } from "react";
import { useForm } from "antd/lib/form/Form";
import { Drawer, Form, Button, Row, Col, Alert, Checkbox, Divider } from "antd";
import { showSuccessMessage, showDangerMessage } from "../../../utils";
import { saveHVCConfiguration, getProject } from "../../../APIClient";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

const HVC_CONFIG_TYPES = {
  spouse: "Spouse",
  other_family: "Other family",
  staff: "Staff",
  close_association_member: "Close association member",
  close_coworker: "Close coworker",
  top_party_leader: "Top party leader",
  top_media_member: "Top media member",
  friend: "Friend",
};

const DEFAULT_HVC_CONFIG = {
  spouse: false,
  other_family: false,
  staff: false,
  close_association_member: false,
  close_coworker: false,
  top_party_leader: false,
  top_media_member: false,
  friend: false,
};

const HVCConfigDrawer = ({ isVisible, setIsVisible }) => {
  const [form] = useForm();
  const project = useSelector((state) => state.project);
  const project_id = project?.id;
  const [btnDisabled, setBtndisabled] = useState(true);
  const [hvcConfig, setHVCConfig] = useState([]);
  const [isAllChecked, setIsAllChecked] = useState(false);

  useEffect(() => {
    const fetchDataFromServerAndUpdateState = async () => {
      try {
        const resp = await getProject(project_id);
        resp["hvc_config"] &&
          setHVCConfig(
            Object.entries(resp["hvc_config"])
              .filter(([key, value]) => value === true)
              .map(([key, value]) => key)
          );
      } catch (error) {
        showDangerMessage(`Failed due to :  ${error}`);
      }
    };

    if (project_id) {
      fetchDataFromServerAndUpdateState();
    }
  }, [project_id]);

  useEffect(() => {
    if (hvcConfig.length === Object.keys(DEFAULT_HVC_CONFIG).length) {
      setIsAllChecked(true);
    } else {
      setIsAllChecked(false);
    }
  }, [hvcConfig]);

  const location = useLocation();
  const history = useHistory();

  const closeDrawer = () => {
    setIsVisible(false);
    const urlParams = new URLSearchParams(location.search);
    if (urlParams.get("hvc_config") === "open") {
      urlParams.delete("hvc_config");
      history.replace({ search: urlParams.toString() });
    }
  };

  const convertConfigValuesToDict = (values) => {
    const newConfigEntries = Object.fromEntries(
      values.map((item) => [item, true])
    );
    return { ...DEFAULT_HVC_CONFIG, ...newConfigEntries };
  };

  const onFinish = async () => {
    try {
      await saveHVCConfiguration(project_id, {
        hvc_config: convertConfigValuesToDict(hvcConfig),
      });
      showSuccessMessage(`HVC configuration was updated successfully.`);
      setBtndisabled(true);
      closeDrawer();
    } catch (error) {
      alert(error);
    }
  };
  const onFinishFailed = (errorInfo) => {
    showDangerMessage(`Failed due to :  ${errorInfo}`);
  };

  const handleCheckAll = () => {
    if (hvcConfig.length === Object.keys(DEFAULT_HVC_CONFIG).length) {
      setHVCConfig([]);
      setIsAllChecked(false);
    } else {
      setHVCConfig(Object.keys(DEFAULT_HVC_CONFIG));
      setIsAllChecked(true);
    }
    setBtndisabled(false);
  };

  const onValuesChange = (checkedValues) => {
    setHVCConfig(checkedValues);
    setBtndisabled(false);
  };

  return (
    <Drawer
      title={`High Value Connections Configuration for Project (id: ${project_id})`}
      onClose={closeDrawer}
      visible={isVisible}
      width={600}
      bodyStyle={{ paddingBottom: 20 }}
    >
      <Alert
        style={{ marginBottom: "20px" }}
        description="Make sure to save the desired configuration before adding decision makers to the project. HVC network blocs will be filtered according to this configuration at the time of adding."
        type="warning"
        closable
      />
      <Checkbox checked={isAllChecked} onClick={handleCheckAll}>
        Check all
      </Checkbox>
      <Divider style={{ marginTop: 5, marginBottom: 5 }} />

      <Form
        form={form}
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Checkbox.Group onChange={onValuesChange} value={hvcConfig}>
          {Object.entries(HVC_CONFIG_TYPES).map(([key, value]) => (
            <Row style={{ paddingBottom: 5 }} key={key}>
              <Col>
                <Checkbox value={key}>{value}</Checkbox>
              </Col>
            </Row>
          ))}
        </Checkbox.Group>
        <Row
          style={{
            paddingBottom: 0,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Col>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={btnDisabled}>
                Save Configuration
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};

export default HVCConfigDrawer;
