import React, { useEffect, useState } from "react";
import { Card, Row, Col, Button } from "antd";
import {
  HomeOutlined,
  PhoneOutlined,
  MailOutlined,
  CalendarOutlined,
  KeyOutlined,
  EnvironmentOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

import "./PersonCard.css";
import ColocatedDrawer from "./ColocatedDrawer";
import { findNeighbors, getPii } from "../../APIClient";
import { combineAddressElements } from "../../utils";

const DOBComponent = ({ year, month, day }) => {
  const modifiedMonth = month < 10 ? `0${month}` : month;
  const modifiedDay = day < 10 ? `0${day}` : day;
  return (
    <>
      <Row className="person-card-header">
        <Col
          style={{
            width: 30,
          }}
        >
          <CalendarOutlined />
        </Col>
        <Col>{month ? "Date of Birth" : "Year"}</Col>
      </Row>
      <Row className="person-card-content">
        <Col
          style={{
            width: 30,
          }}
        ></Col>
        <Col>
          {year && month && day
            ? `${modifiedMonth}/${modifiedDay}/${year}`
            : year && month
            ? `${modifiedMonth}/${year}`
            : year}
        </Col>
      </Row>
    </>
  );
};

const PersonCard = ({
  index,
  person,
  linkTo,
  linkToButtonCallback,
  icon,
  hoverDisabled = false,
}) => {
  const {
    first_name,
    middle_name,
    last_name,
    phones,
    emails,
    addresses,
    year,
    month,
    day,
    p_id,
  } = person;

  const [isColocatedDrawerOpen, toggleColocatedDrawer] = useState(false);
  const [colocated, setColocated] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getColocated = async () => {
      setIsLoading(true);
      const neighbors = await findNeighbors(p_id, 5);
      let colocated_pids = neighbors
        ?.filter(({ is_colocated }) => is_colocated === true)
        .map(({ p_id }) => p_id);

      if (colocated_pids) {
        const pii = await getPii(colocated_pids);
        setColocated(pii);
      }
      setIsLoading(false);
    };

    if (p_id && isColocatedDrawerOpen) {
      getColocated();
    }
  }, [isColocatedDrawerOpen, p_id]);

  const cardStyleClass = (index, hoverDisabled) => {
    if (hoverDisabled) {
      return "person-card-default";
    } else {
      return index % 2 === 0 ? "person-card-even" : "person-card-odd";
    }
  };

  return (
    <Card
      title={`${first_name} ${
        middle_name && middle_name !== null ? middle_name : ""
      } ${last_name}`.toLocaleUpperCase()}
      bordered={false}
      style={{ width: "100%", fontSize: "14px", marginBottom: 10 }}
      className={cardStyleClass(index, hoverDisabled)}
      extra={
        <span>
          <Button
            type="link"
            style={{
              paddingLeft: 0,
              float: "right",
              marginTop: 5,
              fontWeight: "bold",
            }}
            onClick={() => {
              toggleColocatedDrawer(!isColocatedDrawerOpen);
            }}
          >
            {isColocatedDrawerOpen ? "Show PII" : "Find Colocated"}
            {isColocatedDrawerOpen ? (
              <InfoCircleOutlined />
            ) : (
              <EnvironmentOutlined />
            )}
          </Button>
          <Button
            type="link"
            style={{
              paddingLeft: 0,
              float: "right",
              marginTop: 5,
              fontWeight: "bold",
            }}
            onClick={async () => {
              linkToButtonCallback(p_id);
            }}
          >
            {linkTo}
            {icon}
          </Button>
        </span>
      }
    >
      {p_id && p_id && (
        <>
          <Row className="person-card-header">
            <Col
              style={{
                width: 30,
              }}
            >
              <KeyOutlined />
            </Col>
            <Col>P ID</Col>
          </Row>
          <Row className="person-card-content">
            <Col
              style={{
                width: 30,
              }}
            ></Col>
            <Col>{p_id}</Col>
          </Row>
        </>
      )}
      {year && year !== 0 && (
        <DOBComponent year={year} month={month} day={day} />
      )}
      {isColocatedDrawerOpen ? (
        <ColocatedDrawer
          data={colocated}
          isLoading={isLoading}
          toggleColocatedDrawer={toggleColocatedDrawer}
          DOBComponent={DOBComponent}
        />
      ) : (
        <>
          {addresses && addresses.length >= 1 && (
            <>
              <Row className="person-card-header">
                <Col
                  style={{
                    width: 30,
                  }}
                >
                  <HomeOutlined />
                </Col>
                <Col>Address ({addresses.length})</Col>
              </Row>
              <Row className="person-card-content">
                <Col
                  style={{
                    width: 30,
                  }}
                ></Col>

                <Col>
                  {addresses?.map((item, i) => (
                    <div key={`address-${i}`}>
                      {combineAddressElements(item)}
                    </div>
                  ))}
                </Col>
              </Row>
            </>
          )}
          {phones && phones.length >= 1 && (
            <>
              <Row className="person-card-header">
                <Col
                  style={{
                    width: 30,
                  }}
                >
                  <PhoneOutlined />
                </Col>
                <Col>Phones ({phones.length})</Col>
              </Row>
              <Row className="person-card-content">
                <Col
                  style={{
                    width: 30,
                  }}
                ></Col>

                <Col>
                  {phones?.map((item, i) => (
                    <div key={`phones-${i}`}>{item}</div>
                  ))}
                </Col>
              </Row>
            </>
          )}
          {emails && emails.length >= 1 && (
            <>
              <Row className="person-card-header">
                <Col
                  style={{
                    width: 30,
                  }}
                >
                  <MailOutlined />
                </Col>
                <Col>Emails ({emails.length})</Col>
              </Row>
              <Row className="person-card-content">
                <Col
                  style={{
                    width: 30,
                  }}
                ></Col>
                <Col>
                  {emails?.map((item, i) => (
                    <div key={`emails-${i}`}>{item}</div>
                  ))}
                </Col>
              </Row>
            </>
          )}
        </>
      )}
    </Card>
  );
};

export default PersonCard;
