import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Card, Empty, Row, Col } from "antd";
import MPSSearchForm from "./MPSSearchForm";
import PersonCardForm from "./PersonCard";
import { useForm } from "antd/lib/form/Form";
import { SaveOutlined } from "@ant-design/icons";

const MPSSearch = ({ setMPSSearchCallback }) => {
  const [form] = useForm();
  const [results, setResults] = useState([]);
  const [searchTriggered, setSearchTriggered] = useState(false);
  const decisionMaker = useSelector((state) => state.decisionMaker);
  const [searchMethodItems, setSearchMethodItems] = useState({});

  const formResultCallback = (p_id) => {
    p_id && setMPSSearchCallback(p_id);
    form.resetFields();
    form.setFieldsValue({ addresses: [], birthdays: [], names: [] });
    setSearchMethodItems({});
    setResults([]);
  };

  useEffect(() => {
    // reset the page when target is changed
    form.resetFields();
    setResults([]);
  }, [decisionMaker?.id, form]);

  return (
    <div className="target-search-container">
      <Card title="Search Decision Maker" className="display-card">
        <MPSSearchForm
          dataCallback={setResults}
          searchForm={form}
          formResetCallback={formResultCallback}
          setSearchTriggered={setSearchTriggered}
          setSearchMethodItems={setSearchMethodItems}
          searchMethodItems={searchMethodItems}
        />
      </Card>

      {searchTriggered && (
        <div className="results-container">
          <Row>
            <Col span={12}>
              <h2 style={{ marginBottom: 50 }}>
                {results?.length === 50
                  ? "Showing the first 50 profiles"
                  : `Search Results (${results.length})`}
              </h2>
            </Col>
          </Row>

          {!results || results.length === 0 ? (
            <Empty description="No results" />
          ) : (
            <>
              {results.map((row, index) => (
                <PersonCardForm
                  index={index}
                  key={index}
                  person={row}
                  linkTo="Link to Decision Maker"
                  icon={<SaveOutlined />}
                  linkToButtonCallback={formResultCallback}
                />
              ))}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default MPSSearch;
