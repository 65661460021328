import Navbar from "../Navbar";
import React, { useEffect, useState } from "react";
import { Col, Row, Typography, Button } from "antd";
import {
  getClients,
  getAvailableListsPerAccount,
  getInExListByListId,
  getEASCompanies,
  getExclusionCompanies,
} from "../../APIClient";
import { UnorderedListOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import InExListTabs from "./InExListTabs";
import InExListPIIGrid from "./InExListPIIGrid";
import InExListCompanyGrid from "./InExListCompanyGrid";
import CreateInExListModal from "./CreateInExListModal";
import { AccountSelectForm } from "./AccountSelectForm";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { titleCaseNames, createOneAddressString } from "../../utils";

export const InExListsPage = () => {
  const { inExListId } = useParams();
  const history = useHistory();

  const [clientId, setClientId] = useState();
  const [clientNameOptions, setClientNameOptions] = useState([]);
  const [inExLists, setInExLists] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [inExListName, setInExListName] = useState();
  const [inExListType, setInExListType] = useState("INCLUSION");
  const [inExListMembersData, setInExListMembersData] = useState([]);
  const [inExListCompanyData, setInExListCompanyData] = useState([]);
  const [isCreateInExListModalOpen, setIsCreateInExListModalOpen] =
    useState(false);

  const redirectPageURL = (list_id) => {
    if (list_id) {
      history.push(`/inex-lists/${list_id}`);
    } else {
      history.push(`/inex-lists/`);
      setClientId();
      setInExLists();
    }
  };

  const loadClientOptions = async () => {
    const resp = await getClients();
    let data = resp.filter((client) => client.is_billing);

    setClientNameOptions(data.filter((client) => !client?.is_old_name));
  };

  const loadInExLists = async (value) => {
    setIsLoading(true);
    try {
      const data = await getAvailableListsPerAccount(value);
      setInExLists(data);
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  const onAccountChange = (value) => {
    history.push(`/inex-lists/`);
    setClientId(value);
    loadInExLists(value);
  };

  const loadInExListMembersData = async (value) => {
    setIsLoading(true);
    try {
      const response = await getInExListByListId(value);
      if (response.error) {
        alert(response.error);
      }

      if (inExListId && !response[0] && !clientId) {
        redirectPageURL();
      }

      if (response[0]) {
        setInExListName(response[0].list_name);
        loadInExLists(response[0].client_id);
        setClientId(parseInt(response[0].client_id));
        setInExListType(response[0].list_type);
      }
      let response_with_key = [];

      response_with_key = await response.map((el) => {
        return {
          key: el.id,
          ...el,
          has_p_id: el.p_id !== null ? "✓" : null,
          proper_first_name: titleCaseNames(el.first_name),
          proper_last_name: titleCaseNames(el.last_name),
          address: createOneAddressString(el.addresses[0]),
          addresses: el.addresses.map((item) => createOneAddressString(item)),
          email: el.emails[0],
          phone: el.phones[0],
        };
      });

      setInExListMembersData(response_with_key.sort((a, b) => b?.id - a?.id));
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  const loadCompanyData = async (listId) => {
    setIsLoading(true);
    try {
      let exclusionCompanies = await getExclusionCompanies(listId);
      let easCompanies = await getEASCompanies(
        exclusionCompanies?.map((c) => c.company_canonical_id)
      );

      const merged = exclusionCompanies.map((item) => {
        const obj = easCompanies.find(
          (o) => o.company_id === item.company_canonical_id
        );
        return { key: item.id, ...item, ...obj };
      });

      setInExListCompanyData(merged);
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (inExListId) {
      inExListType === "COMPANY_EXCLUSION"
        ? loadCompanyData(inExListId)
        : loadInExListMembersData(inExListId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inExListId]);

  useEffect(() => {
    if (clientNameOptions?.length === 0) {
      loadClientOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshPageInfo = (lists = true, listData = true) => {
    if (clientId && lists) {
      loadInExLists(clientId);
    }
    if (inExListId && listData) {
      inExListType === "COMPANY_EXCLUSION"
        ? loadCompanyData(inExListId)
        : loadInExListMembersData(inExListId);
    }
  };

  return (
    <Navbar>
      <Row style={{ flex: "1 1 auto", height: "100%" }}>
        {/* 1. search column */}
        <Col span={7} style={{ backgroundColor: "white", padding: "20px" }}>
          <Row style={{ alignItems: "baseline" }}>
            <Col>
              <Typography.Title level={5}>
                <UnorderedListOutlined /> Inclusion and Exclusion Lists
              </Typography.Title>
            </Col>
          </Row>
          <Row style={{ width: "100%", marginTop: "20px" }}>
            <AccountSelectForm
              onAccountChange={onAccountChange}
              clientNameOptions={clientNameOptions}
              clientId={clientId}
            />
          </Row>
          <Row style={{ width: "100%", marginTop: "10px" }}>
            {clientId && (
              <Button
                onClick={() => {
                  setIsCreateInExListModalOpen(true);
                }}
              >
                Create New List
              </Button>
            )}
          </Row>
          <Row style={{ width: "100%", marginTop: "30px" }}>
            {clientId && (
              <InExListTabs
                inExLists={inExLists}
                isLoading={isLoading}
                setInExListName={setInExListName}
                setInExListType={setInExListType}
                redirectPageURL={redirectPageURL}
                inExListType={inExListType}
              />
            )}
          </Row>
        </Col>

        {/* 2. lists info column */}

        {inExListId &&
          clientId &&
          (inExListType === "COMPANY_EXCLUSION" ? (
            <InExListCompanyGrid
              inExListCompanyData={inExListCompanyData}
              refreshPageInfo={refreshPageInfo}
              inExListName={inExListName}
              setInExListName={setInExListName}
              isLoading={isLoading}
              inExListId={inExListId}
              redirectPageURL={redirectPageURL}
            />
          ) : (
            <InExListPIIGrid
              inExListMembersData={inExListMembersData}
              refreshPageInfo={refreshPageInfo}
              inExListName={inExListName}
              setInExListName={setInExListName}
              inExListType={inExListType}
              isLoading={isLoading}
              inExListId={inExListId}
              redirectPageURL={redirectPageURL}
            />
          ))}
      </Row>
      <CreateInExListModal
        clientId={clientId}
        isVisible={isCreateInExListModalOpen}
        setIsVisible={setIsCreateInExListModalOpen}
        refreshPageInfo={refreshPageInfo}
      />
    </Navbar>
  );
};
