const reducer = (state = [], action) => {
  switch (action.type) {
    case "get-segments":
      return action.payload;
    case "delete-segment":
    case "delete-audience-members":
      const deletedSegmentId = action.payload;
      return state.filter((segment) => segment.id !== deletedSegmentId);
    default:
      return state;
  }
};

export default reducer;
