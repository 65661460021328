import { useContext } from "react";
import { Table } from "antd";
import { getColumnSearchProps } from "../../../util/columnSearchPropFactory";
import { CompanyGroupsContext } from "../CompanyGroupsPage";
import { sortColumnWithNulls } from "../../../utils";

export const CompanyGrid = ({
  selectedCompanies,
  setSelectedCompanies,
  stateFilterOptions,
}) => {
  const { isLoading, companyGridData } = useContext(CompanyGroupsContext);
  const companyGridColumns = [
    {
      title: "ID",
      dataIndex: "company_id",
      sorter: (a, b) => a.company_id - b.company_id,
      width: 90,
    },
    {
      title: "Group",
      dataIndex: "group_name",
      sorter: (a, b) => a.group_name?.localeCompare(b.group_name),
      width: 200,
    },
    {
      title: "Name",
      dataIndex: "company_name",
      sorter: (a, b) => a.company_name?.localeCompare(b.company_name),
      ...getColumnSearchProps("company_name", "Company Name"),
      width: 200,
    },
    {
      title: "City",
      dataIndex: "city",
      sorter: (a, b) => sortColumnWithNulls(a.city, b.city),
      width: 150,
    },
    {
      title: "State",
      dataIndex: "state",
      filterSearch: true,
      filters: stateFilterOptions,
      sorter: (a, b) => sortColumnWithNulls(a.state, b.state),
      onFilter: (value, record) => record.state === value,
      width: 80,
    },
    {
      title: "Size",
      dataIndex: "employee_count",
      sorter: (a, b) => a?.employee_count - b?.employee_count,
      width: 80,
    },
    {
      title: "Synthetic",
      dataIndex: "synthetic",
      sorter: (a, b) => a.synthetic - b.synthetic,
      render: (val, row) => (row.synthetic ? "Y" : "N"),
      width: 115,
      filters: [
        {
          text: "Yes",
          value: true,
        },
        {
          text: "No",
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.synthetic === value,
    },
    {
      title: "Type",
      dataIndex: "canonical",
      sorter: (a, b) => a.canonical - b.canonical,
      render: (val, row) =>
        row.group_id ? (row.canonical ? "Canonical" : "Alternate") : null,
      width: 95,
      filters: [
        {
          text: "Canonical",
          value: true,
        },
        {
          text: "Alternate",
          value: false,
        },
        {
          text: "Not set",
          value: null,
        },
      ],
      onFilter: (value, record) => record.canonical === value,
    },
  ];

  return (
    <Table
      rowSelection={{
        selectedRowKeys: selectedCompanies.map((row) => row.key),
        onChange: (selectedRowKeys, selectedRows) => {
          setSelectedCompanies(selectedRows);
        },
        getCheckboxProps: (record) => ({
          disabled: !!record.group_id,
        }),
      }}
      columns={companyGridColumns}
      pagination={{ pageSize: 50 }}
      dataSource={companyGridData}
      loading={isLoading}
      virtual
      scroll={{ x: 1000, y: 600 }}
    />
  );
};
