import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Popconfirm, Row, Space, Spin } from "antd";
import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import {
  createDecisionMakerAddress,
  deleteDecisionMakerAddress,
  getDecisionMakerAddresses,
  updateDecisionMakerAddress,
} from "../../APIClient";
import { showSuccessMessage } from "../../utils";
import { AddressForm } from "./AddressForm";
import { DecisionMakerContext } from "./DecisionMakerContext";

export const Address = () => {
  // local state
  const [isLoading, setIsLoading] = useState(false);
  const [creatingNew, setCreatingNew] = useState(false);
  const [addresses, setAddresses] = useState([]);

  // redux state
  const decisionMaker = useSelector((state) => state.decisionMaker);

  const fetchAddresses = async () => {
    // turn on loading
    setIsLoading(true);
    try {
      const data = await getDecisionMakerAddresses(decisionMaker.id);
      setAddresses(data);
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (decisionMaker.id) {
      fetchAddresses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decisionMaker.id, decisionMaker.dcm_id]);

  return (
    <Spin spinning={isLoading}>
      <Space direction="vertical" style={{ width: "100%" }}>
        {creatingNew ? (
          <CreateAddress
            onCancel={() => setCreatingNew(false)}
            callback={() => {
              setCreatingNew(false);
              fetchAddresses();
            }}
            setIsLoading={setIsLoading}
          />
        ) : (
          <Row>
            <Button
              block
              type="link"
              onClick={() => setCreatingNew(true)}
              style={{ textAlign: "left" }}
              size="large"
            >
              <PlusOutlined /> Add another
            </Button>
          </Row>
        )}
        {addresses?.length > 0 && (
          <Divider plain orientation="left">
            Existing Addresses
          </Divider>
        )}
        {addresses
          ?.sort((a, b) => b.id - a.id)
          ?.map((a, i) => (
            <React.Fragment key={i}>
              {i !== 0 && <Divider />}
              <EditAddress
                addressValues={a}
                callback={() => {
                  fetchAddresses();
                }}
                setIsLoading={setIsLoading}
              />
            </React.Fragment>
          ))}
      </Space>
    </Spin>
  );
};

export const CreateAddress = ({ onCancel, callback, setIsLoading }) => {
  const [streetAddress, setStreetAddress] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [zipcode, setZipcode] = useState();
  const [addr2, setAddr2] = useState();
  const [isFormValid, setIsFormValid] = useState(true);

  // redux state
  const decisionMaker = useSelector((state) => state.decisionMaker);
  const { setUpdateDecisionMakerQAStatus } = useContext(DecisionMakerContext);

  const onSave = async () => {
    setIsLoading(true);
    try {
      await createDecisionMakerAddress(decisionMaker.id, {
        streetAddress,
        city,
        state,
        zipcode,
        addr2,
      });
      callback();
      showSuccessMessage("New address added.");
      setUpdateDecisionMakerQAStatus(true);
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  const resetForm = () => {
    setStreetAddress();
    setCity();
    setState();
    setZipcode();
    setAddr2();
  };

  useEffect(() => {
    resetForm();
  }, [decisionMaker.id]);

  return (
    <Row>
      <Col span={16}>
        <AddressForm
          address={streetAddress}
          setIsFormValid={setIsFormValid}
          setAddress={setStreetAddress}
          addr2={addr2}
          setAddr2={setAddr2}
          city={city}
          setCity={setCity}
          state={state}
          setState={setState}
          zipcode={zipcode}
          setZipcode={setZipcode}
        />
      </Col>
      <Col span={8}>
        <Space size={5}>
          <Button onClick={onCancel} size="middle" style={{ width: "75px" }}>
            Cancel
          </Button>
          <Button
            disabled={!isFormValid}
            onClick={onSave}
            type="primary"
            size="middle"
            style={{ width: "65px" }}
          >
            Save
          </Button>
        </Space>
      </Col>
    </Row>
  );
};

export const EditAddress = ({ addressValues, callback, setIsLoading }) => {
  const [isEditing, setIsEditing] = useState(false);

  const [streetAddress, setStreetAddress] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [zipcode, setZipcode] = useState();
  const [addr2, setAddr2] = useState();
  const [isFormValid, setIsFormValid] = useState(false);
  // redux state
  const decisionMaker = useSelector((state) => state.decisionMaker);
  const { setUpdateDecisionMakerQAStatus } = useContext(DecisionMakerContext);

  const resetForm = () => {
    setStreetAddress(addressValues?.street_address);
    setAddr2(addressValues?.addr2);
    setCity(addressValues?.city);
    setState(addressValues?.state);
    setZipcode(addressValues?.zipcode);
  };

  useEffect(() => {
    resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressValues]);

  const onSave = async () => {
    setIsLoading(true);
    try {
      await updateDecisionMakerAddress(decisionMaker.id, addressValues.id, {
        streetAddress,
        city,
        state,
        zipcode,
        addr2,
      });
      callback();
      setUpdateDecisionMakerQAStatus(true);
      showSuccessMessage("Address updated.");
    } catch (error) {
      alert(error);
      resetForm();
    }
    setIsEditing(false);
    setIsLoading(false);
  };

  const onDelete = async () => {
    setIsLoading(true);
    try {
      await deleteDecisionMakerAddress(decisionMaker.id, addressValues.id);
      callback();
      setUpdateDecisionMakerQAStatus(true);
      showSuccessMessage("Address deleted.");
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  return (
    <Row>
      <Col span={16}>
        <AddressForm
          addressValues={addressValues}
          disabled={!isEditing}
          setIsFormValid={setIsFormValid}
          address={streetAddress}
          setAddress={setStreetAddress}
          city={city}
          setCity={setCity}
          state={state}
          setState={setState}
          zipcode={zipcode}
          setZipcode={setZipcode}
          addr2={addr2}
          setAddr2={setAddr2}
        />
      </Col>
      <Col span={7}>
        {isEditing ? (
          <Space size={5}>
            <Button
              onClick={() => {
                setIsEditing(false);
                resetForm();
              }}
              size="middle"
              style={{ width: "75px" }}
            >
              Cancel
            </Button>
            <Button
              disabled={!isFormValid}
              onClick={onSave}
              type="primary"
              size="middle"
              style={{ width: "65px" }}
            >
              Save
            </Button>
          </Space>
        ) : (
          <Button
            onClick={() => setIsEditing(true)}
            size="middle"
            style={{ width: "140px" }}
          >
            Edit
          </Button>
        )}
      </Col>
      <Col span={1}>
        <Popconfirm title={`Delete Address?`} okText="Yes" onConfirm={onDelete}>
          <Button danger type="link" icon={<DeleteOutlined />} />
        </Popconfirm>
      </Col>
    </Row>
  );
};
