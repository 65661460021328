import { useEffect, useState } from "react";
import {
  Typography,
  Row,
  Button,
  Col,
  Space,
  Table,
  Tag,
  Tooltip,
  Divider,
  Popconfirm,
  Form,
  Input,
  Popover,
  Drawer,
} from "antd";
import { getColumnSearchProps } from "../../util/columnSearchPropFactory";
import {
  EditOutlined,
  SaveOutlined,
  DeleteOutlined,
  LogoutOutlined,
  LoginOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  updateInExList,
  deleteInExList,
  deleteInExListMember,
  createInExListMemberByPID,
} from "../../APIClient";
import { CreateInExMemberDrawer } from "./CreateInExMemberDrawer";
import InExListMPSSearch from "./InExListMPSSearch";
import { showSuccessMessage } from "../../utils";

const InExListPIIGrid = ({
  inExListMembersData,
  inExListName,
  setInExListName,
  isLoading,
  inExListType,
  inExListId,
  refreshPageInfo,
  redirectPageURL,
}) => {
  const inExListTypeVisible = inExListType === "EXCLUSION";
  const [editListName, setEditListName] = useState(false);

  const [initialPIIvalues, setInitialPIIvalues] = useState({
    first_name: null,
    last_name: null,
    address1: null,
    address2: null,
    city: null,
    state: null,
    zip: null,
    email: null,
    phone1: null,
    phone2: null,
  });
  const [inExListMemberId, setInExListMemberId] = useState();
  const { Title } = Typography;
  const [
    isCreateListMemberByPIIDrawerVisible,
    setIsCreateListMemberByPIIDrawerVisible,
  ] = useState(false);
  const [isMPSSearchDrawerVisible, setIsMPSSearchDrawerVisible] =
    useState(false);

  const populateInitialValues = (record) => {
    return {
      first_name: record.first_name,
      last_name: record.last_name,
      address1: record.address1,
      address2: record.address2,
      city: record.city,
      state: record.state,
      zip: record.zip,
      email: record.email,
      phone1: record.phone1,
      phone2: record.phone2,
    };
  };

  const saveListName = async () => {
    try {
      const response = await updateInExList(inExListId, inExListName);
      if (response.id) {
        showSuccessMessage("Successfully updated");
        refreshPageInfo(true, false);
        setEditListName(false);
      }
      if (response.error) {
        alert(response.error);
      }
    } catch (error) {
      alert(error);
    }
  };

  const deleteList = async () => {
    try {
      const response = await deleteInExList(inExListId);
      if (response) {
        showSuccessMessage("Successfully deleted");
        refreshPageInfo(true, false);
        redirectPageURL();
      }
      if (response.error) {
        alert(response.error);
      }
    } catch (error) {
      alert(error);
    }
  };

  const deleteMember = async (member_id) => {
    try {
      const response = await deleteInExListMember(member_id);
      if (response) {
        showSuccessMessage("Successfully deleted");
        refreshPageInfo(false, true);
      }
      if (response.error) {
        alert(response.error);
      }
    } catch (error) {
      alert(error);
    }
  };

  const addMemberByPid = async (p_id) => {
    try {
      const response = await createInExListMemberByPID(inExListId, p_id);
      if (response.id) {
        setIsMPSSearchDrawerVisible(false);
        showSuccessMessage("Member attached");
        refreshPageInfo(false, true);
      }
      if (response.error) {
        alert(response.error);
      }
    } catch (error) {
      alert(error);
    }
  };

  const [memberByPIIForm] = Form.useForm();

  const showPIIFormDrawer = (record) => {
    if (record) {
      setInitialPIIvalues(populateInitialValues(record));
      setInExListMemberId(record.id);
    } else {
      setInitialPIIvalues();
      setInExListMemberId();
    }
    setIsCreateListMemberByPIIDrawerVisible(true);
  };

  useEffect(() => {
    memberByPIIForm.resetFields();
    memberByPIIForm.setFieldsValue(initialPIIvalues);
  }, [memberByPIIForm, initialPIIvalues]);

  const inExListMemberColumns = [
    {
      title: "Action",
      key: "action",
      width: "80px",
      render: (record) => (
        <Space size="middle">
          <Popconfirm
            title="Are you sure to remove this member from the list?"
            onConfirm={() => {
              deleteMember(record.id);
            }}
            okText="Yes"
            okButtonProps={{ danger: true }}
            cancelText="No"
          >
            <Button type="text" style={{ padding: 0 }}>
              <Tooltip
                placement="bottom"
                title={`Delete member from ${record.list_name}`}
              >
                <DeleteOutlined style={{ color: "#f5222d", fontSize: 15 }} />
              </Tooltip>
            </Button>
          </Popconfirm>
          {!record.p_id && (
            <Button
              onClick={() => {
                showPIIFormDrawer(record);
              }}
              type="text"
              style={{ padding: 0 }}
            >
              <Tooltip title="Edit member details">
                <EditOutlined style={{ color: "#1990ff", fontSize: 15 }} />
              </Tooltip>
            </Button>
          )}
        </Space>
      ),
    },

    {
      title: "P ID",
      key: "has_p_id",
      width: "90px",
      dataIndex: "has_p_id",
      filters: [
        {
          text: "P ID",
          value: "✓",
        },
        {
          text: "PII",
          value: null,
        },
      ],
      sorter: (a, b) => a.has_p_id?.localeCompare(b.has_p_id),
      onFilter: (value, record) => record.has_p_id === value,
      render: (p_id, record) =>
        p_id ? (
          <Tag bordered="false" color="geekblue">
            <Tooltip title={`P ID: ${record.p_id}`}>{record.has_p_id}</Tooltip>
          </Tag>
        ) : null,
    },
    {
      title: "First Name",
      dataIndex: "proper_first_name",
      sorter: (a, b) => a.proper_first_name?.localeCompare(b.proper_first_name),
      ...getColumnSearchProps("proper_first_name", "First Name"),
    },
    {
      title: "Last Name",
      dataIndex: "proper_last_name",
      sorter: (a, b) => a.proper_last_name?.localeCompare(b.proper_last_name),
      ...getColumnSearchProps("proper_last_name", "Last Name"),
    },
    {
      title: "Address",
      dataIndex: "address",
      sorter: (a, b) => a.address?.localeCompare(b.address),
      render: (select, record) => (
        <Popover
          content={record.addresses.map((item, index) => (
            <p key={index}>{item}</p>
          ))}
          title={`All available addresses:`}
        >
          {record.address}
        </Popover>
      ),
      ...getColumnSearchProps("address", "Address"),
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email?.localeCompare(b.email),
      render: (select, record) => (
        <Popover
          content={record.emails.map((item, index) => (
            <p key={index}>{item}</p>
          ))}
          title={`All available emails:`}
        >
          {record.email}
        </Popover>
      ),
      ...getColumnSearchProps("email", "Email"),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      sorter: (a, b) => a.phone?.localeCompare(b.phone),
      render: (select, record) => (
        <Popover
          content={record.phones.map((item, index) => (
            <p key={index}>{item}</p>
          ))}
          title={`All available phone numbers:`}
        >
          {record.phone}
        </Popover>
      ),
      ...getColumnSearchProps("phone", "Phone"),
    },
  ];

  return (
    <Col
      style={{
        backgroundColor: "white",
        paddingLeft: "15px",
        paddingRight: "35px",
      }}
      span={17}
    >
      <Row style={{ alignItems: "baseline", padding: "35px 0px 15px 0px" }}>
        <Title level={4} style={{ paddingRight: "10px" }}>
          {inExListTypeVisible === true ? (
            <LogoutOutlined style={{ color: "#ff7875" }} />
          ) : (
            <LoginOutlined style={{ color: "#73d13d" }} />
          )}
        </Title>
        {editListName === false && (
          <Space>
            <Title level={3} style={{ paddingRight: "10px" }}>
              {inExListName}
            </Title>

            <Popconfirm
              title="Are you sure to delete the list?"
              onConfirm={deleteList}
              okText="Yes"
              okButtonProps={{ danger: true }}
              cancelText="No"
            >
              <Button style={{ padding: 0, marginBottom: "5px" }} type="text">
                <Tooltip title="Delete List" placement="top">
                  {
                    <DeleteOutlined
                      style={{ color: "#f5222d", fontSize: 18 }}
                    />
                  }
                </Tooltip>
              </Button>
            </Popconfirm>
            <Button
              onClick={() => {
                setEditListName(true);
              }}
              style={{ padding: 0, marginBottom: "5px" }}
              type="text"
            >
              <Tooltip title="Edit List Name" placement="top">
                {<EditOutlined style={{ color: "#1990ff", fontSize: 18 }} />}
              </Tooltip>
            </Button>
          </Space>
        )}
        {editListName === true && (
          <Form>
            <Form.Item>
              <Input
                style={{
                  width: "400px",
                  fontSize: "24px",
                  fontWeight: "600",
                  padding: 0,
                  lineHeight: "1.35",
                }}
                defaultValue={inExListName}
                onChange={(e) => {
                  setInExListName(e.target.value);
                }}
                maxLength={42}
              />
              <Button
                style={{ marginBottom: "5px" }}
                onClick={saveListName}
                disabled={!inExListName}
                type="text"
              >
                <Tooltip title="Save List Name" placement="bottom">
                  {<SaveOutlined style={{ color: "green", fontSize: 18 }} />}
                </Tooltip>
              </Button>
            </Form.Item>
          </Form>
        )}
      </Row>

      <Divider />
      <Row style={{ padding: "0px 0px 25px 0px" }}>
        <Button
          icon={<PlusCircleOutlined />}
          onClick={() => {
            setIsMPSSearchDrawerVisible(true);
          }}
          size="medium"
          style={{ marginRight: "15px" }}
        >
          <Tooltip title="Add a member to the list by finding P ID">
            Add by P ID
          </Tooltip>
        </Button>
        {!inExListTypeVisible && (
          <Button
            icon={<PlusCircleOutlined />}
            onClick={() => {
              showPIIFormDrawer();
            }}
            size="medium"
            style={{ marginRight: "15px" }}
          >
            <Tooltip title="Add a member to the list by entering personal identifiable information">
              Add by PII
            </Tooltip>
          </Button>
        )}
      </Row>
      <Space direction="vertical" size={15} style={{ width: "100%" }}>
        <Row>
          <Table
            columns={inExListMemberColumns}
            dataSource={inExListMembersData}
            loading={isLoading}
            virtual
            scroll={{ x: "max-content", y: "60vh" }}
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "50"],
            }}
          />
        </Row>
      </Space>
      <CreateInExMemberDrawer
        isVisible={isCreateListMemberByPIIDrawerVisible}
        setIsVisible={setIsCreateListMemberByPIIDrawerVisible}
        memberByPIIForm={memberByPIIForm}
        inExListMemberId={inExListMemberId}
        inExListId={inExListId}
        initialPIIvalues={initialPIIvalues}
        refreshPageInfo={refreshPageInfo}
      />
      <Drawer
        title="Attach PII Profile From MPS"
        onClose={() => setIsMPSSearchDrawerVisible(false)}
        visible={isMPSSearchDrawerVisible}
        width={900}
        bodyStyle={{ paddingBottom: 20 }}
      >
        <InExListMPSSearch setMPSSearchCallback={addMemberByPid} />
      </Drawer>
    </Col>
  );
};

export default InExListPIIGrid;
