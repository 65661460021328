import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import {
  getDecisionMakers,
  attachNewHVC,
  editHVC,
  removeHVC,
  getHVCs,
} from "../../../APIClient";
import { Link } from "react-router-dom";
import { Button, Modal, Form, Row, Col, Tag, Tooltip, Divider } from "antd";
import DMSSearch from "../../DMSSearch/DMSSearch";
import { FileTextOutlined, LinkOutlined } from "@ant-design/icons";
import { showDangerMessage, showSuccessMessage } from "../../../utils";
import DMSPersonCard from "../../DMSSearch/DMSPersonCard";
import ConnectionTypeComponent from "./ConnectionTypeComponent";

import {
  DeleteOutlined,
  EditFilled,
  FileSearchOutlined,
  GlobalOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { MPSProfileReviewModal } from "../MPSProfileReviewModal";
import HighValueConnectionsColocatedPane from "./HighValueConnectionsColocatedPane";
import { DecisionMakerContext } from "../DecisionMakerContext";

const HighValueConnections = () => {
  const [connectionTypeForm] = Form.useForm();
  const [connectionTypeForUpdateForm] = Form.useForm();
  const [connectionTypeForColocatedForm] = Form.useForm();
  const [hvcSearchForm] = Form.useForm();
  const [highValueConnections, setHighValueConnections] = useState([]);
  const decisionMaker = useSelector((state) => state.decisionMaker);
  const user = useSelector((state) => state.user);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConnectionTypeCompOpen, setIsConnectionTypeCompOpen] =
    useState(false);
  const [
    isConnectionTypeCompForUpdateOpen,
    setIsConnectionTypeCompForUpdateOpen,
  ] = useState(false);
  const [
    isConnectionTypeCompForColocatedOpen,
    setIsConnectionTypCompForColocateOpen,
  ] = useState(false);
  const [selectedDecisionMakerAsHVC, setSelectedDecisionMakerAsHVC] = useState(
    {}
  );
  const [
    selectedDecisionMakerAsHVCForUpdate,
    setSelectedDecisionMakerAsHVCForUpdate,
  ] = useState({});

  const [
    isMPSProfileReviewHVCModalVisible,
    setMPSProfileReviewHVCModalVisible,
  ] = useState(false);

  const [isColocatedPaneOpen, setIsColocatedPaneOpen] = useState(false);

  const formatURL = (url) => {
    return url?.startsWith("http://") || url?.startsWith("https://")
      ? url
      : "http://" + url;
  };

  const [personID, setPersonID] = useState();

  const decisionMakerPID = useSelector((state) => state.decisionMaker.p_id);
  const { setUpdateDecisionMakerQAStatus } = useContext(DecisionMakerContext);

  const handleProfileReviewModalOpen = (p_id) => {
    setPersonID(p_id);
    setMPSProfileReviewHVCModalVisible(true);
  };

  const attachHVCtoDecisionMaker = async (
    connection_type,
    is_current,
    company_name,
    web_url,
    title
  ) => {
    let response = await attachNewHVC(
      decisionMaker.id,
      selectedDecisionMakerAsHVC.id,
      connection_type,
      is_current,
      company_name || null,
      web_url || null,
      title || null,
      user.email
    );

    if (response.status === 201) {
      setUpdateDecisionMakerQAStatus(true);
      showSuccessMessage(
        selectedDecisionMakerAsHVC.first_name +
          " " +
          selectedDecisionMakerAsHVC.last_name +
          " is added as HVC to " +
          decisionMaker.first_name +
          " " +
          decisionMaker.last_name
      );
    } else {
      showDangerMessage(
        selectedDecisionMakerAsHVC.first_name +
          " " +
          selectedDecisionMakerAsHVC.last_name +
          " couldnt be added for " +
          decisionMaker.first_name +
          " " +
          decisionMaker.last_name
      );
    }
    fetchHVC(decisionMaker.id);
    setSelectedDecisionMakerAsHVC({});
    setIsConnectionTypeCompOpen(false);
    setIsConnectionTypCompForColocateOpen(false);
  };
  const updateHVCForDecisionMaker = async (
    connection_type,
    is_current,
    company_name,
    web_url,
    title
  ) => {
    let response = await editHVC(
      decisionMaker.id,
      selectedDecisionMakerAsHVCForUpdate.hvc_relationship_id,
      connection_type,
      is_current,
      company_name,
      web_url,
      title
    );
    if (response.status === 200) {
      setUpdateDecisionMakerQAStatus(true);
      showSuccessMessage(
        selectedDecisionMakerAsHVCForUpdate.first_name +
          " " +
          selectedDecisionMakerAsHVCForUpdate.last_name +
          " is updated successfully for " +
          decisionMaker.first_name +
          " " +
          decisionMaker.last_name
      );
    } else {
      showDangerMessage(
        selectedDecisionMakerAsHVCForUpdate.first_name +
          " " +
          selectedDecisionMakerAsHVCForUpdate.last_name +
          " could be updated for " +
          decisionMaker.first_name +
          " " +
          decisionMaker.last_name
      );
    }
    setHighValueConnections([]);
    await fetchHVC(decisionMaker.id);
    setIsConnectionTypeCompForUpdateOpen(false);
    setSelectedDecisionMakerAsHVCForUpdate({});
  };

  const deleteHVCForDecisionMaker = async (hvc) => {
    let response = await removeHVC(decisionMaker.id, hvc.hvc_relationship_id);

    if (response.status === 204) {
      setUpdateDecisionMakerQAStatus(true);
      showSuccessMessage(
        hvc.first_name +
          " " +
          hvc.last_name +
          " is deleted successfully for " +
          decisionMaker.first_name +
          " " +
          decisionMaker.last_name
      );
    } else {
      showDangerMessage(
        hvc.first_name +
          " " +
          hvc.last_name +
          " cant be deleted successfully for " +
          decisionMaker.first_name +
          " " +
          decisionMaker.last_name
      );
    }

    setHighValueConnections([]);
    await fetchHVC(decisionMaker.id);
  };

  const fetchHVC = async (id) => {
    try {
      const hvc = await getHVCs(id);

      if (hvc.length) {
        const ids = hvc.map((row) => row.hvc_decision_maker_id);
        const hvcs = await getDecisionMakers(ids);

        const result = hvc.reduce((arr, curr) => {
          const from = hvcs.filter((hvcsObj) => {
            return hvcsObj.id === curr.hvc_decision_maker_id;
          })[0];

          arr.push({ ...curr, ...from, hvc_relationship_id: curr.id });
          return arr;
        }, []);

        setHighValueConnections(
          result.sort((a, b) => a.first_name?.localeCompare(b.first_name))
        );
      }
    } catch (error) {
      alert(error.message);
    }
  };

  useEffect(() => {
    setHighValueConnections([]);
    fetchHVC(decisionMaker.id);
  }, [decisionMaker]);

  const getHeaderForDMSPersonCard = (row) => {
    return (
      <Row
        onMouseOver={() => {
          setSelectedDecisionMakerAsHVCForUpdate(row);
        }}
      >
        <Col style={{ marginRight: 20 }}>
          <Tag color="#108ee9" style={{ fontSize: 14 }}>
            {row.connection_type}
          </Tag>
        </Col>
        <Col>
          <Tooltip title="Open DM page">
            <Link to={`/decision-makers/${row.id}`} target="_blank">
              <UserOutlined
                style={{ fontSize: 20, marginRight: 20, color: "#1677ff" }}
              />
            </Link>
          </Tooltip>
        </Col>
        {row.p_id ? (
          <Col>
            <Tooltip title="Review PII">
              <FileSearchOutlined
                style={{ fontSize: 20, marginRight: 20, color: "#1677ff" }}
                onClick={() => {
                  handleProfileReviewModalOpen(row.p_id);
                }}
              />
            </Tooltip>
          </Col>
        ) : (
          <Col>
            <Tooltip title="No P ID Attached">
              <FileSearchOutlined
                style={{ fontSize: 20, marginRight: 20, color: "#8c8c8c" }}
              />
            </Tooltip>
          </Col>
        )}
        {row.web_url ? (
          <Col>
            <a
              href={formatURL(row.web_url)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Tooltip title="HVC Web URL">
                <GlobalOutlined
                  style={{ fontSize: 20, marginRight: 20, color: "#1677ff" }}
                />
              </Tooltip>
            </a>
          </Col>
        ) : (
          <Col>
            <Tooltip title="No URL">
              <GlobalOutlined
                style={{ fontSize: 20, marginRight: 20, color: "#8c8c8c" }}
              />
            </Tooltip>
          </Col>
        )}
        <Col>
          <Tooltip title="Edit HVC Details">
            <EditFilled
              style={{ fontSize: 20, marginRight: 20 }}
              onClick={() => {
                setIsConnectionTypeCompForUpdateOpen(true);
              }}
              onMouseEnter={() => {
                setSelectedDecisionMakerAsHVCForUpdate(row);
              }}
            />
          </Tooltip>
        </Col>
        <Col>
          <Tooltip title="Delete HVC">
            <DeleteOutlined
              style={{ fontSize: 20, color: "red" }}
              onClick={() => {
                deleteHVCForDecisionMaker(row);
              }}
            />
          </Tooltip>
        </Col>
      </Row>
    );
  };

  const getFooterForDMSPersonCard = (row) => {
    return (
      <>
        <Row className="person-card-header">
          <Col
            style={{
              width: 30,
            }}
          >
            <LinkOutlined />
          </Col>
          <Col>Connection Type</Col>
        </Row>
        <Row className="person-card-content">
          <Col
            style={{
              width: 30,
            }}
          ></Col>
          <Col>{row.connection_type}</Col>
        </Row>
        {row.title && (
          <>
            <Row className="person-card-header">
              <Col
                style={{
                  width: 30,
                }}
              >
                <FileTextOutlined />
              </Col>
              <Col>Title</Col>
            </Row>
            <Row className="person-card-content">
              <Col
                style={{
                  width: 30,
                }}
              ></Col>
              <Col>{row.title}</Col>
            </Row>
          </>
        )}
      </>
    );
  };
  return (
    <>
      <Row style={{ marginTop: 10, marginBottom: 30, width: 800 }}>
        <Button
          htmlType="button"
          type="primary"
          disabled={!decisionMakerPID}
          onClick={() => {
            setIsColocatedPaneOpen(!isColocatedPaneOpen);
          }}
        >
          {isColocatedPaneOpen ? "Hide Colocated" : "Show Colocated"}
        </Button>
        <Button
          htmlType="button"
          type="primary"
          onClick={() => {
            setIsModalOpen(true);
          }}
          style={{ marginLeft: 10 }}
        >
          Add High Value Connections
        </Button>
      </Row>
      <HighValueConnectionsColocatedPane
        isColocatedPaneOpen={isColocatedPaneOpen}
        setIsConnectionTypeCompOpen={setIsConnectionTypCompForColocateOpen}
        isConnectionTypeCompOpen={isConnectionTypeCompForColocatedOpen}
        hvcs={highValueConnections}
        attachHVCtoDecisionMaker={attachHVCtoDecisionMaker}
        selectedDecisionMakerAsHVC={selectedDecisionMakerAsHVC}
        setSelectedDecisionMakerAsHVC={setSelectedDecisionMakerAsHVC}
        connectionTypeForm={connectionTypeForColocatedForm}
        decisionMaker={decisionMaker}
      />
      <Divider />
      <Row style={{ marginTop: 10, marginBottom: 30, width: 800 }} />

      <div style={{ marginTop: 10, width: 800 }}>
        {highValueConnections.map((row, index) => (
          <DMSPersonCard
            index={index}
            key={index}
            person={row}
            linkToButtonCallback={null}
            header={() => {
              return getHeaderForDMSPersonCard(row);
            }}
            footer={() => {
              return getFooterForDMSPersonCard(row);
            }}
          />
        ))}
      </div>
      <Modal
        title={
          "Adding HVCs for " +
          decisionMaker.first_name +
          " " +
          decisionMaker.last_name
        }
        visible={isModalOpen}
        onOk={() => {
          setIsModalOpen(false);
          hvcSearchForm.resetFields();
        }}
        onCancel={() => {
          setIsModalOpen(false);
          hvcSearchForm.resetFields();
        }}
        afterClose={() => {
          setIsModalOpen(false);
          hvcSearchForm.resetFields();
        }}
        footer={() => {}}
        width={800}
        style={{ top: 20, zIndex: "1" }}
      >
        <DMSSearch
          setDMSSearchResultCallback={(person) => {
            setIsConnectionTypeCompOpen(true);
            setSelectedDecisionMakerAsHVC(person);
          }}
          inputForm={hvcSearchForm}
        ></DMSSearch>
      </Modal>
      <ConnectionTypeComponent
        setIsConnectionTypeCompOpen={setIsConnectionTypeCompOpen}
        decisionMaker={decisionMaker}
        selectedDecisionMakerAsHVC={selectedDecisionMakerAsHVC}
        connectionTypeForm={connectionTypeForm}
        isConnectionTypeCompOpen={isConnectionTypeCompOpen}
        attachHVCtoDecisionMaker={attachHVCtoDecisionMaker}
        updateHVCForDecisionMaker={null}
      />
      {selectedDecisionMakerAsHVCForUpdate && (
        <ConnectionTypeComponent
          setIsConnectionTypeCompOpen={setIsConnectionTypeCompForUpdateOpen}
          decisionMaker={decisionMaker}
          selectedDecisionMakerAsHVC={selectedDecisionMakerAsHVCForUpdate}
          connectionTypeForm={connectionTypeForUpdateForm}
          isConnectionTypeCompOpen={isConnectionTypeCompForUpdateOpen}
          attachHVCtoDecisionMaker={null}
          updateHVCForDecisionMaker={updateHVCForDecisionMaker}
        />
      )}
      <MPSProfileReviewModal
        personID={personID}
        isMPSProfileReviewModalVisible={isMPSProfileReviewHVCModalVisible}
        setIsMPSProfileReviewModalVisible={setMPSProfileReviewHVCModalVisible}
      />
    </>
  );
};

export default HighValueConnections;
