import React from "react";
import { Drawer, Table } from "antd";
import { getColumnSearchProps } from "../../util/columnSearchPropFactory";

export const ProjectFileNamesDrawer = ({
  project,
  isVisible,
  setIsVisible,
}) => {
  return (
    <Drawer
      title={`"${project?.name}" File Names`}
      width={600}
      onClose={() => {
        setIsVisible(false);
      }}
      visible={isVisible}
    >
      <Table
        style={{ width: "100%" }}
        columns={[
          {
            title: "File Name",
            dataIndex: "file_name",
            sorter: (a, b) => a?.file_name?.localeCompare(b?.file_name),
            ...getColumnSearchProps("file_name", "File Name"),
          },
        ]}
        dataSource={project?.file_names?.map((file_name) => ({ file_name }))}
        rowKey="id"
      />
    </Drawer>
  );
};
