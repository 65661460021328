import React, { useState, useEffect } from "react";
import { Table, Button, Popconfirm } from "antd";
import { getPii, removePidForStaffer } from "../../APIClient";

import {
  SwapOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { showSuccessMessage, titleCase } from "../../utils";
import { blue } from "@ant-design/colors";
const PIIprofile = ({
  p_id,
  setToggleDrawer,
  setCurrentStaffer,
  currentStaffer,
  setStaffers,
  staffers,
  setRerender,
  updateMostRecentAttempt,
}) => {
  const [person, setPerson] = useState(null);
  useEffect(() => {
    const getPIIProfile = async (p_id) => {
      const [piiProfile] = await getPii([p_id]);
      setPerson(piiProfile);
      return piiProfile;
    };
    getPIIProfile(p_id);
  }, [p_id]);

  const deletePid = async () => {
    try {
      await removePidForStaffer(currentStaffer?.source_id);
      updateMostRecentAttempt(currentStaffer);
      showSuccessMessage("Pii profile was removed successfully");
      setStaffers(
        staffers.map((staffer) => {
          if (staffer.source_id === currentStaffer?.source_id) {
            staffer.p_id = null;
          }
          return staffer;
        })
      );
      setCurrentStaffer({ ...currentStaffer, p_id: null });
      setRerender(true);
    } catch (error) {
      alert(error);
    }
  };

  let columns = [
    {
      title: "P_ID",
      dataIndex: "p_id",
      key: "p_id",
    },
    {
      title: "First name",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "Middle name",
      dataIndex: "middle_name",
      key: "middle_name",
    },
    {
      title: "Last name",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: "Date of birth",
      dataIndex: "",
      key: "date_of_birth",
      render: () => {
        const modifiedMonth =
          person?.month && person?.month < 10
            ? `0${person?.month}`
            : person?.month;
        const modifiedDay =
          person?.day && person?.day < 10 ? `0${person?.day}` : person?.day;
        return person?.year && modifiedMonth && modifiedDay
          ? `${person?.year}-${modifiedMonth}-${modifiedDay}`
          : person?.year && modifiedMonth
          ? `${person?.year}-${modifiedMonth}`
          : person?.year;
      },
    },
    {
      title: "Emails",
      dataIndex: "emails",
      key: "emails",
      render: (emails) =>
        emails?.length > 0 ? (
          emails.length === 1 ? (
            emails[0]
          ) : (
            <ul style={{ margin: 0, padding: 0 }}>
              {emails.map((e) => (
                <li style={{ listStyleType: "none" }} key={emails.indexOf(e)}>
                  {e}
                </li>
              ))}
            </ul>
          )
        ) : (
          "NA"
        ),
    },
    {
      title: "Phone numbers",
      dataIndex: "phone_numbers",
      key: "phone_numbers",
      render: (phone_numbers) =>
        phone_numbers?.length > 0 ? (
          phone_numbers.length === 1 ? (
            phone_numbers[0]
          ) : (
            <ul style={{ margin: 0, padding: 0 }}>
              {phone_numbers.map((e) => (
                <li
                  style={{ listStyleType: "none" }}
                  key={phone_numbers.indexOf(e)}
                >
                  {e}
                </li>
              ))}
            </ul>
          )
        ) : (
          "NA"
        ),
    },
    {
      title: "Addresses",
      dataIndex: "addresses",
      key: "addresses",
      render: (addresses) =>
        addresses?.length > 0 ? (
          addresses.length === 1 ? (
            addresses[0]
              ?.slice(0, 5)
              .filter((val) => val !== null && val)
              .join(", ")
          ) : (
            <ol style={{ margin: 0, padding: "0 0 0 10px" }}>
              {addresses.map((e) => (
                <li key={addresses.indexOf(e)}>
                  {e
                    .slice(0, 5)
                    .filter((val) => val !== null && val)
                    .join(", ")}
                </li>
              ))}
            </ol>
          )
        ) : (
          "NA"
        ),
    },
    {
      title: "",
      dataIndex: "",
      key: "p_id",
      render: () => (
        <Button
          primary="true"
          style={{ color: blue.primary, borderColor: blue.primary }}
          type="outlined"
          onClick={() => {
            setCurrentStaffer(currentStaffer);
            setToggleDrawer(true);
          }}
        >
          <SwapOutlined />
          Swap
        </Button>
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "buttons",
      render: (_, record) => (
        <Popconfirm
          title={`Are you sure you want to remove p_id for staffer ${titleCase(
            currentStaffer.staffer_first_name
          )} ${titleCase(currentStaffer.staffer_last_name)}`}
          icon={
            <QuestionCircleOutlined
              style={{
                color: "red",
              }}
            />
          }
          onConfirm={deletePid}
          okType="danger primary"
          okText="Yes"
        >
          <Button danger>
            <DeleteOutlined />
            Delete
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <Table
      rowKey={(record) => `record-${record?.id}`}
      bordered={true}
      pagination={false}
      loading={!person}
      columns={columns}
      dataSource={[person]}
    />
  );
};

export default PIIprofile;
