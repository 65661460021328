import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Button,
  Drawer,
  Form,
  Alert,
  Input,
  Select,
  DatePicker,
  Checkbox,
} from "antd";
import { LockOutlined, UnlockOutlined } from "@ant-design/icons";
import moment from "moment";
import {
  addProject,
  getAccountCampaigns,
  getClients,
  updateProject,
} from "../../APIClient";
import { isObjectEmpty } from "../../utils";

const ProjectDrawer = ({ onUpdate = null }) => {
  // redux state
  const project = useSelector((state) => state.project);
  const isVisible = useSelector((state) => state.isOpenProjectDrawer);
  const dspExportFilesExist = useSelector(
    (state) => !isObjectEmpty(state.dspExportFiles)
  );

  const dispatch = useDispatch();

  // local state
  const [projectName, setProjectName] = useState();
  const [billingClientHubSpotCompanyId, setBillingClientHubSpotCompanyId] =
    useState();
  const [clientNameOptions, setClientNameOptions] = useState([]);
  const [dueDate, setDueDate] = useState();
  const [status, setStatus] = useState();
  const [abbaCampaignId, setAbbaCampaignId] = useState();
  const [crossDmOverlap, setCrossDmOverlap] = useState(true);
  const [isDMTProject, setIsDMTProject] = useState(true);
  const [campaigns, setCampaigns] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const history = useHistory();

  const projectHasDspExports = project && dspExportFilesExist;

  const loadClientOptions = async () => {
    const resp = await getClients();
    let data = resp.filter((client) => client.is_billing);
    setClientNameOptions(data.filter((client) => !client?.is_old_name));
  };

  const loadCampaignOptions = async (hubspotCompanyId) => {
    try {
      const response = await getAccountCampaigns(hubspotCompanyId);
      setCampaigns(response);
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    setProjectName(project?.name);
    setBillingClientHubSpotCompanyId(
      project?.campaign_object?.billing_client_hubspot_company_id
    );
    setDueDate(project?.due_date ? moment(project.due_date) : null);
    setStatus(project?.status);
    setAbbaCampaignId(project?.campaign);
    setCrossDmOverlap(
      project?.cross_dm_overlap == null ? true : project?.cross_dm_overlap
    );
    setIsDMTProject(project ? project?.is_dmt : true);
  }, [project]);

  useEffect(() => {
    loadClientOptions();
  }, []);

  useEffect(() => {
    if (billingClientHubSpotCompanyId) {
      loadCampaignOptions(billingClientHubSpotCompanyId);
    }
  }, [billingClientHubSpotCompanyId, clientNameOptions]);

  const onSubmit = async () => {
    const due_date = dueDate ? new Date(dueDate).toLocaleDateString() : null;
    const params = {
      name: projectName,
      due_date,
      status,
      campaign: abbaCampaignId || null,
      cross_dm_overlap: crossDmOverlap,
      is_dmt: isDMTProject,
    };
    if (project) {
      const errors = await updateProject(project.id, params);
      if (errors && isObjectEmpty(errors)) {
        dispatch({ type: "set-is-open-project-drawer", payload: false });
        onUpdate();
      } else if (errors && Object.keys(errors).length > 0) {
        setFormErrors(errors);
      }
    } else {
      const [projectId, errors] = await addProject(params);
      if (errors && isObjectEmpty(errors)) {
        dispatch({ type: "set-is-open-project-drawer", payload: false });
        history.push(`/projects/${projectId}?hvc_config=open`);
      } else if (errors && Object.keys(errors).length > 0) {
        setFormErrors(errors);
      }
    }
  };

  const hasPackage = () => {
    return (
      project &&
      project.target_project_links &&
      project.target_project_links.filter((tpl) => tpl.package).length > 0
    );
  };

  return (
    <Drawer
      title={project ? "Update project" : "Create Project"}
      width={400}
      onClose={() =>
        dispatch({ type: "set-is-open-project-drawer", payload: false })
      }
      visible={isVisible}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div style={{ textAlign: "right" }}>
          <Button
            onClick={() =>
              dispatch({ type: "set-is-open-project-drawer", payload: false })
            }
            style={{ marginRight: 8 }}
          >
            Cancel
          </Button>
          <Button
            onClick={onSubmit}
            type="primary"
            disabled={!projectName || !abbaCampaignId}
          >
            {project ? "Save Changes" : "Create"}
          </Button>
        </div>
      }
    >
      <Form layout="vertical">
        {formErrors.non_field_errors && (
          <Alert message={formErrors.non_field_errors} type="error" showIcon />
        )}
        <Form.Item
          label="Name"
          validateStatus={formErrors.hasOwnProperty("name") ? "error" : null}
          help={formErrors.name || null}
          required={true}
          tooltip="Name of Project, not editable after Liveramp export has occurred."
        >
          <Input
            style={{ width: 300 }}
            placeholder="Project name"
            onChange={(e) => setProjectName(e.target.value)}
            value={projectName}
            disabled={projectHasDspExports}
          />
        </Form.Item>
        <Form.Item
          label="Account"
          tooltip="HubSpot client Account, not editable after Liveramp export has occurred."
        >
          <Select
            showSearch
            style={{ width: 300 }}
            placeholder="Account name"
            onChange={(value) => {
              setBillingClientHubSpotCompanyId(value);
              setAbbaCampaignId();
            }}
            value={billingClientHubSpotCompanyId}
            disabled={projectHasDspExports}
            filterOption={(input, option) =>
              (option?.label?.toLowerCase() ?? "").includes(
                input?.toLowerCase()
              )
            }
            options={clientNameOptions
              .map((client) => ({
                label: client.name,
                value: client.hubspot_company_id,
              }))
              .toSorted((a, b) => a.label.localeCompare(b.label))}
          />
        </Form.Item>
        <Form.Item
          label="ABBA Campaign"
          required={true}
          validateStatus={
            formErrors.hasOwnProperty("campaign") ? "error" : null
          }
          help={formErrors?.campaign || null}
          tooltip="ABBA Campaign, not editable after Liveramp export has occurred."
        >
          <Select
            showSearch
            allowClear
            style={{ width: 300 }}
            placeholder="ABBA Campaign"
            disabled={projectHasDspExports}
            value={abbaCampaignId}
            onChange={(value, option) => {
              setAbbaCampaignId(value);
            }}
            filterOption={(input, option) =>
              (option?.label?.toLowerCase() ?? "").includes(
                input?.toLowerCase()
              )
            }
            options={campaigns
              .map((campaign) => ({
                label: campaign.name,
                value: campaign.id,
              }))
              .toSorted((a, b) => a.label.localeCompare(b.label))}
          />
        </Form.Item>
        <Form.Item
          label="ABBA Campaign ID"
          tooltip="ABBA Campaign ID is not editable."
        >
          <Input value={abbaCampaignId} disabled={true} />
        </Form.Item>
        <Form.Item
          label="Client Campaign ID"
          tooltip="HubSpot Client Campaign ID is not editable."
        >
          <Input
            value={
              campaigns?.filter(
                (campaign) => campaign.id === abbaCampaignId
              )?.[0]?.hubspot_client_campaign_id
            }
            disabled={true}
          />
        </Form.Item>
        <Form.Item
          label="Due Date"
          validateStatus={
            formErrors.hasOwnProperty("due_date") ? "error" : null
          }
          help={formErrors?.due_date || null}
        >
          <DatePicker
            allowClear
            value={dueDate}
            onChange={(value) => setDueDate(value || null)}
          />
        </Form.Item>
        {project && (
          <Form.Item label="Project Status" help={formErrors?.status || null}>
            <Select
              style={{ width: 200 }}
              value={status}
              onChange={(val) => setStatus(val)}
            >
              <Select.Option value="IN_PROGRESS">
                <UnlockOutlined /> In Progress
              </Select.Option>
              <Select.Option value="COMPLETED">
                <LockOutlined /> Completed
              </Select.Option>
            </Select>
          </Form.Item>
        )}
        <Form.Item>
          <Checkbox
            onChange={(e) => setCrossDmOverlap(e.target.checked)}
            checked={crossDmOverlap}
            disabled={hasPackage()}
          >
            Cross Dm Overlap
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Checkbox
            onChange={(e) => setIsDMTProject(e.target.checked)}
            checked={isDMTProject}
          >
            This is a DMT Project
          </Checkbox>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default ProjectDrawer;
