export const buildTree = (arr, parent_id = 0) =>
  arr
    .filter((node) => node.parent_id === parent_id)
    .map((node) => {
      let children = buildTree(arr, node.id);
      let nodeCopy = { ...node, title: node.name, value: node.name };
      delete nodeCopy.name;
      return children?.length !== 0
        ? { ...nodeCopy, children, selectable: false }
        : nodeCopy;
    });

export const findNodeInTree = (tree, name) => {
  for (let node of tree) {
    if (node.title.toLowerCase() === name.toLowerCase()) return node;
    else if ("children" in node) {
      let result = findNodeInTree(node.children, name);
      if (result !== null) return result;
    }
  }
  return null;
};

export const findAllChildrenFromParent = (tree) => {
  const result = [];

  result.push(tree);
  const children = tree.children || [];
  children.forEach((child) => {
    const childResult = findAllChildrenFromParent(child);
    result.push(...childResult);
  });

  return result;
};

export const findPath = ({ id, title, children }, target) => {
  if (title === target) return [title];

  for (const child of children ?? []) {
    const leaf = findPath(child, target);

    if (leaf) return [title, ...leaf];
  }

  return null;
};
