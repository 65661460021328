import {
  Input,
  Col,
  Row,
  Spin,
  Button,
  Divider,
  Space,
  Table,
  Form,
} from "antd";
import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import {
  addCandidacyLinks,
  deleteExistingCandidacyLink,
  getCandidatesFromDonor,
  getExistingCandidacies,
} from "../../APIClient";
import {
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { createFilterOptionsFromData } from "../../utils";
import { DecisionMakerContext } from "./DecisionMakerContext";

export const DonationsData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [existingCandidates, setExistingCandidates] = useState([]);
  const [searchCandidatesResults, setSearchCandidatesResults] = useState([]);
  const [searching, setSearching] = useState(false);
  const [linkCandidacy, setLinkCandidacy] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [sourceFilterOptions, setSourceFilterOptions] = useState([]);
  const [stateFilterOptions, setStateFilterOptions] = useState([]);

  const user = useSelector((state) => state.user.email);
  const decisionMaker = useSelector((state) => state.decisionMaker);
  const { setUpdateDecisionMakerQAStatus } = useContext(DecisionMakerContext);

  const getExistingLinkedCandidacies = async () => {
    setIsLoading(true);
    try {
      const data = await getExistingCandidacies(decisionMaker.id);
      const dataWithKey = data.map((el) => {
        return {
          key: el.id,
          ...el,
        };
      });
      setExistingCandidates(dataWithKey);
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  const searchCandidates = async () => {
    setIsLoading(true);
    try {
      const candidatesResults = await getCandidatesFromDonor(
        firstName,
        lastName
      );
      const filteredResults = candidatesResults.filter((candidate) => {
        return !existingCandidates.some((existing) => {
          return candidate.id === existing.id;
        });
      });
      const filteredResultsWithKey = filteredResults.map((el) => {
        return {
          key: el.id,
          ...el,

          state: typeof el.state === "string" ? el.state.toUpperCase() : null,
        };
      });
      setSourceFilterOptions(
        createFilterOptionsFromData(filteredResultsWithKey, "source")
      );
      setStateFilterOptions(
        createFilterOptionsFromData(filteredResultsWithKey, "state")
      );
      setSearchCandidatesResults(filteredResultsWithKey);
    } catch (error) {
      alert(error);
    }

    setIsLoading(false);
  };

  const linkCandidaciesToDm = async () => {
    setIsLoading(true);
    try {
      await addCandidacyLinks(linkCandidacy, user, decisionMaker.id);
      await getExistingLinkedCandidacies();
      setLinkCandidacy([]);
      setSearchCandidatesResults([]);
      setUpdateDecisionMakerQAStatus(true);
      setSearching(false);
    } catch (error) {
      alert(error);
    }

    setIsLoading(false);
  };

  const deleteExistingCandidacy = async (record) => {
    try {
      await deleteExistingCandidacyLink(decisionMaker.id, record.id);
      await getExistingLinkedCandidacies();
      setUpdateDecisionMakerQAStatus(true);
    } catch (error) {
      alert(error);
    }
  };

  const ExistingCandidateColumns = [
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "Election Details",
      dataIndex: "election_details",
      key: "election_details",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => deleteExistingCandidacy(record)}>
            <DeleteOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  const SearchCandidateColumns = [
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
      filters: sourceFilterOptions,
      onFilter: (value, record) => record.source === value,
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      filters: stateFilterOptions,
      onFilter: (value, record) => record.state === value,
    },
    {
      title: "Election Details",
      dataIndex: "election_details",
      key: "election_details",
    },
  ];

  useEffect(() => {
    if (decisionMaker.id) {
      getExistingLinkedCandidacies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decisionMaker.id, decisionMaker.eas_profile_id]);

  return (
    <Spin spinning={isLoading}>
      {existingCandidates?.length > 0 && (
        <Divider plain orientation="left">
          Existing Linked Candidacies
        </Divider>
      )}
      {existingCandidates?.length > 0 && (
        <Table
          dataSource={existingCandidates}
          columns={ExistingCandidateColumns}
        />
      )}
      <Space direction="vertical" style={{ width: "100%" }}>
        {searching ? (
          <Row>
            <Form name="candidate_search_form">
              <Row>
                <Col>
                  <Form.Item name="first_name">
                    <Input
                      allowClear
                      placeholder="First Name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="last_name">
                    <Input
                      allowClear
                      placeholder="Last Name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item style={{ margin: "0", justifyContent: "center" }}>
                    <Button
                      htmlType="submit"
                      disabled={isLoading || (!firstName && !lastName)}
                      onClick={searchCandidates}
                      loading={isLoading}
                      icon={<SearchOutlined />}
                    >
                      Search
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>

            <Col>
              <Button onClick={() => setSearching(false)}>Cancel</Button>
            </Col>
          </Row>
        ) : (
          <Row>
            <Button
              block
              type="link"
              onClick={() => setSearching(true)}
              style={{ textAlign: "left" }}
              size="large"
            >
              <PlusOutlined /> Search and add new candidacies
            </Button>
          </Row>
        )}
        {searching && searchCandidatesResults?.length > 0 && (
          <Divider plain orientation="left">
            Search Results
          </Divider>
        )}
        {searching && searchCandidatesResults?.length > 0 && (
          <Table
            dataSource={searchCandidatesResults}
            columns={SearchCandidateColumns}
            rowSelection={{
              type: "checkbox",
              onChange: (key, vals) => setLinkCandidacy(vals),
            }}
            rowKey="id"
          />
        )}
        {searching && searchCandidatesResults?.length > 0 && (
          <Button
            disabled={linkCandidacy?.length <= 0}
            onClick={() => linkCandidaciesToDm()}
          >
            Link Candidacies
          </Button>
        )}
      </Space>
    </Spin>
  );
};
