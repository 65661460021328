const addDesiredSizeToState = (state, desiredSize) => {
  state[desiredSize.target_project_link] = {
    ...state?.[desiredSize.target_project_link],
    [desiredSize.audience_segment]: desiredSize,
  };
};

const restructureRows = (desiredSizes) => {
  const desiredSizesObj = {};
  desiredSizes.forEach((s) => {
    addDesiredSizeToState(desiredSizesObj, s);
  });
  return desiredSizesObj;
};

const reducer = (state = {}, action) => {
  switch (action.type) {
    case "get-desired-sizes":
      return restructureRows(action.payload);
    case "update-or-create-desired-size":
      return restructureRows(action.payload);
    default:
      return state;
  }
};

export default reducer;
