import React, { useState, useEffect } from "react";
import { Row, Select, Space, Table } from "antd";
import {
  createUserToClient,
  deleteUserToClient,
  getClients,
  getUserToClient,
  getUsers,
} from "../../APIClient";
import Navbar from "../Navbar/Navbar";
import { getColumnSearchProps } from "../../util/columnSearchPropFactory";

export const AccountMembership = () => {
  const [accountMembersData, setAccountMembersData] = useState([]);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSelectOptionsLoading, setIsSelectOptionsLoading] = useState(false);

  useEffect(() => {
    fetchTableData();
    fetchUsers();
  }, []);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      width: 200,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Name",
      dataIndex: "name",
      width: 500,
      sorter: (a, b) => a.name?.localeCompare(b?.name),
      ...getColumnSearchProps("name", "Name"),
    },
    {
      title: "Users",
      ...getColumnSearchProps("userToClients.user_object.email", "Users"),
      onFilter: (value, record) =>
        record.userToClients
          .map((u2c) => u2c.user_object.email.toLowerCase())
          .some((email) => email.includes(value.toLowerCase())),
      render: (value, row) => {
        return (
          <Select
            showSearch
            loading={isSelectOptionsLoading}
            optionFilterProp="label"
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="Select multiple values"
            options={users.map((u) => ({
              label: u.email,
              value: u.id,
            }))}
            value={row.userToClients.map(
              (userToClient) => userToClient.user_object.id
            )}
            onSelect={(userId) => onSelectUser(userId, row.id)}
            onDeselect={(userId) =>
              onDeselectUser(
                row.userToClients.find(
                  (userToClient) => userToClient.user === userId
                ).id
              )
            }
          />
        );
      },
    },
  ];

  const fetchTableData = async () => {
    setIsLoading(true);
    try {
      const userToClientRecords = await getUserToClient();
      const userToClientByClientId = userToClientRecords.reduce(
        (obj, r) => ({ ...obj, [r.client]: [...(obj[r.client] || []), r] }),
        {}
      );

      const clients = await getClients();
      const hubspotClients = clients.filter((c) => !!c.hubspot_company_id);
      hubspotClients.forEach(
        (c) => (c.userToClients = userToClientByClientId[c.id] || [])
      );
      setAccountMembersData(
        hubspotClients.sort((a, b) => a.name?.localeCompare(b?.name))
      );
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  const fetchUsers = async () => {
    setIsSelectOptionsLoading(true);
    try {
      const resp = await getUsers();
      setUsers(resp.sort((a, b) => a.email.localeCompare(b.email)));
    } catch (error) {
      alert(error);
    }
    setIsSelectOptionsLoading(false);
  };

  const onSelectUser = async (userId, clientId) => {
    try {
      await createUserToClient(userId, clientId);
      await fetchTableData();
    } catch (error) {
      alert(error);
    }
  };

  const onDeselectUser = async (userToClientId) => {
    try {
      await deleteUserToClient(userToClientId);
      await fetchTableData();
    } catch (error) {
      alert(error);
    }
  };

  return (
    <Navbar contentClass="landing-page-content">
      <Space direction="vertical" size={15} style={{ width: "100%" }}>
        <Row>
          <Table
            rowKey="id"
            style={{ width: "100%" }}
            columns={columns}
            dataSource={accountMembersData}
            loading={isLoading}
          />
        </Row>
      </Space>
    </Navbar>
  );
};
