export const destinationNameOptions = [
  "180byTWO",
  "1plusX",
  "4C Insights",
  "A+E Networks",
  "A4 Media",
  "AB Tasty",
  "acast.com",
  "Activation Full Stack Destination",
  "AcuityAds",
  "Acxiom",
  "Acxiom APAC",
  "Acxiom Marketing Services (MAE)",
  "Adam Z Test",
  "AddThis",
  "Adelphic",
  "Adform",
  "Admixer",
  "Adobe Ad Cloud (formerly TubeMogul)",
  "Adobe Audience Manager",
  "Adotmob",
  "AdQuick",
  "AdRoll",
  "AdSquare",
  "Adswizz",
  "AdTheorent",
  "Altice / A4 / NYI",
  "Amazon",
  "AMC Networks",
  "Ampersand",
  "Apple",
  "Appnique",
  "ATS Direct e2e demo",
  "ATS Direct TEST",
  "ATS-Direct-QE-TEST",
  "Audience Cloud - 4IN",
  "Audience Cloud - 9DC",
  "Audience Cloud - AOL",
  "Audience Cloud - EBY",
  "Audience Cloud - FBK",
  "Audience Cloud - LinkedIn",
  "Audience Cloud - MSN",
  "Audience Cloud - TWT",
  "Audience Cloud - YHO",
  "AudienceXpress",
  "Audigent",
  "Basis Technologies (FKA Centro DSP)",
  "Beachfront",
  "Beeswax",
  "Blis",
  "Blockboard",
  "Bombora",
  "Brandify",
  "Bridge Marketing",
  "Bridge Provider",
  "Cadent [4INFO]",
  "Canoe",
  "Captify Media",
  "Captiv8",
  "Cardlytics",
  "Caroline's Testing",
  "Centriply",
  "CERT - Connect:Select",
  "Chalice",
  "Chartable",
  "Chartboost",
  "CI Test Account [placeholder]",
  "Cisco",
  "Clarify Health",
  "Clear Channel",
  "Clickagy",
  "Cognitiv",
  "Column6",
  "Comcast",
  "comScore",
  "Conan LLC Destination",
  "Connect:Select",
  "Cox Media",
  "CraveLabs",
  "CreditKarma",
  "Crimtan",
  "Criteo",
  "Cross Pixel",
  "Crosswise",
  "D2 Media Sales",
  "Data + Math",
  "Datonics",
  "DeepIntent",
  "Dianomi",
  "Digital DPA",
  "Digital Turbine",
  "DirecTV",
  "Dish / Sling TV",
  "Disney Advertising Sales (fka Hulu)",
  "Display & Video 360 (DV360)",
  "DISQO",
  "District M",
  "Dosh",
  "Dotdash Meredith",
  "Drawbridge",
  "Dstillery",
  "Dynadmic",
  "Dynata",
  "Emodo (fka Placecast)",
  "EMX",
  "Encore",
  "ENG Test Environment",
  "Epsilon",
  "Equativ",
  "Eulerian Technologies",
  "Eyeota",
  "Facebook",
  "FastG8",
  "Fiksu",
  "Flashtalking",
  "Flipp",
  "Foursquare",
  "Fox TV",
  "FreeWheel Audience Manager",
  "FreeWheel MRM (Audience Integration Center)",
  "FuboTV",
  "Fyllo",
  "Gamoshi",
  "Gimbal",
  "Google | Data Marketplace",
  "Google Ad Manager",
  "Google Customer Match",
  "Google Display Network",
  "Gravy Analytics",
  "GroundTruth (formerly xAd)",
  "Hatim Test Destination",
  "Healthline Media",
  "Himaja test",
  "Hybrid.ai",
  "IBM Watson",
  "iHeart Media",
  "Index Exchange",
  "Inmar",
  "inMarket",
  "InMobi",
  "Innovid",
  "Integral",
  "iponweb",
  "iQM Corporation",
  "ironSource",
  "iSpot",
  "Jamloop",
  "Jivox",
  "Jun Group",
  "Kargo",
  "KBM",
  "Kinetic Social",
  "King/Activision",
  "Knorex",
  "Kyle H. Test DC ATS Direct",
  "LG Ad Solutions",
  "Liftoff Mobile",
  "LinkedIn",
  "LiveIntent",
  "LiveRamp Data Hub",
  "LiveRamp Data Science - UK",
  "LiveRamp Safe Haven - US",
  "LiveRamp TV",
  "Locala",
  "LoopMe",
  "Lotame",
  "Lucid",
  "Madhive",
  "Magnetic",
  "Magnite CTV Platform",
  "Magnite DV+ (Rubicon Project)",
  "Magnite Streaming (formerly SpotX)",
  "Marin Perfect Audience",
  "Marketcast",
  "Marketing Services - FBK",
  "Martin DSP",
  "Media.Net",
  "MediaWallah",
  "Medicx",
  "MGID",
  "Microsoft (Bing)",
  "Millennial Media",
  "Millward Brown Digital",
  "MiQ",
  "Mira (Reveal Mobile)",
  "Moloco Mobile",
  "Mountain",
  "mPlatform",
  "MSN",
  "N.Rich",
  "Nadine Destination Customer",
  "Nativo",
  "NBCU - AdSmart",
  "NBCUniversal",
  "Near",
  "NetSeer",
  "Neustar PlatformOne",
  "News Corp",
  "Nextdoor",
  "Nexxen (fka Amobee)",
  "Nick_Test_ATSDirect",
  "Nielsen Marketing Cloud",
  "Numberly",
  "Octillion Media",
  "Ogury",
  "OneView (Roku)",
  "OpenAP",
  "OpenX",
  "Oracle Data Cloud (BlueKai)",
  "Origin Media",
  "Outbrain",
  "Pandora",
  "Paramount (fka Viacom)",
  "Pelmorex Media",
  "Permutive",
  "Philo TV",
  "PinPoint",
  "Pinterest",
  "Place Exchange",
  "Plex",
  "Postie",
  "Powerlinks",
  "Prisma - ATS Direct",
  "Prodege",
  "Publica",
  "Publicis PeopleCloud",
  "PubMatic",
  "Pubworx",
  "pulsepoint",
  "PushSpring",
  "Quantcast UK + EU",
  "Quantcast US + AU + CA",
  "Quinstreet",
  "Quora",
  "Quotient Media Platform (fka Ubimo)",
  "Rakuten ",
  "Rakuten Rewards (FKA Ebates)",
  "RD&X",
  "Reddit",
  "Reset Digital",
  "Resonate Networks",
  "Rokt",
  "Roku",
  "RTB House",
  "RTBiQ",
  "Sabio",
  "Salesforce Audience Studio (fka Krux)",
  "Samsung Ads",
  "Sciera",
  "Scripps",
  "SDK-Bloomfilter",
  "Semasio",
  "Semcasting",
  "Sharethrough",
  "Simpli.fi",
  "Simulmedia",
  "SITO Mobile",
  "Skydeo",
  "Skyhook",
  "SmartyAds",
  "Snapchat",
  "Spectrum / Charter",
  "Sportradar",
  "Spotify",
  "SpringServe",
  "StackAdapt",
  "Steve Loudon Test",
  "Storygize",
  "Stream",
  "Stream_JinJie",
  "SundaySky",
  "Survey Sampling International",
  "TabMo",
  "Taboola",
  "Tapad",
  "TargetSpot",
  "Teads.tv",
  "The Trade Desk",
  "TikTok",
  "Tradelab",
  "Transparent.ly",
  "TransUnion (fka TruSignal)",
  "TripleLift",
  "TrueX",
  "Truthset",
  "Tubi",
  "Twitter",
  "UberMedia",
  "Undertone",
  "Univision",
  "Valassis Digital",
  "vdx.tv",
  "Verizon FiOS",
  "Verve Group",
  "Vevo",
  "Viant",
  "VideoAmp",
  "Videology",
  "Visible Measures",
  "Vistar Media",
  "Vizio Ads - For End-Clients",
  "Warner Brothers Discovery",
  "Xandr Invest (FKA AppNexus)",
  "Xandr Invest TV (formerly Xandr Data)",
  "Xandr Monetize",
  "Xaxis",
  "Xumo",
  "Yahoo! (fka Verizon Media)",
  "YouGov - US",
  "Zebestof",
  "Zemanta",
  "Zeta DSP (FKA Rocket Fuel/Sizmek)",
  "Ziff Davis",
];
