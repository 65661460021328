import { baseURL } from "../setTokenInterceptor";
import { setPostLoginRedirect } from "./postLoginRedirect";

export const goToLoginPage = () => {
  const loginPage = `${baseURL}/auth/login/`;
  if (window.location.href !== loginPage) {
    setPostLoginRedirect();
    window.location.href = loginPage;
  }
};
