import React, { useState } from "react";
import {
  Button,
  Drawer,
  InputNumber,
  Space,
  Spin,
  Typography,
  Form,
  Select,
  Checkbox,
} from "antd";
import { dedupeArray, makeAutocompleteOptions } from "../../../utils";
import { states } from "../../../constants";

export const EASSegmentDrawer = ({
  isVisible,
  setIsVisible,
  refreshPage,
  createAudienceMembers,
  message,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [desiredNumber, setDesiredNumber] = useState(100);
  const [selectedStates, setSelectedStates] = useState([]);
  const [isDCmetro, setIsDCmetro] = useState(false);

  const makeApiCall = async () => {
    setIsLoading(true);
    await createAudienceMembers(
      desiredNumber,
      dedupeArray(selectedStates.map((v) => v.toLowerCase())),
      isDCmetro
    );
    refreshPageAndDrawer();
    setIsVisible(false);
    setIsLoading(false);
  };

  const refreshPageAndDrawer = () => {
    refreshPage();
    setDesiredNumber(100);
    setSelectedStates([]);
    setIsDCmetro(false);
  };

  return (
    <Drawer
      title={message}
      onClose={() => setIsVisible(false)}
      visible={isVisible}
      bodyStyle={{ paddingBottom: 20 }}
      width={"900px"}
    >
      <p>
        First choose the location(s) you want to add audience members from. Then
        set the desired number of audience members to create.
      </p>
      <Form layout="vertical" style={{ paddingBottom: "10px" }}>
        <Form.Item label="States">
          <Select
            showSearch
            allowClear
            mode="multiple"
            placeholder="Select states"
            style={{ width: 350 }}
            onChange={(e) => setSelectedStates(e)}
            value={selectedStates}
            options={makeAutocompleteOptions(states)}
          />
        </Form.Item>
        <Form.Item>
          <Checkbox
            checked={isDCmetro}
            onChange={(e) => {
              setIsDCmetro(e.target.checked);
            }}
          >
            Include DC Metro Area (DMV Area)
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <DesiredNumberComponent
            desiredNumber={desiredNumber}
            setDesiredNumber={setDesiredNumber}
            addAudienceMembers={makeApiCall}
            isLoading={isLoading}
            message={message}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

const DesiredNumberComponent = ({
  desiredNumber,
  setDesiredNumber,
  addAudienceMembers,
  isLoading,
  message,
}) => {
  return (
    <Spin spinning={isLoading}>
      <Space>
        <Typography>Set Desired Number:</Typography>
        <InputNumber
          size="large"
          min={1}
          max={10000000}
          defaultValue={desiredNumber}
          value={desiredNumber}
          onChange={(val) => setDesiredNumber(val)}
        />
        <Button type={"primary"} htmlType="submit" onClick={addAudienceMembers}>
          {message}
        </Button>
      </Space>
    </Spin>
  );
};
