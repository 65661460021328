import React, { useEffect, useState } from "react";
import { Card, Drawer, Select } from "antd";
import { useSelector } from "react-redux";
import {
  attachListToProject,
  getAvailableListsPerAccount,
  getAvailableListsPerProject,
  removeListFromProject,
} from "../../../APIClient";

const ListView = ({
  availableLists,
  attachedLists,
  project_id,
  setAttachedLists,
  setUpdateUI,
}) => {
  const { Option } = Select;

  const onSelect = async (name) => {
    const { id } = availableLists.filter(
      ({ list_name }) => list_name === name
    )[0];
    const res = await attachListToProject(id, project_id);
    setUpdateUI(true);
    setAttachedLists([...attachedLists, res]);
  };

  const onClear = async (name) => {
    const listToRemove = attachedLists.filter(
      ({ list_name }) => list_name === name
    )[0];
    await removeListFromProject(listToRemove?.id);
    let attachedListCopy = attachedLists.filter(
      ({ list_name }) => list_name === name
    );
    setUpdateUI(true);
    setAttachedLists(attachedListCopy);
  };

  return (
    <Card>
      <h3>Inclusion Lists</h3>

      <Select
        mode="multiple"
        style={{
          width: "50%",
        }}
        placeholder="Add Inclusion List"
        onSelect={onSelect}
        onDeselect={onClear}
        defaultValue={attachedLists
          ?.filter(({ list_type }) => list_type === "INCLUSION")
          ?.map(({ list_name }) => list_name)}
      >
        {availableLists
          ?.filter(({ list_type }) => list_type === "INCLUSION")
          ?.map(({ id, list_name }) => (
            <Option key={id} label={list_name} value={list_name}>
              {list_name}
            </Option>
          ))}
      </Select>

      <h3 style={{ marginTop: 20 }}>Exclusion Lists</h3>

      <Select
        mode="multiple"
        style={{
          width: "50%",
        }}
        placeholder="Add Exclusion List"
        onSelect={onSelect}
        onDeselect={onClear}
        defaultValue={attachedLists
          ?.filter(({ list_type }) => list_type === "EXCLUSION")
          ?.map(({ list_name }) => list_name)}
      >
        {availableLists
          ?.filter(({ list_type }) => list_type === "EXCLUSION")
          ?.map(({ id, list_name }) => (
            <Option key={id} label={list_name} value={list_name}>
              {list_name}
            </Option>
          ))}
      </Select>

      <h3 style={{ marginTop: 20 }}>Company Exclusion Lists</h3>

      <Select
        mode="multiple"
        style={{
          width: "50%",
        }}
        placeholder="Add Company Exclusion List"
        onSelect={onSelect}
        onDeselect={onClear}
        defaultValue={attachedLists
          ?.filter(({ list_type }) => list_type === "COMPANY_EXCLUSION")
          ?.map(({ list_name }) => list_name)}
      >
        {availableLists
          ?.filter(({ list_type }) => list_type === "COMPANY_EXCLUSION")
          ?.map(({ id, list_name }) => (
            <Option key={id} label={list_name} value={list_name}>
              {list_name}
            </Option>
          ))}
      </Select>
    </Card>
  );
};

const InExclusionListDrawer = ({
  isVisible,
  setIsVisible,
  setInExListCount,
}) => {
  const closeDrawer = () => setIsVisible(false);
  const project = useSelector((state) => state.project);
  const project_id = project?.id;
  const [availableLists, setAvailableLists] = useState(null);
  const [attachedLists, setAttachedLists] = useState(null);
  const [updateUI, setUpdateUI] = useState(true);

  useEffect(() => {
    const getAvailableLists = async () => {
      if (project) {
        const accountLists = project?.campaign_object?.billing_client
          ? await getAvailableListsPerAccount(
              project?.campaign_object?.billing_client
            )
          : [];
        const projectLists = await getAvailableListsPerProject(project_id);
        let attachedListIds = projectLists.map(({ list }) => list);
        let availableListsFromServer = accountLists.filter(
          ({ id }) => attachedListIds.includes(id) !== true
        );
        setInExListCount(projectLists?.length);
        setAttachedLists(projectLists);
        setAvailableLists(availableListsFromServer);
        setUpdateUI(false);
      }
    };
    if (updateUI) getAvailableLists();
    // eslint-disable-next-line
  }, [project, attachedLists]);

  return (
    <Drawer
      title={`In/Exclusion Lists for Project (ID: ${project_id})`}
      onClose={closeDrawer}
      visible={isVisible}
      width={800}
      bodyStyle={{ paddingBottom: 20 }}
    >
      <ListView
        availableLists={availableLists}
        attachedLists={attachedLists}
        project_id={project_id}
        setAttachedLists={setAttachedLists}
        setUpdateUI={setUpdateUI}
      />
    </Drawer>
  );
};

export default InExclusionListDrawer;
