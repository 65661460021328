const reducer = (state = null, action) => {
  switch (action.type) {
    case "set-selected-hubspot-company-id":
      return !!action.payload ? Number(action.payload) : null;
    default:
      return state;
  }
};

export default reducer;
