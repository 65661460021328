import { useState } from "react";
import {
  Typography,
  Row,
  Button,
  Col,
  Space,
  Table,
  Tooltip,
  Divider,
  Popconfirm,
  Form,
  Input,
} from "antd";
import { getColumnSearchProps } from "../../util/columnSearchPropFactory";
import {
  EditOutlined,
  SaveOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  BankOutlined,
} from "@ant-design/icons";
import {
  updateInExList,
  deleteInExList,
  deleteExCompanyListMember,
} from "../../APIClient";
import { showSuccessMessage, sortColumnWithNulls } from "../../utils";
import { CreateExCompanyDrawer } from "./CreateExCompanyDrawer";

const InExListCompanyGrid = ({
  inExListCompanyData,
  inExListName,
  setInExListName,
  isLoading,
  inExListId,
  refreshPageInfo,
  redirectPageURL,
}) => {
  const [editListName, setEditListName] = useState(false);

  const { Title } = Typography;
  const [isAddCompanyDrawerVisable, setIsAddCompanyDrawerVisable] =
    useState(false);

  const saveListName = async () => {
    try {
      const response = await updateInExList(inExListId, inExListName);
      if (response.id) {
        showSuccessMessage("Successfully updated");
        refreshPageInfo(true, false);
        setEditListName(false);
      }
      if (response.error) {
        alert(response.error);
      }
    } catch (error) {
      alert(error);
    }
  };

  const deleteList = async () => {
    try {
      const response = await deleteInExList(inExListId);
      if (response) {
        showSuccessMessage("Successfully deleted");
        refreshPageInfo(true, false);
        redirectPageURL();
      }
      if (response.error) {
        alert(response.error);
      }
    } catch (error) {
      alert(error);
    }
  };

  const deleteMember = async (member_id) => {
    try {
      const response = await deleteExCompanyListMember(member_id);
      if (response) {
        showSuccessMessage("Successfully deleted");
        refreshPageInfo(false, true);
      }
      if (response.error) {
        alert(response.error);
      }
    } catch (error) {
      alert(error);
    }
  };

  const inExListCompanyColumns = [
    {
      title: "Action",
      key: "action",
      width: "25px",
      render: (record) => (
        <Space size="middle">
          <Popconfirm
            title="Are you sure to remove this company from the list?"
            onConfirm={() => {
              deleteMember(record.id);
            }}
            okText="Yes"
            okButtonProps={{ danger: true }}
            cancelText="No"
          >
            <Button type="text" style={{ padding: 0 }}>
              <Tooltip
                placement="bottom"
                title={`Delete company from ${record.list_name}`}
              >
                <DeleteOutlined style={{ color: "#f5222d", fontSize: 15 }} />
              </Tooltip>
            </Button>
          </Popconfirm>
        </Space>
      ),
    },

    {
      title: "Name",
      sorter: (a, b) => a.company_name?.localeCompare(b.company_name),
      ...getColumnSearchProps("company_name", "Company Name"),
      width: 200,
      render: (_, record) =>
        record.is_group ? (
          <strong>🏢 {record.company_name}</strong>
        ) : (
          record.company_name
        ),
    },
    {
      title: "City",
      dataIndex: "city",
      sorter: (a, b) => sortColumnWithNulls(a.city, b.city),
      width: 150,
    },
    {
      title: "State",
      dataIndex: "state",
      filterSearch: true,
      sorter: (a, b) => sortColumnWithNulls(a.state, b.state),
      onFilter: (value, record) => record.state === value,
      width: 80,
    },
    {
      title: "Size",
      dataIndex: "employee_count",
      sorter: (a, b) => a?.employee_count - b?.employee_count,
      width: 80,
    },
  ];

  return (
    <Col
      style={{
        backgroundColor: "white",
        paddingLeft: "15px",
        paddingRight: "35px",
      }}
      span={17}
    >
      <Row style={{ alignItems: "baseline", padding: "35px 0px 15px 0px" }}>
        <Title level={4} style={{ paddingRight: "10px" }}>
          <BankOutlined style={{ color: "#ff7875" }} />
        </Title>
        {editListName === false && (
          <Space>
            <Title level={3} style={{ paddingRight: "10px" }}>
              {inExListName}
            </Title>

            <Popconfirm
              title="Are you sure to delete the list?"
              onConfirm={deleteList}
              okText="Yes"
              okButtonProps={{ danger: true }}
              cancelText="No"
            >
              <Button style={{ padding: 0, marginBottom: "5px" }} type="text">
                <Tooltip title="Delete List" placement="top">
                  {
                    <DeleteOutlined
                      style={{ color: "#f5222d", fontSize: 18 }}
                    />
                  }
                </Tooltip>
              </Button>
            </Popconfirm>
            <Button
              onClick={() => {
                setEditListName(true);
              }}
              style={{ padding: 0, marginBottom: "5px" }}
              type="text"
            >
              <Tooltip title="Edit List Name" placement="top">
                {<EditOutlined style={{ color: "#1990ff", fontSize: 18 }} />}
              </Tooltip>
            </Button>
          </Space>
        )}
        {editListName === true && (
          <Form>
            <Form.Item>
              <Input
                style={{
                  width: "400px",
                  fontSize: "24px",
                  fontWeight: "600",
                  padding: 0,
                  lineHeight: "1.35",
                }}
                defaultValue={inExListName}
                onChange={(e) => {
                  setInExListName(e.target.value);
                }}
                maxLength={42}
              />
              <Button
                style={{ marginBottom: "5px" }}
                onClick={saveListName}
                disabled={!inExListName}
                type="text"
              >
                <Tooltip title="Save List Name" placement="bottom">
                  {<SaveOutlined style={{ color: "green", fontSize: 18 }} />}
                </Tooltip>
              </Button>
            </Form.Item>
          </Form>
        )}
      </Row>

      <Divider />
      <Row style={{ padding: "0px 0px 25px 0px" }}>
        <Button
          icon={<PlusCircleOutlined />}
          onClick={() => {
            setIsAddCompanyDrawerVisable(true);
          }}
          size="medium"
          style={{ marginRight: "15px" }}
        >
          Add Company
        </Button>
      </Row>
      <Space direction="vertical" size={15} style={{ width: "100%" }}>
        <Row>
          <Table
            columns={inExListCompanyColumns}
            dataSource={inExListCompanyData}
            loading={isLoading}
            virtual
            scroll={{ x: "max-content", y: "60vh" }}
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "50"],
            }}
          />
        </Row>
      </Space>
      <CreateExCompanyDrawer
        isVisible={isAddCompanyDrawerVisable}
        setIsVisible={setIsAddCompanyDrawerVisable}
        inExListId={inExListId}
        refreshPageInfo={refreshPageInfo}
      />
    </Col>
  );
};

export default InExListCompanyGrid;
