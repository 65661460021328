import React from "react";
import { Table } from "antd";
import { isObjectEmpty, roundNumberToNDecimals } from "../../../utils";

export const GeographyBreakdownTable = ({
  geographyBreakdown,
  isBreakdownLoading,
  project,
  showAllTargetsRow = false,
}) => {
  const idToNameMapping = project
    ? project.target_project_links.reduce((obj, t) => {
        return { ...obj, [t.id]: t.dm_profile?.full_name };
      }, {})
    : {};

  const allTargetsCount = isObjectEmpty(geographyBreakdown)
    ? {}
    : Object.values(geographyBreakdown).reduce(
        (agg_count, obj) => ({
          in_target_state: agg_count.in_target_state + obj.in_target_state,
          in_target_geo: agg_count.in_target_geo + obj.in_target_geo,
          in_dc_area: agg_count.in_dc_area + obj.in_dc_area,
          total: agg_count.total + obj.total,
        }),
        { in_target_state: 0, in_target_geo: 0, in_dc_area: 0, total: 0 }
      );

  // format geo breakdown for table
  const geographyBreakdownTableData = isObjectEmpty(geographyBreakdown)
    ? []
    : Object.entries(geographyBreakdown)
        .filter(([targetId, obj]) => idToNameMapping[targetId])
        .map(([targetId, obj]) => ({
          target: idToNameMapping[targetId],
          in_target_state:
            roundNumberToNDecimals(
              (obj.in_target_state / obj.total) * 100,
              1
            ).toFixed(1) || 0,
          in_target_geo:
            roundNumberToNDecimals(
              (obj.in_target_geo / obj.total) * 100,
              1
            ).toFixed(1) || 0,
          in_dc_area:
            roundNumberToNDecimals(
              (obj.in_dc_area / obj.total) * 100,
              1
            ).toFixed(1) || 0,
          total: obj.total,
        }));

  if (showAllTargetsRow) {
    geographyBreakdownTableData.unshift({
      target: "All Decision Makers",
      in_target_state:
        roundNumberToNDecimals(
          (allTargetsCount.in_target_state / allTargetsCount.total) * 100,
          1
        ).toFixed(1) || 0,
      in_target_geo:
        roundNumberToNDecimals(
          (allTargetsCount.in_target_geo / allTargetsCount.total) * 100,
          1
        ).toFixed(1) || 0,
      in_dc_area:
        roundNumberToNDecimals(
          (allTargetsCount.in_dc_area / allTargetsCount.total) * 100,
          1
        ).toFixed(1) || 0,
      total: allTargetsCount.total,
    });
  }

  const geographyBreakdownTableColumns = [
    {
      title: "Decision Maker Name",
      dataIndex: "target",
    },
    {
      title: "In DM's State",
      dataIndex: "in_target_state",
      render: (val) => `${val}%`,
    },
    {
      title: "In DM's Geography",
      dataIndex: "in_target_geo",
      render: (val) => `${val}%`,
    },
    {
      title: "In DC Area",
      dataIndex: "in_dc_area",
      render: (val) => `${val}%`,
    },
  ];

  return (
    <Table
      bordered
      className="breakdown-table"
      style={{ padding: "20px", paddingTop: "50px" }}
      pagination={false}
      loading={isBreakdownLoading}
      size="small"
      rowKey="target"
      rowClassName={(record, index) =>
        index === 0 ? "table-row-highlighted" : "table-row-light"
      }
      columns={geographyBreakdownTableColumns}
      dataSource={geographyBreakdownTableData.sort((a, b) => b.total - a.total)}
    />
  );
};
