import {
  Input,
  Col,
  Row,
  Spin,
  Button,
  Divider,
  Space,
  Table,
  Form,
} from "antd";
import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import {
  addLegislatorsOrCandidatesLinks,
  deleteExistingLegislatorsOrCandidateLink,
  getLinkedLegislatorsOrCandidates,
  getLegislatorOrCandidatesFromStaffer,
} from "../../APIClient";
import {
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { createFilterOptionsFromData } from "../../utils";
import { DecisionMakerContext } from "./DecisionMakerContext";

export const LegislatorsAndCandidatesData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [existingLegislatorsOrCandidates, setExistingLegislatorsOrCandidates] =
    useState([]);
  const [
    searchLegislatorOrCandidatesResults,
    setSearchLegislatorOrCandidatesResults,
  ] = useState([]);
  const [searching, setSearching] = useState(false);
  const [linkLegislatorOrCandidate, setLinkLegislatorOrCandidate] = useState(
    []
  );
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const user = useSelector((state) => state.user.email);
  const decisionMaker = useSelector((state) => state.decisionMaker);
  const [typeFilterOptions, setTypeFilterOptions] = useState([]);
  const [stateFilterOptions, setStateFilterOptions] = useState([]);
  const { setUpdateDecisionMakerQAStatus } = useContext(DecisionMakerContext);

  const getExistingLegislatorOrCandidates = async () => {
    setIsLoading(true);
    try {
      const data = await getLinkedLegislatorsOrCandidates(decisionMaker.id);
      const dataWithKey = data.map((el) => {
        return {
          key: el.id,
          ...el,
        };
      });
      setExistingLegislatorsOrCandidates(dataWithKey);
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  const searchLegislatorOrCandidates = async () => {
    setIsLoading(true);
    try {
      const legislatorOrCandidatesResults =
        await getLegislatorOrCandidatesFromStaffer(firstName, lastName);
      const filteredResults = legislatorOrCandidatesResults.filter(
        (candidate) => {
          return !existingLegislatorsOrCandidates.some((existing) => {
            return candidate.id === existing.id;
          });
        }
      );
      const filteredResultsWithKey = filteredResults.map((el) => {
        return {
          key: el.id,
          ...el,

          state: typeof el.state === "string" ? el.state.toUpperCase() : null,
        };
      });
      setTypeFilterOptions(
        createFilterOptionsFromData(filteredResultsWithKey, "type")
      );
      setStateFilterOptions(
        createFilterOptionsFromData(filteredResultsWithKey, "state")
      );
      setSearchLegislatorOrCandidatesResults(filteredResultsWithKey);
    } catch (error) {
      alert(error);
    }

    setIsLoading(false);
  };

  const linkLegislatorsOrCandidatesToDm = async () => {
    linkLegislatorOrCandidate.forEach(linkLegislatorOrCandidateToDm);
  };

  const linkLegislatorOrCandidateToDm = async (legislatorOrCandidate) => {
    setIsLoading(true);
    try {
      await addLegislatorsOrCandidatesLinks(
        legislatorOrCandidate,
        user,
        decisionMaker.id
      );
      await getExistingLegislatorOrCandidates();
      setSearchLegislatorOrCandidatesResults([]);
      setLinkLegislatorOrCandidate([]);
      setUpdateDecisionMakerQAStatus(true);
      setSearching(false);
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  const deleteExistingLegislatorOrCandidate = async (record) => {
    try {
      await deleteExistingLegislatorsOrCandidateLink(
        decisionMaker.id,
        record.id,
        record.type
      );
      await getExistingLegislatorOrCandidates();
      setUpdateDecisionMakerQAStatus(true);
    } catch (error) {
      alert(error);
    }
  };

  const ExistingLegislatorsOrCandidatesColumns = [
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      filters: typeFilterOptions,
      onFilter: (value, record) => record.source === value,
    },
    {
      title: "Office",
      dataIndex: "office",
      key: "office",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => deleteExistingLegislatorOrCandidate(record)}>
            <DeleteOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  const SearchLegislatorsOrCandidatesColumns = [
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      filters: typeFilterOptions,
      onFilter: (value, record) => record.type === value,
    },
    {
      title: "Office",
      dataIndex: "office",
      key: "office",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      filters: stateFilterOptions,
      onFilter: (value, record) => record.state === value,
    },
  ];

  useEffect(() => {
    if (decisionMaker.id) {
      getExistingLegislatorOrCandidates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decisionMaker.id, decisionMaker.eas_profile_id]);

  return (
    <Spin spinning={isLoading}>
      {existingLegislatorsOrCandidates?.length > 0 && (
        <Divider plain orientation="left">
          Existing Linked Candidates or Legislators
        </Divider>
      )}
      {existingLegislatorsOrCandidates?.length > 0 && (
        <Table
          dataSource={existingLegislatorsOrCandidates}
          columns={ExistingLegislatorsOrCandidatesColumns}
        />
      )}
      <Space direction="vertical" style={{ width: "100%" }}>
        {searching ? (
          <Row>
            <Form name="candidate_search_form">
              <Row>
                <Col>
                  <Form.Item fieldKey="first_name" key="first_name">
                    <Input
                      allowClear
                      placeholder="First Name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item fieldKey="last_name" key="last_name">
                    <Input
                      allowClear
                      placeholder="Last Name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item style={{ margin: "0", justifyContent: "center" }}>
                    <Button
                      htmlType="submit"
                      disabled={isLoading || (!firstName && !lastName)}
                      onClick={searchLegislatorOrCandidates}
                      loading={isLoading}
                      icon={<SearchOutlined />}
                    >
                      Search
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>

            <Col>
              <Button onClick={() => setSearching(false)}>Cancel</Button>
            </Col>
          </Row>
        ) : (
          <Row>
            <Button
              block
              type="link"
              onClick={() => setSearching(true)}
              style={{ textAlign: "left" }}
              size="large"
            >
              <PlusOutlined /> Search and add new candidacies
            </Button>
          </Row>
        )}
        {searching && searchLegislatorOrCandidatesResults?.length > 0 && (
          <Divider plain orientation="left">
            Search Results
          </Divider>
        )}
        {searching && searchLegislatorOrCandidatesResults?.length > 0 && (
          <Table
            dataSource={searchLegislatorOrCandidatesResults}
            columns={SearchLegislatorsOrCandidatesColumns}
            rowSelection={{
              type: "checkbox",
              onChange: (key, vals) => setLinkLegislatorOrCandidate(vals),
            }}
            rowKey="id"
          />
        )}
        {searching && searchLegislatorOrCandidatesResults?.length > 0 && (
          <Button
            disabled={linkLegislatorOrCandidate?.length <= 0}
            onClick={() => linkLegislatorsOrCandidatesToDm()}
          >
            Link Candidates or Legislator
          </Button>
        )}
      </Space>
    </Spin>
  );
};
