import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store";

import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Skeleton,
  Space,
  Tabs,
  Typography,
} from "antd";
import { CodeSandboxOutlined, PlusOutlined } from "@ant-design/icons";

import Navbar from "../Navbar";
import {
  createPackage,
  findPackagesByName,
  getPackageById,
} from "../../api/packages";
import { PackageForm } from "./PackageForm";
import { useParams } from "react-router-dom";

export const Packages = () => {
  const { packageId: packageIdFromUrl } = useParams();
  // local state
  const [searchName, setSearchName] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedPackageId, setSelectedPackageId] = useState(0);
  const [isCreatingPackage, setIsCreatingPackage] = useState(true);
  const [isPackagesLoading, setIsPackagesLoading] = useState(false);

  const packge = useSelector((state) => state.package);

  const dispatch = useDispatch();
  const { setPackageAction } = bindActionCreators(actionCreators, dispatch);

  const onSearch = async (searchName) => {
    setIsPackagesLoading(true);
    try {
      setSearchResults(await findPackagesByName(searchName));
    } catch (error) {
      alert(error);
    }
    setIsPackagesLoading(false);
  };
  const addPackage = async (packageObj) => {
    const createdPackage = await createPackage(packageObj);
    setPackageAction(createdPackage);
    setSelectedPackageId(createdPackage.id);
  };

  const getPackage = async (key) => {
    setPackageAction(await getPackageById(key));
    setSelectedPackageId(key);
    setIsCreatingPackage(false);
  };

  useEffect(() => {
    if (packageIdFromUrl) {
      getPackage(packageIdFromUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packageIdFromUrl]);

  return (
    <Navbar>
      <Row style={{ flex: "1 1 auto" }}>
        {/* package search column */}
        <Col span={8} style={{ backgroundColor: "#FAFCFF" }}>
          <Row
            justify="space-between"
            style={{ alignItems: "baseline", padding: "20px 24px" }}
          >
            <Col>
              <Typography.Title level={5}>
                <CodeSandboxOutlined /> Packages
              </Typography.Title>
            </Col>
            <Button
              style={{ width: "42px", height: "25px", padding: 0 }}
              onClick={() => {
                setIsCreatingPackage(true);
                setSelectedPackageId(null);
                setPackageAction(null);
              }}
            >
              <PlusOutlined style={{ fontSize: "16px" }} />
            </Button>
          </Row>

          <Row style={{ padding: "0 24px" }}>
            <Form layout="vertical" style={{ width: "100%" }}>
              <Form.Item>
                <Input
                  allowClear
                  placeholder="Package name"
                  onChange={(e) => setSearchName(e.target.value)}
                  value={searchName}
                />
              </Form.Item>
              <Button
                onClick={() => onSearch(searchName)}
                type="primary"
                disabled={!searchName}
                loading={isPackagesLoading}
              >
                Search
              </Button>
            </Form>
          </Row>

          {isPackagesLoading ? (
            <Space
              direction="vertical"
              size="large"
              className="full-width-loading-skeleton"
              style={{
                padding: "0px 24px",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Skeleton.Input
                active
                style={{ height: "40px", width: "100%" }}
              />
              <Skeleton.Input
                active
                style={{ height: "40px", width: "100%" }}
              />
              <Skeleton.Input
                active
                style={{ height: "40px", width: "100%" }}
              />
              <Skeleton.Input
                active
                style={{ height: "40px", width: "100%" }}
              />
            </Space>
          ) : (
            <>
              <Tabs
                tabPosition="left"
                moreIcon={null}
                tabBarStyle={{
                  width: "100%",
                  textAlign: "left",
                  maxHeight: "80vh",
                  overflowY: "auto",
                }}
                className="target-tabs"
                activeKey={selectedPackageId}
                onChange={async (key) => {
                  if (key !== "null") {
                    await getPackage(key);
                  }
                }}
              >
                <Row />
                {searchResults?.map((packge) => (
                  <Tabs.TabPane
                    tab={
                      <Row
                        justify="space-between"
                        style={{ alignItems: "center" }}
                      >
                        <Col span={19} style={{ whiteSpace: "initial" }}>
                          {packge?.name}
                        </Col>
                        <Col span={3}>
                          {/* <Dropdown.Button
                            className="target-dropdown"
                            size="small"
                            overlay={targetDropdown(dm)}
                            key="project_options"
                          /> */}
                        </Col>
                      </Row>
                    }
                    key={packge?.id}
                  />
                ))}
              </Tabs>
            </>
          )}
        </Col>

        {/* package info column */}
        <Col style={{ backgroundColor: "white" }} span={11}>
          <Row
            justify="space-between"
            style={{ alignItems: "baseline", padding: "20px 24px" }}
          >
            <Col>
              {!isCreatingPackage ? (
                <Typography.Title level={5}>
                  <CodeSandboxOutlined /> {`Edit Package`}
                </Typography.Title>
              ) : (
                <Typography.Title level={5}>
                  <CodeSandboxOutlined /> Add Package
                </Typography.Title>
              )}
            </Col>
          </Row>
          <Row>
            <PackageForm
              packge={packge}
              isNew={isCreatingPackage}
              setIsCreatingPackage={setIsCreatingPackage}
              setSelectedPackageId={setSelectedPackageId}
              searchPackages={onSearch}
              setPackageAction={setPackageAction}
              addPackage={addPackage}
            />
          </Row>
        </Col>

        {/* data display column? */}
        <Col span={5} style={{ backgroundColor: "#FAFCFF" }}></Col>
      </Row>
    </Navbar>
  );
};
