import { useState } from "react";
import { ExperienceCompanySelector } from "../DecisionMakers/ExperienceCompanySelector";
import { Space, Button, Drawer } from "antd";
import { showSuccessMessage } from "../../utils";
import { createExCompanyListMember } from "../../APIClient";

export const CreateExCompanyDrawer = ({
  isVisible,
  setIsVisible,
  inExListId,
  refreshPageInfo,
}) => {
  const [companyName, setCompanyName] = useState();
  const [companyId, setCompanyId] = useState();
  const [loading, setLoading] = useState();

  const setCompanyInfo = (companyInfoString) => {
    const companyInfoObject = JSON.parse(companyInfoString);
    const { company_name, company_id } = companyInfoObject;
    setCompanyName(company_name);
    setCompanyId(company_id);
  };

  const attachCompanyToList = async () => {
    try {
      const response = await createExCompanyListMember(inExListId, companyId);
      if (response.id) {
        showSuccessMessage("Company attached");
        setIsVisible(false);
        refreshPageInfo(false, true);
      }
      if (response.error) {
        alert(response.error);
      }
    } catch (error) {
      alert(error);
    }
  };

  return (
    <Drawer
      forceRender
      title={"Select company to add to company exclusion list"}
      onClose={() => {
        setIsVisible(false);
      }}
      visible={isVisible}
      bodyStyle={{ paddingBottom: 20 }}
      width={"900px"}
    >
      <Space>
        <ExperienceCompanySelector
          setCompanyInfo={setCompanyInfo}
          loading={loading}
          setloading={setLoading}
          isGroup={false}
          companyName={companyName}
          employeeCount={null}
          location={null}
        />

        <Button
          disabled={!companyName}
          type={"primary"}
          onClick={attachCompanyToList}
        >
          Add Company
        </Button>
      </Space>
    </Drawer>
  );
};
