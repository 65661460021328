import { Form, Row, Select, DatePicker, Spin } from "antd";
import React, { useState } from "react";
import moment from "moment";
import { debounce } from "lodash";
import { findEASInstitution } from "../../APIClient";

export const EducationForm = ({
  disabled = false,
  institutionName,
  setInstitutionName,
  setInstitutionId,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  isEditEducation = false,
  instituteCount = null,
}) => {
  const { RangePicker } = DatePicker;
  const [institution, setInstitution] = useState([]);
  const [loading, setLoading] = useState(false);

  const callFindEASinstitutionDebounced = debounce(async (val) => {
    setLoading(true);
    try {
      const data = await findEASInstitution(val);
      setInstitution(data);
    } catch (e) {
      alert(e);
    }
    setLoading(false);
  }, 1000);

  const onSearch = async (val) => {
    if (val && val.length > 2) {
      await callFindEASinstitutionDebounced(val);
    }
  };

  const setDates = (dates) => {
    setFromDate(
      !dates?.[0] ? null : moment(String(dates[0].year()) + "-01-01")
    );
    setToDate(!dates?.[1] ? null : moment(String(dates[1].year()) + "-12-31"));
  };

  const setInstitutionInfo = (institutionInfoString) => {
    const institutionInfoObject = JSON.parse(institutionInfoString);
    const { name, id } = institutionInfoObject;
    setInstitutionName(name);
    setInstitutionId(id);
  };
  const { Option } = Select;

  return (
    <Form>
      <Row>
        <Form.Item>
          <Select
            showSearch
            style={{ width: "550px" }}
            placeholder="Select a institution"
            optionFilterProp="children"
            filterOption={false}
            onChange={(val) => setInstitutionInfo(val)}
            notFoundContent={
              loading ? <Spin tip="loading" size="small" /> : null
            }
            onSearch={onSearch}
            value={
              isEditEducation
                ? institutionName + ` (${instituteCount})`
                : institutionName
            }
            disabled={isEditEducation}
          >
            {institution?.length > 0 && (
              <Option
                key="disabled result"
                disabled={true}
                style={{ fontWeight: "bold" }}
              >
                {institution?.length} results found
              </Option>
            )}
            {institution?.map((i) => (
              <Option key={i.id} value={JSON.stringify(i)}>
                {i.name} ({i.count?.toLocaleString()})
              </Option>
            ))}
            )
          </Select>
        </Form.Item>
      </Row>
      <Row>
        <Form.Item>
          <RangePicker
            disabled={disabled}
            value={[
              fromDate ? moment(fromDate, "YYYY") : undefined,
              toDate ? moment(toDate, "YYYY") : undefined,
            ]}
            onChange={(dates) => setDates(dates)}
            placeholder={["From Date", "To Date"]}
            format={"YYYY"}
            style={{ width: "450px" }}
            picker={"year"}
            allowEmpty={[false, true]}
          />
        </Form.Item>
      </Row>
    </Form>
  );
};
