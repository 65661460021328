import React from "react";
import { Col, Row, Select } from "antd";
import { states } from "../../constants";
import { makeAutocompleteOptions } from "../../utils";

export const StateDependentSelectionRow = ({
  selectedValues,
  setSelectedValues,
  selectedStates,
  setSelectedStates,
  valuesByState,
}) => {
  const options = selectedStates
    .map((s) => optionsForState(valuesByState, s))
    .flat();
  return (
    <>
      <Row gutter={10}>
        <Col>
          <Select
            showSearch
            allowClear
            mode="multiple"
            placeholder="States"
            style={{ width: 100 }}
            onChange={(selectedStates) => {
              setSelectedStates(selectedStates);
              setSelectedValues(
                selectedValues.filter((value) =>
                  selectedStates.includes(value.substr(0, 2).toUpperCase())
                )
              );
            }}
            value={selectedStates}
            options={makeAutocompleteOptions(states)}
          />
        </Col>
        <Col>
          <Select
            showSearch
            allowClear
            mode="multiple"
            disabled={!selectedStates}
            placeholder="Selection"
            style={{ width: 240 }}
            onChange={(val) => setSelectedValues(val)}
            value={selectedValues}
            options={options}
          />
        </Col>
      </Row>
    </>
  );
};

export function optionsForState(valuesByState, state) {
  return (valuesByState[state] || []).map((val) => ({
    value: `${state}-${val}`,
    label: `${state}-${val}`,
  }));
}
