import { useState, useContext } from "react";
import { Modal, Input } from "antd";
import { updateCompanyGroup } from "../../../APIClient";
import { showSuccessMessage } from "../../../utils";
import { CompanyGroupsContext } from "../CompanyGroupsPage";

export const RenameGroupModal = ({ setIsVisible, isVisible }) => {
  const [newGroupName, setNewGroupName] = useState();
  const { selectedGroup, configureGroup } = useContext(CompanyGroupsContext);

  const saveGroup = async () => {
    try {
      const response = await updateCompanyGroup(selectedGroup.id, newGroupName);
      if (response.id) {
        configureGroup(response.id);
        showSuccessMessage("Successfully updated");
      }
      if (response.error) {
        alert(response.error);
      }
    } catch (error) {
      alert(error);
    }
  };

  const onSave = () => {
    saveGroup();
    setIsVisible(false);
    setNewGroupName();
  };

  const handleCancel = () => {
    setIsVisible(false);
    setNewGroupName();
  };

  return (
    <Modal
      visible={isVisible}
      okText="Save"
      onCancel={handleCancel}
      onOk={onSave}
      okButtonProps={{
        disabled: newGroupName ? false : true,
      }}
      title={<>Rename Company Group</>}
    >
      <Input
        maxLength={100}
        value={newGroupName}
        onChange={(e) => setNewGroupName(e.target.value)}
        style={{ margin: "20px 0", width: "80%" }}
        placeholder="Enter group name"
      />
    </Modal>
  );
};
