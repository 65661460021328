import { useContext, useState, useEffect } from "react";
import { Divider } from "antd";
import { DecisionMakerContext } from "../DecisionMakerContext";
import {
  getDecisionMakerExperiences,
  getEASCompanies,
  updateDecisionMaker,
} from "../../../APIClient";
import { showSuccessMessage } from "../../../utils";
import { useSelector } from "react-redux";
import { DecisionMakerTitle } from "./DecisionMakerTitle";
import { DecisionMakerOrganization } from "./DecisionMakerOrganization";

export const OrganizationTitle = () => {
  const decisionMaker = useSelector((state) => state.decisionMaker);

  const { setUpdateDecisionMakerQAStatus } = useContext(DecisionMakerContext);
  const [isTitleLoading, setIsTitleLoading] = useState(false);
  const [isOrganizationLoading, setIsOrganizationLoading] = useState(false);
  const [dmTitle, setDmTitle] = useState(null);
  const [organization, setOrganization] = useState();

  useEffect(() => {
    if (decisionMaker.dm_title) {
      setDmTitle(decisionMaker.dm_title);
    }
    const fetchDMPrincipalExperience = async () => {
      setIsOrganizationLoading(true);
      try {
        const data = await getDecisionMakerExperiences(decisionMaker.id);
        const company_ids = data
          .filter((exp) => exp.is_principal)
          .map((exp) => exp.company_id);
        if (company_ids.length > 0) {
          const companies = await getEASCompanies(company_ids);
          if (companies.length > 0) {
            setOrganization(companies[0].company_name);
          }
        }
      } catch (error) {
        alert(error);
      }
      setIsOrganizationLoading(false);
    };
    if (decisionMaker.id) {
      fetchDMPrincipalExperience();
    }
  }, [decisionMaker]);

  const onSave = async () => {
    setIsTitleLoading(true);
    try {
      const resp = await updateDecisionMaker(decisionMaker.id, {
        dm_title: dmTitle || null,
      });
      if (resp.id) {
        setUpdateDecisionMakerQAStatus(true);
        showSuccessMessage("Successfully updated decision maker.", 8);
      }
    } catch (error) {
      alert(error);
    }
    setIsTitleLoading(false);
  };

  return (
    <>
      <DecisionMakerOrganization
        isLoading={isOrganizationLoading}
        organization={organization}
      />
      <DecisionMakerTitle
        isLoading={isTitleLoading}
        decisionMaker={decisionMaker}
        dmTitle={dmTitle}
        setDmTitle={setDmTitle}
        onSave={onSave}
      />
      <Divider />
    </>
  );
};
