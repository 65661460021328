import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store";
import { Button, Drawer, Spin, Tabs } from "antd";
import SegmentationUI from "./SegmentationUI";
import StartNewExportTab from "./ExportTab";
import CurrentExports from "./CurrentExport";

export const ExportUI = () => {
  // redux state

  const project = useSelector((state) => state.project);
  const isVisible = useSelector((state) => state.isOpenExportUIDrawer);
  const dspexportFiles = useSelector((state) => state.dspExportFiles);
  const dispatch = useDispatch();
  const {
    getS3DataUploadLogsAction,
    getProjectAction,
    getDSPExportFilesAction,
  } = bindActionCreators(actionCreators, dispatch);

  // local state

  const [isLoading, setIsLoading] = useState(false);

  const [tpls, setTpls] = useState([]);

  const [selectedTab, setSelectedTab] = useState("current_exports");
  const [refreshTpls, setRefreshTpls] = useState(true);
  const [dspFilenameBase, setDspFileNameBase] = useState(null);

  const updateUniqueGroupNames = (tpls) => {
    const groups = tpls.reduce((acc, tpl) => {
      tpl["groups"].forEach((group) => {
        acc[group] = true;
      });
      return acc;
    }, {});
    return Object.keys(groups).sort();
  };

  const uniqueGroupNames = updateUniqueGroupNames(tpls);

  useEffect(() => {
    if (project?.target_project_links) {
      const initialTpls = project?.target_project_links
        .filter((tpl) => !tpl.package && tpl.is_active)
        .map((tpl) => {
          return {
            id: tpl.id,
            key: tpl.id,
            name: `${tpl.dm_profile?.first_name} ${tpl.dm_profile?.last_name}`,
            groups: tpl.groups,
            individual: false,
            selected_for_export: tpl.selected_for_export,
          };
        });
      setTpls(initialTpls);
    }
    if (project?.dsp_filename_base) {
      setDspFileNameBase(project.dsp_filename_base);
    }
  }, [project]);

  const getS3DataUploadLogs = () => {
    try {
      getS3DataUploadLogsAction(project.id);
    } catch (error) {
      alert(error);
    }
  };

  const fetchProject = async () => {
    try {
      getProjectAction(project.id);
    } catch (error) {
      alert(error);
    }
  };

  const fetchDSPExportFiles = async () => {
    try {
      getDSPExportFilesAction(project.id);
    } catch (error) {
      alert(error);
    }
  };

  return (
    <Drawer
      title={"Export Audiences"}
      width={1000}
      onClose={() => {
        dispatch({
          type: "set-is-open-export-ui-drawer",
          payload: false,
        });
        fetchProject();
      }}
      visible={isVisible}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div style={{ textAlign: "right" }}>
          <Button
            onClick={() =>
              dispatch({
                type: "set-is-open-export-ui-drawer",
                payload: false,
              })
            }
            style={{ marginRight: 8 }}
          >
            Cancel
          </Button>
        </div>
      }
    >
      <Tabs activeKey={selectedTab} onTabClick={setSelectedTab}>
        <Tabs.TabPane key="segmentation" tab={"Segmentation"}>
          <SegmentationUI
            tpls={tpls}
            setTpls={setTpls}
            uniqueGroupNames={uniqueGroupNames}
            setRefreshTpls={setRefreshTpls}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="current_exports" tab={"Current Exports"}>
          <Spin spinning={isLoading}>
            <CurrentExports getS3DataUploadLogs={getS3DataUploadLogs} />
          </Spin>
        </Tabs.TabPane>
        <Tabs.TabPane key={"new_export"} tab={"Start New Export"}>
          <Spin spinning={isLoading}>
            <StartNewExportTab
              setIsLoading={setIsLoading}
              setSelectedTab={setSelectedTab}
              dspexportFiles={dspexportFiles}
              fetchDSPExportFiles={fetchDSPExportFiles}
              getS3DataUploadLogs={getS3DataUploadLogs}
              setRefreshTpls={setRefreshTpls}
              refreshTpls={refreshTpls}
              dspFilenameBase={dspFilenameBase}
              setDspFileNameBase={setDspFileNameBase}
            />
          </Spin>
        </Tabs.TabPane>
      </Tabs>
    </Drawer>
  );
};

export default ExportUI;

export const extractTargetOrGroupNameFromPath = (path, prefix) => {
  const file = path.split("/")[2];
  const file_without_prefix = file.replace(prefix, "");
  const tokens = file_without_prefix.split("_");
  return tokens
    .slice(1, tokens.length - 2)
    .join("-")
    .toLowerCase();
};
