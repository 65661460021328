import {
  Input,
  Upload,
  Row,
  Spin,
  Button,
  Divider,
  Space,
  Table,
  message,
  Popconfirm,
  Radio,
  Select,
  Popover,
} from "antd";
import moment from "moment";
import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import {
  deleteDonationsData,
  getExistingDonationUploads,
  uploadDonationsDataV2,
  updateDonorMetaData,
} from "../../APIClient";
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { DecisionMakerContext } from "./DecisionMakerContext";

export const UploadedDonationsDataV2 = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [existingUploads, setExistingUploads] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [updatingMetaData, setUpdatingMetaData] = useState(false);
  const [updatingMetaDataId, setUpdatingMetaDataId] = useState(null);
  const [note, setNote] = useState();
  const [years, setYears] = useState();
  const [editedNote, setEditedNote] = useState();
  const [editedYears, setEditedYears] = useState();
  const [donorFileColumns, setDonorFileColumns] = useState();
  const [selectedUploadState, setSelectedUploadState] = useState(null);
  const { TextArea } = Input;

  const currentDonorStates = [
    { value: "ca", label: "CA" },
    { value: "fl", label: "FL" },
    { value: "ga", label: "GA" },
    { value: "mt", label: "MT" },
    { value: "nc", label: "NC" },
    { value: "nj", label: "NJ" },
    { value: "ny", label: "NY" },
    { value: "oh", label: "OH" },
    { value: "tx", label: "TX" },
  ];

  const user = useSelector((state) => state.user.email);
  const decisionMaker = useSelector((state) => state.decisionMaker);
  const { setUpdateDecisionMakerQAStatus } = useContext(DecisionMakerContext);

  const getExistingUploads = async () => {
    setIsLoading(true);
    try {
      const data = await getExistingDonationUploads(decisionMaker.id);
      let data_with_key = await data
        .map((el) => {
          return {
            key: el.id,
            ...el,
            state_config: el.state_config?.toUpperCase(),
          };
        })
        .filter((item) => item?.version === "v2");
      setExistingUploads(
        data_with_key.sort(
          (a, b) => moment(b.upload_time) - moment(a.upload_time)
        )
      );
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  const uploadFile = async () => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("dm_id", decisionMaker.id);
      formData.append("user_id", user);
      formData.append("file_name", fileList[0].name);
      formData.append("csv_file", fileList[0]);
      formData.append("file_description", note || "");
      formData.append("years", years || "");
      formData.append("state_config", selectedUploadState);
      await uploadDonationsDataV2(formData);
      message.success("Donation data uploaded successfully.");
      setFileList([]);
      setSelectedUploadState(null);
      setNote(null);
      setYears(null);
      setUpdateDecisionMakerQAStatus(true);
    } catch (error) {
      alert(error);
      message.error("Donation upload failed");
    }
    getExistingUploads();
    setIsLoading(false);
  };

  const deleteDonationRecord = async (record) => {
    try {
      await deleteDonationsData(
        decisionMaker?.id,
        record.candidate_id,
        record.id
      );
      await getExistingUploads();
      setUpdateDecisionMakerQAStatus(true);
    } catch (error) {
      alert(error);
    }
  };

  const isEditingUploadMetaData = (record_id) => {
    return updatingMetaData && updatingMetaDataId === record_id;
  };

  const editUploadMetadata = (record) => {
    setUpdatingMetaData(true);
    setUpdatingMetaDataId(record.id);
    setEditedNote(record.description);
    setEditedYears(record.years);
  };

  const cancelEditUploadMetadata = () => {
    setUpdatingMetaData(false);
    setUpdatingMetaDataId(null);
    setEditedNote(null);
    setEditedYears(null);
  };

  const saveUpdatedUploadMetadata = async () => {
    try {
      await updateDonorMetaData(updatingMetaDataId, editedNote, editedYears);
      await getExistingUploads();
      setUpdatingMetaData(false);
      setUpdatingMetaDataId(null);
      setEditedNote(null);
      setEditedYears(null);
    } catch (error) {
      alert(error);
    }
  };

  const toggleColumns = (value) => {
    switch (value) {
      case "metrics_columns":
        return metricsColumns;
      case "metadata_columns":
        return metaDataColumns;
      default:
        return metaDataColumns;
    }
  };

  const metricsColumns = [
    {
      title: "File Name",
      dataIndex: "file_name",
      key: "file_name",
      width: 250,
      ellipsis: true,
      render: (_, record) => (
        <Popover content={record.file_name}>{record.file_name}</Popover>
      ),
    },
    {
      title: "Total Records",
      dataIndex: "file_rows",
      key: "file_rows",
      width: 120,
    },
    {
      title: "Records Processed",
      dataIndex: "processed_records",
      key: "processed_records",
      width: 80,
    },
    {
      title: "Invalid Donation Records",
      dataIndex: "invalid_donation_row_count",
      key: "invalid_donation_row_count",
      width: 80,
    },
    {
      title: "Invalid Name Records",
      dataIndex: "invalid_name_row_count",
      key: "invalid_name_row_count",
      width: 80,
    },
    {
      title: "Invalid Address Records",
      dataIndex: "invalid_address_row_count",
      key: "invalid_address_row_count",
      width: 80,
    },
    {
      title: "Uploaded Time",
      dataIndex: "upload_time",
      key: "upload_time",
      render: (value) => moment(value).format("MM-DD-YYYY HH:mm"),
      width: 160,
    },
  ];

  const metaDataColumns = [
    {
      title: "File Name",
      dataIndex: "file_name",
      key: "file_name",
      width: 250,
      ellipsis: true,
      render: (_, record) => (
        <Popover content={record.file_name}>{record.file_name}</Popover>
      ),
    },
    {
      title: "State",
      dataIndex: "state_config",
      width: 70,
      key: "state_config",
    },
    {
      title: "Uploaded By",
      dataIndex: "user_id",
      key: "user_id",
      width: 120,
      ellipsis: true,
    },
    {
      title: "Notes",
      dataIndex: "description",
      key: "description",
      width: 200,
      render: (value, record) =>
        isEditingUploadMetaData(record.id) ? (
          <TextArea
            maxLength={80}
            value={editedNote}
            defaultValue={record.description}
            onChange={(e) => setEditedNote(e.target.value)}
            autoSize
          ></TextArea>
        ) : (
          record.description
        ),
    },
    {
      title: "Year(s)",
      dataIndex: "years",
      key: "years",
      width: 120,
      render: (value, record) =>
        isEditingUploadMetaData(record.id) ? (
          <Input
            maxLength={20}
            value={editedYears}
            defaultValue={record.years}
            onChange={(e) => setEditedYears(e.target.value)}
          ></Input>
        ) : (
          record.years
        ),
    },
    {
      title: "Action",
      key: "action",
      width: 80,
      render: (_, record) =>
        isEditingUploadMetaData(record.id) ? (
          <Space size="middle">
            <Button
              onClick={() => saveUpdatedUploadMetadata(record)}
              type="text"
              style={{ padding: 0 }}
            >
              <SaveOutlined style={{ color: "#389e0d" }} />
            </Button>
            <Button
              onClick={() => cancelEditUploadMetadata(record)}
              type="text"
              style={{ padding: 0 }}
            >
              <CloseOutlined />
            </Button>
          </Space>
        ) : (
          <Space size="middle">
            <Button
              onClick={() => editUploadMetadata(record)}
              type="text"
              style={{ padding: 0 }}
            >
              <EditOutlined style={{ color: "#1677ff" }} />
            </Button>
            <Popconfirm
              title="Are you sure?"
              onConfirm={() => deleteDonationRecord(record)}
            >
              <Button type="text" style={{ padding: 0 }}>
                <DeleteOutlined style={{ color: "#f5222d" }} />
              </Button>
            </Popconfirm>
          </Space>
        ),
    },
  ];

  useEffect(() => {
    if (decisionMaker.id) {
      getExistingUploads();
      setDonorFileColumns(metaDataColumns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decisionMaker.id, decisionMaker.eas_profile_id]);

  return (
    <>
      <Row>
        <Radio.Group
          onChange={(e) => setDonorFileColumns(e.target.value)}
          defaultValue="metadata_columns"
          buttonStyle="solid"
          style={{ float: "left" }}
        >
          <Radio.Button value="metadata_columns">Files</Radio.Button>
          <Radio.Button value="metrics_columns">File Metrics</Radio.Button>
        </Radio.Group>
      </Row>
      <Row>
        <Spin spinning={isLoading}>
          <Space direction="vertical" style={{ width: "100%" }}>
            {existingUploads?.length > 0 && (
              <Divider plain orientation="left">
                Existing Donations Uploads
              </Divider>
            )}

            {existingUploads?.length > 0 && (
              <Table
                size="small"
                dataSource={existingUploads}
                columns={toggleColumns(donorFileColumns)}
                virtual
                scroll={{ x: 1000, y: 600 }}
              />
            )}
            <Divider plain orientation="left">
              Upload a new file
            </Divider>

            {uploading ? (
              <>
                <Row>
                  <Upload
                    beforeUpload={(file) => {
                      const isValidFormat =
                        file.type === "text/csv" || file.type === "text/plain";
                      if (!isValidFormat) {
                        message.error(
                          `${file.name} is not a valid format file.`
                        );
                        return isValidFormat;
                      }
                      setFileList([file]);
                      return false;
                    }}
                    onRemove={(file) => {
                      setFileList([]);
                    }}
                    accept=".csv, .txt, .tab"
                    fileList={fileList}
                  >
                    <Button>
                      <PlusOutlined />
                      Select File
                    </Button>
                  </Upload>
                </Row>
                <Row>
                  <Space size="middle">
                    <Select
                      options={currentDonorStates}
                      style={{ minWidth: "120px" }}
                      placeholder="Select State"
                      value={selectedUploadState}
                      onChange={(value) => setSelectedUploadState(value)}
                    />
                    <Input
                      placeholder="Year(s)"
                      value={years}
                      onChange={(event) => setYears(event.target.value)}
                      maxLength={20}
                    />
                    <TextArea
                      placeholder="Notes"
                      value={note}
                      onChange={(event) => setNote(event.target.value)}
                      maxLength={80}
                      style={{ minWidth: 350 }}
                      autoSize={true}
                    />
                  </Space>
                </Row>
                <Row>
                  <Button
                    onClick={uploadFile}
                    disabled={!selectedUploadState || fileList.length === 0}
                  >
                    Upload File
                  </Button>
                </Row>
              </>
            ) : (
              <Row>
                <Button
                  block
                  type="link"
                  onClick={() => setUploading(true)}
                  style={{ textAlign: "left" }}
                  size="large"
                >
                  <PlusOutlined /> Upload New Donations
                </Button>
              </Row>
            )}
          </Space>
        </Spin>
      </Row>
    </>
  );
};
