import React from "react";
import Navbar from "../Navbar/Navbar";
import { Button, Tabs } from "antd";
import AudienceSegment from "./AudienceSegment";
import { ProjectDecisionMakers } from "./ProjectDecisionMakers";
import { useParams } from "react-router-dom";
import { getProjectReport } from "../../APIClient";

const SingleAccountProject = () => {
  const { hubspotCompanyId, projectId } = useParams();

  const onClickReport = async () => {
    try {
      await getProjectReport(hubspotCompanyId, projectId);
    } catch (error) {
      alert(error);
    }
  };

  return (
    <Navbar contentClass="landing-page-content">
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          rel="noopener noreferrer"
          target="_blank"
          href={`https://prod-useast-b.online.tableau.com/#/site/projectapplecart/views/AdvocacyReportv4-Beta/Page1-Overview?Client%20Name=&Project%20Id=${projectId}`}
        >
          View Audience Report
        </Button>
        <Button style={{ marginLeft: 10 }} onClick={() => onClickReport()}>
          Download Project Information
        </Button>
      </div>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab={<b>Decision Makers</b>} key="1">
          <ProjectDecisionMakers />
        </Tabs.TabPane>
        <Tabs.TabPane tab={<b>Audience Segments</b>} key="2">
          <AudienceSegment />
        </Tabs.TabPane>
      </Tabs>
    </Navbar>
  );
};

export default SingleAccountProject;
