import React, { useState } from "react";
import {
  Button,
  Drawer,
  InputNumber,
  Space,
  Spin,
  Typography,
  Form,
} from "antd";

export const SegmentDrawer = ({
  isVisible,
  setIsVisible,
  refreshPage,
  createAudienceMembers,
  message,
  children,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [desiredNumber, setDesiredNumber] = useState(100);

  const makeApiCall = async () => {
    setIsLoading(true);
    await createAudienceMembers(desiredNumber);
    refreshPageAndDrawer();
    setIsVisible(false);
    setIsLoading(false);
  };

  const refreshPageAndDrawer = () => {
    refreshPage();
    setDesiredNumber(100);
  };

  return (
    <Drawer
      title={message}
      onClose={() => setIsVisible(false)}
      visible={isVisible}
      bodyStyle={{ paddingBottom: 20 }}
      width={"900px"}
    >
      <>
        {children}

        <DesiredNumberComponent
          desiredNumber={desiredNumber}
          setDesiredNumber={setDesiredNumber}
          addAudienceMembers={makeApiCall}
          isLoading={isLoading}
          message={message}
        />
      </>
    </Drawer>
  );
};

const DesiredNumberComponent = ({
  desiredNumber,
  setDesiredNumber,
  addAudienceMembers,
  isLoading,
  message,
}) => {
  return (
    <Form layout="vertical" style={{ paddingBottom: "10px" }}>
      <Spin spinning={isLoading}>
        <Space>
          <Typography>Set Desired Number:</Typography>
          <InputNumber
            size="large"
            min={1}
            max={10000000}
            defaultValue={desiredNumber}
            value={desiredNumber}
            onChange={(val) => setDesiredNumber(val)}
          />
          <Button
            type={"primary"}
            htmlType="submit"
            onClick={addAudienceMembers}
          >
            {message}
          </Button>
        </Space>
      </Spin>
    </Form>
  );
};
