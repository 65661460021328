import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store";

import { Button, Result, Row } from "antd";
import { FrownOutlined } from "@ant-design/icons";
import ProjectDrawer from "../ProjectList/ProjectDrawer";
import Navbar from "../Navbar";
import "./style/Project.css";

import { AudienceBreakdownColumn } from "./AudienceBreakdown/AudienceBreakdownColumn";
import { SegmentsColumn } from "./SegmentsColumn/SegmentsColumn";
import { TargetsColumn } from "./TargetsColumn";
import ExportUI from "../ExportAudiencesDrawer/ExportUI";

const Project = () => {
  const { projectId } = useParams();
  // redux state
  const project = useSelector((state) => state.project);
  const selectedTargetId = useSelector((state) => state.selectedTargetId);

  const dispatch = useDispatch();
  const {
    getAudienceCreationLogsAction,
    getProjectAction,
    getSegmentsAction,
    getS3DataUploadLogsAction,
  } = bindActionCreators(actionCreators, dispatch);

  const history = useHistory();
  const effectiveTargetId =
    selectedTargetId === "all" ? undefined : selectedTargetId;

  useEffect(() => {
    refreshPageInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const fetchProject = async () => {
    try {
      getProjectAction(projectId);
    } catch (error) {
      alert(error);
    }
  };

  const fetchAudienceCreationLogs = () => {
    if (!effectiveTargetId) {
      return;
    }
    try {
      getAudienceCreationLogsAction(projectId, effectiveTargetId);
    } catch (error) {
      alert(error);
    }
  };

  const fetchAudienceSegments = async () => {
    try {
      getSegmentsAction(effectiveTargetId, projectId);
    } catch (error) {
      alert(error);
    }
  };

  const getS3DataUploadLogs = () => {
    try {
      getS3DataUploadLogsAction(projectId);
    } catch (error) {
      alert(error);
    }
  };

  const refreshPageInfo = () => {
    fetchProject();
    fetchAudienceSegments();
    dispatch({ type: "set-audience-breakdown-outdated" });
    fetchAudienceCreationLogs();
    getS3DataUploadLogs();
  };

  const MAX_TARGETS = 600;

  const useDocumentTitle = (title) => {
    const defaultTitle = "ABBA by Applecart";
    useEffect(() => {
      document.title = title;
      return () => {
        document.title = defaultTitle;
      };
    }, [title]);
  };
  useDocumentTitle(
    project?.name ? "ABBA: " + project?.name : "ABBA by Applecart"
  );

  return (
    <>
      {project?.target_project_links?.length > MAX_TARGETS ? (
        <Result
          icon={<FrownOutlined />}
          title="Oops! This project is not accessible"
          subTitle={`Projects with more than ${MAX_TARGETS} Decision Makers cannot be accessed through the ABBA interface.`}
          extra={
            <Button
              type="primary"
              onClick={() => {
                history.push("/projects");
              }}
            >
              Back to All Projects
            </Button>
          }
        />
      ) : (
        <Navbar>
          <Row style={{ flex: "1 1 auto" }}>
            <TargetsColumn refreshPageInfo={refreshPageInfo} />

            <SegmentsColumn refreshPageInfo={refreshPageInfo} />

            <AudienceBreakdownColumn />
          </Row>

          <ProjectDrawer onUpdate={refreshPageInfo} />

          <ExportUI />
        </Navbar>
      )}
    </>
  );
};

export default Project;
