import { Divider, Row, Typography, Spin, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

export const DecisionMakerOrganization = ({ isLoading, organization }) => {
  if (isLoading) {
    return <Spin size="small" />;
  }
  return organization ? (
    <>
      <Divider
        orientation="left"
        plain
        orientationMargin="0"
        style={{ width: "50%" }}
      >
        Organization
        {
          <Tooltip title={"Can be changed in the experiences section."}>
            <InfoCircleOutlined style={{ marginLeft: "5px" }} />
          </Tooltip>
        }
      </Divider>
      <Row>
        <Typography.Title level={4}>{organization}</Typography.Title>
      </Row>
    </>
  ) : null;
};
