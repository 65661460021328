import { useState, useContext } from "react";
import { SearchCompaniesInput } from "./SearchCompaniesInput";
import { Row, Col, Button, Typography, Space } from "antd";
import { GroupSelector } from "../GroupSelector";
import AddCompaniesToGroupModal from "./SelectCanonicalModal";
import { bulkAddCompaniesToGroup } from "../../../APIClient";
import { showSuccessMessage } from "../../../utils";
import { CompanyGrid } from "./CompanyGrid";
import { CompanyGroupsContext } from "../CompanyGroupsPage";

export const CompanyView = () => {
  const [companySearchString, setCompanySearchString] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [showSelectCanonicalModal, setShowSelectCanonicalModal] =
    useState(false);
  const [stateFilterOptions, setStateFilterOptions] = useState([]);

  const {
    currentPage,
    switchPage,
    selectedGroup,
    groupGridData,
    configureGroup,
  } = useContext(CompanyGroupsContext);

  const canonicalExists = () =>
    groupGridData.length < 1
      ? setShowSelectCanonicalModal(true)
      : bulkAddCompanies(selectedGroup?.id, selectedRows);

  const bulkAddCompanies = async (groupId, selectedCompanies) => {
    try {
      let companies = selectedCompanies.map(({ company_id, canonical }) => {
        return {
          company_group_id: groupId,
          company_id,
          canonical: canonical || false,
        };
      });

      await bulkAddCompaniesToGroup(companies);
      showSuccessMessage("Companies were added successfully!");
      setSelectedRows([]);
      configureGroup(selectedGroup?.id);
      setShowSelectCanonicalModal(false);
      switchPage("groupView");
    } catch (error) {
      alert(error);
    }
  };

  if (currentPage.companyView) {
    return (
      <>
        <Row span={24} className="company-group-page-row">
          <Typography.Title level={4}>
            Search and Add Companies
          </Typography.Title>
        </Row>

        <Row
          justify="space-between"
          gutter={[16, 16]}
          className="company-group-page-row"
        >
          <Col xs={24} sm={24} md={12}>
            <SearchCompaniesInput
              companySearchString={companySearchString}
              setCompanySearchString={setCompanySearchString}
              setSelectedRows={setSelectedRows}
              setStateFilterOptions={setStateFilterOptions}
            />
          </Col>
          <Col xs={24} sm={24} md={12} align="right">
            <Space wrap={true} size="middle">
              <Button
                onClick={() => {
                  switchPage("groupView");
                }}
              >
                Show Groups
              </Button>

              <GroupSelector />

              <Button
                onClick={() => {
                  canonicalExists();
                }}
                disabled={selectedRows.length < 1 || !selectedGroup?.id}
                type="primary"
              >
                {"Add to Group"}
              </Button>
            </Space>
          </Col>
        </Row>
        <Row className="company-group-page-row">
          <Col span={24}>
            <CompanyGrid
              selectedCompanies={selectedRows}
              setSelectedCompanies={setSelectedRows}
              stateFilterOptions={stateFilterOptions}
            />
          </Col>
        </Row>
        <AddCompaniesToGroupModal
          selectedGroup={selectedGroup}
          selectedCompanies={selectedRows}
          isVisible={showSelectCanonicalModal}
          setIsVisible={setShowSelectCanonicalModal}
          bulkAddCompanies={bulkAddCompanies}
        />
      </>
    );
  } else return null;
};
