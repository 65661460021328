import { Tabs, List, Typography, Button } from "antd";
import { useEffect, useState } from "react";
import { LogoutOutlined, LoginOutlined, BankOutlined } from "@ant-design/icons";

const InExListTabs = ({
  isLoading,
  inExLists,
  setInExListName,
  setInExListType,
  redirectPageURL,
  inExListType,
}) => {
  const [activeKeyTab, setActiveKeyTab] = useState();

  useEffect(() => {
    setActiveKeyTab(inExListType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inExListType]);

  const filteredInExList = (data, list_type) => {
    let filtered_list = data
      ?.filter((item) => item.list_type === list_type)
      .sort((a, b) => b.id - a.id);
    return (
      <List
        pagination={{
          defaultPageSize: 10,
          size: "small",
          hideOnSinglePage: true,
          style: { display: "flex", justifyContent: "center" },
        }}
        loading={isLoading}
        split={false}
        dataSource={filtered_list}
        renderItem={(item) => (
          <List.Item key={item.id} style={{ padding: "25px 0px 0px 0px" }}>
            <Button
              type="text"
              onClick={() => {
                setInExListName(item.list_name);
                setInExListType(item.list_type);
                redirectPageURL(item.id);
              }}
              block={true}
            >
              <Typography.Text strong>{item.list_name}</Typography.Text>
            </Button>
          </List.Item>
        )}
      />
    );
  };
  const TabTitle = ({ title, icon }) => {
    return (
      <span style={{ display: "flex", alignItems: "center", fontSize: "14px" }}>
        {icon} {title}
      </span>
    );
  };
  return (
    <Tabs
      activeKey={activeKeyTab}
      onChange={(event) => setActiveKeyTab(event)}
      tabBarStyle={{ marginBottom: 0 }}
      centered={true}
      size="middle"
    >
      <Tabs.TabPane
        type="card"
        key={"INCLUSION"}
        style={{ backgroundColor: "#f6ffed" }}
        tab={
          <span style={{ width: "33%" }}>
            <TabTitle
              icon={<LoginOutlined style={{ color: "#73d13d" }} />}
              title="Inclusion"
            />
          </span>
        }
      >
        {filteredInExList(inExLists, "INCLUSION")}
      </Tabs.TabPane>
      <Tabs.TabPane
        type="card"
        key={"EXCLUSION"}
        style={{ backgroundColor: "#fff1f0" }}
        tab={
          <span style={{ width: "33%" }}>
            <TabTitle
              icon={<LogoutOutlined style={{ color: "#ff7875" }} />}
              title="Exclusion"
            />
          </span>
        }
      >
        {filteredInExList(inExLists, "EXCLUSION")}
      </Tabs.TabPane>
      <Tabs.TabPane
        type="card"
        key={"COMPANY_EXCLUSION"}
        style={{ backgroundColor: "#fff1f0" }}
        tab={
          <span style={{ width: "33%" }}>
            <TabTitle
              icon={<BankOutlined style={{ color: "#ff7875" }} />}
              title="Company"
            />
          </span>
        }
      >
        {filteredInExList(inExLists, "COMPANY_EXCLUSION")}
      </Tabs.TabPane>
    </Tabs>
  );
};
export default InExListTabs;
