import React, { useState, useEffect } from "react";
import { Button, Table, Select, Drawer, Row } from "antd";
import { getColumnSearchProps } from "../../util/columnSearchPropFactory";
import {
  getAudienceSegmentDestination,
  getDestination,
  postDspAudienceDestination,
  deleteDspAudienceDestination,
} from "../../APIClient";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const AudienceSegment = () => {
  const { hubspotCompanyId, projectId } = useParams();
  const [availableDestinations, setAvailableDestinations] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchTableData = async () => {
    setIsLoading(true);
    try {
      const response = await getAudienceSegmentDestination(
        hubspotCompanyId,
        projectId
      );
      setTableData(response);
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  const fetchAvailableDestinations = async () => {
    try {
      const response = await getDestination(hubspotCompanyId);
      setAvailableDestinations(response);
    } catch (error) {
      alert(error);
    }
  };

  const onSelectDestination = async (dspAudienceId, destinationId) => {
    setIsLoading(true);
    try {
      await postDspAudienceDestination(dspAudienceId, destinationId);
      await fetchTableData();
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  const onDeselectDestination = async (dspAudienceDestinationId) => {
    setIsLoading(true);
    try {
      await deleteDspAudienceDestination(dspAudienceDestinationId);
      await fetchTableData();
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  const onClearDspAudienceDestination = async (dspAudienceTodestinationIds) => {
    setIsLoading(true);
    try {
      for await (const myId of dspAudienceTodestinationIds) {
        await deleteDspAudienceDestination(myId);
      }
      await fetchTableData();
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    projectId && fetchTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    hubspotCompanyId && fetchAvailableDestinations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hubspotCompanyId]);

  const [rowObject, setRowObject] = useState({});

  const columns = [
    {
      title: "Segment Name",
      dataIndex: "file_name",
      width: 700,
      ...getColumnSearchProps("file_name", "Segment Name"),
      render: (value, row) => (
        <Row justify="space-between">
          <>{row.file_name + " "}</>
          <Button
            type="primary"
            onClick={() => {
              setIsDrawerOpen(true);
              setRowObject(row);
            }}
          >
            Preview
          </Button>
          <Drawer
            placement="right"
            visible={isDrawerOpen}
            onClose={() => {
              setIsDrawerOpen(false);
            }}
          >
            <Table
              style={{ width: "100%" }}
              columns={[
                {
                  title: "Decision Makers",
                  dataIndex: "full_name",
                  ...getColumnSearchProps("full_name", "Decision Makers"),
                  defaultSortOrder: "ascend",
                  sorter: (a, b) => a.full_name?.localeCompare(b?.full_name),
                },
              ]}
              dataSource={rowObject["decision_makers"]}
              rowKey="id"
            />
          </Drawer>
        </Row>
      ),
    },
    {
      title: "Segment Size",
      dataIndex: "distinct_row_count",
      width: 150,
      render: (val) => val?.toLocaleString(),
    },
    {
      title: "Destination (Ad Account)",
      dataIndex: "audience_destinations",
      ...getColumnSearchProps(
        "audience_destinations",
        "Destination (Ad Account)"
      ),
      onFilter: (value, record) =>
        record.audience_destinations.some((d) =>
          d.name?.toString()?.toLowerCase()?.includes(value.toLowerCase())
        ),
      render: (v, row) => {
        const destinationIdToDspAudienceDestinationId =
          row.audience_destinations.reduce(
            (accumulatorObject, destinationObject) => {
              accumulatorObject[destinationObject.id] =
                destinationObject.dsp_audience_destination_id;
              return accumulatorObject;
            },
            {}
          );
        return (
          <Select
            mode="multiple"
            allowClear
            loading={isLoading}
            disabled={isLoading}
            style={{ width: "100%" }}
            placeholder="Select multiple values"
            optionFilterProp="label"
            options={availableDestinations.reduce(
              (accumulatorArray, myObject) =>
                accumulatorArray.concat({
                  label:
                    myObject.name +
                    " (" +
                    myObject.advertising_account_id +
                    ")",
                  value: myObject.id,
                }),
              []
            )}
            value={row.audience_destinations.map((myObject) => myObject.id)}
            onSelect={(destinationId) =>
              onSelectDestination(row.id, destinationId)
            }
            onDeselect={(destinationId) =>
              onDeselectDestination(
                destinationIdToDspAudienceDestinationId[destinationId]
              )
            }
            onClear={() => {
              onClearDspAudienceDestination(
                row.audience_destinations.map(
                  (d) => destinationIdToDspAudienceDestinationId[d.id]
                )
              );
            }}
          />
        );
      },
    },
  ];

  return (
    <Table
      loading={isLoading}
      style={{ width: "100%" }}
      columns={columns}
      dataSource={tableData}
      rowKey="id"
    />
  );
};

export default AudienceSegment;
