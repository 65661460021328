import { Form, Select, TreeSelect } from "antd";
import { useEffect, useState } from "react";
import { buildTree, findPath } from "./utils";
import { getDecisionMakerTaxonomy } from "../../../APIClient";
import { makeAutocompleteOptions } from "../../../utils";
import { electedOfficeStatusOptions } from "../../../constants";
import { intersection } from "lodash";

const DecisionMakerTaxonomy = ({
  setType,
  type,
  isEditing,
  section = null,
  setIsPolitical = null,
  electedOfficeStatus,
  setElectedOfficeStatus,
}) => {
  const [treeData, setTreeData] = useState(null);

  useEffect(() => {
    const getTaxonomy = async () => {
      let data = await getDecisionMakerTaxonomy();
      setTreeData(buildTree(data));
    };
    getTaxonomy();
  }, []);

  useEffect(() => {
    if (treeData) {
      if (section === "personal_info") {
        const path = findPath(
          { id: 0, title: "root", children: treeData },
          type
        );
        intersection(path, ["Federal", "State and Local", "Political Others"])
          ?.length > 0
          ? setIsPolitical(true)
          : setIsPolitical(false);
      }
    }
    // eslint-disable-next-line
  }, [isEditing]);
  return (
    <>
      <Form.Item label="Type">
        {isEditing || section !== "personal_info" ? (
          <TreeSelect
            treeLine={true}
            showSearch
            style={{ width: "70%" }}
            value={type}
            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
            placeholder="Decision maker type"
            onClear={() => setType(null)}
            onSelect={(e, info) => {
              setType(e);
              if (isEditing) {
                const path = findPath(
                  { id: 0, title: "root", children: treeData },
                  e
                );
                path.includes("Political")
                  ? setIsPolitical(true)
                  : setIsPolitical(false);
              }
            }}
            treeData={treeData}
          />
        ) : (
          <Select
            disabled={true}
            defaultValue={type}
            value={type}
            style={{ width: "70%" }}
            options={[{ value: type, label: type, disabled: true }]}
          />
        )}
      </Form.Item>

      <Form.Item label="Elected Office Status">
        <Select
          disabled={!(isEditing || section === "create desicion_maker")}
          onChange={(e) => setElectedOfficeStatus(e)}
          style={{ width: "70%" }}
          value={electedOfficeStatus}
          options={makeAutocompleteOptions(electedOfficeStatusOptions)}
        ></Select>
      </Form.Item>
    </>
  );
};

export default DecisionMakerTaxonomy;
