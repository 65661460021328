import { Button, Popconfirm, Space, Table, Tooltip } from "antd";
import { BankOutlined, DeleteOutlined } from "@ant-design/icons";
import { showSuccessMessage, sortColumnWithNulls } from "../../../utils";
import { getColumnSearchProps } from "../../../util/columnSearchPropFactory";
import {
  deleteCompanyFromGroup,
  updateCanonicalCompany,
} from "../../../APIClient";
import { CompanyGroupsContext } from "../CompanyGroupsPage";
import { useContext } from "react";

export const GroupGrid = () => {
  const { groupGridData, isLoading, configureGroup } =
    useContext(CompanyGroupsContext);

  const deleteCompany = async (id, group_id) => {
    try {
      const response = await deleteCompanyFromGroup(id);
      if (response) {
        configureGroup(group_id);
        showSuccessMessage("Successfully deleted");
      }
      if (response.error) {
        alert(response.error);
      }
    } catch (error) {
      alert(error);
    }
  };

  const setAsNewCanonicalCompany = async (group_id, company_id) => {
    try {
      const response = await updateCanonicalCompany(group_id, company_id);
      if (response) {
        configureGroup(group_id);
        showSuccessMessage("Successfully updated");
      }
      if (response.error) {
        alert(response.error);
      }
    } catch (error) {
      alert(error);
    }
  };

  const groupGridColumns = [
    {
      title: "ID",
      dataIndex: "company_id",
      sorter: (a, b) => a.company_id - b.company_id,
      width: 90,
    },
    {
      title: "Name",
      dataIndex: "company_name",
      sorter: (a, b) => a.company_name?.localeCompare(b.company_name),
      ...getColumnSearchProps("company_name", "Company Name"),
      width: 200,
    },
    {
      title: "City",
      dataIndex: "city",
      sorter: (a, b) => sortColumnWithNulls(a.city, b.city),
      width: 150,
    },
    {
      title: "State",
      dataIndex: "state",
      sorter: (a, b) => sortColumnWithNulls(a.state, b.state),
      width: 80,
    },
    {
      title: "Size",
      dataIndex: "employee_count",
      sorter: (a, b) => a?.employee_count - b?.employee_count,
      width: 80,
    },
    {
      title: "Synthetic",
      dataIndex: "synthetic",
      sorter: (a, b) => a.synthetic - b.synthetic,
      render: (val, row) => (row.synthetic ? "Y" : "N"),
      width: 115,
      filters: [
        {
          text: "Yes",
          value: true,
        },
        {
          text: "No",
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.synthetic === value,
    },
    {
      title: "Type",
      dataIndex: "canonical",
      sorter: (a, b) => a.canonical - b.canonical,
      render: (val, row) => (row.canonical ? "Canonical" : "Alternate"),
      width: 95,
      filters: [
        {
          text: "Canonical",
          value: true,
        },
        {
          text: "Alternate",
          value: false,
        },
      ],
      onFilter: (value, record) => record.canonical === value,
    },
    {
      title: "Action",
      key: "action",
      width: 75,
      render: (record) => (
        <Space size="middle">
          {!record.canonical ? (
            <Popconfirm
              title="Are you sure you want to set this company as canonical?"
              onConfirm={() => {
                setAsNewCanonicalCompany(record.group_id, record.company_id);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button type="text" style={{ padding: 0 }}>
                <Tooltip title="Set as canonical">
                  <BankOutlined style={{ color: "#1990ff", fontSize: 15 }} />
                </Tooltip>
              </Button>
            </Popconfirm>
          ) : (
            <Button type="text" style={{ padding: 0 }} disabled={true}>
              <BankOutlined style={{ color: "#808080", fontSize: 15 }} />
            </Button>
          )}
          {!record.canonical ? (
            <Popconfirm
              title="Are you sure you want to delete this company?"
              onConfirm={() => {
                deleteCompany(record.id, record.group_id);
              }}
              okText="Yes"
              okButtonProps={{ danger: true }}
              cancelText="No"
            >
              <Button type="text" style={{ padding: 0 }}>
                <Tooltip
                  placement="left"
                  title={`Remove company from group ${record.group_name}`}
                >
                  <DeleteOutlined style={{ color: "#f5222d", fontSize: 15 }} />
                </Tooltip>
              </Button>
            </Popconfirm>
          ) : (
            <Button type="text" style={{ padding: 0 }} disabled={true}>
              <DeleteOutlined style={{ color: "#808080", fontSize: 15 }} />
            </Button>
          )}
        </Space>
      ),
    },
  ];

  return (
    <Table
      columns={groupGridColumns}
      dataSource={groupGridData}
      pagination={{ pageSize: 50 }}
      loading={isLoading}
      virtual
      scroll={{ x: 1000, y: 600 }}
    />
  );
};
