import React, { useState, useContext } from "react";
import { GroupSelector } from "../GroupSelector";
import { Row, Col, Button, Typography, Space, Tooltip, Popover } from "antd";
import { DeleteGroupModal } from "./DeleteGroupModal";
import { RenameGroupModal } from "./RenameGroupModal";
import { CreateGroupModal } from "./CreateGroupModal";
import { GroupGrid } from "./GroupGrid";
import { CompanyGroupsContext } from "../CompanyGroupsPage";
import { ReloadOutlined } from "@ant-design/icons";
import { updateCompanyGroupQualityAssuranceTimestamp } from "../../../APIClient";
import { daysSince, formatTimestamp } from "../../../utils";

export const GroupView = () => {
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);

  const {
    selectedGroup,
    groupGridData,
    setCompanyGridData,
    currentPage,
    switchPage,
    configureGroup,
    qualityAssuranceTextAndIconColor,
    setSelectedGroup,
  } = useContext(CompanyGroupsContext);

  const updateQATimestamp = async (selectedGroup) => {
    try {
      const response = await updateCompanyGroupQualityAssuranceTimestamp(
        selectedGroup?.id
      );
      if (response.most_recent_qa) {
        setSelectedGroup(response);
      }
      if (response.error) {
        alert(response.error);
      }
    } catch (error) {
      alert(error);
    }
  };

  if (currentPage.groupView)
    return (
      <>
        <Row span={24} className="company-group-page-row">
          <Typography.Title level={4}>Company Groups</Typography.Title>
        </Row>
        <Row
          justify="space-between"
          className="company-group-page-row"
          gutter={[16, 16]}
        >
          <Col xs={24} sm={24} md={8}>
            <Space wrap={true}>
              <GroupSelector />
              <Typography.Text strong>
                {groupGridData?.length > 0
                  ? `Total: ${groupGridData.length}`
                  : null}
              </Typography.Text>
            </Space>
          </Col>
          <Col xs={24} sm={24} md={7}>
            <Space wrap={true} size="middle">
              <Button
                onClick={() => {
                  setShowRenameModal(true);
                }}
                disabled={selectedGroup?.id ? false : true}
                type="primary"
              >
                Rename Group
              </Button>
              <Button
                onClick={() => {
                  setShowDeleteModal(true);
                }}
                disabled={selectedGroup?.id ? false : true}
                danger
              >
                Delete Group
              </Button>
            </Space>
          </Col>
          <Col xs={24} sm={24} md={9} align="right">
            <Space wrap={true} size="middle">
              <Space wrap={true} size="small">
                {selectedGroup?.id && (
                  <Popover
                    title={
                      qualityAssuranceTextAndIconColor(selectedGroup).text +
                      `Click to reset the QA timestamp`
                    }
                    content={
                      selectedGroup.most_recent_qa
                        ? `Most recent QA: ` +
                          formatTimestamp(selectedGroup.most_recent_qa) +
                          `, ` +
                          daysSince(selectedGroup?.most_recent_qa)
                        : ``
                    }
                  >
                    <Button
                      onClick={() => {
                        updateQATimestamp(selectedGroup);
                      }}
                      type="ghost"
                      shape="circle"
                      disabled={!selectedGroup?.id}
                    >
                      <ReloadOutlined
                        style={{
                          color:
                            qualityAssuranceTextAndIconColor(selectedGroup)
                              .color,
                        }}
                      />
                    </Button>
                  </Popover>
                )}
                <Tooltip title={"Show QA Grid"}>
                  <Button
                    onClick={() => {
                      switchPage("qualityAssuranceGroupView");
                      configureGroup();
                    }}
                    type="ghost"
                    shape="round"
                  >
                    QA
                  </Button>
                </Tooltip>
              </Space>
              <Button
                onClick={() => {
                  setShowCreateModal(true);
                }}
                type="primary"
              >
                Create Group
              </Button>

              <Button
                onClick={() => {
                  switchPage("companyView");
                  setCompanyGridData();
                }}
                type="primary"
              >
                {selectedGroup?.id ? "Add Companies" : "Search Companies"}
              </Button>
            </Space>
          </Col>
        </Row>
        <Row className="company-group-page-row">
          <Col span={24}>
            <GroupGrid />
          </Col>
        </Row>
        <DeleteGroupModal
          isVisible={showDeleteModal}
          setIsVisible={setShowDeleteModal}
        />
        <RenameGroupModal
          isVisible={showRenameModal}
          setIsVisible={setShowRenameModal}
        />
        <CreateGroupModal
          isVisible={showCreateModal}
          setIsVisible={setShowCreateModal}
        />
      </>
    );
  else return null;
};
