import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Spin,
  AutoComplete,
  message,
  Affix,
  Space,
  Empty,
  Card,
  Descriptions,
} from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { states } from "../../constants";
import { makeAutocompleteOptions } from "../../utils";
import { actionCreators } from "../../store";
import { addPackage, addTarget, findExistingPackages } from "../../APIClient";
import { useForm } from "antd/lib/form/Form";

const PackageMatchCard = ({ packageObj, onClick }) => {
  return (
    <Card
      key={`${packageObj.id}`}
      title={
        <Row style={{ justifyContent: "space-between" }}>
          <b>{packageObj.name}</b>
          <Button type="link" onClick={() => onClick()}>
            Use this package <CopyOutlined />
          </Button>
        </Row>
      }
    >
      <Descriptions column={1}>
        {
          <Descriptions.Item label={<b>Package Name</b>}>
            {packageObj.name}
          </Descriptions.Item>
        }
        {
          <Descriptions.Item label={<b>Political Parties</b>}>
            {packageObj.political_parties.join(", ")}
          </Descriptions.Item>
        }
        {
          <Descriptions.Item label={<b>Decision Maker Types</b>}>
            {packageObj.decision_maker_types.join(", ")}
          </Descriptions.Item>
        }
        {
          <Descriptions.Item label={<b>Distribution Rule</b>}>
            {packageObj.distribution_rule}
          </Descriptions.Item>
        }
        {/*  /!*<Descriptions.Item label={<b>Package Name</b>}>{packageObj.name}</Descriptions.Item>*!/*/}
        {/*  /!*<Descriptions.Item label={<b>State</b>}>{packageState}</Descriptions.Item>*!/*/}
        {/*  /!*<Descriptions.Item label={<b>Projects</b>}>{packageObj.project_names.join()}</Descriptions.Item>*!/*/}
      </Descriptions>
    </Card>
  );
};

const PackageForm = ({
  pack,
  isLoading,
  setIsVisible,
  isEdit,
  isVisible,
  uniqueTargetId,
}) => {
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const [loading, setLoading] = useState(false);
  const [internalKey, setInternalKey] = useState(pack?.internal_key);
  const [packageName, setPackageName] = useState(pack?.first_name);
  const [state, setState] = useState();

  const [form] = useForm();
  const isValid = internalKey && packageName;

  useEffect(() => {
    setInternalKey(pack?.internal_key || null);
    setPackageName(pack?.first_name || null);
    setState(pack?.state || null);
  }, [pack, isEdit]);

  const { updateTargetAction } = bindActionCreators(actionCreators, dispatch);

  const onSubmit = async () => {
    if (
      form.getFieldsError().filter(({ errors }) => errors.length).length > 0
    ) {
      alert("Please correct form errors before submitting.");
      return;
    }
    if (!isEdit) {
      try {
        setLoading(true);
        await addTarget({
          project: projectId,
          internal_key: internalKey,
          state: state || null,
          first_name: packageName,
          is_package: true,
          target: uniqueTargetId,
        });
        setLoading(false);
        message.success("Successfully created package", 3);
      } catch (err) {
        setLoading(false);
        message.error("Failed to create Package", 3);
      }
    } else {
      updateTargetAction({
        projectId,
        internalKey,
        state: state || null,
        firstName: packageName,
        targetId: pack?.id,
        is_package: true,
      });
    }
    setIsVisible(false);
  };

  return (
    <>
      <Spin spinning={loading}>
        {/* internal_key must be controlled at the Form level b/c it uses validation rules */}
        <Form
          layout="vertical"
          fields={[{ name: ["internal_key"], value: internalKey }]}
          form={form}
        >
          <Form.Item
            label="Internal Key"
            required={true}
            name="internal_key"
            rules={[
              {
                max: 100,
                pattern: new RegExp(/^[a-z_0-9]{1,100}$/g),
                message:
                  "Only lowercase letters, numbers, and underscore allowed; 100 character max.",
              },
            ]}
          >
            <Input
              onChange={(e) => setInternalKey(e.target.value)}
              value={internalKey}
            />
          </Form.Item>
          <Form.Item label="Package name" required={true}>
            <AutoComplete
              allowClear
              // options={packageNameOptions}
              onChange={(val) => setPackageName(val)}
              value={packageName}
            />
          </Form.Item>
          <Row>
            <Col span={8}>
              <Form.Item label="State">
                <Select
                  showSearch
                  allowClear
                  placeholder="State"
                  style={{ width: 120 }}
                  onChange={(e) => {
                    setState(e);
                  }}
                  value={state}
                  options={makeAutocompleteOptions(states)}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
      <Affix
        offsetBottom={0}
        style={{ textAlign: "right", backgroundColor: "white" }}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "white",
            paddingBottom: "10px",
            paddingTop: "10px",
            borderTop: "1px solid #f0f0f0",
          }}
        >
          <Button
            onClick={() => setIsVisible(false)}
            style={{ marginRight: 8 }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => onSubmit()}
            type="primary"
            disabled={!isValid}
            loading={isLoading}
          >
            Save
          </Button>
        </div>
      </Affix>
    </>
  );
};

export const EditPackageDrawer = ({
  isVisible,
  setIsVisible,
  refreshPackages,
}) => {
  const pack = useSelector((state) => state.target);
  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }
    if (!isVisible) {
      refreshPackages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return (
    <Drawer
      title="Add Packages"
      onClose={() => {
        setIsVisible(false);
        refreshPackages();
      }}
      visible={isVisible}
      width={600}
      bodyStyle={{ paddingBottom: 20 }}
    >
      <PackageForm
        pack={pack}
        setIsVisible={setIsVisible}
        isEdit={true}
        isVisible={isVisible}
      />
    </Drawer>
  );
};

const CreatePackageDrawer = ({
  projectId,
  isVisible,
  setIsVisible,
  refreshPackages,
}) => {
  const [packageSearchValue, setPackageSearchValue] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [packageMatches, setPackageMatches] = useState([]);

  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }
    if (!isVisible) {
      refreshPackages();
      setPackageMatches([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const onSearch = async () => {
    try {
      setIsLoading(true);
      const data = await findExistingPackages(packageSearchValue);
      if (data) {
        setPackageMatches([...data]);
      }
      setIsLoading(false);
    } catch (e) {
      alert(e);
      setIsLoading(false);
    }
  };

  const onSubmit = async (pkg) => {
    try {
      await addPackage({ project: projectId, package_id: pkg.id });
      message.success("Successfully added Package to Project", 3);
      setIsVisible(false);
    } catch (err) {
      alert(err);
    }
  };

  return (
    <Drawer
      title="Add Packages"
      onClose={() => {
        setIsVisible(false);
        refreshPackages();
      }}
      visible={isVisible}
      width={600}
      bodyStyle={{ paddingBottom: 20 }}
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <Spin spinning={isLoading}>
          <Form layout="vertical">
            <Form.Item label="Package name">
              <Input
                allowClear
                onChange={(e) => setPackageSearchValue(e.target.value)}
                value={packageSearchValue}
              />
            </Form.Item>
            <Button
              onClick={onSearch}
              type="primary"
              htmlType="submit"
              disabled={!packageSearchValue}
              loading={isLoading}
            >
              Search
            </Button>
          </Form>
        </Spin>
        {packageMatches.length > 0 ? (
          packageMatches.map((row) => (
            <PackageMatchCard
              key={row.id}
              packageObj={row}
              onClick={() => {
                onSubmit(row);
              }}
            />
          ))
        ) : (
          <Empty />
        )}
      </Space>
    </Drawer>
  );
};

export default CreatePackageDrawer;
