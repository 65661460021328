import { Select } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMyClients } from "../../APIClient";

export const AccountSelector = () => {
  const user = useSelector((state) => state.user);

  const selectedHubspotCompanyId = useSelector(
    (state) => state.selectedHubspotCompanyId
  );
  const dispatch = useDispatch();

  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchUserClients = async () => {
    setIsLoading(true);
    try {
      const resp = await getMyClients();
      setClients(resp.filter((item) => item.is_billing));
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (user.id) {
      fetchUserClients();
    }
  }, [user?.id]);

  return (
    <Select
      showSearch
      loading={isLoading}
      placeholder="Choose an Account"
      filterOption={(input, option) =>
        (option?.label?.toLowerCase() ?? "").includes(input?.toLowerCase())
      }
      style={{ width: "120%" }}
      options={clients
        .filter((c) => !!c.hubspot_company_id)
        .map((c) => ({
          value: c.hubspot_company_id,
          label: c.name,
        }))}
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? "")
          .toLowerCase()
          .localeCompare((optionB?.label ?? "").toLowerCase())
      }
      onChange={(selectedHubspotCompanyId) =>
        dispatch({
          type: "set-selected-hubspot-company-id",
          payload: selectedHubspotCompanyId,
        })
      }
      value={selectedHubspotCompanyId}
    />
  );
};
