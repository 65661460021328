import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Drawer, Spin, Space, Select, Alert } from "antd";
import { useState } from "react";
import { createAutomatedNetworkBlocs } from "../../../APIClient";
import { showSuccessMessage } from "../../../utils";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../store";

export const NetworkMembersDrawer = ({
  isVisible,
  setIsVisible,
  refreshPage,
}) => {
  const dispatch = useDispatch();
  const [isLoading] = useState(false);
  const { projectId } = useParams();
  const target = useSelector((state) => state.target);
  const [selectedNetworkSize, setSelectedNetworkSize] = useState("");

  const { updateTargetAction } = bindActionCreators(actionCreators, dispatch);
  const createNetworkMembers = async () => {
    try {
      await createAutomatedNetworkBlocs(parseInt(projectId), [
        {
          dm_id: target?.dm_id,
          tpl_id: target?.id,
          project_id: parseInt(projectId),
          dm_network_size: selectedNetworkSize,
        },
      ]);
      updateTargetAction({
        projectId,
        targetId: target?.id,
        dm_network_size: selectedNetworkSize,
      });
      showSuccessMessage(
        `Network blocs of size ${selectedNetworkSize} was added successfully`
      );
    } catch (error) {
      alert(error);
    }
    refreshPage();
    setSelectedNetworkSize("");
    setIsVisible(false);
  };

  return (
    <Drawer
      title={`Add Network Members`}
      onClose={() => setIsVisible(false)}
      visible={isVisible}
      width={900}
      bodyStyle={{ paddingBottom: 20 }}
    >
      <Space direction="vertical" size="large">
        Select Network Size for this Decision Makers
        <Alert
          message="Make sure you have deleted all existing audience members for this decision maker before adding their network."
          type="warning"
        />
        <Select
          onSelect={(value) => {
            setSelectedNetworkSize(value);
          }}
          style={{ width: 120 }}
          value={selectedNetworkSize}
          options={[
            {
              value: "EXTRA_NARROW",
              label: "Extra Narrow",
            },
            {
              value: "NARROW",
              label: "Narrow",
            },
            {
              value: "STANDARD",
              label: "Standard",
            },
            {
              value: "EXPANDED",
              label: "Expanded",
            },
            {
              value: "EXTRA_EXPANDED",
              label: "Extra Expanded",
            },
          ]}
        />
        <Spin spinning={isLoading}>
          <Button
            onClick={() => createNetworkMembers()}
            disabled={!selectedNetworkSize}
          >
            Add Automated Network Blocs
          </Button>
        </Spin>
      </Space>
    </Drawer>
  );
};
