import moment from "moment";
import React from "react";
import { ResponsiveLine } from "@nivo/line";
import { Row, Spin } from "antd";
import { roundNumberToNDecimals } from "../../utils";

export const AccountBalanceChart = ({
  selectedContractStartDate,
  zeroDates,
  apiResponse,
  isLoading,
  chartDataBalance,
}) => {
  const selectedZeroDate = zeroDates[selectedContractStartDate];

  const previousMonth = moment().subtract(1, "months");
  const balanceAtEndOfPreviousMonth = apiResponse[
    selectedContractStartDate
  ]?.filter(
    (record) =>
      record.month - 1 === previousMonth.month() &&
      record.year === previousMonth.year()
  )?.[0]?.balance;

  return (
    <Row
      style={{
        display: "flex",
        marginBottom: 40,
      }}
    >
      <Row style={{ width: "100%" }}>
        {selectedZeroDate && (
          <div>
            AU balance hits <b>0</b> in <b>{selectedZeroDate}</b>
          </div>
        )}
      </Row>
      <Row style={{ width: "100%" }}>
        {balanceAtEndOfPreviousMonth !== null &&
          balanceAtEndOfPreviousMonth !== undefined && (
            <div>
              AU balance was{" "}
              <b>
                {roundNumberToNDecimals(
                  balanceAtEndOfPreviousMonth,
                  2
                )?.toLocaleString()}
              </b>{" "}
              at end of{" "}
              <b>
                {previousMonth.format("MMMM")} {previousMonth.format("YYYY")}
              </b>{" "}
            </div>
          )}
      </Row>

      <Row>
        <Spin spinning={isLoading}>
          <div style={{ height: 500, width: 600 }}>
            <ResponsiveLine
              colors={{ scheme: "category10" }}
              data={[
                {
                  id: "Projected AU Balance",
                  data: chartDataBalance || [],
                },
              ]}
              margin={{ top: 50, right: 110, bottom: 100, left: 60 }}
              yScale={{
                type: "linear",
                min: "auto",
                max: "auto",
              }}
              axisBottom={{
                legend: "Date (months)",
                legendOffset: 80,
                legendPosition: "middle",
                tickRotation: -90,
              }}
              axisLeft={{
                legend: "Applecart Units",
                legendOffset: -40,
                legendPosition: "middle",
              }}
              enablePointLabel={true}
              enableArea={true}
            />
          </div>
        </Spin>
      </Row>
    </Row>
  );
};
