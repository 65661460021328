import React from "react";
import { Form, Button, Space } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import "./DynamicRow.css";

export const DynamicRow = ({ fields, add, remove, makeRow }) => {
  return (
    <span>
      {fields.map((field, index) => (
        <div key={field.key}>
          {index > 0 && (
            <h4>
              <b>OR</b>
            </h4>
          )}
          <Space className="form-row" align="start">
            {makeRow(field, index)}

            <MinusCircleOutlined
              style={{
                marginTop: "10px",
                color: "red",
                fontSize: 15,
              }}
              onClick={() => remove(field.name)}
            />
          </Space>
        </div>
      ))}

      <Form.Item>
        <Button
          type="link"
          className="add-row-button"
          onClick={() => add()}
          block
        >
          <PlusOutlined /> Add another
        </Button>
      </Form.Item>
    </span>
  );
};
