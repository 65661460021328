const restructureRows = (data) => {
  const groupedBySegment = data.reduce((groups, item) => {
    if (item.audience_segment.id in groups) {
      groups[item.audience_segment.id] = [
        ...groups[item.audience_segment.id],
        item,
      ];
    } else {
      groups[item.audience_segment.id] = [item];
    }
    return groups;
  }, {});
  return groupedBySegment;
};

const removeLogFromState = (state, log) => {
  state[log.audience_segment.id] = [
    ...state[log.audience_segment.id].filter((item) => item.id !== log.id),
  ];
  return state;
};

const reducer = (state = {}, action) => {
  switch (action.type) {
    case "get-creation-logs":
      return restructureRows(action.payload);
    case "delete-creation-log":
      return removeLogFromState(state, action.payload);
    default:
      return state;
  }
};

export default reducer;
