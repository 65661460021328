import { ResponsiveLine } from "@nivo/line";
import { Row, Spin } from "antd";

export const AccountConsumptionChart = ({
  isLoading,
  chartDataConsumption,
}) => {
  return (
    <Row
      style={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
      }}
    >
      <Spin spinning={isLoading}>
        <div style={{ height: 500, width: 600 }}>
          <ResponsiveLine
            colors={{ scheme: "category10" }}
            data={chartDataConsumption}
            margin={{ top: 50, right: 110, bottom: 100, left: 60 }}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
            }}
            axisBottom={{
              legend: "Date (months)",
              legendOffset: 80,
              legendPosition: "middle",
              tickRotation: -90,
            }}
            axisLeft={{
              legend: "AU Used",
              legendOffset: -40,
              legendPosition: "middle",
            }}
            enablePointLabel={true}
            enableArea={true}
          />
        </div>
      </Spin>
    </Row>
  );
};
