import moment from "moment";
import React, { useState } from "react";
import { patchUsageStartDates } from "../../../APIClient";
import { Table, DatePicker, Row, Popover, Typography } from "antd";
import { getColumnSearchProps } from "../../../util/columnSearchPropFactory";

const { Text } = Typography;

export const ExplodedDMsTable = ({
  tableData,
  isLoading,
  changeUsageStartCallback,
}) => {
  const [checkedRows, setCheckedRows] = useState([]);

  const datePickerUsageStartSelected = async (usageLogIds, date) => {
    try {
      await patchUsageStartDates(usageLogIds, date);
      await changeUsageStartCallback();
    } catch (error) {
      alert(error);
    }
  };

  const checkIsDateDisabled = (current) => {
    let activateDates = checkedRows.map((myObject) =>
      moment(myObject["ACTIVATE"]["effective_date"]).unix()
    );

    let maxActivateDate = moment.unix(Math.max(...activateDates));

    let deactivateDates = checkedRows
      .filter((myObject) => myObject["DEACTIVATE"])
      .map((myObject) =>
        moment(myObject["DEACTIVATE"]["effective_date"]).unix()
      );

    let minDeactivateDate = moment.unix(Math.min(...deactivateDates));

    return current < maxActivateDate || current >= minDeactivateDate;
  };

  const columns = [
    {
      title: "Billing Customer Name",
      dataIndex: "billing_customer_name",
      render: (value, row) => (
        <Popover
          content={
            <div>
              <p>
                Billing Customer Name: <b>{value}</b>
              </p>
              <p>
                Billing Customer ID: <b>{row.billing_customer_id || "None"}</b>
              </p>
            </div>
          }
        >
          <Text ellipsis={true}>{value}</Text>
        </Popover>
      ),
      width: 120,
      defaultSortOrder: "ascend",
      sorter: {
        compare: (a, b) =>
          a.billing_customer_name.localeCompare(b.billing_customer_name),
        multiple: 3,
      },
      ...getColumnSearchProps("billing_customer_name", "Billing Customer Name"),
    },
    {
      title: "End Customer Name",
      dataIndex: "end_customer_name",
      render: (value, row) => (
        <Popover
          content={
            <div>
              <p>
                End Customer Name: <b>{value}</b>
              </p>
              <p>
                End Customer ID: <b>{row.end_customer_id || "None"}</b>
              </p>
            </div>
          }
        >
          <Text ellipsis={true}>{value}</Text>
        </Popover>
      ),
      width: 120,
      sorter: {
        compare: (a, b) =>
          a.end_customer_name.localeCompare(b.end_customer_name),
        multiple: 3,
      },
      ...getColumnSearchProps("end_customer_name", "End Customer Name"),
    },
    {
      title: "Client Project ID",
      dataIndex: "hubspot_client_project_id",
      width: 75,
      ...getColumnSearchProps("hubspot_client_project_id", "Client Project ID"),
    },
    {
      title: "ABBA Project ID",
      dataIndex: "project_id",
      width: 75,
      ...getColumnSearchProps("project_id", "ABBA Project ID"),
    },
    {
      title: "Decision Maker",
      dataIndex: "decision_maker_full_name",
      render: (value, row) => (
        <Popover
          content={
            <div>
              <p>
                Name: <b>{value}</b>
              </p>
              <p>
                Decison Maker ID: <b>{row.dm_id}</b>
              </p>
            </div>
          }
        >
          <Text ellipsis={true}>{value}</Text>
        </Popover>
      ),
      width: 110,
      sorter: {
        compare: (a, b) =>
          a.decision_maker_full_name.localeCompare(b.decision_maker_full_name),
        multiple: 1,
      },
      ...getColumnSearchProps("decision_maker_full_name", "Decision Maker"),
    },
    {
      title: "Activation Date",
      dataIndex: "activate_date",
      align: "center",
      width: 80,
      sorter: (a, b) =>
        moment(a.ACTIVATE.effective_date).unix() -
        moment(b.ACTIVATE.effective_date).unix(),
      render: (text, record, index) => record.ACTIVATE.effective_date,
    },
    {
      title: "Usage Start Date",
      dataIndex: "USAGE_START",
      align: "center",
      width: 120,
      sorter: (a, b) =>
        moment(a?.USAGE_START?.effective_date)?.unix() -
        moment(b?.USAGE_START?.effective_date)?.unix(),
      render: (text, record, index) => record?.USAGE_START.effective_date,
    },
    {
      title: "Deactivation Date",
      dataIndex: "DEACTIVATE",
      align: "center",
      width: 80,
      sorter: (a, b) =>
        (a.DEACTIVATE ? moment(a.DEACTIVATE.effective_date).unix() : 0) -
        (b.DEACTIVATE ? moment(b.DEACTIVATE.effective_date).unix() : 0),
      render: (text, record, index) => record.DEACTIVATE?.effective_date || "",
    },
  ];
  return (
    <>
      <Row>
        <div style={{ height: 100, width: 200 }}>
          <h1>Edit Usage Start Dates</h1>
          <DatePicker
            onChange={(date) => {
              datePickerUsageStartSelected(
                checkedRows.map((myObject) => myObject["USAGE_START"]["id"]),
                date.format("DD-MM-YYYY")
              );
            }}
            disabled={!checkedRows.length}
            disabledDate={checkIsDateDisabled}
            value={null}
          />
        </div>
      </Row>
      <Row>
        <Table
          rowSelection={{
            onChange: (selectedRowKeys, selectedRows) => {
              setCheckedRows(selectedRows);
            },
          }}
          scroll={{ y: "60vh" }}
          style={{ width: "100%" }}
          columns={columns}
          dataSource={tableData.flatMap((element, dm_index) => {
            return element.targets.flatMap((t, tpl_index) =>
              t.active_durations.map((ad, duration_index) => ({
                ...element,
                ...t,
                ...ad,
                key: `${dm_index}_${tpl_index}_${duration_index}`,
              }))
            );
          })}
          loading={isLoading}
        />
      </Row>
    </>
  );
};
