import { useContext } from "react";
import { Row, Col, Typography, Space, Button } from "antd";
import { CompanyGroupsContext } from "../CompanyGroupsPage";
import { QualityAssuranceGroupGrid } from "./QualityAssuranceGroupGrid";
export const QualityAssuranceGroupView = () => {
  const { currentPage, switchPage, setCompanyGridData } =
    useContext(CompanyGroupsContext);

  if (currentPage.qualityAssuranceGroupView)
    return (
      <>
        <Row span={24} className="company-group-page-row">
          <Typography.Title level={4}>Review Company Groups</Typography.Title>{" "}
        </Row>
        <Row
          justify="space-between"
          className="company-group-page-row"
          gutter={[16, 16]}
        >
          <Col xs={24} sm={24} md={24} align="left">
            <Space wrap={true} size="middle">
              <Button
                type="primary"
                onClick={() => {
                  switchPage("groupView");
                }}
              >
                Back to Group View
              </Button>
              <Button
                onClick={() => {
                  switchPage("companyView");
                  setCompanyGridData();
                }}
                type="primary"
              >
                Search Companies
              </Button>
            </Space>
          </Col>
        </Row>
        <Row className="company-group-page-row">
          <Col span={24}>
            <QualityAssuranceGroupGrid />
          </Col>
        </Row>
      </>
    );
  else return null;
};
