import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../store";
import "antd/dist/antd.css";

import { Modal, Input } from "antd";

const DeleteSegmentModal = ({ segment, isVisible, setIsVisible }) => {
  const dispatch = useDispatch();
  const { deleteSegmentAction, deleteAudienceMembersAction } =
    bindActionCreators(actionCreators, dispatch);
  const { projectId } = useParams();
  const target = useSelector((state) => state.target);
  const selectedTargetId = useSelector((state) => state.selectedTargetId);
  const [typeDeleteText, setTypeDeleteText] = useState("");

  let message = "Deleting this segment will delete ",
    handler,
    ending = ", are you sure you wish to continue?",
    deleteMessageContent;

  if (selectedTargetId === "all") {
    message += `${segment?.type_name} audience members across all targets in this project`;
    handler = () =>
      deleteSegmentAction(
        segment.id,
        projectId,
        segment?.source,
        segment?.type_name
      );
    deleteMessageContent = (
      <>
        {message + ending}
        <p>
          <Input
            value={typeDeleteText}
            onChange={(e) => setTypeDeleteText(e.target.value)}
            style={{ marginTop: "20px", width: "200px" }}
            placeholder="Type DELETE to proceed"
          />
        </p>
      </>
    );
  } else {
    message += `${segment?.type_name} audience members for target ${target?.full_name}`;
    handler = () =>
      deleteAudienceMembersAction(
        projectId,
        segment.id,
        parseInt(selectedTargetId),
        segment.source[selectedTargetId],
        segment.type_name
      );
    deleteMessageContent = <div>{message + ending}</div>;
  }
  const handleOk = () => {
    handler();
    dispatch({ type: "set-audience-breakdown-outdated" });
    setIsVisible(false);
    setTypeDeleteText("");
  };

  const handleCancel = () => {
    setIsVisible(false);
    setTypeDeleteText("");
  };

  return (
    <Modal
      visible={isVisible}
      okText="Yes"
      okType="danger"
      onCancel={handleCancel}
      onOk={handleOk}
      okButtonProps={{
        disabled:
          selectedTargetId === "all" && !(typeDeleteText === "DELETE")
            ? true
            : false,
      }}
      title={
        <>
          <ExclamationCircleOutlined
            style={{ marginRight: "8px", color: "#ff4d4f" }}
          />
          Are you sure?
        </>
      }
    >
      {deleteMessageContent}
    </Modal>
  );
};

export default DeleteSegmentModal;
