import Navbar from "../Navbar";
import { Typography } from "antd";
import StaffersGrid from "./StaffersGrid";
import { useEffect, useState } from "react";
import { getStaffersForGrid } from "../../APIClient";
import { formatSaffersForGrid } from "./utils";

const StaffersPage = () => {
  const { Title } = Typography;
  const [staffers, setStaffers] = useState([]);
  const [reRender, setRerender] = useState(true);

  useEffect(() => {
    const getStaffers = async () => {
      try {
        const data = await getStaffersForGrid();
        const staffersFormatted = formatSaffersForGrid(data);
        setStaffers(staffersFormatted);
      } catch (error) {
        alert(error.message);
      }
    };
    if (reRender) {
      getStaffers();
      setRerender(false);
    }
  }, [staffers, reRender]);

  return (
    <Navbar contentClass="landing-page-content">
      <Title level={1}>Staffers</Title>
      <StaffersGrid
        staffers={staffers}
        setStaffers={setStaffers}
        setRerender={setRerender}
      />
    </Navbar>
  );
};

export default StaffersPage;
