import React from "react";
import { Form, Input, Row, Col, Select } from "antd";
import { states } from "../../../constants";
import { makeAutocompleteOptions } from "../../../utils";
import { DynamicRow } from "../../DynamicRow/DynamicRow";

const AddressRow = () => {
  const makeNameRow = (field, index) => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Row gutter={15}>
          <Col>
            <Form.Item
              {...field}
              name={[field.name, "addr1"]}
              key={[field.name, "addr1", index]}
            >
              <Input allowClear placeholder="Address 1" />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              {...field}
              name={[field.name, "addr2"]}
              key={[field.name, "addr2", index]}
            >
              <Input allowClear placeholder="Address 2" />
            </Form.Item>
          </Col>
        </Row>
        <Row
          style={{ display: "flex", justifyContent: " space-between" }}
          gutter={15}
        >
          <Col>
            <Form.Item
              {...field}
              name={[field.name, "city"]}
              key={[field.name, "city", index]}
            >
              <Input allowClear placeholder="City" />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              {...field}
              name={[field.name, "state"]}
              key={[field.name, "state", index]}
            >
              <Select
                showSearch
                allowClear
                placeholder="State"
                style={{ minWidth: 60 }}
                options={makeAutocompleteOptions(
                  states.map((st) => st.toLowerCase())
                )}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              {...field}
              name={[field.name, "zip"]}
              key={[field.name, "zip", index]}
            >
              <Input allowClear placeholder="Zipcode" />
            </Form.Item>
          </Col>
        </Row>
      </div>
    );
  };
  return (
    <Form.List name="addresses">
      {(fields, { add, remove }) => (
        <DynamicRow
          fields={fields}
          add={add}
          remove={remove}
          makeRow={makeNameRow}
        />
      )}
    </Form.List>
  );
};

const AddressFormInputs = () => {
  return (
    <>
      <AddressRow />
    </>
  );
};

export default AddressFormInputs;
