import React, { useEffect, useState } from "react";
import {
  Form,
  Select,
  Row,
  Col,
  Typography,
  Tag,
  Card,
  Table,
  Button,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import NavBar from "../Navbar";
import moment from "moment";

import { AccountBalanceChart } from "./AccountBalanceChart";
import { AccountConsumptionChart } from "./AccountConsumptionChart";
import {
  getAccount,
  getDmSummary,
  getDecisionMakerReport,
  getBalance,
  getConsumption,
} from "../../APIClient";

import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { getColumnSearchProps } from "../../util/columnSearchPropFactory";
import { ClientProjectsDrawer } from "./ClientProjectsDrawer";
import { PacingCard } from "./PacingCard";

const Dashboard = () => {
  const { accountId: accountIdFromUrlParams } = useParams();
  const history = useHistory();

  const selectedHubspotCompanyId = useSelector(
    (state) => state.selectedHubspotCompanyId
  );

  const [selectedContractStartDate, setSelectedContractStartDate] =
    useState("");
  const [zeroDates, setZeroDates] = useState([]);
  const [apiResponse, setApiResponse] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [account, setAccount] = useState({});
  const [dmSummary, setDmSummary] = useState({});
  const [isReportLoading, setIsReportLoading] = useState(false);
  const [decisionMakers, setDecisionMakers] = useState([]);
  const [decisionMakerCardActiveTab, setDecisionMakerCardActiveTab] =
    useState("details");
  const [isClientProjectsDrawerVisible, setIsClientProjectsDrawerVisible] =
    useState(false);
  const [recordForClientProjectsDrawer, setRecordForClientProjectsDrawer] =
    useState(false);

  const [chartDataBalance, setChartDataBalance] = useState([]);

  const [chartDataConsumption, setchartDataConsumption] = useState([]);

  const dispatch = useDispatch();

  const ondecisionMakerCardTabChange = (key) => {
    setDecisionMakerCardActiveTab(key);
  };

  const isLegacyAccount = account.billing_type === "Legacy";

  const getSelectedContractEndDate = () => {
    const myIndex = apiResponse[selectedContractStartDate].length - 1;

    const contractEndMonth =
      apiResponse[selectedContractStartDate][myIndex]["month"];

    const contractEndYear =
      apiResponse[selectedContractStartDate][myIndex]["year"];

    const contractEndDate = moment(
      contractEndMonth + "/1/" + contractEndYear,
      "MM-DD-YYYY"
    );

    return moment(contractEndDate).add(1, "M");
  };

  useEffect(() => {
    onChangeDropdown(selectedContractStartDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContractStartDate]);

  useEffect(() => {
    if (selectedHubspotCompanyId && selectedContractStartDate) {
      onChangeRangePicker([
        moment(selectedContractStartDate, "MM-DD-YYYY"),
        getSelectedContractEndDate(),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHubspotCompanyId, selectedContractStartDate]);

  const onClickReport = async () => {
    setIsReportLoading(true);
    try {
      await getDecisionMakerReport(selectedHubspotCompanyId);
    } catch (error) {
      alert(error);
    }
    setIsReportLoading(false);
  };

  const onChangeDropdown = (selectedContractStartDate) => {
    const callback = (myObject) => {
      const newObject = {
        x: myObject["month"] + "/" + myObject["year"],
        y: Math.round(myObject["balance"] * 100) / 100,
      };
      return newObject;
    };

    let data = apiResponse[selectedContractStartDate]?.map(callback);

    setChartDataBalance(data);
  };

  const getMostRecentContractStartDate = (apiResponse) =>
    Object.keys(apiResponse).sort(
      (a, b) => moment(b).unix() - moment(a).unix()
    )[0];

  const getZeroDatesInContract = (result) => {
    const zeroDateTuples = Object.entries(result).map(
      ([dateString, balanceByMonth], idx) => {
        let record = getFirstNonNegativeRecord(balanceByMonth);
        return [
          dateString,
          record
            ? `${moment()
                .month(record.month - 1)
                .format("MMMM")} ${moment().year(record.year).format("YYYY")}`
            : record,
        ];
      }
    );

    return Object.fromEntries(zeroDateTuples);
  };

  const getFirstNonNegativeRecord = (balanceByMonth) => {
    for (let i = 0; i < balanceByMonth.length; i++) {
      const element = balanceByMonth[i];

      if (element.balance < 0) {
        return element;
      }
    }
  };

  const onChangeRangePicker = async (selectedDateRange) => {
    setIsLoading(true);
    try {
      let result = await getConsumption(
        selectedHubspotCompanyId,
        selectedDateRange[0],
        selectedDateRange[1]
      );
      let callback = (myArray, myObject) => {
        myArray.push({
          x: myObject["month"] + "/" + myObject["year"],
          y: Math.round(myObject["total_units_used"] * 100) / 100,
        });
        return myArray;
      };
      result = result.reduce(callback, []);
      let chartDataConsumption = [
        {
          id: "AU Consumption",
          data: result,
        },
      ];
      setchartDataConsumption(chartDataConsumption);
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  const fetchAccount = async () => {
    try {
      const data = await getAccount(selectedHubspotCompanyId);
      setAccount(data);
    } catch (error) {
      alert(error);
    }
  };

  const fetchDmSummary = async () => {
    try {
      const data = await getDmSummary(selectedHubspotCompanyId);
      setDmSummary(data);
      console.log(data);
      setDecisionMakers(data.decision_makers);
    } catch (error) {
      alert(error);
    }
  };

  const fetchBalance = async (_accountId) => {
    setIsLoading(true);
    try {
      let result = await getBalance(_accountId);
      let zeroes = getZeroDatesInContract(result);
      setZeroDates(zeroes);
      setApiResponse(result);
      setSelectedContractStartDate(getMostRecentContractStartDate(result));
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  let tabListNoTitle = [
    {
      key: "details",
      label: "details",
      tab: "Details",
    },
    {
      key: "decision_makers",
      label: "decision_makers",
      tab: "Decision Makers",
    },
  ];

  const decision_maker_columns = [
    {
      title: "Active",
      dataIndex: "active",
      render: (select, record) => (record.active ? "Y" : "N"),
      sorter: {
        compare: (a, b) => (a.active ? 1 : 0) - (b.active ? 1 : 0),
        multiple: 2,
      },
    },
    {
      title: "Decision Maker Full Name",
      dataIndex: ["dm_profile", "full_name"],
      sorter: {
        compare: (a, b) =>
          a?.dm_profile?.full_name?.localeCompare(b?.dm_profile?.full_name),
        multiple: 1,
      },
      ...getColumnSearchProps("dm_profile.full_name", "Full Name"),
    },
    {
      title: "Activation Date",
      dataIndex: "activation_date",
      sorter: (a, b) =>
        (a?.activation_date ? moment(a?.activation_date).unix() : 0) -
        (b?.activation_date ? moment(b?.activation_date).unix() : 0),
      render: (text, record, index) =>
        record?.activation_date &&
        moment(record?.activation_date)?.format("MM-DD-YYYY"),
    },
    {
      title: "Deactivation Date",
      dataIndex: "deactivation_date",
      sorter: (a, b) =>
        (a?.deactivation_date ? moment(a?.deactivation_date).unix() : 0) -
        (b?.deactivation_date ? moment(b?.deactivation_date).unix() : 0),
      render: (text, record, index) =>
        record?.deactivation_date &&
        moment(record?.deactivation_date)?.format("MM-DD-YYYY"),
    },

    {
      title: "Number of Unique Campaigns",
      dataIndex: "campaign_names",
      sorter: (a, b) =>
        (a?.campaign_names?.length || 0) - (b?.campaign_names?.length || 0),
      render: (text, record) => (
        <Row justify="space-between">
          <Col>{record.campaign_names.length || 0}</Col>
          <Col>
            <Button
              onClick={() => {
                setRecordForClientProjectsDrawer(record);
                setIsClientProjectsDrawerVisible(true);
              }}
              disabled={(record.campaign_names.length || 0) === 0}
            >
              Preview
            </Button>
          </Col>
        </Row>
      ),
    },
  ];

  const contentListNoTitle = {
    details: (
      <ul>
        <li
          style={{
            marginBottom: "14px",
          }}
        >
          There are{" "}
          <b>
            {dmSummary?.number_of_active_targets} unique active decision
            maker(s)
          </b>
          for the account <b>{account.name}</b>.
        </li>

        {dmSummary.deactive_dms && dmSummary.deactive_dms?.length > 0 && (
          <li>
            There are{" "}
            <b>
              {dmSummary.deactive_dms.length} deactivated decision marker(s)
            </b>
            in <b>{account.name}</b> account.
          </li>
        )}
      </ul>
    ),
    decision_makers: (
      <>
        <Button
          style={{ float: "right" }}
          onClick={() => onClickReport()}
          loading={isReportLoading}
        >
          Download Decision Makers
        </Button>
        <Table
          style={{ width: "100%" }}
          columns={decision_maker_columns}
          dataSource={decisionMakers}
        />
      </>
    ),
  };

  useEffect(() => {
    if (selectedHubspotCompanyId) {
      fetchAccount();
      fetchDmSummary();
      setDecisionMakerCardActiveTab("details");
      fetchBalance(selectedHubspotCompanyId);
      history.push(`/account-dashboard/${selectedHubspotCompanyId}`);
    } else if (!!accountIdFromUrlParams) {
      dispatch({
        type: "set-selected-hubspot-company-id",
        payload: accountIdFromUrlParams,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHubspotCompanyId]);

  return (
    <NavBar contentClass="landing-page-content">
      <Row style={{ width: "100%" }}>
        <Col style={{ width: "100%" }}>
          <Row style={{ width: "100%" }}>
            <Col style={{ width: "100%" }}>
              <Row
                style={{
                  alignItems: "center",
                  marginBottom: 40,
                  width: "100%",
                }}
              >
                <Col
                  style={{
                    width: "25%",
                  }}
                >
                  <Typography.Title level={2}>Account Summary</Typography.Title>
                </Col>
              </Row>
              {isLegacyAccount && (
                <Tag
                  icon={<ExclamationCircleOutlined />}
                  color="error"
                  style={{
                    fontSize: "14px",
                    maxWidth: "100%",
                    whiteSpace: "normal",
                  }}
                >
                  "{account.name}" is on a legacy pricing deal and does not use
                  Applecart Units. We currently <b>do not</b> support this view
                  for Accounts on legacy pricing deals.
                </Tag>
              )}
              {selectedHubspotCompanyId && !isLegacyAccount && (
                <>
                  <Row
                    style={{
                      marginBottom: 40,
                    }}
                    gutter={[16, 16]}
                  >
                    <Col
                      span={6}
                      style={{
                        minWidth: 400,
                      }}
                    >
                      <PacingCard />
                    </Col>
                    <Col
                      span={16}
                      style={{
                        height: "100%",
                      }}
                    >
                      <Card
                        style={{
                          width: "100%",
                          opacity: 0.8,
                        }}
                        tabList={tabListNoTitle}
                        activeTabKey={decisionMakerCardActiveTab}
                        onTabChange={ondecisionMakerCardTabChange}
                      >
                        {contentListNoTitle[decisionMakerCardActiveTab]}
                      </Card>
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
          {selectedHubspotCompanyId && !isLegacyAccount && (
            <>
              <Row style={{ width: "100%" }}>
                <Form.Item label="Contract Start Date">
                  <Select
                    onChange={(dateString) =>
                      setSelectedContractStartDate(dateString)
                    }
                    value={selectedContractStartDate}
                    style={{ width: 150, marginRight: 10 }}
                  >
                    {Object.keys(apiResponse)
                      .sort((a, b) => moment(b).unix() - moment(a).unix())
                      .map((contractStartDate) => (
                        <Select.Option
                          value={contractStartDate}
                          key={contractStartDate}
                        >
                          {contractStartDate}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                {/* <RangePicker
                  style={{ width: 300 }}
                  allowClear={false}
                  onChange={onChangeRangePicker}
                  picker="month"
                  defaultValue={[
                    selectedStartDateConsumption,
                    selectedEndDateConsumption,
                  ]}
                /> */}
              </Row>

              <Row style={{ width: 1200 }}>
                <Col style={{ width: 600 }}>
                  <b style={{ marginBottom: 20 }}>Projected AU Balance</b>
                  <AccountBalanceChart
                    selectedContractStartDate={selectedContractStartDate}
                    zeroDates={zeroDates}
                    apiResponse={apiResponse}
                    isLoading={isLoading}
                    chartDataBalance={chartDataBalance}
                  />
                </Col>
                <Col style={{ width: 600 }}>
                  <b style={{ marginBottom: 20 }}>AU Consumption</b>
                  <AccountConsumptionChart
                    isLoading={isLoading}
                    chartDataConsumption={chartDataConsumption}
                  />
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
      <ClientProjectsDrawer
        record={recordForClientProjectsDrawer}
        isVisible={isClientProjectsDrawerVisible}
        setIsVisible={setIsClientProjectsDrawerVisible}
      />
    </NavBar>
  );
};

export default Dashboard;
