import React, { useEffect, useState } from "react";
import { CreateDecisionMakerDrawer } from "../DecisionMakerList/CreateDecisionMakerDrawer";
import SelectDecisionMakerAsHVCDrawer from "./SelectDecisionMakerAsHVCDrawer";
import { addPidToDecisionMaker } from "../../../APIClient";
import { showSuccessMessage } from "../../../utils";
import ConnectionTypeComponent from "./ConnectionTypeComponent";

const AddColocatedAsHVCComponent = ({
  colocatedInfo,
  openDrawerState,
  setOpenDrawerState,
  attachHVCtoDecisionMaker,
  selectedDecisionMakerAsHVC,
  setSelectedDecisionMakerAsHVC,
  connectionTypeForm,
  decisionMaker,
  isConnectionTypeCompOpen,
  setIsConnectionTypeCompOpen,
}) => {
  const [isSelectDecisionMakerDrawerOpen, setIsSelectDecisionMakerDrawerOpen] =
    useState(false);
  const [isCreateDecisionMakerDrawerOpen, setIsCreateDecisionMakerDrawerOpen] =
    useState(false);

  useEffect(() => {
    if (
      colocatedInfo?.dm_info.filter((item) => item.is_hvc === false).length >
        0 &&
      openDrawerState
    ) {
      setIsSelectDecisionMakerDrawerOpen(openDrawerState);
    } else if (openDrawerState) {
      setIsCreateDecisionMakerDrawerOpen(openDrawerState);
    }
  }, [colocatedInfo, openDrawerState]);

  const addPid = async (dm_id, p_id) => {
    try {
      const resp = await addPidToDecisionMaker(dm_id, p_id);
      showSuccessMessage("P_ID attached");
      if (resp.error) {
        alert(resp.error);
      }
    } catch (error) {
      alert(error);
    }
  };

  const handleNewlyCreatedDecisionMaker = (dm_id, first_name, last_name) => {
    if (colocatedInfo.p_id && dm_id) {
      addPid(dm_id, colocatedInfo.p_id);
      setSelectedDecisionMakerAsHVC({
        id: dm_id,
        first_name: first_name,
        last_name: last_name,
      });
      setIsConnectionTypeCompOpen(true);
      setOpenDrawerState(false);
    }
  };

  const handleSelectedDecisionMaker = (record) => {
    if (record.id) {
      setSelectedDecisionMakerAsHVC(record);
      setIsSelectDecisionMakerDrawerOpen(false);
      setIsConnectionTypeCompOpen(true);
      setOpenDrawerState(false);
    }
  };

  const handleCloseSelectedDecisionMaker = (val) => {
    setIsSelectDecisionMakerDrawerOpen(val);
    setOpenDrawerState(val);
  };
  const handleCloseCreateDecisionMaker = (val) => {
    setIsCreateDecisionMakerDrawerOpen(val);
    setOpenDrawerState(val);
  };

  return (
    <>
      <SelectDecisionMakerAsHVCDrawer
        isVisible={isSelectDecisionMakerDrawerOpen}
        setIsVisible={handleCloseSelectedDecisionMaker}
        setIsCreateDecisionMakerDrawerOpen={setIsCreateDecisionMakerDrawerOpen}
        setSelectedDecisionMakerAsHVC={setSelectedDecisionMakerAsHVC}
        colocatedInfo={colocatedInfo}
        handleSelectedDecisionMaker={handleSelectedDecisionMaker}
      />

      <CreateDecisionMakerDrawer
        isVisible={isCreateDecisionMakerDrawerOpen}
        setIsVisible={handleCloseCreateDecisionMaker}
        handleNewlyCreatedDecisionMaker={handleNewlyCreatedDecisionMaker}
        optionalDefaultName={colocatedInfo}
      />
      <ConnectionTypeComponent
        isConnectionTypeCompOpen={isConnectionTypeCompOpen}
        setIsConnectionTypeCompOpen={setIsConnectionTypeCompOpen}
        decisionMaker={decisionMaker}
        selectedDecisionMakerAsHVC={selectedDecisionMakerAsHVC}
        connectionTypeForm={connectionTypeForm}
        attachHVCtoDecisionMaker={attachHVCtoDecisionMaker}
        isDrawer={true}
      />
    </>
  );
};

export default AddColocatedAsHVCComponent;
