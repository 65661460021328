import { notification } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";

export const titleCase = (str) => {
  const titleCased = str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
  return titleCased;
};

export const titleCaseNames = (str) => {
  const titleCased = str?.replace(/\p{L}+(\p{L}+)?/gu, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
  return titleCased;
};

export const parseHitCount = (relation, value) =>
  relation === "gte"
    ? `>${value?.toLocaleString()}`
    : `${value?.toLocaleString()}`;

export const flattenArray = (arr) => Array.prototype.concat.apply([], arr);

export const makeAutocompleteOptions = (arr) => {
  if (!arr) {
    return [];
  }
  return arr.map((val) => ({ value: val, label: val }));
};

export const isObjectEmpty = (obj) => Object.keys(obj).length === 0;

export const dedupeArray = (arr) => [...new Set(arr)];

export const roundNumberToNDecimals = (num, decimals) =>
  Math.round(num * 10 ** decimals) / 10 ** decimals;

export const numberRange = (num) => [...Array(num).keys()];

export const removeKeyFromObject = (obj, keyToRemove) =>
  Object.keys(obj)
    .filter(
      // eslint-disable-next-line eqeqeq
      (key) => key != keyToRemove
    )
    .reduce((newObj, key) => {
      // use `!=` rather than `!==` to allow for type conversion
      newObj[key] = obj[key];
      return newObj;
    }, {});

export function showSuccessMessage(message, duration = 4) {
  notification.open({
    placement: "bottomLeft",
    duration: duration,
    description: (
      <span>
        <CheckCircleTwoTone twoToneColor="#52c41a" />
        &nbsp;&nbsp;{message}
      </span>
    ),
    style: {
      backgroundColor: "#F6FFED",
      padding: "8px 24px",
      border: "1px solid #B7EB8F",
      borderRadius: "2px",
    },
  });
}

export function showDangerMessage(message) {
  notification.open({
    placement: "bottomLeft",
    description: (
      <span>
        <CheckCircleTwoTone twoToneColor="#52c41a" />
        &nbsp;&nbsp;{message}
      </span>
    ),
    style: {
      backgroundColor: "#f55a42",
      padding: "8px 24px",
      border: "1px solid #B7EB8F",
      borderRadius: "2px",
    },
  });
}

export function showWarningMessage(message) {
  notification.open({
    placement: "bottomLeft",
    description: (
      <span>
        <CheckCircleTwoTone twoToneColor="#52c41a" />
        &nbsp;&nbsp;{message}
      </span>
    ),
    style: {
      backgroundColor: "#FFFBE6",
      padding: "8px 24px",
      border: "1px solid #ffe58f",
      borderRadius: "2px",
    },
  });
}

export const getYearFromTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  return date.toUTCString().split(" ")[3];
};

export const formatTimestamp = (event_timestamp) => {
  if (event_timestamp) {
    const date = new Date(Date.parse(event_timestamp + "Z"));
    const dateOptions = {
      timeZone: "America/New_York",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };
    return date.toLocaleString("en-US", dateOptions);
  }
};

export const combineAddressElements = (address) => {
  if (address) {
    return createOneAddressString({
      address1: address[0],
      address2: address[1],
      city: address[2],
      state: address[3],
      zip: address[4],
    });
  }
};

export const createOneAddressString = (address) => {
  if (address && !Object.values(address).every((value) => value === null)) {
    const addressParts = [
      titleCase(
        (address.address1 !== null ? address.address1 : "") +
          (address.address2 !== null ? " " + address.address2 : "")
      ),
      [
        titleCase(address.city === null ? "" : address.city),
        address.state === null ? "" : address.state.toUpperCase(),
        address.zip === null ? "" : address.zip,
      ]
        .filter((part) => part)
        .join(", "),
    ];
    return addressParts.filter((part) => part).join(", ");
  }
};

export function createFilterOptionsFromData(data, field) {
  const uniqueFilterOptions = new Set();
  data.forEach((item) => {
    if (item.hasOwnProperty(`${field}`)) {
      uniqueFilterOptions.add(item[`${field}`]);
    }
  });
  const FilterOptions = Array.from(uniqueFilterOptions).map((field) => ({
    text: field === null ? "Unspecified" : field,
    value: field,
  }));

  return FilterOptions.sort((a, b) =>
    a.text === b.text
      ? 0
      : a.text === "Unspecified"
      ? 1
      : b.text === "Unspecified"
      ? -1
      : a.text.localeCompare(b.text)
  );
}

export function sortColumnWithNulls(a, b) {
  if (a === null && b === null) {
    return 0;
  } else if (a === null) {
    return 1;
  } else if (b === null) {
    return -1;
  } else {
    return a.toString().localeCompare(b.toString());
  }
}

export function daysSince(timestamp) {
  const now = new Date();
  const pastDate = new Date(timestamp);
  const diffInMilliseconds = now - pastDate;
  const millisecondsInADay = 24 * 60 * 60 * 1000;
  const diffInDays = Math.floor(diffInMilliseconds / millisecondsInADay);
  return diffInDays < 1
    ? "less than a day ago"
    : `${diffInDays} day${diffInDays === 1 ? `` : `s`} ago`;
}

export function checkFormChanged(initialValues, formItemValues) {
  // form item keys and intital value keys need to be identical
  return Object.keys(formItemValues).some(
    (key) => initialValues[key]?.toString() !== formItemValues[key]?.toString()
  );
}
