import axios from "axios";
import { store } from "./store/store";
import { userLogoutAction } from "./store/action-creators";

export const baseURL = process.env.REACT_APP_BASE_URL
  ? process.env.REACT_APP_BASE_URL
  : `http://localhost:8000`;

let instance = axios.create({
  baseURL: baseURL,
});

instance.defaults.xsrfCookieName = "csrftoken";
instance.defaults.xsrfHeaderName = "X-CSRFToken";
instance.defaults.withCredentials = true;

instance.interceptors.request.use((config) => {
  config["validateStatus"] = (status) => true;
  return config;
});

instance.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      userLogoutAction(false)(store.dispatch);
      console.log(
        "Your session has expired, close this window to be redirected to the login page"
      );
    } else if (response.status === 429) {
      alert(
        "Your account has been rate-limited. Please contact a system admin if you believe this was in error."
      );
    } else if (response.status === 403) {
      alert(response.data);
    }
    return response;
  },
  (error) => error
);

export const http = instance;
