import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../store";
import { useParams } from "react-router";
import {
  Drawer,
  Form,
  Button,
  Select,
  Spin,
  Affix,
  ConfigProvider,
  Tag,
  Tabs,
  Badge,
  InputNumber,
  Checkbox,
  Divider,
  Row,
  Col,
} from "antd";
import { InfoCircleTwoTone } from "@ant-design/icons";
import { states } from "../../../constants";
import {
  isObjectEmpty,
  dedupeArray,
  makeAutocompleteOptions,
} from "../../../utils";
import { StateDependentSelectionRow } from "../../SearchFormItems/StateDependentSelectionRow";
import { CongDistsByState, getStateFromCongDist } from "../constants/cong_dist";
import { CountiesByState } from "../constants/counties";
import { DesignatedMarketAreas } from "../constants/dmas";
import {
  StateLowerDistsByState,
  StateUpperDistsByState,
} from "../constants/state_dist";

const DesiredAudienceSizeForm = ({
  isVisible,
  setIsVisible,
  isGlobalFilter,
  segment,
  targetId,
  relevantSegments = [],
  refreshPage,
}) => {
  // redux state
  const desiredAudienceSizes = useSelector(
    (state) => state.desiredAudienceSizes
  )?.[targetId];
  const isLoading = useSelector((state) => state.isAudienceMemberFilterLoading);
  const target = useSelector((state) => state.target);

  const dispatch = useDispatch();
  const { updateOrCreateDesiredAudienceSizesAction } = bindActionCreators(
    actionCreators,
    dispatch
  );

  const [desiredSizeFormValues, setDesiredSizeFormValues] = useState({});

  const { projectId } = useParams();

  useEffect(() => {
    const initialFormValues = {};
    relevantSegments.forEach((s) => {
      initialFormValues[s.id] = desiredAudienceSizes?.[s.id]?.audience_size;
    });
    setDesiredSizeFormValues(initialFormValues);
  }, [isVisible, relevantSegments, desiredAudienceSizes]);

  return (
    <>
      <Spin spinning={isLoading}>
        <Tag
          icon={<InfoCircleTwoTone />}
          color="processing"
          style={{
            color: "black",
            padding: "5px",
            width: "auto",
            marginBottom: "30px",
          }}
        >
          You're setting audience size limit for{" "}
          <b>{`${!!segment ? segment.type_name : "all segments"}`}</b> belonging
          to <b>{isGlobalFilter ? "all decision makers" : target?.full_name}</b>
        </Tag>
        <Form
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 30 }}
          layout="horizontal"
        >
          {relevantSegments.map((item) => {
            return (
              <Form.Item
                key={item.id}
                label={
                  <div style={{ textAlign: "left" }}>{item.type_name} </div>
                }
                className="left-align-title"
              >
                <InputNumber
                  min={1}
                  value={desiredSizeFormValues[item.id]}
                  onChange={(v) => {
                    const newState = { ...desiredSizeFormValues };
                    newState[item.id] = v;
                    setDesiredSizeFormValues(newState);
                  }}
                />
              </Form.Item>
            );
          })}
        </Form>
      </Spin>
      <Affix
        offsetBottom={0}
        style={{ textAlign: "right", backgroundColor: "white" }}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "white",
            paddingBottom: "10px",
            paddingTop: "10px",
            borderTop: "1px solid #f0f0f0",
          }}
        >
          <Button
            onClick={() => setIsVisible(false)}
            style={{ marginRight: 8 }}
          >
            Cancel
          </Button>
          <Button
            onClick={async () => {
              try {
                const segmentSizes = Object.entries(desiredSizeFormValues).map(
                  ([k, v]) => ({
                    audience_segment: k,
                    audience_size: v || null,
                  })
                );
                updateOrCreateDesiredAudienceSizesAction(
                  projectId,
                  targetId || null,
                  segmentSizes,
                  refreshPage
                );
                setIsVisible(false);
              } catch (error) {
                alert(error);
              }
            }}
            type="primary"
            loading={isLoading}
          >
            Save
          </Button>
        </div>
      </Affix>
    </>
  );
};

const AudienceMemberFilterForm = ({
  isFirstDegree,
  isVisible,
  setIsVisible,
  isGlobalFilter,
  segment,
  audienceMemberFilter,
  refreshPage,
}) => {
  const [selectedStates, setSelectedStates] = useState([]);
  const [congDistStates, setCongDistStates] = useState([]);
  const [selectedCongressionalDistricts, setSelectedCongressionalDistricts] =
    useState([]);
  const [countyStates, setCountyStates] = useState([]);
  const [selectedCounties, setSelectedCounties] = useState([]);

  const [stateUpperDistrictStates, setStateUpperDistrictStates] = useState([]);
  const [selectedStateUpperDistricts, setSelectedStateUpperDistricts] =
    useState([]);

  const [stateLowerDistrictStates, setStateLowerDistrictStates] = useState([]);
  const [selectedStateLowerDistricts, setSelectedStateLowerDistricts] =
    useState([]);
  const [selectedDMAs, setSelectedDMAs] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedCityState, setSelectedCityState] = useState(null);
  const [selectedZipcodes, setSelectedZipcodes] = useState([]);
  const [selectedPiiZipcode, setSelectedPiiZipcode] = useState(false);
  const [selectedPiiAddr1, setSelectedPiiAddr1] = useState(false);
  const [selectedPiiDob, setSelectedPiiDob] = useState(false);

  const isLoading = useSelector((state) => state.isAudienceMemberFilterLoading);
  const target = useSelector((state) => state.target);
  const dispatch = useDispatch();
  const {
    deleteAudienceMemberFilterAction,
    createAudienceMemberFilterAction,
    updateAudienceMemberFilterAction,
  } = bindActionCreators(actionCreators, dispatch);

  const { projectId } = useParams();

  const filterParams = {
    states: dedupeArray(selectedStates.map((v) => v.toLowerCase())),
    congressional_districts: dedupeArray(
      selectedCongressionalDistricts.map((v) => v.toLowerCase())
    ),
    counties: dedupeArray(selectedCounties.map((v) => v.toLowerCase())),
    state_upper_districts: dedupeArray(
      selectedStateUpperDistricts.map((v) => v.toLowerCase())
    ),
    state_lower_districts: dedupeArray(
      selectedStateLowerDistricts.map((v) => v.toLowerCase())
    ),
    dmas: dedupeArray(selectedDMAs.map((v) => v.toLowerCase())),
    cities:
      selectedCityState?.length > 0
        ? dedupeArray(
            selectedCities.map(
              (v) => `${selectedCityState.toLowerCase()}-${v.toLowerCase()}`
            )
          )
        : [],
    zipcodes: dedupeArray(selectedZipcodes.map((v) => v.toLowerCase())),
    has_pii_zipcode: selectedPiiZipcode,
    has_pii_addr1: selectedPiiAddr1,
    has_pii_dob: selectedPiiDob,
  };

  const targetId = isGlobalFilter ? null : target?.id;
  const isFilterParamsEmpty = Object.values(filterParams)
    .map((val) => {
      const bool = typeof val === "object" ? !val?.length : !val;
      return bool;
    })
    .reduce((final, current) => final && current, true);

  useEffect(() => {
    if (audienceMemberFilter && !isObjectEmpty(audienceMemberFilter)) {
      setSelectedStates(
        audienceMemberFilter.states.map((v) => v.toUpperCase())
      );
      setCongDistStates(
        dedupeArray(
          audienceMemberFilter.congressional_districts?.map((v) =>
            getStateFromCongDist(v).toUpperCase()
          )
        ) || []
      );
      setSelectedCongressionalDistricts(
        audienceMemberFilter.congressional_districts?.map((v) =>
          v.toUpperCase()
        ) || []
      );

      setCountyStates(
        dedupeArray(
          audienceMemberFilter.counties?.map((v) =>
            v.substr(0, 2).toUpperCase()
          )
        ) || []
      );
      setSelectedCounties(
        audienceMemberFilter.counties?.map((v) => v.toUpperCase()) || []
      );

      setStateUpperDistrictStates(
        dedupeArray(
          audienceMemberFilter.filter_params?.state_upper_districts?.map((v) =>
            v.substr(0, 2).toUpperCase()
          )
        ) || []
      );
      setSelectedStateUpperDistricts(
        audienceMemberFilter.filter_params?.state_upper_districts?.map((v) =>
          v.toUpperCase()
        ) || []
      );

      setStateLowerDistrictStates(
        dedupeArray(
          audienceMemberFilter.filter_params?.state_lower_districts?.map((v) =>
            v.substr(0, 2).toUpperCase()
          )
        ) || []
      );
      setSelectedStateLowerDistricts(
        audienceMemberFilter.filter_params?.state_lower_districts?.map((v) =>
          v.toUpperCase()
        ) || []
      );

      setSelectedDMAs(
        audienceMemberFilter.dmas?.map((v) => v.toUpperCase()) || []
      );

      if (
        audienceMemberFilter.cities.length > 0 &&
        audienceMemberFilter.cities[0].charAt(2) === "-"
      ) {
        setSelectedCityState(
          audienceMemberFilter.cities?.[0]?.substr(0, 2).toUpperCase()
        );
        setSelectedCities(
          dedupeArray(audienceMemberFilter.cities.map((v) => v.substr(3)))
        );
      } else {
        setSelectedCityState(null);
        setSelectedCities([]);
      }

      setSelectedZipcodes(audienceMemberFilter.zipcodes);
      setSelectedPiiAddr1(audienceMemberFilter.filter_params.has_pii_addr1);
      setSelectedPiiZipcode(audienceMemberFilter.filter_params.has_pii_zipcode);
      setSelectedPiiDob(audienceMemberFilter.filter_params.has_pii_dob);
    } else {
      setSelectedStates([]);
      setCongDistStates([]);
      setSelectedCongressionalDistricts([]);

      setCountyStates([]);
      setSelectedCounties([]);

      setStateUpperDistrictStates([]);
      setSelectedStateUpperDistricts([]);

      setStateLowerDistrictStates([]);
      setSelectedStateLowerDistricts([]);

      setSelectedDMAs([]);
      setSelectedCities([]);
      setSelectedZipcodes([]);
      setSelectedPiiAddr1(false);
      setSelectedPiiZipcode(false);
      setSelectedPiiDob(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, audienceMemberFilter?.id]);

  return (
    <>
      <Spin spinning={isLoading}>
        <Tag
          icon={<InfoCircleTwoTone />}
          color="processing"
          style={{
            color: "black",
            padding: "5px",
            width: "auto",
            marginBottom: "30px",
          }}
        >
          You're filtering{" "}
          <b>{`${!!segment ? segment.type_name : "all segments"}`}</b> for{" "}
          <b>{isGlobalFilter ? "all decision makers" : target?.full_name}</b>
        </Tag>
        <>
          <Form layout="vertical" style={{ paddingBottom: "10px" }}>
            <Form.Item label="States">
              <Select
                showSearch
                allowClear
                mode="multiple"
                placeholder="Select states"
                style={{ width: 350 }}
                onChange={(e) => setSelectedStates(e)}
                value={selectedStates}
                options={makeAutocompleteOptions(states)}
              />
            </Form.Item>
            <Form.Item label="Congressional District">
              <StateDependentSelectionRow
                selectedValues={selectedCongressionalDistricts}
                setSelectedValues={setSelectedCongressionalDistricts}
                selectedStates={congDistStates}
                setSelectedStates={setCongDistStates}
                valuesByState={CongDistsByState}
              />
            </Form.Item>
            <Form.Item label="County">
              <StateDependentSelectionRow
                selectedValues={selectedCounties}
                setSelectedValues={setSelectedCounties}
                selectedStates={countyStates}
                setSelectedStates={setCountyStates}
                valuesByState={CountiesByState}
              />
            </Form.Item>
            <Form.Item label="State Upper District">
              <StateDependentSelectionRow
                selectedValues={selectedStateUpperDistricts}
                setSelectedValues={setSelectedStateUpperDistricts}
                selectedStates={stateUpperDistrictStates}
                setSelectedStates={setStateUpperDistrictStates}
                valuesByState={StateUpperDistsByState}
              />
            </Form.Item>
            <Form.Item label="State Lower District">
              <StateDependentSelectionRow
                selectedValues={selectedStateLowerDistricts}
                setSelectedValues={setSelectedStateLowerDistricts}
                selectedStates={stateLowerDistrictStates}
                setSelectedStates={setStateLowerDistrictStates}
                valuesByState={StateLowerDistsByState}
              />
            </Form.Item>
            <ConfigProvider
              renderEmpty={() => <div>Type and click to add new</div>}
            >
              <Form.Item label="DMAs">
                <Select
                  allowClear
                  mode="multiple"
                  placeholder="Select DMAs"
                  style={{ width: 350 }}
                  value={selectedDMAs}
                  onChange={(value) => setSelectedDMAs(value)}
                  options={makeAutocompleteOptions(DesignatedMarketAreas)}
                />
              </Form.Item>
            </ConfigProvider>
            <Form.Item label="Cities">
              <>
                <Row gutter={10}>
                  <Col>
                    <Select
                      showSearch
                      allowClear
                      placeholder="State"
                      style={{ width: 100 }}
                      onChange={(selectedState) => {
                        setSelectedCityState(selectedState);
                        if (!selectedState) {
                          setSelectedCities([]);
                        }
                      }}
                      value={selectedCityState}
                      options={makeAutocompleteOptions(states)}
                    />
                  </Col>
                  <Col>
                    <ConfigProvider
                      renderEmpty={() => <div>Type and click to add new</div>}
                    >
                      <Select
                        disabled={!selectedCityState}
                        showSearch
                        allowClear
                        mode="tags"
                        placeholder="Select cities"
                        style={{ width: 240 }}
                        onChange={(val) => setSelectedCities(val)}
                        value={selectedCities}
                      />
                    </ConfigProvider>
                  </Col>
                </Row>
              </>
            </Form.Item>
            <ConfigProvider
              renderEmpty={() => <div>Type and click to add new</div>}
            >
              <Form.Item
                label="Zipcodes"
                name="zipcodes"
                rules={[
                  {
                    pattern: new RegExp(/^[0-9]{5}(,[0-9]{5})*$/),
                    message: "zipcode must be a 5 digit number",
                  },
                ]}
              >
                <Select
                  allowClear
                  mode="tags"
                  placeholder="Select zipcodes"
                  style={{ width: 350 }}
                  value={selectedZipcodes}
                  onChange={(value) => setSelectedZipcodes(value)}
                />
              </Form.Item>
            </ConfigProvider>
          </Form>
          <Divider />
          <div style={{ paddingBottom: "20px", fontSize: "medium" }}>
            Only Include Audiences where PII exists
          </div>
          <Checkbox
            checked={selectedPiiAddr1}
            defaultChecked={false}
            onChange={(value) => setSelectedPiiAddr1(value.target.checked)}
          >
            Street Address
          </Checkbox>
          <Checkbox
            checked={selectedPiiZipcode}
            defaultChecked={false}
            onChange={(value) => setSelectedPiiZipcode(value.target.checked)}
          >
            Zipcode
          </Checkbox>
          <Checkbox
            checked={selectedPiiDob}
            defaultChecked={false}
            onChange={(value) => setSelectedPiiDob(value.target.checked)}
          >
            DOB
          </Checkbox>
        </>
      </Spin>
      <Affix
        offsetBottom={0}
        style={{ textAlign: "right", backgroundColor: "white" }}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "white",
            paddingBottom: "10px",
            paddingTop: "10px",
            borderTop: "1px solid #f0f0f0",
          }}
        >
          <Button
            onClick={() => setIsVisible(false)}
            style={{ marginRight: 8 }}
          >
            Cancel
          </Button>
          <Button
            onClick={async () => {
              try {
                if (isFilterParamsEmpty && audienceMemberFilter) {
                  deleteAudienceMemberFilterAction(
                    projectId,
                    audienceMemberFilter,
                    refreshPage
                  );
                } else if (audienceMemberFilter) {
                  updateAudienceMemberFilterAction(
                    audienceMemberFilter.id,
                    projectId,
                    targetId,
                    segment?.id,
                    filterParams,
                    isFirstDegree,
                    refreshPage
                  );
                } else if (!isFilterParamsEmpty) {
                  createAudienceMemberFilterAction(
                    projectId,
                    targetId,
                    segment?.id || null,
                    filterParams,
                    isFirstDegree,
                    refreshPage
                  );
                }
                setIsVisible(false);
              } catch (error) {
                alert(error);
              }
            }}
            type="primary"
            loading={isLoading}
          >
            Save
          </Button>
        </div>
      </Affix>
    </>
  );
};

const AudienceMemberFilterDrawer = ({
  isVisible,
  setIsVisible,
  isGlobalFilter,
  segments,
  segment,
  refreshPage,
}) => {
  const target = useSelector((state) => state.target);
  const targetId = isGlobalFilter ? null : target?.id;
  const project = useSelector((state) => state.project);
  const selectedTargetSegments = useSelector(
    (state) => state.selectedTargetSegments
  );

  const relevantSegments = isGlobalFilter
    ? project?.audience_segments
    : segment
    ? [segment]
    : selectedTargetSegments;
  const desiredAudienceSizes = useSelector(
    (state) => state.desiredAudienceSizes
  )?.[targetId];
  const hasDesiredSize = relevantSegments?.some(
    (item) => !!desiredAudienceSizes?.[item.id]?.audience_size
  );

  const audienceMemberFilters = useSelector(
    (state) => state.audienceMemberFilters
  );

  const filters =
    audienceMemberFilters?.[targetId || null]?.[segment?.id || null];

  const props = {
    isVisible,
    setIsVisible,
    isGlobalFilter,
    segment,
    refreshPage,
  };

  return (
    <Drawer
      title={
        isGlobalFilter
          ? "Global Filters"
          : !!segment
          ? "Local Filters"
          : "Decision Maker Filters"
      }
      onClose={() => setIsVisible(false)}
      visible={isVisible}
      width={600}
      bodyStyle={{ paddingBottom: 20 }}
    >
      <Tabs>
        {!segment && (
          <Tabs.TabPane
            key="desired_audience_size"
            tab={
              <Badge
                dot
                offset={[5, -2]}
                style={{ backgroundColor: "#40a9ff" }}
                count={hasDesiredSize ? 1 : 0}
              >
                Desired Audience Size
              </Badge>
            }
          >
            <DesiredAudienceSizeForm
              isVisible={isVisible}
              setIsVisible={setIsVisible}
              isGlobalFilter={isGlobalFilter}
              segment={segment}
              targetId={targetId}
              relevantSegments={relevantSegments}
              refreshPage={refreshPage}
            />
          </Tabs.TabPane>
        )}

        <Tabs.TabPane
          key="first_degree"
          tab={
            <Badge
              dot
              offset={[5, -2]}
              style={{ backgroundColor: "#40a9ff" }}
              count={filters?.firstDegree ? 1 : 0}
            >
              First degree
            </Badge>
          }
        >
          <AudienceMemberFilterForm
            {...props}
            audienceMemberFilter={filters?.firstDegree}
            isFirstDegree={true}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          key="second_degree"
          tab={
            <Badge
              dot
              offset={[5, -2]}
              style={{ backgroundColor: "#40a9ff" }}
              count={filters?.secondDegree ? 1 : 0}
            >
              Second degree
            </Badge>
          }
        >
          <AudienceMemberFilterForm
            {...props}
            audienceMemberFilter={filters?.secondDegree}
            isFirstDegree={false}
          />
        </Tabs.TabPane>
      </Tabs>
    </Drawer>
  );
};

export default AudienceMemberFilterDrawer;
