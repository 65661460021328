import {
  createAudienceMemberFilter,
  deleteAudienceMemberFilter,
  deleteAudienceMemberLog,
  deleteAudienceMembersBySegment,
  deleteAudienceSegment,
  deleteTpl,
  expireCookies,
  getAudienceBreakdown,
  getAudienceMemberFilters,
  getAudienceMemberLogs,
  getAudienceSegments,
  getDesiredAudienceSizes,
  getProject,
  getS3DataUploadLogs,
  getTarget,
  getTargetDspFiles,
  getUserGroups,
  logout,
  updateAudienceMemberFilter,
  updateOrCreateDesiredAudienceSizes,
  updateProject,
  updateTarget,
  updateTargetDCMIds,
} from "../../APIClient";
import { resetCookie } from "../../util/cookies";

// Target actions
export const getTargetAction = (targetId, projectId) => {
  return async (dispatch) => {
    dispatchIsTargetLoading(dispatch, true);
    const targetData = await getTarget(targetId, projectId);
    dispatch({
      type: "get-target",
      payload: targetData,
    });
    dispatchIsTargetLoading(dispatch, false);
  };
};

export const deleteTargetAction = (targetId, projectId) => {
  return async (dispatch) => {
    dispatchIsTargetLoading(dispatch, true);
    await deleteTpl(targetId, projectId);
    dispatch({
      type: "delete-target",
      payload: targetId,
    });
    dispatchIsTargetLoading(dispatch, false);
  };
};

export const updateTargetAction = (targetParams) => {
  return async (dispatch) => {
    dispatchIsTargetLoading(dispatch, true);
    const updatedTarget = await updateTarget(targetParams);
    dispatch({
      type: "update-target",
      payload: updatedTarget,
    });
    dispatchIsTargetLoading(dispatch, false);
  };
};

export const updateTargetDCM = (targetId, projectId, DCMIds) => {
  return async (dispatch) => {
    dispatchIsTargetLoading(dispatch, true);
    const targetData = await updateTargetDCMIds(targetId, projectId, DCMIds);
    dispatch({
      type: "update-target",
      payload: targetData,
    });
    dispatchIsTargetLoading(dispatch, false);
  };
};

export const setTargetNull = () => {
  return (dispatch) => {
    dispatch({
      type: "set-target-null",
    });
  };
};

// Target Loading actions
const dispatchIsTargetLoading = (dispatch, isLoading) => {
  dispatch({ type: "is-target-loading", payload: isLoading });
};

export const setIsTargetLoading = (isLoading) => {
  return async (dispatch) => {
    dispatch({
      type: "is-target-loading",
      payload: isLoading,
    });
  };
};

// Project actions
export const getProjectAction = (projectId) => {
  return async (dispatch) => {
    dispatchIsProjectLoading(dispatch, true);

    try {
      const data = await getProject(projectId);
      dispatch({
        type: "get-project",
        payload: data,
      });
    } catch (error) {
      alert(error);
    }
    dispatchIsProjectLoading(dispatch, false);
  };
};

export const updateProjectAction = (projectId, params) => {
  return async (dispatch) => {
    dispatchIsProjectLoading(dispatch, true);
    const data = await updateProject(projectId, params);
    dispatch({
      type: "update-project",
      payload: data,
    });
    dispatchIsProjectLoading(dispatch, false);
  };
};

export const setProjectNull = () => {
  return (dispatch) => {
    dispatch({
      type: "set-project-null",
    });
  };
};

// Project loading actions
const dispatchIsProjectLoading = (dispatch, isLoading) => {
  dispatch({ type: "is-project-loading", payload: isLoading });
};

// Project drawer actions
export const setIsOpenProjectDrawer = (dispatch, isOpen) => {
  dispatch({ type: "set-is-open-project-drawer", payload: isOpen });
};

// Export UI actions
export const setIsOpenExportUIDrawer = (dispatch, isOpen) => {
  dispatch({ type: "set-is-open-export-ui-drawer", payload: isOpen });
};

// S3 Upload Log actions
export const getS3DataUploadLogsAction = (projectId) => {
  return async (dispatch) => {
    const data = await getS3DataUploadLogs(projectId);
    dispatch({
      type: "get-s3-data-upload-logs",
      payload: data,
    });
  };
};

// User actions
export const userLogoutAction = (apiLogout) => {
  return async (dispatch) => {
    // backward compatibility for users who last logged in with original cookie domain, remove when all users have reset their cookies [sc-8963]
    resetCookie("abba-id-token", `.${process.env.REACT_APP_BASE_URL}`);
    if (apiLogout) {
      await logout();
    } else {
      await expireCookies();
    }
    dispatch({ type: "logout" });
  };
};

export const updateUserGroupsAction = () => {
  return async (dispatch) => {
    try {
      const userGroups = await getUserGroups();
      dispatch({
        type: "update-user-groups",
        payload: userGroups,
      });
    } catch (error) {
      console.error(`Failed to fetch user groups: ${error}`);
    }
  };
};

// Audience Member Filter actions
export const getAudienceMemberFiltersAction = (projectId) => {
  return async (dispatch) => {
    dispatchIsAudienceMemberFilterLoading(dispatch, true);
    const filterData = await getAudienceMemberFilters(projectId);
    await dispatch({
      type: "get-filters",
      payload: filterData,
    });
    dispatchIsAudienceMemberFilterLoading(dispatch, false);
  };
};

export const createAudienceMemberFilterAction = (
  projectId,
  targetProjectLink,
  audienceSegment,
  filterParams,
  firstDegree,
  callBack
) => {
  return async (dispatch) => {
    dispatchIsAudienceMemberFilterLoading(dispatch, true);
    const data = await createAudienceMemberFilter(
      projectId,
      targetProjectLink,
      audienceSegment,
      filterParams,
      firstDegree
    );
    await dispatch({
      type: "create-filter",
      payload: data,
    });
    dispatchIsAudienceMemberFilterLoading(dispatch, false);
    if (callBack) {
      callBack();
    }
  };
};

export const updateAudienceMemberFilterAction = (
  filterId,
  projectId,
  targetProjectLink,
  audienceSegment,
  filterParams,
  firstDegree,
  callBack
) => {
  return async (dispatch) => {
    dispatchIsAudienceMemberFilterLoading(dispatch, true);
    const data = await updateAudienceMemberFilter(
      filterId,
      projectId,
      targetProjectLink,
      audienceSegment,
      filterParams,
      firstDegree
    );
    await dispatch({
      type: "update-filter",
      payload: data,
    });
    dispatchIsAudienceMemberFilterLoading(dispatch, false);
    if (callBack) {
      callBack();
    }
  };
};

export const deleteAudienceMemberFilterAction = (
  projectId,
  filter,
  callBack
) => {
  return async (dispatch) => {
    dispatchIsAudienceMemberFilterLoading(dispatch, true);
    await deleteAudienceMemberFilter(projectId, filter.id);
    await dispatch({
      type: "delete-filter",
      payload: filter,
    });
    dispatchIsAudienceMemberFilterLoading(dispatch, false);
    if (callBack) {
      callBack();
    }
  };
};

// Audience Member Filter Loading actions
const dispatchIsAudienceMemberFilterLoading = (dispatch, isLoading) => {
  dispatch({ type: "is-filter-loading", payload: isLoading });
};

// Desired Audience Size actions
export const getDesiredAudienceSizesAction = (projectId) => {
  return async (dispatch) => {
    dispatchIsAudienceMemberFilterLoading(dispatch, true);
    const data = await getDesiredAudienceSizes(projectId);
    await dispatch({
      type: "get-desired-sizes",
      payload: data,
    });
    dispatchIsAudienceMemberFilterLoading(dispatch, false);
  };
};

export const updateOrCreateDesiredAudienceSizesAction = (
  projectId,
  targetId,
  segmentSizes,
  callBack
) => {
  return async (dispatch) => {
    dispatchIsAudienceMemberFilterLoading(dispatch, true);
    const data = await updateOrCreateDesiredAudienceSizes(
      projectId,
      targetId,
      segmentSizes
    );
    await dispatch({
      type: "update-or-create-desired-size",
      payload: data,
    });
    dispatchIsAudienceMemberFilterLoading(dispatch, false);
    if (callBack) {
      callBack();
    }
  };
};

// audience member create logs actions
export const getAudienceCreationLogsAction = (projectId, targetId) => {
  return async (dispatch) => {
    dispatchIsAudienceCreationLogsLoading(dispatch, true);
    const data = await getAudienceMemberLogs(projectId, targetId);
    await dispatch({
      type: "get-creation-logs",
      payload: data,
    });
    dispatchIsAudienceCreationLogsLoading(dispatch, false);
  };
};

export const deleteAudienceCreationLogsAction = (projectId, creationLog) => {
  return async (dispatch) => {
    dispatchIsAudienceCreationLogsLoading(dispatch, true);
    await deleteAudienceMemberLog(projectId, creationLog.id);
    await dispatch({
      type: "delete-creation-log",
      payload: creationLog,
    });
    dispatchIsAudienceCreationLogsLoading(dispatch, false);
  };
};

// Audience Create logs loading actions
const dispatchIsAudienceCreationLogsLoading = (dispatch, isLoading) => {
  dispatch({ type: "is-create-logs-loading", payload: isLoading });
};

export const isAudienceCreationLogsLoadingAction = (isLoading) => {
  return async (dispatch) => {
    dispatchIsAudienceCreationLogsLoading(dispatch, isLoading);
  };
};

// Audience Segment actions
export const getSegmentsAction = (targetId, projectId) => {
  return async (dispatch) => {
    dispatchIsAudienceSegmentsLoading(dispatch, true);
    const data = await getAudienceSegments(projectId, targetId);
    dispatch({
      type: "get-segments",
      payload: data,
    });
    dispatchIsAudienceSegmentsLoading(dispatch, false);
  };
};

export const deleteSegmentAction = (
  segmentId,
  projectId,
  source,
  segment_type
) => {
  return async (dispatch) => {
    dispatchIsAudienceSegmentsLoading(dispatch, true);
    try {
      await deleteAudienceSegment(segmentId, projectId, source, segment_type);
      dispatch({
        type: "delete-segment",
        payload: segmentId,
      });
    } catch (error) {
      alert(`Deletion failed: ${error}`);
    }
    dispatchIsAudienceSegmentsLoading(dispatch, false);
  };
};

export const deleteAudienceMembersAction = (
  projectId,
  segmentId,
  targetId,
  source,
  segment_type
) => {
  return async (dispatch) => {
    dispatchIsAudienceSegmentsLoading(dispatch, true);
    try {
      await deleteAudienceMembersBySegment(
        projectId,
        segmentId,
        targetId,
        source,
        segment_type
      );
      dispatch({
        type: "delete-audience-members",
        payload: segmentId,
      });
    } catch (error) {
      alert(`Deletion failed: ${error}`);
    }
    dispatchIsAudienceSegmentsLoading(dispatch, false);
  };
};

// Audience Segments loading actions
const dispatchIsAudienceSegmentsLoading = (dispatch, isLoading) => {
  dispatch({ type: "is-segments-loading", payload: isLoading });
};

export const setPackageAction = (packge) => {
  return async (dispatch) => {
    dispatch({
      type: "set-package",
      payload: packge,
    });
  };
};

export const setDecisionMakerAction = (decisionMaker) => {
  return async (dispatch) => {
    dispatch({
      type: "set-decision-maker",
      payload: decisionMaker,
    });
  };
};

// breakdown actions
export const getBreakdownAction = (projectId) => {
  return async (dispatch) => {
    dispatch({ type: "is-audience-breakdown-loading", payload: true });

    try {
      const resp = await getAudienceBreakdown(projectId);
      dispatch({ type: "set-audience-breakdown-up-to-date" });
      dispatch({
        type: "get-breakdown",
        payload: resp,
      });
    } catch (error) {
      alert(error);
    }
    dispatch({ type: "is-audience-breakdown-loading", payload: false });
  };
};

// dsp export files
export const getDSPExportFilesAction = (projectId) => {
  return async (dispatch) => {
    const data = await getTargetDspFiles(projectId);
    dispatch({
      type: "set-dsp-export-files",
      payload: data,
    });
  };
};
