import React, { useEffect, useState } from "react";
import ColocatedDrawer from "../../MPSSearch/ColocatedDrawer";
import AddColocatedAsHVCComponent from "./AddColocatedAsHVCComponent";
import {
  findNeighbors,
  getDecisionMakerByPIDs,
  getPii,
  getDecisionMakerByExactNames,
} from "../../../APIClient";
import { green, grey } from "@ant-design/colors";
import {
  PlusCircleOutlined,
  SearchOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { Button, Popover, Space, Tooltip } from "antd";

const HighValueConnectionsColocatedPane = ({
  isColocatedPaneOpen,
  hvcs,
  attachHVCtoDecisionMaker,
  decisionMaker,
  selectedDecisionMakerAsHVC,
  setSelectedDecisionMakerAsHVC,
  connectionTypeForm,
  isConnectionTypeCompOpen,
  setIsConnectionTypeCompOpen,
}) => {
  const [colocated, setColocated] = useState(null);
  const [enrichedColocated, setEnrichedColocated] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openDrawerState, setOpenDrawerState] = useState(false);
  const [colocatedInfo, setColocatedInfo] = useState();

  useEffect(() => {
    const getColocated = async () => {
      setIsLoading(true);
      const neighbors = await findNeighbors(decisionMaker.p_id, 5);
      let colocated_pids = neighbors
        ?.filter(({ is_colocated }) => is_colocated === true)
        .map(({ p_id }) => p_id);
      if (colocated_pids) {
        const pii = await getPii(colocated_pids);
        setColocated(pii);
      }
      setIsLoading(false);
    };
    if (decisionMaker.p_id && isColocatedPaneOpen) {
      getColocated();
    }
  }, [isColocatedPaneOpen, decisionMaker.p_id]);

  useEffect(() => {
    const fetchDecisionMakerIDsByPIDs = async () => {
      try {
        const data = await getDecisionMakerByPIDs(
          colocated?.map((item) => item.p_id)
        );
        return data;
      } catch (error) {
        alert(error);
      }
    };
    const fetchDecisionMakerIDsByNames = async () => {
      try {
        const data = await getDecisionMakerByExactNames(
          colocated?.map((item) => {
            return {
              input_id: item.p_id.toString(),
              name: { first_name: item.first_name, last_name: item.last_name },
            };
          })
        );
        return data;
      } catch (error) {
        alert(error);
      }
    };
    const combineDMData = (byPIDs, byNames) => {
      const formatList = (list, id_name) => {
        return list.map((item) => ({
          p_id: parseInt(item[id_name]),
          dm_info: item.dm_info,
        }));
      };
      const mergeDMData = (byPIDs, byNames) => {
        const resultDict = {};

        [...byPIDs, ...byNames].forEach((item) => {
          resultDict[item.p_id] = resultDict[item.p_id] || {
            p_id: item.p_id,
            dm_info: [],
          };
          resultDict[item.p_id].dm_info.push(...item.dm_info);
        });
        const finalList = Object.values(resultDict).map((item) => ({
          p_id: item.p_id,
          dm_info: [...new Set(item.dm_info.map(JSON.stringify))].map(
            JSON.parse
          ),
        }));
        return finalList;
      };
      const combinedDMList = mergeDMData(
        formatList(byPIDs, "p_id"),
        formatList(byNames, "input_id")
      );
      return combinedDMList;
    };
    const enrichColocatedWithDecisionMakerAndHVCData = async () => {
      setIsLoading(true);
      const byPIDs = await fetchDecisionMakerIDsByPIDs();
      const byNames = await fetchDecisionMakerIDsByNames();
      const combinedDecisionMakerData = combineDMData(byPIDs, byNames);
      const decisionMakersWhoAreHVC = combinedDecisionMakerData.map((item) => ({
        p_id: item.p_id,
        info: item.dm_info?.map((val) => ({
          ...val,
          is_hvc: hvcs?.some((hvc) => val.id === hvc.hvc_decision_maker_id),
        })),
        is_hvc: item.dm_info?.some((val) =>
          hvcs?.some((hvc) => val.id === hvc.hvc_decision_maker_id)
        ),
      }));
      const colocatedEnrichedWithDecisionMakerAndHVCData = colocated.map(
        (item) => ({
          ...item,
          dm_info:
            decisionMakersWhoAreHVC.find((val) => item.p_id === val.p_id)
              ?.info || [],
          is_hvc:
            decisionMakersWhoAreHVC.find((val) => item.p_id === val.p_id)
              ?.is_hvc || false,
        })
      );
      setEnrichedColocated(colocatedEnrichedWithDecisionMakerAndHVCData);
      setIsLoading(false);
    };
    if (colocated) {
      enrichColocatedWithDecisionMakerAndHVCData();
    }
  }, [colocated, hvcs]);

  const handleOpenAddColocatedAsHVCDrawer = (record) => {
    setColocatedInfo(record);
    setOpenDrawerState(true);
  };

  const colocatedHVCColumns = [
    {
      title: "Is HVC",
      dataIndex: "is_hvc",
      key: "is_hvc",
      render: (text, record, index) =>
        record?.is_hvc ? (
          <CheckCircleOutlined style={{ color: green.primary }} />
        ) : (
          <CloseCircleOutlined style={{ color: grey.primary }} />
        ),
    },
    {
      title: "Actions",
      dataIndex: "dm_info",
      key: "actions",
      render: (text, record, index) =>
        record?.is_hvc ? (
          <Space size={1}>
            <Popover
              trigger="click"
              content={record.dm_info
                ?.filter(({ is_hvc }) => is_hvc === true)
                .map((item, index) => (
                  <p key={index}>
                    <b>
                      {item.first_name} {item.last_name}
                    </b>
                    {" -"} {item.decision_maker_type}
                  </p>
                ))}
              title={`Review the following HVCs:`}
            >
              <Tooltip title="Review" placement="bottom">
                <Button type="link" icon={<SearchOutlined />} />
              </Tooltip>
            </Popover>
            <Tooltip title="Add HVC" placement="bottom">
              <Button
                type="link"
                onClick={() => handleOpenAddColocatedAsHVCDrawer(record)}
                icon={<PlusCircleOutlined />}
              />
            </Tooltip>
          </Space>
        ) : (
          <Tooltip title="Add HVC" placement="bottom">
            <Button
              type="link"
              onClick={() => handleOpenAddColocatedAsHVCDrawer(record)}
              icon={<PlusCircleOutlined />}
            />
          </Tooltip>
        ),
    },
  ];
  if (!isColocatedPaneOpen) {
    return null;
  }
  return (
    <>
      <ColocatedDrawer
        data={enrichedColocated}
        isLoading={isLoading}
        optionalColumns={colocatedHVCColumns}
      />
      <AddColocatedAsHVCComponent
        colocatedInfo={colocatedInfo}
        openDrawerState={openDrawerState}
        setOpenDrawerState={setOpenDrawerState}
        attachHVCtoDecisionMaker={attachHVCtoDecisionMaker}
        decisionMaker={decisionMaker}
        selectedDecisionMakerAsHVC={selectedDecisionMakerAsHVC}
        setSelectedDecisionMakerAsHVC={setSelectedDecisionMakerAsHVC}
        connectionTypeForm={connectionTypeForm}
        setIsConnectionTypeCompOpen={setIsConnectionTypeCompOpen}
        isConnectionTypeCompOpen={isConnectionTypeCompOpen}
      />
    </>
  );
};
export default HighValueConnectionsColocatedPane;
