import React, { Fragment, useEffect, useState } from "react";
import { Space, Table, Typography, Button, Select, Modal, Input } from "antd";
import { titleCase } from "../../utils";
import { useSelector } from "react-redux";
import moment from "moment";

import FileNameRow from "./FileNameRow";
import {
  createAudienceExportV3,
  getProject,
  updateProject,
} from "../../APIClient";
import { ExportOutlined, SaveOutlined } from "@ant-design/icons";
import { removeNonAlphanumerics } from "./groupingsUtil";

const dateFormat = "YYYY-MM-DD";

const ExportConfirmation = ({
  selectedRows,
  dspexportFiles,
  dmWithoutSegment,
}) => {
  const getExistingFiles = (dspexportFiles) => {
    return [
      ...new Set(
        Object.values(dspexportFiles)
          .flat()
          .map(({ file_name }) => file_name)
      ),
    ];
  };
  let existingFiles = getExistingFiles(dspexportFiles);

  const separateNewFilesFromExisitngFiles = (selectedRows, existingFiles) => {
    const overwriteFiles = [];
    const newFiles = [];

    selectedRows.forEach(({ file_name }) => {
      existingFiles?.find((ef) =>
        removeNonAlphanumerics(file_name).includes(removeNonAlphanumerics(ef))
      )
        ? overwriteFiles.push(file_name)
        : newFiles.push(file_name);
    });

    return { newFiles, overwriteFiles };
  };

  const { newFiles, overwriteFiles } = separateNewFilesFromExisitngFiles(
    selectedRows,
    existingFiles
  );

  return (
    <span>
      {newFiles.length > 0 && (
        <Fragment>
          <>
            <h4>
              {" "}
              The following {newFiles.length > 1 && newFiles.length} new file
              {newFiles.length > 1 && "s"} will be created
            </h4>
            <ul style={{ marginLeft: 0, paddingLeft: 0 }}>
              {newFiles.map((file_name) => (
                <li
                  key={newFiles.indexOf(file_name)}
                  style={{ listStyleType: "none" }}
                >
                  {file_name}
                </li>
              ))}
            </ul>
          </>
          <>
            {dmWithoutSegment?.length > 0 && (
              <h4> The following DMs are not in a segment</h4>
            )}
            <ul style={{ marginLeft: 0, paddingLeft: 0 }}>
              {dmWithoutSegment?.map((dm) => (
                <li key={dm} style={{ listStyleType: "none" }}>
                  {dm}
                </li>
              ))}
            </ul>
          </>
        </Fragment>
      )}
      {overwriteFiles.length > 0 && (
        <>
          <h4>
            {" "}
            The following {overwriteFiles.length > 1 &&
              overwriteFiles.length}{" "}
            file
            {overwriteFiles.length > 1 && "s"} will be overwritten
          </h4>
          <ul style={{ marginLeft: 0, paddingLeft: 0 }}>
            {overwriteFiles.map((file_name) => (
              <li
                key={overwriteFiles.indexOf(file_name)}
                style={{ listStyleType: "none" }}
              >
                {file_name}
              </li>
            ))}
          </ul>
        </>
      )}
    </span>
  );
};

const StartNewExportTab = ({
  setIsLoading,
  setSelectedTab,
  dspexportFiles,
  fetchDSPExportFiles,
  getS3DataUploadLogs,
  setRefreshTpls,
  refreshTpls = true,
  dspFilenameBase,
  setDspFileNameBase,
}) => {
  const project = useSelector((state) => state.project);
  const s3DataUploadLogs = useSelector((state) => state.s3DataUploadLogs);
  const validateString = (value) => {
    const regEx = /[^a-z0-9-]/gim;
    value = value.trim().replaceAll(" ", "-");
    value = value.replaceAll(regEx, "");
    return value;
  };

  const getDefaultPrefix = () => {
    return dspFilenameBase ? validateString(dspFilenameBase) : "";
  };

  const [globalProjectName, setGlobalProjectName] = useState(
    getDefaultPrefix()
  );

  const [globalSuffix, setGlobalSuffix] = useState("");
  const [globalDate, setGlobalDate] = useState(moment().format(dateFormat));

  const getDefaultFileNames = (segmentsArr) => {
    let intitialFileNames = {};
    for (let seg in segmentsArr) {
      intitialFileNames[seg] = `ac_${getDefaultPrefix()}_${segmentsArr[
        seg
      ]?.replaceAll(/(-| )/gi, "_")}_${globalDate}_${globalSuffix}.csv`;
    }
    return intitialFileNames;
  };

  const [segments, setSegments] = useState([]);
  const [tpls, setTpls] = useState();
  const [fileNames, setFileNames] = useState({});

  const [selectedRows, setSelectedRows] = useState([]);
  const [onboarding, setOnboarding] = useState("Individual");
  const [tableData, setTableData] = useState();
  const [dmWithoutSegment, setDmWithoutSegment] = useState([]);
  const [hasDSPFileNameBase, setHasDSPFileNameBase] = useState(
    !!dspFilenameBase
  );

  const updateTpls = async () => {
    let projectUpdated = await getProject(project?.id);
    const initialTpls = projectUpdated?.target_project_links
      .filter((tpl) => !tpl.package && tpl.is_active)
      .map((tpl) => {
        return {
          id: tpl.id,
          key: tpl.id,
          name: `${tpl.dm_profile?.first_name} ${tpl.dm_profile?.last_name}`,
          groups: tpl.groups,
          individual: false,
          selected_for_export: tpl.selected_for_export,
        };
      });

    const groups = initialTpls
      .map((tpl) => tpl.groups)
      .flat()
      .sort((a, b) => a.localeCompare(b));
    const names = initialTpls
      .filter((tpl) => tpl["selected_for_export"])
      .map((tpl) => tpl.name.toLowerCase())
      .flat()
      .sort((a, b) => a.localeCompare(b));
    setDmWithoutSegment(
      initialTpls
        .filter((tpl) => tpl.groups.length <= 0 && !tpl.selected_for_export)
        .map((tpl) => tpl.name)
    );
    let segmentsArray = ["COMBINED", ...new Set(groups), ...names];
    let initialFileNames =
      fileNames?.length > 0 ? fileNames : getDefaultFileNames(segmentsArray);
    setSegments(segmentsArray);
    setFileNames(initialFileNames);
    const data = segmentsArray?.map((seg) => {
      return {
        key: segmentsArray?.indexOf(seg),
        group: hasUpperCase(seg) ? seg : titleCase(seg),
        file_name: (
          <FileNameRow
            id={segmentsArray.indexOf(seg)}
            globalProjectName={globalProjectName}
            targetOrGroup={seg}
            validateString={validateString}
            globalDate={globalDate}
            globalSuffix={globalSuffix}
            setFileNames={setFileNames}
            rowFileNames={initialFileNames}
          />
        ),
      };
    });
    setTableData(data);
    setTpls(initialTpls);
  };

  const updateDspFileNameBase = async () => {
    if (project && dspFilenameBase) {
      const params = {
        dsp_filename_base: dspFilenameBase,
      };
      await updateProject(project.id, params);
      setGlobalProjectName(dspFilenameBase);
      setRefreshTpls(true);
      setHasDSPFileNameBase(true);
    }
  };

  useEffect(() => {
    if (project?.target_project_links && refreshTpls) {
      updateTpls();
      setRefreshTpls(false);
    } // eslint-disable-next-line
  }, [project, refreshTpls, fileNames]);

  const { Title } = Typography;
  const { confirm } = Modal;

  const rowSelection = {
    onChange: (rowKeys, rows) => setSelectedRows(rows.length < 1 ? [] : rows),
  };

  const columns = [
    {
      title: "All",
      dataIndex: "group",
      key: "group",
    },
    {
      title: (
        <FileNameRow
          validateString={validateString}
          globalProjectName={globalProjectName}
          setGlobalProjectName={setGlobalProjectName}
          global={true}
          globalDate={globalDate}
          setGlobalDate={setGlobalDate}
          globalSuffix={globalSuffix}
          setGlobalSuffix={setGlobalSuffix}
          setFileNames={setFileNames}
          fileNames={fileNames}
          segments={segments}
          setRefreshTpls={setRefreshTpls}
        />
      ),
      dataIndex: "file_name",
      key: "file_name",
    },
  ];

  const hasUpperCase = (str) => /[A-Z]/.test(str);

  const attachTplId = (group) => {
    let tplId = tpls.filter((tpl) => titleCase(tpl.name) === group)[0]?.id;
    return group === "COMBINED"
      ? tpls.map(({ id }) => id)
      : tplId
      ? [tplId]
      : tpls.filter(({ groups }) => groups.includes(group)).map(({ id }) => id);
  };

  const isProjectExportable = () => {
    let exportable = true;
    if (!project.campaign) {
      exportable = false;
      alert(
        "This project has empty Campaign, please set Campaign name before exporting."
      );
    } else if (!project.campaign_object.hubspot_client_campaign_id) {
      exportable = false;
      alert(
        "Selected Campaign has no Client Campaign, please wait for Campaign to sync with HubSpot before exporting."
      );
    } else if (!hasDSPFileNameBase && selectedRows.length > 0) {
      exportable = false;
      alert("Enter file name base to export to S3.");
    }

    if (exportable) {
      exportTOS3();
    }
  };

  const exportTOS3 = async () => {
    setIsLoading(true);
    fetchDSPExportFiles();
    getS3DataUploadLogs();
    setSelectedTab("current_exports");

    let export_details = null;
    let onboardingParam = null;
    if (selectedRows.length > 0) {
      export_details = selectedRows.map(({ key, group }) => {
        return {
          target_or_group_name: group,
          file_name: fileNames[key],
          tpl_id: attachTplId(group),
        };
      });
      onboardingParam = onboarding;
    }

    try {
      await createAudienceExportV3(project.id, export_details, onboardingParam);
      setSelectedRows([]);
      getS3DataUploadLogs();
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  const error = () => {
    Modal.error({
      width: "35%",
      title: "Error",
      content: (
        <p>
          You can't export project without saving segmentation, please go back
          to segmentation UI and click save
        </p>
      ),
    });
  };
  return (
    <>
      {hasDSPFileNameBase ? (
        <div style={{ margin: "20px 0 50px" }}>
          <Title level={4}>Select Audience Segments to Write to Liveramp</Title>
          <Title level={5}>Reporting file will include full audience</Title>
        </div>
      ) : (
        <div style={{ margin: "20px 0 50px" }}>
          <Title level={5}>Enter file name base to export to S3 </Title>
        </div>
      )}
      <div style={{ marginBottom: 20 }}>
        DSP Filename Base :-
        <Input
          placeholder={getDefaultPrefix()}
          defaultValue={getDefaultPrefix()}
          type="text"
          style={{ marginRight: 10, width: 180 }}
          onChange={(e) => setDspFileNameBase(validateString(e.target.value))}
        />
        <SaveOutlined
          style={{ color: "green", fontSize: 20 }}
          onClick={updateDspFileNameBase}
        />
      </div>
      <Space
        align="center"
        style={{
          marginBottom: 16,
        }}
      ></Space>

      <Table
        columns={columns}
        rowSelection={{
          selectedRowKeys: selectedRows.map((row) => row.key),
          ...rowSelection,
        }}
        virtual
        scroll={{ x: "max-content", y: "60vh" }}
        pagination={false}
        dataSource={tableData}
        loading={!tableData}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "end",
          width: "100%",
          marginTop: 30,
        }}
      >
        {selectedRows.length > 0 && (
          <Select
            style={{ width: "150px", margin: "0 10px" }}
            disabled={selectedRows.length < 1}
            value={onboarding}
            onSelect={(v) => setOnboarding(v)}
            options={[
              { label: "Individual", value: "individual" },
              {
                label: "Household",
                value: "household",
              },
            ]}
          />
        )}
        {selectedRows.length > 0 ? (
          <Button
            onClick={() => {
              getS3DataUploadLogs();
              fetchDSPExportFiles();
              if (
                selectedRows
                  .map(({ key }) => fileNames[key])
                  .includes(undefined)
              ) {
                error();
              } else {
                confirm({
                  title: "Export Confirmation",
                  icon: <ExportOutlined />,
                  width: "35%",
                  content: (
                    <ExportConfirmation
                      s3DataUploadLogs={s3DataUploadLogs}
                      dspexportFiles={dspexportFiles}
                      selectedRows={selectedRows.map(({ key, group }) => {
                        return { key, group, file_name: fileNames[key] };
                      })}
                      dmWithoutSegment={dmWithoutSegment}
                    />
                  ),
                  okText: "Continue",
                  okType: "primary",
                  async onOk() {
                    isProjectExportable();
                  },
                });
              }
            }}
            type="primary"
            disabled={!hasDSPFileNameBase}
          >
            Export to S3
          </Button>
        ) : (
          <Button onClick={isProjectExportable} type="primary">
            Generate reporting export
          </Button>
        )}
      </div>
    </>
  );
};
export default StartNewExportTab;
