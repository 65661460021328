export const DesignatedMarketAreas = [
  "ABILENE-SWEETWATER",
  "ALBANY-SCHENECTADY-TROY",
  "ALBANY, GA",
  "ALBUQUERQUE-SANTA FE",
  "ALEXANDRIA, LA",
  "ALPENA",
  "AMARILLO",
  "ANCHORAGE",
  "ATLANTA",
  "AUGUSTA-AIKEN",
  "AUSTIN",
  "BAKERSFIELD",
  "BALTIMORE",
  "BANGOR",
  "BATON ROUGE",
  "BEAUMONT-PORT ARTHUR",
  "BEND, OR",
  "BILLINGS",
  "BILOXI-GULFPORT",
  "BINGHAMTON",
  "BIRMINGHAM (ANN AND TUSC)",
  "BLUEFIELD-BECKLEY-OAK HILL",
  "BOISE",
  "BOSTON (MANCHESTER)",
  "BOWLING GREEN",
  "BUFFALO",
  "BURLINGTON-PLATTSBURGH",
  "BUTTE-BOZEMAN",
  "CASPER-RIVERTON",
  "CEDAR RAPIDS-WTRLO-IWC&DUB",
  "CHAMPAIGN&SPRNGFLD-DECATUR",
  "CHARLESTON-HUNTINGTON",
  "CHARLESTON, SC",
  "CHARLOTTE",
  "CHARLOTTESVILLE",
  "CHATTANOOGA",
  "CHEYENNE-SCOTTSBLUF",
  "CHICAGO",
  "CHICO-REDDING",
  "CINCINNATI",
  "CLARKSBURG-WESTON",
  "CLEVELAND-AKRON (CANTON)",
  "COLORADO SPRINGS-PUEBLO",
  "COLUMBIA-JEFFERSON CITY",
  "COLUMBIA, SC",
  "COLUMBUS-TUPELO-W PNT-HSTN",
  "COLUMBUS, GA (OPELIKA, AL)",
  "COLUMBUS, OH",
  "CORPUS CHRISTI",
  "DALLAS-FT. WORTH",
  "DAVENPORT-R.ISLAND-MOLINE",
  "DAYTON",
  "DENVER",
  "DES MOINES-AMES",
  "DETROIT",
  "DOTHAN",
  "DULUTH-SUPERIOR",
  "EL PASO (LAS CRUCES)",
  "ELMIRA (CORNING)",
  "ERIE",
  "EUGENE",
  "EUREKA",
  "EVANSVILLE",
  "FARGO-VALLEY CITY",
  "FLINT-SAGINAW-BAY CITY",
  "FRESNO-VISALIA",
  "FT. MYERS-NAPLES",
  "FT. SMITH-FAY-SPRNGDL-RGRS",
  "FT. WAYNE",
  "GAINESVILLE",
  "GLENDIVE",
  "GRAND JUNCTION-MONTROSE",
  "GRAND RAPIDS-KALMZOO-B.CRK",
  "GREAT FALLS",
  "GREEN BAY-APPLETON",
  "GREENSBORO-H.POINT-W.SALEM",
  "GREENVILLE-N.BERN-WASHNGTN",
  "GREENVLL-SPART-ASHEVLL-AND",
  "GREENWOOD-GREENVILLE",
  "HARLINGEN-WSLCO-BRNSVL-MCA",
  "HARRISBURG-LNCSTR-LEB-YORK",
  "HARRISONBURG",
  "HARTFORD & NEW HAVEN",
  "HATTIESBURG-LAUREL",
  "HELENA",
  "HONOLULU",
  "HOUSTON",
  "HUNTSVILLE-DECATUR (FLOR)",
  "IDAHO FALS-POCATLLO(JCKSN)",
  "INDIANAPOLIS",
  "JACKSON, MS",
  "JACKSON, TN",
  "JACKSONVILLE",
  "JOHNSTOWN-ALTOONA-ST COLGE",
  "JONESBORO",
  "JOPLIN-PITTSBURG",
  "KANSAS CITY",
  "KNOXVILLE",
  "LA CROSSE-EAU CLAIRE",
  "LAFAYETTE, IN",
  "LAFAYETTE, LA",
  "LAKE CHARLES",
  "LANSING",
  "LAREDO",
  "LAS VEGAS",
  "LEXINGTON",
  "LIMA",
  "LINCOLN & HASTINGS-KRNY",
  "LITTLE ROCK-PINE BLUFF",
  "LOS ANGELES",
  "LOUISVILLE",
  "LUBBOCK",
  "MACON",
  "MADISON",
  "MANKATO",
  "MARQUETTE",
  "MEDFORD-KLAMATH FALLS",
  "MEMPHIS",
  "MERIDIAN",
  "MIAMI-FT. LAUDERDALE",
  "MILWAUKEE",
  "MINNEAPOLIS-ST. PAUL",
  "MINOT-BSMRCK-DCKNSN(WLSTN)",
  "MISSOULA",
  "MOBILE-PENSACOLA (FT WALT)",
  "MONROE-EL DORADO",
  "MONTEREY-SALINAS",
  "MONTGOMERY-SELMA",
  "MYRTLE BEACH-FLORENCE",
  "NASHVILLE",
  "NEW ORLEANS",
  "NEW YORK",
  "NORFOLK-PORTSMTH-NEWPT NWS",
  "NORTH PLATTE",
  "ODESSA-MIDLAND",
  "OKLAHOMA CITY",
  "OMAHA",
  "ORLANDO-DAYTONA BCH-MELBRN",
  "OTTUMWA-KIRKSVILLE",
  "PADUCAH-CAPE GIRARD-HARSBG",
  "PALM SPRINGS",
  "PANAMA CITY",
  "PARKERSBURG",
  "PEORIA-BLOOMINGTON",
  "PHILADELPHIA",
  "PHOENIX (PRESCOTT)",
  "PITTSBURGH",
  "PORTLAND-AUBURN",
  "PORTLAND, OR",
  "PRESQUE ISLE",
  "PROVIDENCE-NEW BEDFORD",
  "QUINCY-HANNIBAL-KEOKUK",
  "RALEIGH-DURHAM (FAYETVLLE)",
  "RAPID CITY",
  "RENO",
  "RICHMOND-PETERSBURG",
  "ROANOKE-LYNCHBURG",
  "ROCHESTER, NY",
  "ROCHESTR-MASON CITY-AUSTIN",
  "ROCKFORD",
  "SACRAMNTO-STKTON-MODESTO",
  "SALISBURY",
  "SALT LAKE CITY",
  "SAN ANGELO",
  "SAN ANTONIO",
  "SAN DIEGO",
  "SAN FRANCISCO-OAK-SAN JOSE",
  "SANTABARBRA-SANMAR-SANLUOB",
  "SAVANNAH",
  "SEATTLE-TACOMA",
  "SHERMAN-ADA",
  "SHREVEPORT",
  "SIOUX CITY",
  "SIOUX FALLS(MITCHELL)",
  "SOUTH BEND-ELKHART",
  "SPOKANE",
  "SPRINGFIELD-HOLYOKE",
  "SPRINGFIELD, MO",
  "ST. JOSEPH",
  "ST. LOUIS",
  "SYRACUSE",
  "TALLAHASSEE-THOMASVILLE",
  "TAMPA-ST. PETE (SARASOTA)",
  "TERRE HAUTE",
  "TOLEDO",
  "TOPEKA",
  "TRAVERSE CITY-CADILLAC",
  "TRI-CITIES, TN-VA",
  "TUCSON (SIERRA VISTA)",
  "TULSA",
  "TWIN FALLS",
  "TYLER-LONGVIEW(LFKN&NCGD)",
  "UTICA",
  "VICTORIA",
  "WACO-TEMPLE-BRYAN",
  "WASHINGTON, DC (HAGRSTWN)",
  "WATERTOWN",
  "WAUSAU-RHINELANDER",
  "WEST PALM BEACH-FT. PIERCE",
  "WHEELING-STEUBENVILLE",
  "WICHITA FALLS & LAWTON",
  "WICHITA-HUTCHINSON PLUS",
  "WILKES BARRE-SCRANTON-HZTN",
  "WILMINGTON",
  "YAKIMA-PASCO-RCHLND-KNNWCK",
  "YOUNGSTOWN",
  "YUMA-EL CENTRO",
  "ZANESVILLE",
];
