export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const states = [
  "DC",
  "AK",
  "AL",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

export const defaultSegmentNames = {
  FRIENDS_AND_FAMILY: "Friends and family",
  CLOSE_CONNECTIONS: "Close connections",
  CLOSE_ASSOCIATES: "Close associates",
  CLASSMATES: "Classmates",
  COWORKERS: "Coworkers",
  ELECTED_OFFICIALS: "Elected officials",
  DONORS: "Donors",
  STAFFERS: "Staffers",
  PARTY_LEADERS: "Party leaders",
  ASSOCIATIONS: "Association members",
  MEDIA_LEADERS: "Media members",
  BUSINESS_LEADERS: "Business leaders",
  CHAMBERS_OF_COMMERCE: "Chambers of commerce",
  LOBBYISTS: "Lobbyists",
  REPUBLICAN_ACTIVISTS: "Republican activists",
  DEMOCRATIC_ACTIVISTS: "Democratic activists",
  KEY_STAFFERS: "Key staffers",
};

export const defaultSegmentNamesWithCount = Object.values(
  defaultSegmentNames
).map((s) => ({ type_name: s, audience_member_count: null }));

export const validMonths = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const range = (start, end) =>
  Array.from({ length: end - start + 1 }, (v, k) => k + start);
export const validYears = range(1900, new Date().getFullYear()).reverse();
export const validDays = range(1, 31);

export const electedOfficeStatusOptions = [
  "Not Elected",
  "Elected - Current",
  "Elected - Former",
];

export const ResearchRequestStatus = {
  RESEARCH: "research",
  QA: "qa",
};
