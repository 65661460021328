import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card, Col, Progress, Row, Select, Skeleton, Typography } from "antd";
import { CalendarOutlined, StockOutlined } from "@ant-design/icons";
import moment from "moment";

import { getBalance } from "../../APIClient";
import { roundNumberToNDecimals } from "../../utils";
import "./PacingCard.css";

const getMostRecentContractStartDate = (data) =>
  Object.keys(data).sort((a, b) => moment(b).unix() - moment(a).unix())[0];

export const PacingCard = () => {
  const selectedHubspotCompanyId = useSelector(
    (state) => state.selectedHubspotCompanyId
  );

  const [isLoading, setIsLoading] = useState(false);
  const [balanceData, setBalanceData] = useState({});
  const [selectedContractStartDate, setSelectedContractStartDate] = useState();

  const previousMonth = moment().subtract(1, "months");
  const balanceAtEndOfPreviousMonth =
    balanceData[getMostRecentContractStartDate(balanceData)]?.filter(
      (record) =>
        record.month - 1 === previousMonth.month() &&
        record.year === previousMonth.year()
    )?.[0]?.balance || 0;

  const startOfSelectedContract = balanceData[selectedContractStartDate]?.[0];
  const startingBalance =
    startOfSelectedContract?.balance -
    startOfSelectedContract?.debits -
    startOfSelectedContract?.credits;

  const endOfSelectedContract =
    balanceData[selectedContractStartDate]?.[
      balanceData[selectedContractStartDate].length - 1
    ];

  const sumCreditsInSelectedContract = balanceData[
    selectedContractStartDate
  ]?.reduce((acc, record) => acc + record.credits, 0);

  const sumDebitsInSelectedContract = balanceData[
    selectedContractStartDate
  ]?.reduce((acc, record) => acc + record.debits, 0);

  const pacingPercentage = roundNumberToNDecimals(
    (Math.abs(sumDebitsInSelectedContract) /
      (sumCreditsInSelectedContract + startingBalance)) *
      100,
    1
  );

  useEffect(() => {
    if (selectedHubspotCompanyId) {
      fetchBalance(selectedHubspotCompanyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHubspotCompanyId]);

  const fetchBalance = async (_accountId) => {
    setIsLoading(true);
    try {
      let result = await getBalance(_accountId);
      setBalanceData(result);
      setSelectedContractStartDate(getMostRecentContractStartDate(result));
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };
  return (
    <Card bodyStyle={{ padding: 0 }}>
      <Row className="date-picker-row">
        <Col span={2} style={{ fontSize: 24 }}>
          <CalendarOutlined />
        </Col>

        <Col span={20}>
          <Select
            loading={isLoading}
            disabled={isLoading}
            style={{ width: "100%" }}
            onChange={(dateString) => setSelectedContractStartDate(dateString)}
            value={selectedContractStartDate}
            options={Object.keys(balanceData)
              .sort((a, b) => moment(b).unix() - moment(a).unix())
              .map((contractStartDate) => ({
                value: contractStartDate,
                label: `Contract Date: ${contractStartDate}`,
              }))}
          />
        </Col>
      </Row>
      <Row align="bottom" style={{ padding: 20 }}>
        <Col span={2} style={{ fontSize: 24 }}>
          <StockOutlined />
        </Col>
        <Col span={22}>
          <Typography.Title level={5}>AU Pacing</Typography.Title>
        </Col>
      </Row>
      <Row align="middle" justify="center" style={{ padding: 16 }}>
        <Progress
          percent={pacingPercentage}
          format={() =>
            isLoading ? (
              <Skeleton.Button active shape="square" />
            ) : (
              `${pacingPercentage}%`
            )
          }
          type="circle"
        />
      </Row>
      <Row
        align="middle"
        style={{ padding: 20, minHeight: "150px" }}
        gutter={[16, 16]}
      >
        <Col span={6} style={{ minWidth: "85px" }}>
          <LargeCalendar
            month={
              endOfSelectedContract?.month &&
              moment(endOfSelectedContract?.month, "M").format("MMM")
            }
            year={endOfSelectedContract?.year}
          />
        </Col>
        <Col span={16}>
          <Skeleton
            loading={isLoading}
            active
            title={false}
            paragraph={{ rows: 3 }}
          >
            <div style={{ fontSize: "14px" }}>
              AU Balance at End of Contract:
            </div>
            <Typography.Title level={4}>
              {roundNumberToNDecimals(
                endOfSelectedContract?.balance,
                2
              )?.toLocaleString()}{" "}
              AUs
            </Typography.Title>
            <div>
              The balance was{" "}
              <b>
                {roundNumberToNDecimals(
                  balanceAtEndOfPreviousMonth,
                  2
                )?.toLocaleString()}
              </b>{" "}
              at the end of{" "}
              <b>
                {previousMonth.format("MMMM")} {previousMonth.format("YYYY")}
              </b>
              .
            </div>
          </Skeleton>
        </Col>
      </Row>
    </Card>
  );
};

const LargeCalendar = ({ month, year }) => {
  return (
    <Row className="calendar-card">
      <Row className="calendar-month" justify="center" align="middle">
        {month ? month.toUpperCase() : <div>&nbsp;</div>}
      </Row>
      <Row justify="center" align="middle" className="calendar-year">
        {year}
      </Row>
    </Row>
  );
};
