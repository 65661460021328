const default_value = "targets";

const reducer = (state = default_value, action) => {
  switch (action.type) {
    case "set-target-package-tab-key":
      return action.payload;
    case "reset-target-package-tab-key":
      return default_value;
    default:
      return state;
  }
};

export default reducer;
