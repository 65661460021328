import { Select, Spin } from "antd";
import { useState } from "react";
import { debounce } from "lodash";
import { findEASCompanies } from "../../APIClient";

export const ExperienceCompanySelector = ({
  setCompanyInfo,
  loading,
  setloading,
  isGroup,
  isEditExperience = false,
  companyName,
  employeeCount,
  location,
}) => {
  const { Option } = Select;
  const [companies, setCompanies] = useState([]);
  const callFindEASCompaniesDebounced = debounce(async (val) => {
    setloading(true);
    try {
      const data = await findEASCompanies(val);
      setCompanies(data);
    } catch (e) {
      alert(e);
    }
    setloading(false);
  }, 1000);

  const onSearch = async (val) => {
    if (val && val.length > 2) {
      callFindEASCompaniesDebounced(val);
    }
  };

  const parseLocation = (city, state) => {
    if (city && state) return `${city}-${state}`;
    else if (city) return city;
    else if (state) return state;
    else return null;
  };
  return (
    <Select
      showSearch
      style={{
        width: "600px",
      }}
      placeholder="Select a Company"
      optionFilterProp="children"
      filterOption={false}
      onChange={(val) => setCompanyInfo(val)}
      loading={loading}
      notFoundContent={null}
      className={isGroup && "isBold"}
      onSearch={onSearch}
      value={
        isEditExperience
          ? isGroup
            ? "🏢 " + companyName + ` (${employeeCount}) (${location})`
            : companyName + ` (${employeeCount}) (${location})`
          : companyName
      }
      disabled={isEditExperience}
    >
      {loading ? (
        <Option
          disabled={true}
          style={{
            textAlign: "center",
          }}
        >
          <Spin tip="Loading results" />
        </Option>
      ) : (
        <>
          {companies?.length > 0 && (
            <Option
              key="disabled result"
              disabled={true}
              style={{ fontWeight: "bold" }}
            >
              {companies?.length} results found
            </Option>
          )}
          {companies
            ?.sort((a, b) => (a.employee_count > b.employee_count ? -1 : 1))
            .map((c) =>
              c.is_group === true ? (
                <Option key={c.company_id} value={JSON.stringify(c)}>
                  <strong>
                    🏢 {c.company_name}({c.employee_count?.toLocaleString()})
                    {c.city || c.state
                      ? " (" + parseLocation(c.city, c.state) + ")"
                      : ""}
                  </strong>
                </Option>
              ) : (
                <Option key={c.company_id} value={JSON.stringify(c)}>
                  {c.company_name} ({c.employee_count?.toLocaleString()})
                  {c.city || c.state
                    ? " (" + parseLocation(c.city, c.state) + ")"
                    : ""}
                </Option>
              )
            )}
        </>
      )}
    </Select>
  );
};
