import React, { createContext, useState, useEffect } from "react";
import Navbar from "../Navbar";
import { CompanyView } from "./Company/CompanyView";
import { GroupView } from "./Group/GroupView";
import { useParams, useHistory } from "react-router-dom";
import { titleCase } from "../../utils";
import {
  getCompanyGroupInfo,
  getGroupCompaniesByGroupId,
} from "../../APIClient";
import "./CompanyGroupsPage.css";
import { QualityAssuranceGroupView } from "./QualityAssuranceGroup/QualityAssuranceGroupView";

export const CompanyGroupsContext = createContext();

export const CompanyGroupsContextProvider = ({ children }) => {
  const { groupId } = useParams();
  const history = useHistory();
  const [groupGridData, setGroupGridData] = useState([]);
  const [companyGridData, setCompanyGridData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState({});
  const [groupNames, setGroupNames] = useState([]);

  const [currentPage, setCurrentPage] = useState({
    groupView: true,
    companyView: false,
    qualityAssuranceGroupView: false,
  });

  const switchPage = (page) => {
    let newPageDict = {};
    Object.keys(currentPage).map((key) => (newPageDict[key] = false));
    setCurrentPage({ ...newPageDict, [page]: true });
  };

  const redirectPageURL = (groupId) => {
    if (groupId) {
      history.push(`/company-groups/${groupId}`);
    } else {
      history.push(`/company-groups`);
    }
  };

  const configureGroup = async (groupId) => {
    try {
      if (groupId) {
        const response = await getCompanyGroupInfo(groupId);
        if (response.id) {
          setSelectedGroup(response);
          redirectPageURL(response.id);
          return;
        } else if (response.error) alert(response.error);
      }
      setSelectedGroup();
      redirectPageURL();
    } catch (error) {
      alert(error);
    }
  };

  const loadGroupGridData = async (groupId) => {
    setIsLoading(true);
    try {
      const response = await getGroupCompaniesByGroupId(groupId);
      if (response.error) {
        alert(response.error);
      }
      let response_with_key = await response.map((el) => {
        return {
          key: el.id,
          ...el,
          city: typeof el.city === "string" ? titleCase(el.city) : null,
          state: typeof el.state === "string" ? el.state.toUpperCase() : null,
        };
      });
      setGroupGridData(
        response_with_key
          .sort((a, b) => b?.employee_count - a?.employee_count)
          .sort((a, b) => b?.canonical - a?.canonical)
      );
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  const qualityAssuranceTextAndIconColor = (record) => {
    if (!record.most_recent_qa) {
      return { text: "No QA. ", color: "#f5222d" };
    } else if (
      new Date(record.most_recent_edit) >= new Date(record.most_recent_qa)
    ) {
      return { text: "QA is out of date. ", color: "#f5222d" };
    } else if (
      new Date(record.most_recent_edit) < new Date(record.most_recent_qa) ||
      !record.most_recent_edit
    )
      return { text: "", color: "#52c41a" };
  };

  useEffect(() => {
    groupId && configureGroup(groupId);
    // eslint-disable-next-line
  }, [groupId]);

  useEffect(() => {
    if (selectedGroup?.id) {
      loadGroupGridData(selectedGroup?.id);
    } else {
      setGroupGridData();
    }
  }, [selectedGroup]);

  return (
    <CompanyGroupsContext.Provider
      value={{
        groupGridData,
        loadGroupGridData,
        isLoading,
        setIsLoading,
        selectedGroup,
        setSelectedGroup,
        groupNames,
        setGroupNames,
        configureGroup,
        companyGridData,
        setCompanyGridData,
        currentPage,
        switchPage,
        qualityAssuranceTextAndIconColor,
      }}
    >
      {children}
    </CompanyGroupsContext.Provider>
  );
};

export const CompanyGroupsPage = () => {
  return (
    <Navbar contentClass="landing-page-content">
      <CompanyGroupsContextProvider>
        <GroupView />
        <CompanyView />
        <QualityAssuranceGroupView />
      </CompanyGroupsContextProvider>
    </Navbar>
  );
};
