import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Popconfirm, Row, Space, Spin } from "antd";
import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  createDecisionMakerEducation,
  deleteDecisionMakerEducation,
  getDecisionMakerEducations,
  getEASInstitutes,
  updateDecisionMakerEducation,
} from "../../APIClient";
import { checkFormChanged, showSuccessMessage } from "../../utils";
import { EducationForm } from "./EducationForm";
import { DecisionMakerContext } from "./DecisionMakerContext";

export const EducationComponent = () => {
  // local state
  const [isLoading, setIsLoading] = useState(false);
  const [creatingNew, setCreatingNew] = useState(false);
  const [educationHistory, setEducationHistory] = useState([]);

  // redux state
  const decisionMaker = useSelector((state) => state.decisionMaker);

  const fetchEducationHistory = async () => {
    // turn on loading
    setIsLoading(true);
    try {
      const data = await getDecisionMakerEducations(decisionMaker.id);
      const institutionIds = data.map((institute) => institute.institution_id);
      const institution = await getEASInstitutes(institutionIds);
      setEducationHistory(
        data.map((dm) => {
          return {
            ...dm,
            institute_count: institution.filter(
              (i) => i.id === dm.institution_id
            )[0]?.count,
          };
        })
      );
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (decisionMaker.id) {
      fetchEducationHistory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decisionMaker.id, decisionMaker.eas_profile_id]);

  return (
    <Spin spinning={isLoading}>
      <Space direction="vertical" style={{ width: "100%" }}>
        {creatingNew ? (
          <CreateEducation
            onCancel={() => setCreatingNew(false)}
            callback={() => {
              setCreatingNew(false);
              fetchEducationHistory();
            }}
            setIsLoading={setIsLoading}
          />
        ) : (
          <Row>
            <Button
              block
              type="link"
              onClick={() => setCreatingNew(true)}
              style={{ textAlign: "left" }}
              size="large"
            >
              <PlusOutlined /> Add another
            </Button>
          </Row>
        )}
        {educationHistory?.length > 0 && (
          <Divider plain orientation="left">
            Existing education history
          </Divider>
        )}
        {educationHistory?.map((e, i) => (
          <React.Fragment key={i}>
            {i !== 0 && <Divider />}
            <EditEducationHistory
              educationValues={e}
              callback={() => {
                fetchEducationHistory();
              }}
              setIsLoading={setIsLoading}
            />
          </React.Fragment>
        ))}
      </Space>
    </Spin>
  );
};

export const CreateEducation = ({ onCancel, callback, setIsLoading }) => {
  const [institutionId, setInstitutionId] = useState();
  const [institutionName, setInstitutionName] = useState();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  // redux state
  const decisionMaker = useSelector((state) => state.decisionMaker);
  const { setUpdateDecisionMakerQAStatus } = useContext(DecisionMakerContext);

  const onSave = async () => {
    setIsLoading(true);
    try {
      await createDecisionMakerEducation(decisionMaker.id, {
        institutionId,
        institutionName,
        fromDate: fromDate?.format("YYYY-MM-DD"),
        toDate: toDate?.format("YYYY-MM-DD"),
      });
      callback();
      setUpdateDecisionMakerQAStatus(true);
      showSuccessMessage("New Education added.");
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  const resetForm = () => {
    setFromDate(undefined);
    setToDate(undefined);
    setInstitutionId();
    setInstitutionName();
  };

  useEffect(() => {
    resetForm();
  }, [decisionMaker.id]);

  return (
    <Row>
      <Col span={16}>
        <EducationForm
          institutionName={institutionName}
          setInstitutionId={setInstitutionId}
          setInstitutionName={setInstitutionName}
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
        />
      </Col>
      <Col span={8}>
        <Space size={5}>
          <Button onClick={onCancel} size="middle" style={{ width: "75px" }}>
            Cancel
          </Button>
          <Button
            onClick={onSave}
            type="primary"
            disabled={!institutionName || !fromDate}
            size="middle"
            style={{ width: "65px" }}
          >
            Save
          </Button>
        </Space>
      </Col>
    </Row>
  );
};

export const EditEducationHistory = ({
  educationValues,
  callback,
  setIsLoading,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [institutionId, setInstitutionId] = useState();
  const [institutionName, setInstitutionName] = useState();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [instituteCount, setInstituteCount] = useState();

  // redux state
  const decisionMaker = useSelector((state) => state.decisionMaker);
  const { setUpdateDecisionMakerQAStatus } = useContext(DecisionMakerContext);

  const resetForm = () => {
    setInstitutionId(educationValues?.institution_id);
    setInstitutionName(educationValues?.institution_name);
    setFromDate(
      educationValues?.from_date ? moment(educationValues?.from_date) : null
    );
    setToDate(
      educationValues?.to_date ? moment(educationValues?.to_date) : null
    );
    setInstituteCount(educationValues?.institute_count);
  };

  useEffect(() => {
    resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [educationValues]);

  const onSave = async () => {
    setIsLoading(true);
    try {
      await updateDecisionMakerEducation(decisionMaker.id, educationValues.id, {
        institutionId,
        institutionName: institutionName
          .replace(` (${instituteCount})`, "")
          .trim(),
        fromDate: fromDate?.format("YYYY-MM-DD"),
        toDate: toDate?.format("YYYY-MM-DD"),
      });
      callback();
      setUpdateDecisionMakerQAStatus(true);
      showSuccessMessage("Education updated.");
    } catch (error) {
      alert(error);
      resetForm();
    }
    setIsEditing(false);
    setIsLoading(false);
  };

  const onDelete = async () => {
    setIsLoading(true);
    try {
      await deleteDecisionMakerEducation(decisionMaker.id, educationValues.id);
      callback();
      setUpdateDecisionMakerQAStatus(true);
      showSuccessMessage("Education deleted.");
    } catch (error) {
      console.log(error);
      alert(error);
    }
    setIsLoading(false);
  };

  return (
    <Row>
      <Col span={16}>
        <EducationForm
          institutionName={institutionName}
          setInstitutionName={setInstitutionName}
          setInstitutionId={setInstitutionId}
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
          disabled={!isEditing}
          isEditEducation={true}
          instituteCount={instituteCount}
        />
      </Col>
      <Col span={7}>
        {isEditing ? (
          <Space size={5}>
            <Button
              onClick={() => {
                setIsEditing(false);
                resetForm();
              }}
              size="middle"
              style={{ width: "75px" }}
            >
              Cancel
            </Button>
            <Button
              onClick={onSave}
              type="primary"
              size="middle"
              disabled={
                !fromDate ||
                !checkFormChanged(educationValues, {
                  from_date: fromDate?.format("YYYY-MM-DD"),
                  to_date: toDate?.format("YYYY-MM-DD"),
                })
              }
              style={{ width: "65px" }}
            >
              Save
            </Button>
          </Space>
        ) : (
          <Button
            onClick={() => setIsEditing(true)}
            size="middle"
            style={{ width: "140px" }}
          >
            Edit
          </Button>
        )}
      </Col>
      <Col span={1}>
        <Popconfirm
          title={`Delete Education?`}
          okText="Yes"
          onConfirm={onDelete}
        >
          <Button danger type="link" icon={<DeleteOutlined />} />
        </Popconfirm>
      </Col>
    </Row>
  );
};
