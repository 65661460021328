const reducer = (state = null, action) => {
  switch (action.type) {
    case "get-project":
      return action.payload;
    case "update-project":
      return action.payload;
    case "set-project-null":
      return null;
    default:
      return state;
  }
};

export default reducer;
