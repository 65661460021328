import { Form, Input, Button, Space } from "antd";
import { getGroupCompaniesBySearchString } from "../../../APIClient";
import { createFilterOptionsFromData, titleCase } from "../../../utils";
import { CompanyGroupsContext } from "../CompanyGroupsPage";
import { useContext } from "react";

export const SearchCompaniesInput = ({
  companySearchString,
  setCompanySearchString,
  setSelectedRows,
  setStateFilterOptions,
}) => {
  const { setIsLoading, setCompanyGridData } = useContext(CompanyGroupsContext);

  const loadCompanyGridData = async (searchString) => {
    setIsLoading(true);
    try {
      const response = await getGroupCompaniesBySearchString(searchString);
      if (response.error) {
        alert(response.error);
      }
      let response_with_key = await response.map((el) => {
        return {
          key: el.company_id,
          ...el,
          city: typeof el.city === "string" ? titleCase(el.city) : null,
          state: typeof el.state === "string" ? el.state.toUpperCase() : null,
        };
      });
      setSelectedRows([]);
      setStateFilterOptions(
        createFilterOptionsFromData(response_with_key, "state")
      );
      setCompanyGridData(
        response_with_key.sort((a, b) => b?.employee_count - a?.employee_count)
      );
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  return (
    <Form>
      <Form.Item>
        <Space wrap={true}>
          <Input
            allowClear
            style={{ width: "300px" }}
            placeholder="Type a company name"
            onChange={(e) => {
              e.target.value === null && setCompanyGridData([]);
              setCompanySearchString(e.target.value);
            }}
            onPressEnter={() =>
              companySearchString && loadCompanyGridData(companySearchString)
            }
          />
          <Button
            onClick={() => {
              loadCompanyGridData(companySearchString);
            }}
            disabled={companySearchString.length === 0 || !companySearchString}
          >
            Search
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
