import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { Space, Row, Table, Button } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { getProjects } from "../../APIClient";
import "./ProjectList.css";
import { getColumnSearchProps } from "../../util/columnSearchPropFactory";
import ProjectDrawer from "./ProjectDrawer";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const ProjectList = () => {
  const dispatch = useDispatch();
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const selectedHubspotCompanyId = useSelector(
    (state) => state.selectedHubspotCompanyId
  );

  const { setProjectNull, setTargetNull } = bindActionCreators(
    actionCreators,
    dispatch
  );

  const project_table_columns = [
    {
      title: "Account",
      dataIndex: "client_name",
      width: 350,
      sorter: (a, b) => a.client_name?.localeCompare(b.client_name),
      ...getColumnSearchProps("client_name", "Account"),
    },
    {
      title: "Project Name",
      dataIndex: "name",
      width: 350,
      sorter: (a, b) => a.name?.localeCompare(b.name),
      ...getColumnSearchProps("name", "Project Name"),
      render: (select, record) => (
        // eslint-disable-next-line
        <Link to={`/projects/${record.id}`}>{record.name}</Link>
      ),
    },
    {
      title: "ABBA Campaign ID",
      dataIndex: "campaign",
      render: (text, campaign, index) => campaign?.campaign,
      ...getColumnSearchProps("campaign", "ABBA Campaign ID"),
    },
    {
      title: "Created Date",
      dataIndex: "created_on",
      sorter: (a, b) => moment(a?.created_on) - moment(b?.created_on),
      render: (text, record, index) =>
        moment(record.created_on).format("MM-DD-YYYY"),
    },

    {
      title: "# Decision Makers",
      dataIndex: "target_count",
      sorter: (a, b) => a.target_count - b.target_count,
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status?.localeCompare(b.status),
    },
  ];

  const loadProjects = async () => {
    setIsLoading(true);

    let resp_with_key = [];
    const resp = await getProjects();

    if (selectedHubspotCompanyId !== null) {
      resp_with_key = resp
        .filter(
          (el) => el.client_hubspot_company_id === selectedHubspotCompanyId
        )
        .map((el) => {
          return { key: el.id, ...el };
        });
    } else {
      resp_with_key = resp.map((el) => {
        return { key: el.id, ...el };
      });
    }

    setProjects(resp_with_key);
    setIsLoading(false);
  };

  useEffect(() => {
    setProjectNull();
    loadProjects();
    // eslint-disable-next-line
  }, [selectedHubspotCompanyId]);

  return (
    <div>
      <Button
        type="primary"
        icon={<PlusCircleOutlined />}
        onClick={() => {
          setProjectNull();
          setTargetNull();
          dispatch({ type: "set-is-open-project-drawer", payload: true });
        }}
        size="large"
        style={{ float: "right" }}
      >
        Create New Project
      </Button>
      <Space direction="vertical" size={15} style={{ width: "100%" }}>
        <Row>
          <Table
            scroll={{
              y: "60vh",
            }}
            style={{ width: "100%" }}
            columns={project_table_columns}
            dataSource={projects}
            loading={isLoading}
          />
        </Row>
      </Space>
      <ProjectDrawer />
    </div>
  );
};

export default ProjectList;
