import {
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";
import { Button, Col, Drawer, Modal, Popconfirm, Row, Tag } from "antd";
import React, { useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { addPidToDecisionMaker, updateDecisionMaker } from "../../APIClient";
import { actionCreators } from "../../store";
import { showSuccessMessage } from "../../utils";
import { HeaderWithInfoTip } from "./HeaderWithInfoTip";
import MPSSearch from "../MPSSearch/MPSSearch";
import { MPSProfileReviewModal } from "./MPSProfileReviewModal";
import { DecisionMakerContext } from "./DecisionMakerContext";

export const DecisionMakerDM = () => {
  const [isMPSSearchVisible, setIsMPSSearchDrawerVisible] = useState(false);
  const [isMPSProfileReviewModalVisible, setIsMPSProfileReviewModalVisible] =
    useState(false);

  // redux state
  const decisionMaker = useSelector((state) => state.decisionMaker);
  const { setUpdateDecisionMakerQAStatus } = useContext(DecisionMakerContext);

  const dispatch = useDispatch();
  const { setDecisionMakerAction } = bindActionCreators(
    actionCreators,
    dispatch
  );

  const addPid = async (p_id) => {
    const displayWarning = () =>
      Modal.warning({
        width: "35%",
        title: "Warning",
        content: (
          <p>
            You may need to manually delete previously attached PII from the
            previous P_ID{" "}
          </p>
        ),
        onOk: () => {
          attachPid(p_id);
        },
      });

    const attachPid = async (p_id) => {
      const resp = await addPidToDecisionMaker(decisionMaker.id, p_id);
      setIsMPSSearchDrawerVisible(false);
      setDecisionMakerAction(resp);
      setUpdateDecisionMakerQAStatus(true);
      showSuccessMessage("P_ID attached");
      if (resp.error) {
        alert(resp.error);
      }
    };

    try {
      if (!decisionMaker?.p_id) attachPid(p_id);
      else displayWarning();
    } catch (error) {
      alert(error);
    }
  };

  const removePid = async () => {
    try {
      const resp = await updateDecisionMaker(decisionMaker.id, {
        ...decisionMaker,
        p_id: null,
      });
      setDecisionMakerAction(resp);
      setUpdateDecisionMakerQAStatus(true);
      showSuccessMessage("P_ID removed.");
    } catch (error) {
      alert(error);
    }
  };

  return (
    <>
      <Row>
        <Row style={{ marginLeft: 0 }}>
          <HeaderWithInfoTip
            title={
              <h3 style={{ marginBottom: 0, marginLeft: 0 }}>
                PII Profile from MPS
              </h3>
            }
            message={<>PII Profile from MPS</>}
          />
        </Row>

        <Row style={{ width: "100%", margin: "20px 0" }}>
          {decisionMaker.p_id ? (
            <Tag
              style={{
                padding: "5px 20px",
                width: "600px",
                borderRadius: "3px",
              }}
            >
              <Row justify="space-between" style={{ alignItems: "center" }}>
                <Col span={12}>
                  <h2 style={{ margin: 0 }}>{decisionMaker?.p_id}</h2>
                </Col>

                <Col span={3} style={{ textAlign: "center" }}>
                  <Button
                    type="link"
                    style={{ paddingLeft: 0, float: "right" }}
                    onClick={() => {
                      setIsMPSProfileReviewModalVisible(true);
                    }}
                  >
                    Review
                    <FileSearchOutlined />
                  </Button>
                </Col>

                <Col span={3} style={{ textAlign: "center" }}>
                  <Button
                    type="link"
                    style={{ paddingLeft: 0, float: "right" }}
                    onClick={() => {
                      setIsMPSSearchDrawerVisible(true);
                    }}
                  >
                    Update
                    <EditOutlined />
                  </Button>
                </Col>
                <Col span={3} style={{ textAlign: "center" }}>
                  <Popconfirm
                    title={`Remove Profile?`}
                    okText="Yes"
                    onConfirm={() => removePid()}
                  >
                    <Button
                      type="link"
                      style={{ paddingLeft: 0, float: "right" }}
                    >
                      Remove
                      <DeleteOutlined />
                    </Button>
                  </Popconfirm>
                </Col>
              </Row>
            </Tag>
          ) : (
            <Button
              onClick={() => setIsMPSSearchDrawerVisible(true)}
              icon={<PlusOutlined />}
            >
              {" "}
              Add
            </Button>
          )}
        </Row>
      </Row>
      <AttachNewDMDrawer />
      <MPSProfileReviewModal
        personID={decisionMaker?.p_id}
        isMPSProfileReviewModalVisible={isMPSProfileReviewModalVisible}
        setIsMPSProfileReviewModalVisible={setIsMPSProfileReviewModalVisible}
      />
      <Drawer
        title="Attach PII Profile From MPS"
        onClose={() => setIsMPSSearchDrawerVisible(false)}
        visible={isMPSSearchVisible}
        width={900}
        bodyStyle={{ paddingBottom: 20 }}
      >
        <MPSSearch setMPSSearchCallback={addPid} />
      </Drawer>
    </>
  );
};
const AttachNewDMDrawer = ({ isVisible, setIsVisible }) => {
  return (
    <Drawer
      title="Attach PII Profile"
      onClose={() => setIsVisible(false)}
      visible={isVisible}
      width={800}
      bodyStyle={{ paddingBottom: 20 }}
    ></Drawer>
  );
};
