import {
  Input,
  Upload,
  Col,
  Row,
  Spin,
  Button,
  Divider,
  Space,
  Table,
  message,
  Tooltip,
} from "antd";
import moment from "moment";
import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import {
  deleteDonationsData,
  getExistingDonationUploads,
  uploadDonationsData,
} from "../../APIClient";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { HeaderWithInfoTip } from "./HeaderWithInfoTip";
import { DecisionMakerContext } from "./DecisionMakerContext";

export const UploadedDonationsData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [existingUploads, setExistingUploads] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [fileDescription, setFileDescription] = useState("");
  const [uploading, setUploading] = useState(false);

  const user = useSelector((state) => state.user.email);
  const decisionMaker = useSelector((state) => state.decisionMaker);
  const { setUpdateDecisionMakerQAStatus } = useContext(DecisionMakerContext);

  const getExistingUploads = async () => {
    setIsLoading(true);
    try {
      const data = await getExistingDonationUploads(decisionMaker.id);
      setExistingUploads(
        data.filter((item) => item?.version === null || item?.version === "v1")
      );
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  const uploadFile = async () => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("dm_id", decisionMaker.id);
      formData.append("user_id", user);
      formData.append("file_name", fileList[0].name);
      formData.append("csv_file", fileList[0]);
      formData.append("file_description", fileDescription);
      await uploadDonationsData(formData);
      message.success("Donation data uploaded successfully.");
      await getExistingUploads();
      setFileList([]);
      setUpdateDecisionMakerQAStatus(true);
    } catch (error) {
      alert(error);
      message.error("Donation Upload failed. Please validate CSV file.");
    }
    setIsLoading(false);
  };

  const deleteDonationRecord = async (record) => {
    try {
      await deleteDonationsData(
        decisionMaker?.id,
        record.candidate_id,
        record.id
      );
      await getExistingUploads();
      setUpdateDecisionMakerQAStatus(true);
    } catch (error) {
      alert(error);
    }
  };

  const metaDataColumns = [
    {
      title: "File Name",
      dataIndex: "file_name",
      key: "file_name",
    },
    {
      title: "File Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Uploaded By",
      dataIndex: "user_id",
      key: "user_id",
    },
    {
      title: "Total Records",
      dataIndex: "file_rows",
      key: "file_rows",
    },
    {
      title: "Records Processed",
      dataIndex: "processed_records",
      key: "processed_records",
    },
    {
      title: "Invalid Donation Records",
      dataIndex: "invalid_donation_row_count",
      key: "invalid_donation_row_count",
    },
    {
      title: "Invalid Name Records",
      dataIndex: "invalid_name_row_count",
      key: "invalid_name_row_count",
    },
    {
      title: "Invalid Address Records",
      dataIndex: "invalid_address_row_count",
      key: "invalid_address_row_count",
    },
    {
      title: "Uploaded Time",
      dataIndex: "upload_time",
      key: "upload_time",
      render: (value) => moment(value).format("MM-DD-YYYY HH:mm"),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => deleteDonationRecord(record)}>
            <DeleteOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (decisionMaker.id) {
      getExistingUploads();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decisionMaker.id, decisionMaker.eas_profile_id]);

  return (
    <Spin spinning={isLoading}>
      <Space direction="vertical" style={{ width: "100%" }}>
        {existingUploads?.length > 0 && (
          <Divider plain orientation="left">
            Existing Donations Uploads
          </Divider>
        )}

        {existingUploads?.length > 0 && (
          <Table
            size="small"
            dataSource={existingUploads}
            columns={metaDataColumns}
          />
        )}
        {uploading ? (
          <Row>
            <Col>
              <Upload
                beforeUpload={(file) => {
                  const isPNG = file.type === "text/csv";
                  if (!isPNG) {
                    message.error(`${file.name} is not a csv file`);
                    return isPNG;
                  }
                  setFileList([file]);
                  return false;
                }}
                onRemove={(file) => {
                  setFileList([]);
                }}
                fileList={fileList}
              >
                <Button>
                  <PlusOutlined />
                  Select File
                </Button>
              </Upload>
            </Col>
            <Col>
              <Tooltip title="Sample CSV File">
                <a
                  href="https://s3.console.aws.amazon.com/s3/object/ac-advocacy-prod?region=us-east-1&prefix=sample-files%2Fdonor_data_sample.csv"
                  target="_blank"
                  rel="noreferrer"
                >
                  <HeaderWithInfoTip />
                </a>
              </Tooltip>
            </Col>
            <Col span={1} />
            <Col>
              <Input
                placeholder="Description"
                onChange={(event) => setFileDescription(event.target.value)}
              />
            </Col>
            <Col span={1} />
            <Col>
              <Button onClick={uploadFile} disabled={fileList.length === 0}>
                Upload File
              </Button>
            </Col>
          </Row>
        ) : (
          <Row>
            <Button
              block
              type="link"
              onClick={() => setUploading(true)}
              style={{ textAlign: "left" }}
              size="large"
            >
              <PlusOutlined /> Upload New Donations
            </Button>
          </Row>
        )}
      </Space>
    </Spin>
  );
};
