import React from "react";
import { Drawer, Table } from "antd";
import { getColumnSearchProps } from "../../util/columnSearchPropFactory";

export const ClientProjectsDrawer = ({ record, isVisible, setIsVisible }) => {
  return (
    <Drawer
      title={`${record?.dm_profile?.full_name} Unique Client Campaigns`}
      width={600}
      onClose={() => {
        setIsVisible(false);
      }}
      visible={isVisible}
    >
      <Table
        style={{ width: "100%" }}
        columns={[
          {
            title: "Campaign Name",
            dataIndex: "name",
            sorter: (a, b) => a?.name?.localeCompare(b?.name),
            ...getColumnSearchProps("name", "Campaign Name"),
          },
        ]}
        dataSource={record?.campaign_names?.map((name) => ({ name }))}
        rowKey="id"
      />
    </Drawer>
  );
};
