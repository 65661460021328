import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Popconfirm,
  Row,
  Space,
  Spin,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import {
  getDecisionMakerEmails,
  createDecisionMakerEmail,
  updateDecisionMakerEmail,
  deleteDecisionMakerEmail,
} from "../../APIClient";
import { checkFormChanged, showSuccessMessage } from "../../utils";
import { DecisionMakerContext } from "./DecisionMakerContext";

const emailRegex = new RegExp(/^[^ ]+@[^ ]+\.[^ ]+$/);

export const Email = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [creatingNew, setCreatingNew] = useState(false);
  const [emails, setEmails] = useState([]);

  // redux state
  const decisionMaker = useSelector((state) => state.decisionMaker);

  const fetchEmails = async () => {
    // turn on loading
    setIsLoading(true);
    try {
      const data = await getDecisionMakerEmails(decisionMaker.id);
      setEmails(data);
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (decisionMaker.id) {
      fetchEmails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decisionMaker.id, decisionMaker.dcm_id]);

  return (
    <Spin spinning={isLoading}>
      <Space direction="vertical" style={{ width: "100%" }}>
        {creatingNew ? (
          <CreateEmail
            onCancel={() => setCreatingNew(false)}
            callback={() => {
              setCreatingNew(false);
              fetchEmails();
            }}
            setIsLoading={setIsLoading}
          />
        ) : (
          <Row>
            <Button
              block
              type="link"
              onClick={() => setCreatingNew(true)}
              style={{ textAlign: "left" }}
              size="large"
            >
              <PlusOutlined /> Add another
            </Button>
          </Row>
        )}
        {emails?.length > 0 && (
          <Divider plain orientation="left">
            Existing Emails
          </Divider>
        )}
        {emails
          ?.sort((a, b) => b.id - a.id)
          ?.map((e) => (
            <EditEmail
              key={e.id}
              existingRow={e}
              callback={() => {
                fetchEmails();
              }}
              setIsLoading={setIsLoading}
            />
          ))}
      </Space>
    </Spin>
  );
};

const CreateEmail = ({ onCancel, callback, setIsLoading }) => {
  const [email, setEmail] = useState();
  const [isFormValid, setIsFormValid] = useState(true);

  // redux state
  const decisionMaker = useSelector((state) => state.decisionMaker);
  const { setUpdateDecisionMakerQAStatus } = useContext(DecisionMakerContext);
  const [form] = useForm();

  const validate = async () => {
    try {
      await form.validateFields();
      setIsFormValid(true);
    } catch (error) {
      setIsFormValid(false);
    }
  };
  // form validation
  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  const onSave = async () => {
    setIsLoading(true);
    try {
      await createDecisionMakerEmail(decisionMaker.id, email);
      callback();
      setUpdateDecisionMakerQAStatus(true);
      showSuccessMessage("New Email added.");
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setEmail();
  }, [decisionMaker.id]);

  return (
    <Row>
      <Col span={16}>
        <Form form={form} fields={[{ name: ["email"], value: email }]}>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                pattern: emailRegex,
                message: "Email must be of the form `foo@example.com`",
              },
            ]}
          >
            <Input
              style={{ width: "200px" }}
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Col>
      <Col span={8}>
        <Space size={5}>
          <Button onClick={onCancel} size="middle" style={{ width: "75px" }}>
            Cancel
          </Button>
          <Button
            disabled={!isFormValid}
            onClick={onSave}
            type="primary"
            size="middle"
            style={{ width: "65px" }}
          >
            Save
          </Button>
        </Space>
      </Col>
    </Row>
  );
};

const EditEmail = ({ existingRow, callback, setIsLoading }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [email, setEmail] = useState();
  const [isFormValid, setIsFormValid] = useState(false);

  // redux state
  const decisionMaker = useSelector((state) => state.decisionMaker);
  const { setUpdateDecisionMakerQAStatus } = useContext(DecisionMakerContext);
  const [form] = useForm();

  const validate = async () => {
    try {
      await form.validateFields().then((values) => {
        setIsFormValid(
          checkFormChanged({ email: existingRow?.email_id }, values)
        );
      });
    } catch (error) {
      setIsFormValid(false);
    }
  };
  // form validation
  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  useEffect(() => {
    setEmail(existingRow.email_id);
  }, [existingRow]);

  const onSave = async () => {
    setIsLoading(true);
    try {
      await updateDecisionMakerEmail(decisionMaker.id, existingRow.id, email);
      callback();
      setUpdateDecisionMakerQAStatus(true);
      showSuccessMessage("Email updated.");
    } catch (error) {
      alert(error);
      setEmail();
    }
    setIsEditing(false);
    setIsLoading(false);
  };

  const onDelete = async () => {
    setIsLoading(true);
    try {
      await deleteDecisionMakerEmail(decisionMaker.id, existingRow.id);
      callback();
      setUpdateDecisionMakerQAStatus(true);
      showSuccessMessage("Email deleted.");
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  return (
    <Row>
      <Col span={16}>
        <Form form={form} fields={[{ name: ["email"], value: email }]}>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                pattern: emailRegex,
                message: "Email must be of the form `foo@example.com`",
              },
            ]}
          >
            <Input
              style={{ width: "200px" }}
              disabled={!isEditing}
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Col>
      <Col span={7}>
        {isEditing ? (
          <Space size={5}>
            <Button
              onClick={() => {
                setIsEditing(false);
                setEmail(existingRow.email_id);
              }}
              size="middle"
              style={{ width: "75px" }}
            >
              Cancel
            </Button>
            <Button
              disabled={!isFormValid}
              onClick={onSave}
              type="primary"
              size="middle"
              style={{ width: "65px" }}
            >
              Save
            </Button>
          </Space>
        ) : (
          <Button
            onClick={() => setIsEditing(true)}
            size="middle"
            style={{ width: "140px" }}
          >
            Edit
          </Button>
        )}
      </Col>
      <Col span={1}>
        <Popconfirm title={`Delete Email?`} okText="Yes" onConfirm={onDelete}>
          <Button danger type="link" icon={<DeleteOutlined />} />
        </Popconfirm>
      </Col>
    </Row>
  );
};
