import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Dropdown, Menu } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { CoworkersDrawer } from "../../EdgeSearchDrawer/CreateSegment/Coworkers";
import { ClassmatesDrawer } from "../../EdgeSearchDrawer/CreateSegment/Classmates";
import { DonorsDrawer } from "../../EdgeSearchDrawer/CreateSegment/Donors";
import { StaffersDrawer } from "../../EdgeSearchDrawer/CreateSegment/Staffers";
import { HVCDrawer } from "../../EdgeSearchDrawer/CreateSegment/HVC";
import { AssociationsDrawer } from "../../EdgeSearchDrawer/CreateSegment/Associations";
import { SecondDegreeDrawer } from "../../EdgeSearchDrawer/CreateSegment/SecondDegree";
import { LocationBasedSegmentsDrawer } from "../../EdgeSearchDrawer/CreateSegment/LocationBasedSegments";
import { LocalActivistsDrawer } from "../../EdgeSearchDrawer/CreateSegment/LocalActivists";
import { NeighborsDrawer } from "../../EdgeSearchDrawer/CreateSegment/Neighbors";
import { NetworkMembersDrawer } from "../../EdgeSearchDrawer/CreateSegment/NetworkMembers";

export const SegmentCreationDropdown = ({ refreshPageInfo }) => {
  // redux state
  const selectedTargetId = useSelector((state) => state.selectedTargetId);
  const targetOrPackage = useSelector((state) => state.targetPackageTabKey);

  // local state
  const [showCoworkerDrawer, setShowCoworkerDrawer] = useState(false);
  const [showClassmateDrawer, setShowClassmateDrawer] = useState(false);
  const [showDonorDrawer, setShowDonorDrawer] = useState(false);
  const [showStafferDrawer, setShowStafferDrawer] = useState(false);
  const [showAssociationsDrawer, setShowAssociationsDrawer] = useState(false);
  const [showNeighborsDrawer, setShowNeighborsDrawer] = useState(false);
  const [showSecondDegreeDrawer, setShowSecondDegreeDrawer] = useState(false);
  const [showLocationBasedSegmentsDrawer, setShowLocationBasedSegmentsDrawer] =
    useState(false);
  const [showLocalActivistsDrawer, setShowLocalActivistsDrawer] =
    useState(false);
  const [showHVCDrawer, setShowHVCDrawer] = useState(false);
  const [showNetworkMembersDrawer, setShowNetworkMembersDrawer] =
    useState(false);

  const disableForAllTargetsAndPackages =
    selectedTargetId === "all" || targetOrPackage !== "targets";

  const targetSegments = [
    {
      segmentName: "Associations",
      onClick: () => setShowAssociationsDrawer(true),
      disabled: false,
    },
    {
      segmentName: "Classmates",
      onClick: () => setShowClassmateDrawer(true),
      disabled: false,
    },
    {
      segmentName: "Coworkers",
      onClick: () => setShowCoworkerDrawer(true),
      disabled: false,
    },
    {
      segmentName: "Donors",
      onClick: () => setShowDonorDrawer(true),
      disabled: false,
    },
    {
      segmentName: "High Value Connections (HVC)",
      onClick: () => setShowHVCDrawer(true),
      disabled: false,
    },
    {
      segmentName: "Local Activists",
      onClick: () => setShowLocalActivistsDrawer(true),
      disabled: false,
    },
    {
      segmentName: "Location Segments",
      onClick: () => setShowLocationBasedSegmentsDrawer(true),
      disabled: false,
    },
    {
      segmentName: "Neighbors",
      onClick: () => setShowNeighborsDrawer(true),
      disabled: false,
    },
    {
      segmentName: "Staffers",
      onClick: () => setShowStafferDrawer(true),
      disabled: false,
    },
    {
      segmentName: "2nd Degree",
      onClick: () => setShowSecondDegreeDrawer(true),
      disabled: false,
    },
    {
      segmentName: "Network Members",
      onClick: () => setShowNetworkMembersDrawer(true),
      disabled: false,
    },
  ];

  return (
    <>
      <Dropdown
        style={{ marginLeft: 20 }}
        overlay={
          <Menu
            items={targetSegments.map((item) => {
              return {
                key: `${item.segmentName}-key`,
                onClick: item.onClick,
                label: `Add ${item.segmentName}`,
                disabled: item.disabled,
              };
            })}
          />
        }
        placement="bottomLeft"
        disabled={disableForAllTargetsAndPackages}
      >
        <Button style={{ width: "42px", height: "25px", padding: 0 }}>
          <PlusOutlined style={{ fontSize: "16px" }} />
        </Button>
      </Dropdown>
      <CoworkersDrawer
        isVisible={showCoworkerDrawer}
        setIsVisible={setShowCoworkerDrawer}
        refreshPage={refreshPageInfo}
      />
      <ClassmatesDrawer
        isVisible={showClassmateDrawer}
        setIsVisible={setShowClassmateDrawer}
        refreshPage={refreshPageInfo}
      />

      <DonorsDrawer
        isVisible={showDonorDrawer}
        setIsVisible={setShowDonorDrawer}
        refreshPage={refreshPageInfo}
      />

      <HVCDrawer
        isVisible={showHVCDrawer}
        setIsVisible={setShowHVCDrawer}
        refreshPage={refreshPageInfo}
      />

      <StaffersDrawer
        isVisible={showStafferDrawer}
        setIsVisible={setShowStafferDrawer}
        refreshPage={refreshPageInfo}
      />
      <AssociationsDrawer
        isVisible={showAssociationsDrawer}
        setIsVisible={setShowAssociationsDrawer}
        refreshPage={refreshPageInfo}
      />
      <LocalActivistsDrawer
        isVisible={showLocalActivistsDrawer}
        setIsVisible={setShowLocalActivistsDrawer}
        refreshPage={refreshPageInfo}
      />
      <LocationBasedSegmentsDrawer
        isVisible={showLocationBasedSegmentsDrawer}
        setIsVisible={setShowLocationBasedSegmentsDrawer}
        refreshPage={refreshPageInfo}
      />
      <NeighborsDrawer
        isVisible={showNeighborsDrawer}
        setIsVisible={setShowNeighborsDrawer}
        refreshPage={refreshPageInfo}
      />
      <SecondDegreeDrawer
        isVisible={showSecondDegreeDrawer}
        setIsVisible={setShowSecondDegreeDrawer}
        refreshPage={refreshPageInfo}
      />
      <NetworkMembersDrawer
        isVisible={showNetworkMembersDrawer}
        setIsVisible={setShowNetworkMembersDrawer}
        refreshPage={refreshPageInfo}
      />
    </>
  );
};
