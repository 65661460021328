import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { createDonorsSegment } from "../../../APIClient";
import {
  showWarningMessage,
  showSuccessMessage,
  showDangerMessage,
  makeAutocompleteOptions,
  dedupeArray,
} from "../../../utils";
import { SegmentDrawer } from "./SegmentDrawer";
import { Select, Form, Checkbox } from "antd";
import { states } from "../../../constants";

export const DonorsDrawer = ({ isVisible, setIsVisible, refreshPage }) => {
  const [selectedStates, setSelectedStates] = useState([]);

  const { projectId } = useParams();
  const target = useSelector((state) => state.target);

  const [isDCMetro, setIsDCMetro] = useState(false);

  const createAudienceMembers = async (desiredNumber) => {
    try {
      const {
        audience_length: numberOfDonors,
        matched_count: numberOfDonorsMatched,
      } = await createDonorsSegment(
        target?.dm_id,
        desiredNumber,
        projectId,
        target.id,
        dedupeArray(selectedStates.map((v) => v.toLowerCase())),
        isDCMetro
      );

      if (numberOfDonors === 0) {
        showDangerMessage(
          `Total of ${numberOfDonors} donors were found but we were unable to match any`
        );
      } else if (desiredNumber > numberOfDonors) {
        showWarningMessage(
          `Total of ${numberOfDonorsMatched} were found/matched and ${numberOfDonors} donors were added.`
        );
      } else {
        showSuccessMessage(
          `Total of ${numberOfDonorsMatched} were found/matched and ${numberOfDonors} donors were added.`
        );
      }
    } catch (error) {
      alert(error);
    }
    refreshPage();
    setSelectedStates([]);
    setIsDCMetro(false);
  };

  return (
    <SegmentDrawer
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      refreshPage={refreshPage}
      createAudienceMembers={createAudienceMembers}
      message={"Create Donors"}
    >
      <Form layout="vertical" style={{ paddingBottom: "10px" }}>
        <Form.Item label="States">
          <Select
            showSearch
            allowClear
            mode="multiple"
            placeholder="Select states"
            style={{ width: 350 }}
            onChange={(e) => setSelectedStates(e)}
            value={selectedStates}
            options={makeAutocompleteOptions(states)}
          />
        </Form.Item>
        <Form.Item>
          <Checkbox
            checked={isDCMetro}
            onChange={(e) => {
              setIsDCMetro(e.target.checked);
            }}
          >
            Include DC Metro Area (DMV Area)
          </Checkbox>
        </Form.Item>
      </Form>
    </SegmentDrawer>
  );
};
