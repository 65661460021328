import React, { useEffect, useState } from "react";
import { DatePicker, Form, Input } from "antd";

import moment from "moment";
import { EditOutlined, SaveOutlined } from "@ant-design/icons";

const FileNameRow = ({
  id = "",
  globalProjectName,
  globalDate,
  globalSuffix,
  targetOrGroup = "",
  validateString,
  setGlobalProjectName = null,
  setGlobalDate = null,
  setGlobalSuffix = null,
  global = false,
  fileNames = null,
  setFileNames = null,
  segments = null,
  setRefreshTpls = null,
  rowFileNames = null,
}) => {
  const dateFormat = "YYYY-MM-DD";
  const [date, setDate] = useState(globalDate);
  const [editFileName, setEditFileName] = useState(false);
  const [projectName, setProjectName] = useState(globalProjectName);
  const [suffix, setSuffix] = useState(globalSuffix);

  useEffect(() => {
    setProjectName(globalProjectName);
  }, [globalProjectName]);

  useEffect(() => {
    setDate(globalDate);
  }, [globalDate]);

  useEffect(() => {
    setSuffix(globalSuffix);
  }, [globalSuffix]);

  const renameFile = () => {
    if (global) {
      let fileNamesCopy = {
        ...fileNames,
      };
      for (let key of Object.keys(fileNamesCopy)) {
        fileNamesCopy[key] = `ac_${projectName}_${segments[key].replace(
          " ",
          "_"
        )}_${date}_${suffix}.csv`;
      }
      setFileNames(fileNamesCopy);
      setRefreshTpls(true);
    } else {
      rowFileNames[id] = `ac_${projectName}_${targetOrGroup.replaceAll(
        /(-| )/gi,
        "_"
      )}_${date}_${suffix}.csv`;
      setFileNames(rowFileNames);
    }

    setEditFileName(false);
  };

  return (
    <span
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
      }}
    >
      {!editFileName ? (
        global ? (
          <p style={{ margin: 0, padding: 0, marginRight: 10 }}>Edit all</p>
        ) : (
          <p
            style={{ margin: 0, padding: 0, marginRight: 10 }}
          >{`ac_${projectName}_${targetOrGroup?.replaceAll(
            /(-| )/gi,
            "_"
          )}_${date}_${suffix}.csv`}</p>
        )
      ) : (
        <Form
          style={{
            margin: 0,
            padding: 0,
            marginRight: 10,
            display: "flex",
            justifyContent: "space-evenly",
            width: "75%",
          }}
        >
          <Input
            placeholder={projectName}
            defaultValue={projectName}
            onChange={(e) =>
              setGlobalProjectName
                ? setGlobalProjectName(
                    validateString(e.target.value) || projectName
                  )
                : setProjectName(validateString(e.target.value) || projectName)
            }
            type="text"
            style={{ marginRight: 10, width: 180 }}
          />

          <DatePicker
            style={{ width: 140, marginRight: 10 }}
            placeholder={date}
            format={dateFormat}
            onChange={(date) =>
              setGlobalDate
                ? setGlobalDate(
                    date ? date.format(dateFormat) : moment().format(dateFormat)
                  )
                : setDate(
                    date ? date.format(dateFormat) : moment().format(dateFormat)
                  )
            }
          />

          <Input
            placeholder="suffix"
            defaultValue={suffix}
            onChange={(e) =>
              setGlobalSuffix
                ? setGlobalSuffix(validateString(e.target.value) || "")
                : setSuffix(validateString(e.target.value) || "")
            }
            type="text"
            style={{ marginRight: 10, width: 120 }}
          />
        </Form>
      )}
      {!editFileName ? (
        <EditOutlined
          onClick={() => setEditFileName(true)}
          style={{ color: "#1990ff", fontSize: 20 }}
        />
      ) : (
        <SaveOutlined
          style={{ color: "green", fontSize: 20 }}
          onClick={renameFile}
        />
      )}
    </span>
  );
};

export default FileNameRow;
