import { useEffect, useState, createContext } from "react";
import { getDecisionMaker } from "../../APIClient";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store";
import {
  determineComprehensiveReviewStatus,
  determineQualityAssuranceStatus,
} from "./QualityAssurance/DecisionMakerStatus";

export const DecisionMakerContext = createContext();

export const DecisionMakerContextProvider = ({ children }) => {
  const { decisionMakerId: decisionMakerIdFromUrl } = useParams();
  const [updateDecisionMakerQAStatus, setUpdateDecisionMakerQAStatus] =
    useState(false);
  const [isDecisionMakerQAStatusLoading, setIsDecisionMakerQAStatusLoading] =
    useState(false);
  const [qualityAssuranceInfo, setQualityAssuranceInfo] = useState({});
  const [comprehensiveReviewInfo, setComprehensiveReviewInfo] = useState({});

  const dispatch = useDispatch();
  const { setDecisionMakerAction } = bindActionCreators(
    actionCreators,
    dispatch
  );

  const fetchDecisionMaker = async (dmId) => {
    setIsDecisionMakerQAStatusLoading(true);
    try {
      const response = await getDecisionMaker(dmId);
      setDecisionMakerAction(response);
      setQualityAssuranceInfo(
        determineQualityAssuranceStatus({
          ...response.qa_events,
          qa_status: response.qa_status,
        })
      );
      setComprehensiveReviewInfo(
        determineComprehensiveReviewStatus(response.qa_events)
      );
    } catch (error) {
      alert(error);
    }
    setIsDecisionMakerQAStatusLoading(false);
  };

  useEffect(() => {
    if (decisionMakerIdFromUrl && updateDecisionMakerQAStatus) {
      fetchDecisionMaker(decisionMakerIdFromUrl);
      setUpdateDecisionMakerQAStatus(false);
    }
    return () => {
      setUpdateDecisionMakerQAStatus(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decisionMakerIdFromUrl, updateDecisionMakerQAStatus]);

  return (
    <DecisionMakerContext.Provider
      value={{
        isDecisionMakerQAStatusLoading,
        setUpdateDecisionMakerQAStatus,
        qualityAssuranceInfo,
        setQualityAssuranceInfo,
        comprehensiveReviewInfo,
        setComprehensiveReviewInfo,
      }}
    >
      {children}
    </DecisionMakerContext.Provider>
  );
};
