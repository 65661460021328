const addFilterToState = (state, filter) => {
  const degree = filter.first_degree ? "firstDegree" : "secondDegree";
  state[filter.target_project_link] = {
    ...state?.[filter.target_project_link],
    [filter.audience_segment]: {
      ...state?.[filter.target_project_link]?.[filter.audience_segment],
      [degree]: filter,
    },
  };
};

const restructureFilterRows = (filters) => {
  const filterObj = {};
  filters.forEach((f) => {
    addFilterToState(filterObj, f);
  });
  return filterObj;
};

const reducer = (state = false, action) => {
  switch (action.type) {
    case "get-filters":
      return restructureFilterRows(action.payload);
    case "delete-filter":
      // remove filter from state
      const deletedFilter = action.payload;
      delete state[deletedFilter.target_project_link][
        deletedFilter.audience_segment
      ][deletedFilter.first_degree ? "firstDegree" : "secondDegree"];
      return { ...state };
    case "update-filter":
      const updatedFilter = action.payload;
      state[updatedFilter.target_project_link][updatedFilter.audience_segment][
        updatedFilter.first_degree ? "firstDegree" : "secondDegree"
      ] = updatedFilter;
      return { ...state };
    case "create-filter":
      const newFilter = action.payload;
      addFilterToState(state, newFilter);
      return { ...state };
    default:
      return state;
  }
};

export default reducer;
