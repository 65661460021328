import { Table, Popover, Typography } from "antd";
import moment from "moment";
import React from "react";
import { getColumnSearchProps } from "../../../util/columnSearchPropFactory";

const { Text } = Typography;

export const AggregatedDMsTable = ({ tableData, isLoading }) => {
  const columns = [
    {
      title: "Billing Customer Name",
      dataIndex: "billing_customer_name",
      render: (value, row) => (
        <Popover
          content={
            <div>
              <p>
                Billing Customer Name: <b>{value}</b>
              </p>
              <p>
                Billing Customer ID: <b>{row.billing_customer_id || "None"}</b>
              </p>
            </div>
          }
        >
          <Text ellipsis={true}>{value}</Text>
        </Popover>
      ),
      width: 120,
      defaultSortOrder: "ascend",
      sorter: {
        compare: (a, b) =>
          a.billing_customer_name.localeCompare(b.billing_customer_name),
        multiple: 3,
      },
      ...getColumnSearchProps("billing_customer_name", "Billing Customer Name"),
    },
    {
      title: "End Customer Name",
      dataIndex: "end_customer_name",
      render: (value, row) => (
        <Popover
          content={
            <div>
              <p>
                End Customer Name: <b>{value}</b>
              </p>
              <p>
                End Customer ID: <b>{row.end_customer_id || "None"}</b>
              </p>
            </div>
          }
        >
          <Text ellipsis={true}>{value}</Text>
        </Popover>
      ),
      width: 120,
      sorter: {
        compare: (a, b) =>
          a.end_customer_name.localeCompare(b.end_customer_name),
        multiple: 3,
      },
      ...getColumnSearchProps("end_customer_name", "End Customer Name"),
    },
    {
      title: "Client Projects",
      dataIndex: "client_project_names",
      render: (value, row) => (
        <Popover
          content={
            <div>
              <p>
                Client Project Names: <b>{value.join(", ")}</b>
              </p>
              <p>
                Client Project IDs: <b>{row.client_project_ids.join(", ")}</b>
              </p>
            </div>
          }
        >
          <Text ellipsis={true} style={{ whiteSpace: "pre-line" }}>
            {value.join("\r\n")}
          </Text>
        </Popover>
      ),
      width: 120,
      sorter: {
        compare: (a, b) =>
          a.client_project_names.localeCompare(b.client_project_names),
        multiple: 3,
      },
      ...getColumnSearchProps("client_project_names", "Client Projects"),
    },
    {
      title: "Decision Maker",
      dataIndex: "decision_maker_full_name",
      render: (value, row) => (
        <Popover
          content={
            <div>
              <p>
                Name: <b>{value}</b>
              </p>
              <p>
                Decison Maker ID: <b>{row.dm_id}</b>
              </p>
            </div>
          }
        >
          <Text ellipsis={true}>{value}</Text>
        </Popover>
      ),
      width: 110,
      // defaultSortOrder: "ascend",
      sorter: {
        compare: (a, b) =>
          a.decision_maker_full_name.localeCompare(b.decision_maker_full_name),
        multiple: 1,
      },
      ...getColumnSearchProps("decision_maker_full_name", "Decision Maker"),
    },
    {
      title: "Activation Date",
      dataIndex: "activate_date",
      align: "center",
      width: 80,
      sorter: (a, b) =>
        moment(a.ACTIVATE.effective_date).unix() -
        moment(b.ACTIVATE.effective_date).unix(),
      render: (text, record, index) => record.ACTIVATE.effective_date,
    },
    {
      title: "Usage Start Date",
      dataIndex: "USAGE_START",
      align: "center",
      width: 120,
      sorter: (a, b) =>
        moment(a?.USAGE_START?.effective_date)?.unix() -
        moment(b?.USAGE_START?.effective_date)?.unix(),
      render: (text, record, index) => record?.USAGE_START.effective_date,
    },
    {
      title: "Deactivation Date",
      dataIndex: "DEACTIVATE",
      align: "center",
      width: 80,
      sorter: (a, b) =>
        (a.DEACTIVATE ? moment(a.DEACTIVATE.effective_date).unix() : 0) -
        (b.DEACTIVATE ? moment(b.DEACTIVATE.effective_date).unix() : 0),
      render: (text, record, index) => record.DEACTIVATE?.effective_date || "",
    },
    {
      title: "Activation Units",
      dataIndex: "activation_units",
      width: 50,
      sorter: {
        compare: (a, b) => a.activation_units - b.activation_units,
      },
    },
    {
      title: "Usage Units",
      width: 50,
      dataIndex: "usage_units",
      sorter: {
        compare: (a, b) => a.usage_units - b.usage_units,
      },
      render: (text, record, index) => record.usage_units.toFixed(2),
    },
  ];
  return (
    <Table
      scroll={{ y: "60vh" }}
      style={{ width: "100%" }}
      columns={columns}
      dataSource={tableData
        .map((element, tpl_index) => {
          return element.active_durations.map((ad, duration_index) => ({
            ...element,
            ...ad,
            key: `${tpl_index}_${duration_index}`,
          }));
        })
        .flat()}
      loading={isLoading}
    />
  );
};
