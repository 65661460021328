import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../store";
import {
  Button,
  Col,
  Result,
  Row,
  Spin,
  Table,
  Tooltip,
  Typography,
  Tabs,
} from "antd";
import { BarChartOutlined, WarningOutlined } from "@ant-design/icons";
import { isObjectEmpty, roundNumberToNDecimals } from "../../../utils";
import AllTargetsBreakdown from "./AllTargetsBreakdown";
import { GeographyBreakdownTable } from "./GeographyBreakdownTable";

export const AudienceBreakdownColumn = () => {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const { getBreakdownAction } = bindActionCreators(actionCreators, dispatch);

  // redux state
  const target = useSelector((state) => state.target);
  const project = useSelector((state) => state.project);
  const selectedTargetId = useSelector((state) => state.selectedTargetId);
  const audienceBreakdownNeedsUpdate = useSelector(
    (state) => state.audienceBreakdownNeedsUpdate
  );
  const fullBreakdownResp = useSelector((state) => state.breakdown);
  const isBreakdownLoading = useSelector((state) => state.isBreakdownLoading);
  const targetOrPackage = useSelector((state) => state.targetPackageTabKey);

  const setBreakdownUpToDate = () =>
    dispatch({ type: "set-audience-breakdown-up-to-date" });

  useEffect(() => {
    fetchAudienceBreakdown();
    setBreakdownUpToDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const breakdown =
    selectedTargetId === "all" || targetOrPackage === "targets"
      ? fullBreakdownResp?.audience_breakdown || {}
      : fullBreakdownResp?.package_breakdown || {};
  const geographyBreakdown =
    selectedTargetId === "all" || targetOrPackage === "targets"
      ? fullBreakdownResp?.geography_breakdown || {}
      : fullBreakdownResp?.package_geography_breakdown || {};

  const fetchAudienceBreakdown = async () => {
    getBreakdownAction(projectId);
  };

  const segmentNames = () => {
    if (breakdown.first_degree?.segment_total)
      return Object.keys(breakdown.first_degree?.segment_total);
    return [];
  };

  const singleTargetBreakdownAllData =
    selectedTargetId === "all" || isObjectEmpty(breakdown) || !target
      ? []
      : segmentNames().map((segment_name) => {
          const targetFirstDegree = breakdown?.first_degree?.[target?.id];
          const targetSecondDegree = breakdown?.second_degree?.[target?.id];
          const totalAudienceMembers =
            (targetFirstDegree?.target_total || 0) +
            (targetSecondDegree?.target_total || 0);

          const targetAndSegmentFirstDegree =
            targetFirstDegree?.[segment_name] || 0;
          const targetAndSegmentSecondDegree =
            targetSecondDegree?.[segment_name] || 0;
          const segmentTotal =
            targetAndSegmentFirstDegree + targetAndSegmentSecondDegree;
          return {
            segment_name:
              segment_name === "target_total" ? "All Segments" : segment_name,
            total_count: segmentTotal,
            percent_of_total: roundNumberToNDecimals(
              (segmentTotal / (totalAudienceMembers || 1)) * 100,
              1
            ).toFixed(1),
            percent_first_degree: roundNumberToNDecimals(
              (targetAndSegmentFirstDegree / (segmentTotal || 1)) * 100,
              1
            ).toFixed(1),
          };
        });

  singleTargetBreakdownAllData.sort((a, b) => {
    if (a.segment_name === "All Segments") return -1;
    else if (a.segment_name > b.segment_name) return 1;
    else if (a.segment_name < b.segment_name) return -1;
    return 0;
  });

  const singleTargetBreakdownColumns = [
    {
      title: "Segment Name",
      dataIndex: "segment_name",
    },
    {
      title: "Total Count",
      dataIndex: "total_count",
    },
    {
      title: "Percent of Project Audience",
      dataIndex: "percent_of_total",
      render: (val) => `${val}%`,
    },
    {
      title: "Percent First Degree",
      dataIndex: "percent_first_degree",
      render: (val) => `${val}%`,
    },
  ];

  const singleDMNetworkBlockbyGeoColumns = [
    {
      title: "Segment Name",
      dataIndex: "segment_name",
    },
    {
      title: "In State",
      dataIndex: "in_target_state",
      render: (text, record) =>
        `${roundNumberToNDecimals(
          (record.in_target_state / (record.target_total || 1)) * 100,
          1
        )}%`,
    },
    {
      title: "In Geo",
      dataIndex: "in_target_geo",
      render: (text, record) =>
        `${roundNumberToNDecimals(
          (record.in_target_geo / (record.target_total || 1)) * 100,
          1
        )}%`,
    },
    {
      title: "In DC",
      dataIndex: "in_dc_area",
      //render: (val) => `${val}%`,
      render: (text, record) =>
        `${roundNumberToNDecimals(
          (record.in_dc_area / (record.target_total || 1)) * 100,
          1
        )}%`,
    },
  ];

  const formatDataForGeoPerDMTable = () => {
    if (target) {
      const formattedData =
        fullBreakdownResp?.geography_per_dm_per_block[target?.id];
      return Object.keys(formattedData || {}).map((item) => ({
        segment_name: item,
        ...formattedData[item],
      }));
    }
  };

  return (
    <Col span={9} style={{ backgroundColor: "#FAFCFF" }}>
      <Row
        justify="space-between"
        style={{
          alignItems: "baseline",
          padding: "20px",
          margin: "0px",
        }}
        gutter={16}
      >
        <Col>
          <Typography.Title level={5}>
            <BarChartOutlined /> Audience Breakdown
          </Typography.Title>
          {audienceBreakdownNeedsUpdate && (
            <Row>
              <Tooltip
                title='Changes have been made that will impact Audience Breakdown since the
        last refresh. Please click the "Refresh" button for an updated breakdown.'
              >
                <span style={{ color: "#fa8c16" }}>
                  <WarningOutlined />
                </span>
                &nbsp;&nbsp;
                <span style={{ color: "#656565" }}>
                  Breakdown requires update
                </span>
              </Tooltip>
            </Row>
          )}
        </Col>
        <Col>
          <Button
            type="primary"
            disabled={isBreakdownLoading}
            onClick={fetchAudienceBreakdown}
          >
            Refresh Breakdown
          </Button>
        </Col>
      </Row>
      <Row></Row>
      {isObjectEmpty(breakdown) ? (
        <Spin spinning={isBreakdownLoading}>
          <Result
            style={{ marginTop: "32px" }}
            icon={<BarChartOutlined className="empty-results-icon" />}
            subTitle={
              <>
                Add Decision Makers and Audience Members to see a visual
                breakdown of your audience here
              </>
            }
          />
        </Spin>
      ) : selectedTargetId === "all" ? (
        <AllTargetsBreakdown
          audienceBreakdown={fullBreakdownResp?.audience_breakdown || {}}
          geographyBreakdown={fullBreakdownResp?.geography_breakdown || {}}
          project={project}
          isBreakdownLoading={isBreakdownLoading}
        />
      ) : (
        <Tabs
          type="card"
          style={{ paddingLeft: "10px" }}
          className="all-targets-breakdown-tabs"
        >
          <Tabs.TabPane tab="Counts" key="1">
            <div>
              <Table
                bordered
                style={{ padding: "30px", fontWeight: "medium" }}
                rowKey="segment_name"
                pagination={false}
                loading={isBreakdownLoading}
                size="medium"
                rowClassName={(record, index) =>
                  index === 0 ? "table-row-highlighted" : "table-row-light"
                }
                columns={singleTargetBreakdownColumns}
                dataSource={singleTargetBreakdownAllData.sort(
                  (a, b) => b.total_count - a.total_count
                )}
              />

              <GeographyBreakdownTable
                geographyBreakdown={Object.fromEntries(
                  Object.entries(geographyBreakdown).filter(
                    ([k]) => Number(k) === target?.id
                  )
                )}
                project={project}
                isBreakdownLoading={isBreakdownLoading}
              />
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab={"Geography"} key="2">
            <div>
              <Table
                bordered
                style={{ padding: "30px", fontWeight: "medium" }}
                rowKey="segment_name"
                pagination={false}
                loading={isBreakdownLoading}
                size="medium"
                rowClassName={(record, index) =>
                  index === 0 ? "table-row-highlighted" : "table-row-light"
                }
                columns={singleDMNetworkBlockbyGeoColumns}
                dataSource={formatDataForGeoPerDMTable()}
              />
            </div>
          </Tabs.TabPane>
        </Tabs>
      )}
    </Col>
  );
};
