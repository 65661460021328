export const generateOutput = (tpls, uniqueGroupNames) => {
  return {
    ...getGroups(uniqueGroupNames, tpls),
    ...getIndividuals(tpls),
    ...validation(tpls),
  };
};

const getIndividuals = (tpls) => {
  return tpls
    .filter((tpl) => tpl.individual)
    .reduce((acc, tpl) => {
      const key = `${tpl.id}-${tpl.name.replaceAll(" ", "-").toLowerCase()}`;
      acc[key] = [tpl.id];
      return acc;
    }, {});
};

const getGroups = (uniqueGroupNames, tpls) => {
  return uniqueGroupNames.reduce((acc, group) => {
    acc[group] = tpls
      .filter((tpl) => tpl["groups"].includes(group))
      .map((tpl) => tpl.id);
    return acc;
  }, {});
};

const getFirstThreeNames = (tpls) => {
  const names = tpls
    .slice(0, 3)
    .map((tpl) => tpl.name)
    .join(", ");
  if (tpls.length > 3) {
    return `${names}, and Others...`;
  } else {
    return names;
  }
};

const validation = (tpls) => {
  const digest = tpls.reduce(
    (acc, tpl) => {
      const assignments = tpl.groups.length + (tpl.individual ? 1 : 0);
      acc.total += assignments;
      if (assignments === 0) {
        acc.unassigned.push(tpl);
      }
      return acc;
    },
    { total: 0, unassigned: [] }
  );

  if (digest.total === 0) {
    return { meta: { valid: false, details: "empty export" } };
  } else if (digest.unassigned.length > 0) {
    const details = `${
      digest.unassigned.length
    } target(s) unassigned: ${getFirstThreeNames(digest.unassigned)}`;
    return { meta: { valid: false, details: details } };
  } else {
    return { meta: { valid: true } };
  }
};

export const getTargetAndGroupNames = (groupings) => {
  return Object.keys(groupings || {})
    .filter((key) => Array.isArray(groupings[key]) && groupings[key].length > 0)
    .map((key) => key.toLowerCase());
};

export const removeNonAlphanumerics = (str) => {
  return str && str.replace(/[^a-zA-Z0-9]/g, "");
};
