import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./App.less";
import AuthedContainer from "./AuthedContainer";
import { decodeJWT, getCookie } from "../../util/cookies";
import { goToLoginPage } from "../../util/navigation";

const makeUserFromCookies = () => {
  const cookie = getCookie("abba-id-token");
  if (cookie) {
    const decoded = decodeJWT(cookie);
    return {
      id: decoded.user_id,
      email: decoded.email,
      full_name: decoded.name,
    };
  }
  return null;
};

const Authenticate = () => {
  useEffect(() => {
    goToLoginPage();
  }, []);

  return <div />;
};

function App() {
  const user = useSelector((state) => state.user);
  const [hasCookie, setHasCookie] = useState(!!makeUserFromCookies());

  const dispatch = useDispatch();

  useEffect(() => {
    const userFromCookies = makeUserFromCookies();
    setHasCookie(!!userFromCookies);
    if (user === null) {
      if (!!userFromCookies) {
        dispatch({ type: "update-user", payload: userFromCookies });
      } else {
        goToLoginPage();
      }
    }
    // eslint-disable-next-line
  }, [user]);

  return hasCookie ? user ? <AuthedContainer /> : <div /> : <Authenticate />;
}

export default App;
