import * as Sentry from "@sentry/react";

const reducer = (state = null, action) => {
  switch (action.type) {
    case "logout":
      return null;
    case "update-user":
      Sentry.setUser({
        id: action.payload.id,
        email: action.payload.email,
        username: action.payload.email,
      });
      return { ...action.payload };
    default:
      return state;
  }
};

export default reducer;
