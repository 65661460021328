import { EnvironmentOutlined, SyncOutlined } from "@ant-design/icons";
import { Col, Row, Table } from "antd";

const ColocatedDrawer = ({ data, isLoading, optionalColumns }) => {
  const dataSource = data?.map((el) => {
    let result = {
      key: el.p_id,
      ...el,
    };
    return result;
  });

  const columns = [
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "Middle Name",
      dataIndex: "midlle_name",
      key: "middle_name",
      render: (middle_name) => middle_name || "NA",
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: "Date Of Birth",
      dataIndex: "",
      key: "date_of_birth",
      render: (text, record) => {
        const modifiedMonth =
          record?.month && record?.month < 10
            ? `0${record?.month}`
            : record?.month;
        const modifiedDay =
          record?.day && record?.day < 10 ? `0${record?.day}` : record?.day;
        return record?.year && modifiedMonth && modifiedDay
          ? `${record?.year}-${modifiedMonth}-${modifiedDay}`
          : record?.year && modifiedMonth
          ? `${record?.year}-${modifiedMonth}`
          : record?.year;
      },
    },
  ];

  return (
    <>
      <Row className="person-card-header">
        <Col
          style={{
            width: 30,
          }}
        >
          <EnvironmentOutlined />
        </Col>
        <Col>
          Colocated ({data?.length || 0}) {isLoading && <SyncOutlined spin />}{" "}
        </Col>
      </Row>
      {data?.length > 0 && (
        <Row style={{ marginTop: 10 }} className="person-card-content">
          <div style={{ marginLeft: 30 }}>
            <Table
              style={{ backgroundColor: "transparent" }}
              pagination={false}
              dataSource={dataSource}
              columns={
                optionalColumns ? [...columns, ...optionalColumns] : columns
              }
            />
          </div>
        </Row>
      )}
    </>
  );
};

export default ColocatedDrawer;
