import Navbar from "../Navbar";
import React, { useEffect, useState } from "react";
import {
  getDestination,
  postDestination,
  deleteDestination,
} from "../../APIClient";
import { Button, Table, Row, Col, Drawer, Form, Select, Input } from "antd";

import { destinationNameOptions } from "./destinationNameOptions";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

const Destination = () => {
  const { accountId: accountIdFromUrlParams } = useParams();
  const history = useHistory();

  const selectedHubspotCompanyId = useSelector(
    (state) => state.selectedHubspotCompanyId
  );

  const [tableData, setTableData] = useState([]);
  const [selectedDestinationName, setSelectedDestinationName] = useState(null);
  const [selectedAdvertisingAccountId, setSelectedAdvertisingAccountId] =
    useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const fetchAccountDestinations = async () => {
    setIsLoading(true);
    try {
      const data = await getDestination(selectedHubspotCompanyId);
      setTableData(data);
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!!selectedHubspotCompanyId) {
      fetchAccountDestinations();
      history.push(`/destination/${selectedHubspotCompanyId}`);
    } else if (!!accountIdFromUrlParams) {
      dispatch({
        type: "set-selected-hubspot-company-id",
        payload: accountIdFromUrlParams,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHubspotCompanyId]);

  const columns = [
    {
      title: "Destination Name",
      dataIndex: "name",
    },
    {
      title: "Advertising Account ID",
      dataIndex: "advertising_account_id",
    },
    {
      title: "Action",
      render: (value, row) => {
        return (
          <Button
            danger
            onClick={() => {
              console.log(row);
              onClickDelete(row.id);
            }}
          >
            Delete
          </Button>
        );
      },
    },
  ];

  const onClickSave = async () => {
    try {
      await postDestination(
        selectedHubspotCompanyId,
        selectedDestinationName,
        selectedAdvertisingAccountId || null
      );
      fetchAccountDestinations();
    } catch (error) {
      alert(error);
    }
  };

  const onClickDelete = async (destinationId) => {
    try {
      await deleteDestination(destinationId);
      fetchAccountDestinations();
    } catch (error) {
      alert(error);
    }
  };

  return (
    <Navbar contentClass="landing-page-content">
      <Row style={{ marginBottom: 20 }}>
        <Col style={{ textAlign: "right" }} flex={"auto"}>
          <Button
            type="primary"
            disabled={!selectedHubspotCompanyId}
            onClick={() => setIsDrawerOpen(true)}
          >
            Add New Destination
          </Button>
          <Drawer
            placement="right"
            visible={isDrawerOpen}
            onClose={() => {
              setIsDrawerOpen(false);
              fetchAccountDestinations();
            }}
          >
            <h2>Add New Destination</h2>
            <Form>
              <Form.Item>
                <Select
                  allowClear
                  showSearch
                  placeholder="Destination name"
                  style={{ width: 300 }}
                  disabled={false}
                  onChange={(event) => {
                    setSelectedDestinationName(event);
                  }}
                >
                  {destinationNameOptions.map((destinationName) => (
                    <Select.Option
                      value={destinationName}
                      key={destinationName}
                    >
                      {destinationName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item>
                <Input
                  placeholder="Advertising Account ID"
                  style={{ width: 300 }}
                  onChange={(event) => {
                    setSelectedAdvertisingAccountId(event.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  disabled={!selectedDestinationName}
                  style={{ marginRight: 10 }}
                  onClick={onClickSave}
                >
                  Save
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={() => {
                    setIsDrawerOpen(false);
                    fetchAccountDestinations();
                  }}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          </Drawer>
        </Col>
      </Row>

      <Row>
        <Table
          loading={isLoading}
          style={{ width: "100%" }}
          columns={columns}
          dataSource={tableData}
          rowKey="id"
        />
      </Row>
    </Navbar>
  );
};

export default Destination;
