import { Row, Col, Form, Input, Select } from "antd";
import { DynamicRow } from "../../DynamicRow/DynamicRow";
import "./MethodSearchForm.css";

const NameRow = () => {
  const searchTypeOptionsMapping = [
    { value: "exact", label: "Exact" },
    { value: "starts_with", label: "Starts with" },
    { value: "contains", label: "Contains" },
    { value: "ends_with", label: "Ends with" },
  ];

  const searchTypeOptions = (
    <Select
      className="method-search-mode-options"
      dropdownStyle={{ minWidth: "100px" }}
      options={searchTypeOptionsMapping}
    />
  );

  const makeNameRow = (field, index) => {
    return (
      <>
        <Col>
          <Form.Item
            {...field}
            name={[field.name, "first_name"]}
            key={["first_name", index]}
          >
            <Input
              allowClear
              addonBefore={
                <Form.Item
                  name={[field.name, "first_name_search_type"]}
                  key={[field.name, "first_name_search_type", index]}
                  initialValue="exact"
                  noStyle
                >
                  {searchTypeOptions}
                </Form.Item>
              }
              onInput={(e) => (e.target.value = e.target.value.toLowerCase())}
              placeholder="First Name"
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            {...field}
            name={[field.name, "middle_name"]}
            key={["middle_name", index]}
          >
            <Input
              allowClear
              addonBefore={
                <Form.Item
                  name={[field.name, "middle_name_search_type"]}
                  key={[field.name, "middle_name_search_type", index]}
                  initialValue="exact"
                  noStyle
                >
                  {searchTypeOptions}
                </Form.Item>
              }
              onInput={(e) => (e.target.value = e.target.value.toLowerCase())}
              placeholder="Middle Name"
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            {...field}
            name={[field.name, "last_name"]}
            key={["last_name", index]}
          >
            <Input
              allowClear
              addonBefore={
                <Form.Item
                  name={[field.name, "last_name_search_type"]}
                  key={[field.name, "last_name_search_type", index]}
                  initialValue="exact"
                  noStyle
                >
                  {searchTypeOptions}
                </Form.Item>
              }
              onInput={(e) => (e.target.value = e.target.value.toLowerCase())}
              placeholder="Last Name"
            />
          </Form.Item>
        </Col>
      </>
    );
  };
  return (
    <Form.List name="names">
      {(fields, { add, remove }) => (
        <DynamicRow
          fields={fields}
          add={add}
          remove={remove}
          makeRow={makeNameRow}
        />
      )}
    </Form.List>
  );
};

const NameForm = () => {
  return (
    <Row gutter={15}>
      <NameRow />
    </Row>
  );
};

export default NameForm;
