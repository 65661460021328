import { useState, useContext } from "react";
import { Modal, Input, Button } from "antd";
import { createCompanyGroup } from "../../../APIClient";
import { showSuccessMessage } from "../../../utils";
import { CompanyGroupsContext } from "../CompanyGroupsPage";

export const CreateGroupModal = ({ setIsVisible, isVisible }) => {
  const [newGroupName, setNewGroupName] = useState();
  const { switchPage, configureGroup, setCompanyGridData } =
    useContext(CompanyGroupsContext);

  const saveNewGroup = async () => {
    try {
      const response = await createCompanyGroup(newGroupName);
      if (response) {
        configureGroup(response.id);
        showSuccessMessage("Successfully created");
      }
      if (response.error) {
        alert(response.error);
      }
    } catch (error) {
      alert(error.message);
    }
  };

  const onSave = () => {
    saveNewGroup();
    setIsVisible(false);
    setNewGroupName();
  };

  const handleCancel = () => {
    setIsVisible(false);
    setNewGroupName();
  };

  return (
    <Modal
      visible={isVisible}
      title={<>Create New Company Group</>}
      onCancel={handleCancel}
      footer={[
        <Button
          key="Cancel"
          onClick={() => {
            handleCancel();
          }}
        >
          Cancel
        </Button>,
        <Button
          key="Finish"
          type="primary"
          onClick={() => {
            onSave();
          }}
          disabled={newGroupName ? false : true}
        >
          Finish
        </Button>,
        <Button
          key="Finish + Add Companies"
          type="primary"
          onClick={() => {
            onSave();
            setCompanyGridData();
            switchPage("companyView");
          }}
          disabled={newGroupName ? false : true}
        >
          Finish + Add Companies
        </Button>,
      ]}
    >
      <Input
        maxLength={100}
        value={newGroupName}
        onChange={(e) => setNewGroupName(e.target.value)}
        style={{ margin: "20px 0", width: "80%" }}
        placeholder="Enter group name"
      />
    </Modal>
  );
};
