import { Modal, Form, Radio, Input, Space } from "antd";
import { LogoutOutlined, LoginOutlined, BankOutlined } from "@ant-design/icons";
import { createInExList } from "../../APIClient";
import { showSuccessMessage } from "../../utils";

const CreateInExListModal = ({
  refreshPageInfo,
  clientId,
  isVisible,
  setIsVisible,
}) => {
  const [createInExListForm] = Form.useForm();

  const initialValues = {
    list_type: null,
    list_name: null,
  };
  const isListTypeValue = Form.useWatch(["list_type"], createInExListForm);
  const isListNameValue = Form.useWatch(["list_name"], createInExListForm);

  const onOk = async () => {
    try {
      const allFormValues = createInExListForm.getFieldsValue();
      const response = await createInExList(
        clientId,
        allFormValues.list_name,
        allFormValues.list_type
      );
      if (response.id) {
        showSuccessMessage("List created");
        refreshPageInfo(true, false);
        setIsVisible(false);
      }
      if (response.error) {
        alert(response.error);
      }
    } catch (error) {
      alert(error);
    }
  };

  return (
    <>
      <Modal
        visible={isVisible}
        okText="Create"
        title="Create New List"
        onCancel={() => {
          setIsVisible(false);
        }}
        afterClose={() => {
          createInExListForm.setFieldsValue(initialValues);
        }}
        onOk={onOk}
        okButtonProps={{ disabled: !isListTypeValue || !isListNameValue }}
      >
        <Form form={createInExListForm} layout="vertical">
          <Form.Item label="Select List Type" name="list_type">
            <Radio.Group size="medium">
              <Space>
                <Radio.Button
                  value="INCLUSION"
                  style={{ backgroundColor: "#f6ffed" }}
                >
                  <LoginOutlined style={{ color: "#73d13d" }} /> Inclusion
                </Radio.Button>

                <Radio.Button
                  value="EXCLUSION"
                  style={{ backgroundColor: "#fff1f0" }}
                >
                  <LogoutOutlined style={{ color: "#ff7875" }} /> Exclusion
                </Radio.Button>

                <Radio.Button
                  value="COMPANY_EXCLUSION"
                  style={{ backgroundColor: "#fff1f0" }}
                >
                  <BankOutlined style={{ color: "#ff7875" }} /> Company
                </Radio.Button>
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Enter List Name"
            name="list_name"
            rules={[
              {
                max: 42,
                message: "The name is too long",
              },
            ]}
          >
            <Input placeholder="Name" style={{ width: "320px" }} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default CreateInExListModal;
