import React from "react";
import { Drawer, Table } from "antd";
import { getColumnSearchProps } from "../../util/columnSearchPropFactory";
import { isTargetActive } from "./utils";

export const ProjectDMsDrawer = ({ project, isVisible, setIsVisible }) => {
  return (
    <Drawer
      title={`"${project?.name}" Decision Makers`}
      width={600}
      onClose={() => {
        setIsVisible(false);
      }}
      visible={isVisible}
    >
      <Table
        style={{ width: "100%" }}
        columns={[
          {
            title: "Decision Makers",
            dataIndex: ["dm_profile", "full_name"],
            sorter: (a, b) =>
              a.dm_profile?.full_name?.localeCompare(b?.dm_profile?.full_name),
            ...getColumnSearchProps("dm_profile.full_name", "Decision Makers"),
          },
          {
            title: "Is Active",
            render: (row) => (isTargetActive(row) ? "Y" : "N"),
            sorter: (a, b) => isTargetActive(a) - isTargetActive(b),
            defaultSortOrder: "descend",
          },
        ]}
        dataSource={project?.decision_makers?.filter((row) => !!row.dm_id)}
        rowKey="id"
      />
    </Drawer>
  );
};
