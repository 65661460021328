import { combineReducers } from "redux";
import selectedTargetReducer from "./selectedTargetReducer";
import isTargetLoadingReducer from "./isTargetLoadingReducer";
import userReducer from "./userReducer";
import userGroupsReducer from "./userGroupsReducer";
import audienceMemberFiltersReducer from "./audienceMemberFiltersReducer";
import isAudienceMemberFilterLoading from "./isAudienceMemberFilterLoading";
import desiredAudienceSizeReducer from "./desiredAudienceSizeReducer";
import audienceCreationLogsReducer from "./audienceCreationLogsReducer";
import isAudienceCreationLogsLoadingReducer from "./isAudienceCreationLogsLoadingReducer";
import projectReducer from "./projectReducer";
import isProjectLoadingReducer from "./isProjectLoadingReducer";
import selectedTargetSegmentsReducer from "./selectedTargetSegmentsReducer";
import isAudienceSegmentsLoadingReducer from "./isAudienceSegmentsLoadingReducer";
import isOpenProjectDrawerReducer from "./isOpenProjectDrawerReducer";
import isOpenProjectDetailsDrawerReducer from "./isOpenProjectDetailsDrawerReducer";
import isOpenExportUIDrawerReducer from "./isOpenExportUIDrawerReducer";
import s3DataUploadLogsReducer from "./s3DataUploadLogsReducer";
import selectedTargetIdReducer from "./selectedTargetIdReducer";
import audienceBreakdownNeedsUpdateReducer from "./audienceBreakdownNeedsUpdateReducer";
import packageReducer from "./packageReducer";
import decisionMakerReducer from "./decisionMakerReducer";
import isBreakdownLoadingReducer from "./isBreakdownLoadingReducer";
import breakdownReducer from "./breakdownReducer";
import targetPackageTabKeyReducer from "./targetPackageTabKeyReducer";
import dspExportFilesReducer from "./dspExportFilesReducer";
import selectedHubspotCompanyIdReducer from "./selectedHubspotCompanyIdReducer";

const reducers = combineReducers({
  target: selectedTargetReducer,
  isTargetLoading: isTargetLoadingReducer,
  user: userReducer,
  userGroups: userGroupsReducer,
  audienceMemberFilters: audienceMemberFiltersReducer,
  isAudienceMemberFilterLoading: isAudienceMemberFilterLoading,
  desiredAudienceSizes: desiredAudienceSizeReducer,
  audienceCreationLogs: audienceCreationLogsReducer,
  isAudienceCreationLogsLoading: isAudienceCreationLogsLoadingReducer,
  project: projectReducer,
  isProjectLoading: isProjectLoadingReducer,
  isBreakdownLoading: isBreakdownLoadingReducer,
  selectedTargetSegments: selectedTargetSegmentsReducer,
  isAudienceSegmentsLoading: isAudienceSegmentsLoadingReducer,
  isOpenProjectDrawer: isOpenProjectDrawerReducer,
  isOpenProjectDetailsDrawer: isOpenProjectDetailsDrawerReducer,
  isOpenExportUIDrawer: isOpenExportUIDrawerReducer,
  s3DataUploadLogs: s3DataUploadLogsReducer,
  selectedTargetId: selectedTargetIdReducer,
  package: packageReducer,
  audienceBreakdownNeedsUpdate: audienceBreakdownNeedsUpdateReducer,
  decisionMaker: decisionMakerReducer,
  breakdown: breakdownReducer,
  targetPackageTabKey: targetPackageTabKeyReducer,
  dspExportFiles: dspExportFilesReducer,
  selectedHubspotCompanyId: selectedHubspotCompanyIdReducer,
});

export default reducers;
