import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { createNeighborsAudienceMembers } from "../../../APIClient";
import {
  showDangerMessage,
  showSuccessMessage,
  showWarningMessage,
} from "../../../utils";
import { SegmentDrawer } from "./SegmentDrawer";

export const NeighborsDrawer = ({ isVisible, setIsVisible, refreshPage }) => {
  const { projectId } = useParams();
  const target = useSelector((state) => state.target);

  const createAudienceMembers = async (desiredNumber) => {
    try {
      const {
        audience_length: neighborsFound,
        matched_count: neighborsMatched,
      } = await createNeighborsAudienceMembers(
        target?.dm_id,
        desiredNumber,
        projectId,
        target.id
      );
      if (neighborsMatched === 0) {
        showDangerMessage(
          `Total of ${neighborsFound} neighbors were found but we were unable to match any`
        );
      } else if (desiredNumber > neighborsMatched) {
        showWarningMessage(
          `Total of ${neighborsMatched} were found/matched and ${neighborsFound} neighbors were added.`
        );
      } else {
        showSuccessMessage(
          `Total of ${neighborsMatched} were found/matched and ${neighborsFound} neighbors were added.`
        );
      }
    } catch (error) {
      alert(error);
    }
  };

  return (
    <SegmentDrawer
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      refreshPage={refreshPage}
      createAudienceMembers={createAudienceMembers}
      message={"Create Neighbors"}
    />
  );
};
