import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store";
import {
  Button,
  Col,
  Drawer,
  Empty,
  Popconfirm,
  Row,
  Space,
  Spin,
  Tag,
} from "antd";
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  EditOutlined,
  InfoCircleTwoTone,
} from "@ant-design/icons";
import { useParams } from "react-router";
import { deleteAudienceMemberLog } from "../../APIClient";
import { showSuccessMessage } from "../../utils";

const QueryEditorDrawer = ({
  isVisible,
  setIsVisible,
  selectedSegment,
  refreshPage,
}) => {
  const { projectId } = useParams();

  // redux state
  const target = useSelector((state) => state.target);
  const segmentQueries =
    useSelector((state) => state.audienceCreationLogs)?.[selectedSegment?.id] ||
    [];
  const isAudienceCreationLogsLoading = useSelector(
    (state) => state.isAudienceCreationLogsLoading
  );
  const dispatch = useDispatch();

  const { isAudienceCreationLogsLoadingAction } = bindActionCreators(
    actionCreators,
    dispatch
  );

  // local state
  const [selectedSegmentQuery, setSelectedSegmentQuery] = useState();

  let title;
  return (
    <Drawer
      title={
        selectedSegmentQuery ? `Edit ${title} Query` : "Select a Query to Edit"
      }
      onClose={() => {
        setIsVisible(false);
        setSelectedSegmentQuery();
      }}
      visible={isVisible}
      bodyStyle={{ paddingBottom: 20 }}
      width="800px"
    >
      <Tag
        icon={<InfoCircleTwoTone />}
        color="processing"
        style={{
          color: "black",
          padding: "5px",
          width: "auto",
          marginBottom: "30px",
        }}
      >
        You're editing queries for <b>{selectedSegment?.type_name}</b> belonging
        to <b>{!!target ? target.full_name : "Wildcard"}</b>
      </Tag>
      <Spin spinning={isAudienceCreationLogsLoading} style={{ width: "100%" }}>
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          {!selectedSegmentQuery ? (
            segmentQueries.length > 0 ? (
              <>
                {segmentQueries.map((query) => (
                  <Tag
                    key={query.id}
                    style={{
                      maxWidth: "600px",
                      padding: "5px 20px",
                      width: "100%",
                      borderRadius: "3px",
                    }}
                  >
                    <Row style={{ alignItems: "center" }}>
                      <Col span={16}>
                        <b>{query.search_type}</b>
                      </Col>
                      <Col span={4}>
                        <span className="label-title">EDGE COUNT:</span>{" "}
                        <b>{query.audience_member_count.toLocaleString()}</b>
                      </Col>
                      <Col span={2}></Col>
                      <Col span={1}>
                        <Button
                          type="link"
                          onClick={() => setSelectedSegmentQuery(query)}
                          icon={<EditOutlined />}
                          style={{ textAlign: "left" }}
                        />
                      </Col>
                      <Col span={1}>
                        <Popconfirm
                          title={`Delete query and ${query.audience_member_count.toLocaleString()} audience members?`}
                          okText="Yes"
                          onConfirm={async () => {
                            isAudienceCreationLogsLoadingAction(true);
                            try {
                              await deleteAudienceMemberLog(
                                projectId,
                                query.id
                              );
                              showSuccessMessage(
                                "Successfully deleted query and associated Audience Members",
                                6
                              );
                              refreshPage();
                            } catch (error) {
                              alert(error);
                            }
                            isAudienceCreationLogsLoadingAction(false);
                          }}
                        >
                          <Button
                            danger
                            type="link"
                            icon={<DeleteOutlined />}
                          />
                        </Popconfirm>
                      </Col>
                    </Row>
                  </Tag>
                ))}
              </>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )
          ) : (
            <>
              <Button onClick={() => setSelectedSegmentQuery()}>
                <ArrowLeftOutlined /> Back to Queries
              </Button>
            </>
          )}
        </Space>
      </Spin>
    </Drawer>
  );
};

export default QueryEditorDrawer;
