import { useState } from "react";
import { Form, Input, Select, Button, Drawer } from "antd";
import { makeAutocompleteOptions, showSuccessMessage } from "../../utils";
import { states } from "../../constants";
import {
  createInExListMemberByPII,
  updateInExListMember,
} from "../../APIClient";
import { convertEmptyToNull } from "./util";

export const CreateInExMemberDrawer = ({
  isVisible,
  setIsVisible,
  memberByPIIForm,
  inExListId,
  refreshPageInfo,
  inExListMemberId,
  initialPIIvalues,
}) => {
  const [isFormChanged, setIsFormChanged] = useState(false);

  const handleFormChange = () => {
    const currentValues = memberByPIIForm.getFieldsValue();
    const formChanged =
      JSON.stringify(currentValues) !== JSON.stringify(initialPIIvalues);
    setIsFormChanged(formChanged);
  };

  const createMember = async () => {
    const required_values = await memberByPIIForm.validateFields([
      "first_name",
      "last_name",
    ]);
    const values = await memberByPIIForm.getFieldsValue();
    if (required_values.first_name && required_values.last_name) {
      try {
        const response = await createInExListMemberByPII(
          inExListId,
          convertEmptyToNull({ ...values, ...required_values })
        );
        if (response.id) {
          showSuccessMessage("Member attached");
          setIsVisible(false);
          refreshPageInfo(false, true);
        }
        if (response.error) {
          alert(response.error);
        }
      } catch (error) {
        alert(error);
      }
    }
  };

  const updateMember = async () => {
    const required_values = await memberByPIIForm.validateFields([
      "first_name",
      "last_name",
    ]);
    const values = await memberByPIIForm.getFieldsValue();
    if (required_values.first_name && required_values.last_name) {
      try {
        const response = await updateInExListMember(
          inExListMemberId,
          convertEmptyToNull({ ...values, ...required_values })
        );
        if (response.id) {
          showSuccessMessage("Successfully updated");
          setIsVisible(false);
          refreshPageInfo(false, true);
        }
        if (response.error) {
          alert(response.error);
        }
      } catch (error) {
        alert(error);
      }
    }
  };
  const handleSubmit = () => {
    if (inExListMemberId) {
      updateMember();
    } else {
      createMember();
    }
  };

  return (
    <Drawer
      forceRender
      title={"Enter List Member Details"}
      onClose={() => {
        setIsVisible(false);
        memberByPIIForm.resetFields();
      }}
      visible={isVisible}
      bodyStyle={{ paddingBottom: 20 }}
      width={"900px"}
    >
      <Form
        form={memberByPIIForm}
        onValuesChange={handleFormChange}
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 15,
        }}
        layout="horizontal"
        style={{
          maxWidth: 600,
        }}
      >
        <div style={{ marginTop: "16px" }}>
          <Form.Item
            label="First Name"
            name="first_name"
            rules={[
              {
                required: true,
                message: "The first name is required",
              },
              {
                max: 60,
                message: "The first name is too long",
              },
            ]}
          >
            <Input allowClear />
          </Form.Item>

          <Form.Item
            label="Last Name"
            name="last_name"
            rules={[
              {
                required: true,
                message: "The last name is required",
              },
              {
                max: 60,
                message: "The last name is too long",
              },
            ]}
          >
            <Input allowClear />
          </Form.Item>
        </div>
        <div style={{ marginTop: "50px" }}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                type: "email",
                message: "Please input valid email address",
              },
              {
                max: 60,
                message: "The last name is too long",
              },
            ]}
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item
            label="Primary Phone"
            name="phone1"
            rules={[
              {
                pattern: /^\d{10}$/,
                message: "Please input valid phone number",
              },
            ]}
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item
            label="Secondary Phone"
            name="phone2"
            rules={[
              {
                pattern: /^\d{10}$/,
                message: "Please input valid phone number",
              },
            ]}
          >
            <Input allowClear />
          </Form.Item>
        </div>
        <div style={{ marginTop: "50px" }}>
          <Form.Item
            label="Address Line 1"
            name="address1"
            rules={[
              {
                max: 80,
                message: "The address is too long",
              },
            ]}
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item
            label="Address Line 2"
            name="address2"
            rules={[
              {
                max: 80,
                message: "The address is too long",
              },
            ]}
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item
            label="City"
            name="city"
            rules={[
              {
                max: 80,
                message: "The city is too long",
              },
            ]}
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item label="State" name="state">
            <Select
              showSearch
              allowClear
              options={makeAutocompleteOptions(states)}
              style={{ width: "75px" }}
            />
          </Form.Item>
          <Form.Item
            label="ZIP Code"
            name="zip"
            rules={[
              {
                pattern: /^\d{5}$/,
                message: "Please enter a 5-digit zip code",
              },
            ]}
          >
            <Input allowClear style={{ width: "150px" }} />
          </Form.Item>
        </div>

        <Form.Item wrapperCol={{ offset: 16, span: 12 }}>
          <Button
            type="primary"
            onClick={handleSubmit}
            disabled={!isFormChanged}
          >
            {inExListMemberId ? "Update Details" : "Add to List"}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};
