import React from "react";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";

import { Button, Table, Drawer, InputNumber, Spin, Form, Select } from "antd";
import { createLocationBasedAudienceMembers } from "../../../APIClient";
import {
  showDangerMessage,
  showSuccessMessage,
  showWarningMessage,
} from "../../../utils";
import { useParams } from "react-router-dom";

import { StateDependentSelectionRow } from "../../SearchFormItems/StateDependentSelectionRow";
import { CongDistsByState } from "../../Project/constants/cong_dist";
import { states, defaultSegmentNames } from "../../../constants";
import { dedupeArray, makeAutocompleteOptions } from "../../../utils";

export const LocationBasedSegmentsDrawer = ({
  isVisible,
  setIsVisible,
  refreshPage,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { projectId } = useParams();

  const target = useSelector((state) => state.target);

  const [selectedLocationSegments, setSelectedLocationSegments] = useState([]);

  const defaultLocationBasedSegmentNames = [
    "Business leaders",
    "Media members",
    "Party leaders",
    "Chambers of commerce",
    "Elected officials",
    "Lobbyists",
  ];

  const formatLocationBasedSegmentNames = (segments) => {
    return segments.map((segment, index) => {
      return {
        id: index,
        type_name: segment,
      };
    });
  };

  const selectedDefaultLocationSegments = formatLocationBasedSegmentNames(
    defaultLocationBasedSegmentNames
  );

  const [desiredNumber, setDesiredNumber] = useState(100);

  const defaultSegmentSize = () => {
    const segmentSize = {};
    selectedDefaultLocationSegments.forEach((segments) => {
      segmentSize[segments["type_name"]] = 100;
    });
    return segmentSize;
  };

  const [
    selectedDefaultLocationSegmentsSize,
    setSelectedDefaultLocationSegmentsSize,
  ] = useState(defaultSegmentSize());

  const createAudienceMembers = async () => {
    setIsLoading(true);
    const locationBasedSegmentsRequest = [];
    selectedLocationSegments.forEach((segments) => {
      const request = {};
      request.segment_type = segments["type_name"];
      request.desired_size =
        selectedDefaultLocationSegmentsSize[segments["type_name"]];
      locationBasedSegmentsRequest.push(request);
    });
    try {
      const { audience_length: audienceSize, matched_count: matchedCount } =
        await createLocationBasedAudienceMembers(
          target?.dm_id,
          projectId,
          target.id,
          filterParams["states"],
          filterParams["congressional_districts"],
          locationBasedSegmentsRequest
        );
      if (matchedCount === 0) {
        showDangerMessage(
          `Total of ${audienceSize} audience members were found but we were unable to match any`
        );
      } else if (desiredNumber > matchedCount) {
        showWarningMessage(
          `Total of ${matchedCount} were found/matched and ${audienceSize} audience members were added.`
        );
      } else {
        showSuccessMessage(
          `Total of ${matchedCount} were found/matched and ${audienceSize} audience members were added.`
        );
      }
    } catch (error) {
      alert(error);
    }

    refreshPage();
    setSelectedStates([]);
    setSelectedCongressionalDistricts([]);
    setIsLoading(false);
    setIsVisible(false);
  };

  useEffect(() => {
    if (projectId) {
      setDesiredNumber(100);
      setSelectedDefaultLocationSegmentsSize(defaultSegmentSize());
      setSelectedLocationSegments([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const setDesiredSize = (record, size) => {
    selectedDefaultLocationSegmentsSize[record["type_name"]] = size;
  };

  const existingLocationSegmentsColumns = [
    {
      title: "Segment Type",
      dataIndex: "type_name",
      key: "type_name",
    },
    {
      title: "Desired Size",
      width: 150,
      key: "desired_size",
      render: (text, record) => (
        <InputNumber
          size="large"
          min={1}
          max={10000000}
          defaultValue={desiredNumber}
          onChange={(val) => setDesiredSize(record, val)}
        />
      ),
    },
  ];

  const rowSelection = {
    selections: selectedLocationSegments,
    selectedRowKeys: selectedLocationSegments.map((segments) => segments["id"]),
    getCheckboxProps: (segments) => ({
      disabled:
        segments["disabled"] ||
        (selectedCongressionalDistricts?.length > 0 &&
          segments["type_name"] === defaultSegmentNames.LOBBYISTS),
    }),

    onChange: (key, vals) => setSelectedLocationSegments(vals),
    type: "checkbox",
  };

  const [selectedStates, setSelectedStates] = useState([]);
  const [congDistStates, setCongDistStates] = useState([]);
  const [selectedCongressionalDistricts, setSelectedCongressionalDistricts] =
    useState([]);

  const filterParams = {
    states: dedupeArray(selectedStates.map((v) => v.toLowerCase())),
    congressional_districts: dedupeArray(
      selectedCongressionalDistricts.map((v) => v.toLowerCase())
    ),
  };

  return (
    <Drawer
      title={`Location Based Segments`}
      onClose={() => setIsVisible(false)}
      visible={isVisible}
      width={800}
      bodyStyle={{ paddingBottom: 20 }}
    >
      <p>
        First choose the location(s) you want to add audience members from. Then
        select the segments you want to include and set the number of audience
        members to create.
      </p>

      <Spin spinning={isLoading}>
        <Form layout="vertical" style={{ paddingBottom: "10px" }}>
          <Form.Item label="States">
            <Select
              showSearch
              allowClear
              mode="multiple"
              placeholder="Select states"
              style={{ width: 350 }}
              onChange={(e) => setSelectedStates(e)}
              value={selectedStates}
              options={makeAutocompleteOptions(states)}
            />
          </Form.Item>
          <Form.Item label="Congressional District">
            <StateDependentSelectionRow
              selectedValues={selectedCongressionalDistricts}
              setSelectedValues={setSelectedCongressionalDistricts}
              selectedStates={congDistStates}
              setSelectedStates={setCongDistStates}
              valuesByState={CongDistsByState}
            />
          </Form.Item>
          <Form.Item>
            <Table
              style={{ paddingBottom: "15px" }}
              dataSource={selectedDefaultLocationSegments}
              columns={existingLocationSegmentsColumns}
              pagination={false}
              rowSelection={rowSelection}
              rowKey="id"
            />
            <Spin spinning={isLoading}>
              <Button
                type={"primary"}
                htmlType="submit"
                onClick={() => createAudienceMembers()}
                disabled={
                  selectedLocationSegments?.length <= 0 ||
                  (selectedLocationSegments.some((dict) =>
                    Object.values(dict).includes(defaultSegmentNames.LOBBYISTS)
                  ) &&
                    selectedCongressionalDistricts?.length > 0) ||
                  !(
                    selectedStates?.length > 0 ||
                    selectedCongressionalDistricts?.length > 0
                  )
                }
              >
                Create Location Segments
              </Button>
            </Spin>
          </Form.Item>
        </Form>
      </Spin>
    </Drawer>
  );
};
