import { CountiesByState } from "../Project/constants/counties";
import { Radio, Space, Select, Form } from "antd";
import { CongDistsByState } from "../Project/constants/cong_dist";
import {
  StateLowerDistsByState,
  StateUpperDistsByState,
} from "../Project/constants/state_dist";

export const DecisionMakerGeo = ({
  isPolitical,
  isEditing,
  representedState,
  decisionMakerGeo,
  setDecisionMakerGeo,
  congressionalDistrict,
  setCongressionalDistrict,
  representedStateUpperDistrict,
  setRepresentedStateUpperDistrict,
  representedStateLowerDistrict,
  setRepresentedStateLowerDistrict,
  representedCounty,
  setRepresentedCounty,
}) => {
  function createOptionsList(districtMapping, representedState) {
    return (districtMapping[representedState] || []).map((val) => ({
      value: `${val}`,
      label: `${val}`,
    }));
  }

  const onChange = (e) => {
    setDecisionMakerGeo(e.target.value);
    setCongressionalDistrict(null);
    setRepresentedStateUpperDistrict(null);
    setRepresentedStateLowerDistrict(null);
    setRepresentedCounty(null);
  };

  const selectStyle = {
    marginLeft: "10px",
    lineHeight: "20px",
    width: 200,
  };

  return (
    <Form.Item label="Represented Geography" style={{ marginTop: "20px" }}>
      <Radio.Group
        onChange={onChange}
        disabled={!isEditing}
        value={decisionMakerGeo}
        defaultValue={decisionMakerGeo}
        style={{ margin: "10px 0px 10px 0px", width: 400 }}
      >
        <Space direction="vertical">
          <Radio value={"congressional district"}>
            Congressional District
            {decisionMakerGeo === "congressional district" ? (
              <Select
                showSearch
                allowClear
                disabled={!representedState || !isEditing || !isPolitical}
                placeholder="Selection"
                style={selectStyle}
                onChange={(val) => setCongressionalDistrict(val)}
                value={congressionalDistrict}
                options={createOptionsList(CongDistsByState, representedState)}
              />
            ) : null}
          </Radio>
          <Radio value={"county"}>
            County
            {decisionMakerGeo === "county" ? (
              <Select
                showSearch
                allowClear
                disabled={!representedState || !isEditing}
                placeholder="Selection"
                style={selectStyle}
                onChange={(val) => setRepresentedCounty(val)}
                value={representedCounty}
                options={createOptionsList(CountiesByState, representedState)}
              />
            ) : null}
          </Radio>
          <Radio value={"state upper district"}>
            State Upper District
            {decisionMakerGeo === "state upper district" ? (
              <Select
                showSearch
                allowClear
                disabled={!representedState || !isEditing}
                placeholder="Selection"
                style={selectStyle}
                onChange={(val) => setRepresentedStateUpperDistrict(val)}
                value={representedStateUpperDistrict}
                options={createOptionsList(
                  StateUpperDistsByState,
                  representedState
                )}
              />
            ) : null}{" "}
          </Radio>
          <Radio value={"state lower district"}>
            State Lower District
            {decisionMakerGeo === "state lower district" ? (
              <Select
                showSearch
                allowClear
                disabled={!representedState || !isEditing}
                placeholder="Selection"
                style={selectStyle}
                onChange={(val) => setRepresentedStateLowerDistrict(val)}
                value={representedStateLowerDistrict}
                options={createOptionsList(
                  StateLowerDistsByState,
                  representedState
                )}
              />
            ) : null}
          </Radio>
          <Radio value={"None"}>None</Radio>
        </Space>
      </Radio.Group>
    </Form.Item>
  );
};
