import { Drawer, Table, Tooltip, Button } from "antd";
import { Link } from "react-router-dom";
import { PlusCircleOutlined } from "@ant-design/icons";

const SelectDecisionMakerAsHVCDrawer = ({
  isVisible,
  setIsVisible,
  colocatedInfo,
  setIsCreateDecisionMakerDrawerOpen,
  handleSelectedDecisionMaker,
}) => {
  const dataSource = colocatedInfo?.dm_info
    .filter((item) => item.is_hvc === false)
    .map((el) => {
      let result = {
        key: el.id,
        ...el,
      };
      return result;
    });

  const possibleDecisionMakersColumns = [
    {
      title: "DM id",
      dataIndex: "dm_id",
      sorter: (a, b) => a.id - b.id,
      render: (select, record) => (
        <Link
          to={`/decision-makers/${record.id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {record.id}
        </Link>
      ),
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      sorter: (a, b) => a.first_name?.localeCompare(b.first_name),
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "age",
      sorter: (a, b) => a.last_name?.localeCompare(b.last_name),
    },
    {
      title: "Decision Maker Type",
      dataIndex: "decision_maker_type",
      key: "decision_maker_type",
      sorter: (a, b) =>
        a.decision_maker_type?.localeCompare(b.decision_maker_type),
    },
    {
      title: "Action",
      dataIndex: "dm_id",
      key: "action",
      render: (select, record) => (
        <Tooltip title="Add this decision maker as HVC">
          <Button
            type="link"
            onClick={() => handleSelectedDecisionMaker(record)}
            icon={<PlusCircleOutlined />}
          >
            Add
          </Button>
        </Tooltip>
      ),
    },
  ];

  const handleCreateNewDecisionMaker = () => {
    setIsVisible(false);
    setIsCreateDecisionMakerDrawerOpen(true);
  };

  return (
    <Drawer
      title={`Adding ${colocatedInfo?.first_name.toUpperCase()} ${colocatedInfo?.last_name.toUpperCase()} as HVC`}
      onClose={() => setIsVisible(false)}
      visible={isVisible}
      width={900}
      bodyStyle={{ paddingBottom: 20 }}
    >
      <div style={{ fontSize: "16px" }}>
        <p>{`Found ${dataSource?.length} possible record${
          dataSource?.length > 1 ? "s" : ""
        } for ${colocatedInfo?.first_name.toUpperCase()} ${colocatedInfo?.last_name.toUpperCase()}.`}</p>
        <p>{`Please review the following decision maker${
          dataSource?.length > 1 ? "s" : ""
        } and choose to add ${
          dataSource?.length > 1 ? "one" : "them"
        } as HVC:`}</p>
      </div>
      <Table dataSource={dataSource} columns={possibleDecisionMakersColumns} />
      <p style={{ fontSize: "16px" }}>{`If the one${
        dataSource?.length > 1 ? "s above are" : " above is"
      } not right, you can create a new decision maker.`}</p>
      <Button type="primary" onClick={() => handleCreateNewDecisionMaker()}>
        Create Decision Maker
      </Button>
    </Drawer>
  );
};

export default SelectDecisionMakerAsHVCDrawer;
