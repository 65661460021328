import { Button, Popconfirm, Table, Tooltip } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import {
  formatTimestamp,
  sortColumnWithNulls,
  titleCaseNames,
} from "../../../utils";
import { Link } from "react-router-dom";
import { getColumnSearchProps } from "../../../util/columnSearchPropFactory";
import {
  getAllCompanyGroups,
  updateCompanyGroupQualityAssuranceTimestamp,
} from "../../../APIClient";
import { CompanyGroupsContext } from "../CompanyGroupsPage";
import { useState, useContext, useEffect } from "react";

export const QualityAssuranceGroupGrid = () => {
  const {
    isLoading,
    setIsLoading,
    currentPage,
    qualityAssuranceTextAndIconColor,
  } = useContext(CompanyGroupsContext);
  const [qualityAssuranceGroupGridData, setQualityAssuranceGroupGridData] =
    useState([]);

  const loadQualityAssuranceCompanyGroupGridData = async () => {
    setIsLoading(true);
    try {
      const response = await getAllCompanyGroups();
      if (response.error) {
        alert(response.error);
      }
      let response_with_key = await response.map((el) => {
        return {
          key: el.id,
          ...el,
          city: typeof el.city === "string" ? titleCaseNames(el.city) : null,
          state: typeof el.state === "string" ? el.state.toUpperCase() : null,
        };
      });
      setQualityAssuranceGroupGridData(
        response_with_key.sort(
          (a, b) =>
            new Date(b?.most_recent_edit) - new Date(a?.most_recent_edit)
        )
      );
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  const updateTimestamp = async (groupId) => {
    try {
      const response = await updateCompanyGroupQualityAssuranceTimestamp(
        groupId
      );

      if (response.most_recent_qa) {
        setQualityAssuranceGroupGridData(
          qualityAssuranceGroupGridData.map((item) => {
            if (item.id === groupId) {
              item.most_recent_qa = response.most_recent_qa;
              item.most_recent_edit = response.most_recent_edit;
            }
            return item;
          })
        );
      }
      if (response.error) {
        alert(response.error);
      }
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    if (currentPage.qualityAssuranceGroupView) {
      loadQualityAssuranceCompanyGroupGridData();
    }
    // eslint-disable-next-line
  }, [currentPage]);

  const qualityAssuranceGroupGridColumns = [
    {
      title: "Group Name",
      dataIndex: "name",
      sorter: (a, b) => a.name - b.name,
      ...getColumnSearchProps("name", "name"),
      width: 180,
      render: (select, record) => (
        <Link
          to={`/company-groups/${record.id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {record.name}
        </Link>
      ),
    },
    {
      title: "Canonical Company",
      dataIndex: "company_name",
      sorter: (a, b) => a.company_name?.localeCompare(b.company_name),
      ...getColumnSearchProps("company_name", "Company Name"),
      width: 200,
    },
    {
      title: "City",
      dataIndex: "city",
      sorter: (a, b) => sortColumnWithNulls(a.city, b.city),
      width: 150,
    },
    {
      title: "State",
      dataIndex: "state",
      sorter: (a, b) => sortColumnWithNulls(a.state, b.state),
      width: 80,
    },
    {
      title: "Size",
      dataIndex: "employee_count",
      sorter: (a, b) => a?.employee_count - b?.employee_count,
      width: 80,
    },
    {
      title: "Count",
      dataIndex: "company_count",
      sorter: (a, b) => a?.company_count - b?.company_count,
      width: 80,
    },
    {
      title: "Most Recent Edit",
      dataIndex: "most_recent_edit",
      width: 150,
      sorter: (a, b) =>
        new Date(a.most_recent_edit) - new Date(b.most_recent_edit),
      render: (text, record) => formatTimestamp(record.most_recent_edit),
    },
    {
      title: "Most Recent QA",
      dataIndex: "most_recent_qa",
      width: 150,
      sorter: (a, b) => new Date(a.most_recent_qa) - new Date(b.most_recent_qa),
      render: (text, record) => formatTimestamp(record.most_recent_qa),
    },
    {
      title: "Action",
      key: "action",
      width: 75,
      render: (record) => (
        <Popconfirm
          title="Are you sure you want to reset most recent QA?"
          onConfirm={() => {
            updateTimestamp(record.id);
          }}
          okText="Yes"
          cancelText="No"
        >
          <Button type="text" style={{ padding: 0 }}>
            <Tooltip
              title={
                qualityAssuranceTextAndIconColor(record).text +
                "Reset QA timestamp"
              }
              placement="left"
            >
              <ReloadOutlined
                style={{
                  color: qualityAssuranceTextAndIconColor(record).color,
                  fontSize: 15,
                }}
              />
            </Tooltip>
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <Table
      columns={qualityAssuranceGroupGridColumns}
      dataSource={qualityAssuranceGroupGridData}
      pagination={{ pageSize: 50 }}
      loading={isLoading}
      virtual
      scroll={{ x: 1000, y: 600 }}
    />
  );
};
