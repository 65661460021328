import React, { useState } from "react";
import { Button, Col, Form, Row, Spin, Input } from "antd";
import { findDecisionMaker } from "../../APIClient";

const DMSSearchForm = ({ dataCallback, searchForm, formResetCallback }) => {
  const [btnDisabled, setBtndisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      const data = await findDecisionMaker(values.first_name, values.last_name);
      dataCallback(data);
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onValuesChange = (changedValues, allValues) => {
    if (
      allValues.last_name !== undefined ||
      allValues.first_name !== undefined
    ) {
      setBtndisabled(false);
    } else {
      setBtndisabled(true);
    }
  };

  const onReset = () => {
    setBtndisabled(true);
    formResetCallback();
  };

  return (
    <>
      <Spin spinning={isLoading}>
        <Form
          form={searchForm}
          name="dms_search_form"
          onValuesChange={onValuesChange}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item name="first_name">
            <Input placeholder="First Name" />
          </Form.Item>
          <Form.Item name="last_name">
            <Input placeholder="Last Name" />
          </Form.Item>
          <Row gutter={10}>
            <Col>
              <Form.Item>
                <Button htmlType="button" onClick={onReset}>
                  Reset
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button type="primary" htmlType="submit" disabled={btnDisabled}>
                  Search
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </>
  );
};

export default DMSSearchForm;
