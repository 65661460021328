import React, { useState, useEffect } from "react";
import { Card, Empty } from "antd";
import { useForm } from "antd/lib/form/Form";
import DMSSearchForm from "./DMSSearchForm";
import DMSPersonCard from "./DMSPersonCard";

const DMSSearch = ({ setDMSSearchResultCallback, inputForm }) => {
  let [form] = useForm();
  if (inputForm) {
    form = inputForm;
  }
  const [results, setResults] = useState(null);

  const formResetCallback = () => {
    form.resetFields();
    setResults(null);
  };

  useEffect(() => {
    form.resetFields();
    setResults(null);
  }, [form]);

  return (
    <div
      className="target-search-container"
      style={{ marginLeft: "2%", marginRight: "2%" }}
    >
      <Card title="Decision Maker Search" className="display-card">
        <DMSSearchForm
          dataCallback={setResults}
          searchForm={form}
          formResetCallback={formResetCallback}
        />
      </Card>
      {results &&
        (form.getFieldValue("first_name") ||
          form.getFieldValue("last_name")) && (
          <Card
            title="Search Results"
            className="display-card"
            style={{ marginTop: "2%" }}
          >
            <div style={{ overflow: "auto", height: 400 }}>
              {!results || results.length === 0 ? (
                <Empty description="No results" />
              ) : (
                <>
                  {results.map((row, index) => (
                    <DMSPersonCard
                      index={index}
                      key={index}
                      person={row}
                      linkToButtonCallback={setDMSSearchResultCallback}
                    />
                  ))}
                </>
              )}
            </div>
          </Card>
        )}
    </div>
  );
};

export default DMSSearch;
