import React, { useState, useEffect } from "react";
import { Space, Row, Table, Button, Radio, TreeSelect } from "antd";
import {
  PlusCircleOutlined,
  SearchOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  getDecisionMakerList,
  getDecisionMakerTaxonomy,
} from "../../../../src/APIClient";
import { useHistory, useParams } from "react-router-dom";
import { getColumnSearchProps } from "../../../util/columnSearchPropFactory";
import { CreateDecisionMakerDrawer } from "./CreateDecisionMakerDrawer";
import Navbar from "../../Navbar";
import {
  titleCase,
  createFilterOptionsFromData,
  formatTimestamp,
} from "../../../utils";
import { buildTree, findNodeInTree, findAllChildrenFromParent } from "./utils";
import { CSVLink } from "react-csv";
import {
  ALL_GROUPS,
  isUserInAllowedGroups,
} from "../../../util/feature_flags/users";
import { useSelector } from "react-redux";

const searchTree = (dataIndex, tree) => {
  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <TreeSelect
        treeLine
        multiple={true}
        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
        placeholder="Decision maker type"
        allowClear={true}
        onClear={() => {
          setSelectedKeys([]);
        }}
        onChange={(e) => {
          let nodes = e.map((e) => findNodeInTree(tree, e || "") || {});

          nodes.filter((node) => node.hasOwnProperty("children"))?.length > 0
            ? setSelectedKeys(
                nodes
                  .map((node) =>
                    findAllChildrenFromParent(node).map(({ title }) => title)
                  )
                  .flat()
              )
            : setSelectedKeys(e ? e : []);
          confirm({ closeDropdown: false });
        }}
        showSearch
        style={{ width: 250 }}
        treeData={tree}
      />
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          backgroundColor: filtered ? "#1890ff" : undefined,
          color: filtered ? "whitesmoke" : undefined,
          fontSize: "15px",
          borderRadius: "100px",
          padding: "4px",
        }}
      />
    ),
    onFilter: (value, record) => {
      return (
        record[dataIndex]?.toString()?.toLowerCase() === value?.toLowerCase()
      );
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => {}, 100);
      }
    },
  };
};

const DecisionMakerList = () => {
  const userGroups = useSelector((state) => state.userGroups);
  const { projectId: projectIdFromUlr } = useParams();
  const history = useHistory();
  const [decisionMakersGrid, setDecisionMakersGrid] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [partyFilterOptions, setPartyFilterOptions] = useState([]);
  const [stateFilterOptions, setStateFilterOptions] = useState([]);

  const [decisionMakerTypeFilterOptions, setDecisionMakerTypeFilterOptions] =
    useState([]);

  const [creationDrawerIsVisible, setCreationDrawerIsVisible] = useState(false);
  const [tree, setTree] = useState([]);

  const format_nested = (obj) => {
    return obj.hasOwnProperty("children")
      ? {
          text: obj.title,
          value: obj.value,
          expanded: false,
          children: obj.children.map((child) => format_nested(child)),
        }
      : { text: obj.title, value: obj.value };
  };

  const makeParentSelectable = (node) => {
    if (node.hasOwnProperty("children")) {
      delete node.selectable;
      return {
        ...node,
        children: node?.children.map((child) => makeParentSelectable(child)),
      };
    } else {
      return node;
    }
  };

  const fetchDecisionMakerTypeOptions = async () => {
    try {
      let resp = await getDecisionMakerTaxonomy();
      let tree = buildTree(resp)?.map((node) => makeParentSelectable(node));
      setTree(tree);
      let filters = tree.map((node) => format_nested(node));
      setDecisionMakerTypeFilterOptions(filters);
    } catch (error) {
      alert(error);
    }
  };

  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const loadDecisionMakers = async () => {
    setIsLoading(true);
    const response = await getDecisionMakerList(projectIdFromUlr);
    let response_with_key = await response.map((el) => {
      return {
        key: el.dm_id,
        ...el,
        has_p_id: el.has_p_id === true ? "Y" : "N",
        has_full_address: el.has_full_address === true ? "Y" : "N",
        has_current_experience: el.has_current_experience === true ? "Y" : "N",
        has_chief_of_staff: el.has_chief_of_staff === true ? "Y" : "N",
        has_spouse: el.has_spouse === true ? "Y" : "N",
        represented_state:
          typeof el.represented_state === "string"
            ? el.represented_state.toUpperCase()
            : null,
        political_party:
          typeof el.political_party === "string"
            ? titleCase(el.political_party.replace(/_/g, " "))
            : null,
      };
    });
    setPartyFilterOptions(
      createFilterOptionsFromData(response_with_key, "political_party")
    );
    setStateFilterOptions(
      createFilterOptionsFromData(response_with_key, "represented_state")
    );
    setDecisionMakersGrid(
      response_with_key
        .sort((a, b) => b.key - a.key)
        .sort((a, b) => b.project_count - a.project_count)
    );
    setIsLoading(false);
  };

  useEffect(() => {
    if (decisionMakerTypeFilterOptions?.length === 0) {
      fetchDecisionMakerTypeOptions();
    }
    loadDecisionMakers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultColumns = [
    {
      title: "DM id",
      dataIndex: "dm_id",
      width: 150,
      sorter: (a, b) => a.dm_id - b.dm_id,
      ...getColumnSearchProps("dm_id", "DM id"),
      render: (select, record) => (
        // eslint-disable-next-line
        <a onClick={() => history.push(`/decision-makers/${record.dm_id}`)}>
          {record.dm_id}
        </a>
      ),
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      width: 200,
      sorter: (a, b) => a.first_name?.localeCompare(b.first_name),
      ...getColumnSearchProps("first_name", "First Name"),
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      width: 200,
      sorter: (a, b) => a.last_name?.localeCompare(b.last_name),
      ...getColumnSearchProps("last_name", "Last Name"),
    },
    {
      title: "Decision Maker Type",
      dataIndex: "decision_maker_type",
      filters: decisionMakerTypeFilterOptions,
      filterSearch: true,
      width: 300,
      filterMode: "tree",
      ...searchTree("decision_maker_type", tree),
      sorter: (a, b) =>
        a.decision_maker_type?.localeCompare(b.decision_maker_type),
    },
  ];
  const piiColumns = [
    {
      title: "Project Count",
      dataIndex: "project_count",
      width: 150,
      filterMultiple: false,
      filters: [
        {
          text: "0 projects",
          value: (c) => c === 0,
        },
        {
          text: "1 or more projects",
          value: (c) => c >= 1,
        },
      ],
      sorter: (a, b) => a.project_count - b.project_count,
      onFilter: (value, record) => value(record.project_count),
    },
    {
      title: "Has P ID",
      dataIndex: "has_p_id",
      width: 150,
      filters: [
        {
          text: "Has P ID",
          value: "Y",
        },
        {
          text: "No P ID",
          value: "N",
        },
      ],
      sorter: (a, b) => a.has_p_id?.localeCompare(b.has_p_id),
      onFilter: (value, record) => record.has_p_id === value,
    },
    {
      title: "Has Full Address",
      dataIndex: "has_full_address",
      width: 150,
      filters: [
        {
          text: "Has Address",
          value: "Y",
        },
        {
          text: "No Address",
          value: "N",
        },
      ],
      sorter: (a, b) => a.has_full_address?.localeCompare(b.has_full_address),
      onFilter: (value, record) => record.has_full_address === value,
    },
  ];

  const experiencesColumns = [
    {
      title: "Has Current Experience",
      dataIndex: "has_current_experience",
      filters: [
        {
          text: "Has Experience",
          value: "Y",
        },
        {
          text: "No Experience",
          value: "N",
        },
      ],
      width: 150,
      sorter: (a, b) =>
        a.has_current_experience?.localeCompare(b.has_current_experience),
      onFilter: (value, record) => record.has_current_experience === value,
    },
    {
      width: 150,
      title: "Number of Experiences",
      dataIndex: "number_of_experiences",
      sorter: (a, b) => a.number_of_experiences - b.number_of_experiences,
    },
    {
      width: 150,
      title: "Number of Associations",
      dataIndex: "number_of_associations",
      sorter: (a, b) => a.number_of_associations - b.number_of_associations,
    },
    {
      width: 150,
      title: "Number of Educations",
      dataIndex: "number_of_educations",
      sorter: (a, b) => a.number_of_educations - b.number_of_educations,
    },
    {
      title: "Most Recent Edit",
      dataIndex: "most_recent_edit",
      width: 200,
      sorter: (a, b) =>
        new Date(a.most_recent_edit) - new Date(b.most_recent_edit),
      render: (text, record) => formatTimestamp(record.most_recent_edit),
    },
    {
      title: "Most Recent QA",
      dataIndex: "most_recent_qa",
      width: 200,
      sorter: (a, b) => new Date(a.most_recent_qa) - new Date(b.most_recent_qa),
      render: (text, record) => formatTimestamp(record.most_recent_qa),
    },
    {
      title: "Most Recent Review",
      dataIndex: "most_recent_review",
      width: 200,
      sorter: (a, b) =>
        new Date(a.most_recent_review) - new Date(b.most_recent_review),
      render: (text, record) => formatTimestamp(record.most_recent_review),
    },
  ];

  const hvcsColumns = [
    {
      title: "Total HVCs",
      dataIndex: "total_hvcs",
      width: 150,
      sorter: (a, b) => a.total_hvcs - b.total_hvcs,
    },
    {
      title: "Staff HVCs",
      dataIndex: "staff_hvcs",
      width: 150,
      sorter: (a, b) => a.staff_hvcs - b.staff_hvcs,
    },
    {
      title: "Family HVCs",
      dataIndex: "family_hvcs",
      width: 150,
      sorter: (a, b) => a.family_hvcs - b.family_hvcs,
    },
    {
      title: "C-Suite HVCs",
      dataIndex: "c_suite_hvcs",
      width: 150,
      sorter: (a, b) => a.c_suite_hvcs - b.c_suite_hvcs,
    },
    {
      title: "Has Chief of Staff",
      dataIndex: "has_chief_of_staff",
      width: 150,
      filters: [
        {
          text: "Has Chief of Staff",
          value: "Y",
        },
        {
          text: "No Chief of Staff",
          value: "N",
        },
      ],
      sorter: (a, b) =>
        a.has_chief_of_staff?.localeCompare(b.has_chief_of_staff),
      onFilter: (value, record) => record.has_chief_of_staff === value,
    },
    {
      title: "Has Spouse",
      dataIndex: "has_spouse",
      width: 150,
      sorter: (a, b) => a.has_spouse?.localeCompare(b.has_spouse),
      onFilter: (value, record) => record.has_spouse === value,
      filters: [
        {
          text: "Has Spouse",
          value: "Y",
        },
        {
          text: "No Spouse",
          value: "N",
        },
      ],
    },
  ];

  const electedOfficialsColumns = [
    {
      title: "Party",
      dataIndex: "political_party",
      width: 150,
      filters: partyFilterOptions,
      onFilter: (value, record) => record.political_party === value,
      sorter: (a, b) => a.political_party?.localeCompare(b.political_party),
    },
    {
      title: "Rep State",
      dataIndex: "represented_state",
      width: 150,
      filterSearch: true,
      filters: stateFilterOptions,
      onFilter: (value, record) => record.represented_state === value,
      sorter: (a, b) => a.represented_state?.localeCompare(b.represented_state),
    },
    {
      title: "Rep Geo",
      width: 150,
      dataIndex: "represented_geo",
      sorter: (a, b) => a.represented_geo?.localeCompare(b.represented_geo),
    },
    {
      title: "Donor IDs",
      dataIndex: "donor_ids",
      width: 150,
      sorter: (a, b) => a.donor_ids - b.donor_ids,
    },
    {
      title: "Donor Uploads",
      dataIndex: "donor_uploads",
      width: 150,
      sorter: (a, b) => a.donor_uploads - b.donor_uploads,
    },
    {
      title: "Candidate Staff IDs",
      dataIndex: "candidate_staff_ids",
      width: 150,
      sorter: (a, b) => a.candidate_staff_ids - b.candidate_staff_ids,
    },
    {
      title: "Legislator Staff IDs",
      dataIndex: "legislator_staff_ids",
      width: 150,
      sorter: (a, b) => a.legislator_staff_ids - b.legislator_staff_ids,
    },
    {
      title: "Matched Staff Count",
      dataIndex: "matched_staffers",
      width: 150,
      sorter: (a, b) => a.matched_staffers - b.matched_staffers,
    },
    {
      title: "Matched Staff Percentage",
      dataIndex: "matched_percentage",
      width: 150,
      sorter: (a, b) => a.matched_percentage - b.matched_percentage,
      render: (text, record) => formatter.format(record.matched_percentage),
    },
  ];

  const [currentColumns, setCurrentColumns] = useState(piiColumns);

  const toggleColumns = (value) => {
    switch (value) {
      case "pii":
        setCurrentColumns(piiColumns);
        break;
      case "elected_officials":
        setCurrentColumns(electedOfficialsColumns);
        break;
      case "experiences":
        setCurrentColumns(experiencesColumns);
        break;
      case "hvcs":
        setCurrentColumns(hvcsColumns);
        break;
      default:
        setCurrentColumns(piiColumns);
    }
  };

  return (
    <Navbar contentClass="landing-page-content">
      <div>
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          onClick={() => {
            setCreationDrawerIsVisible(true);
          }}
          size="large"
          style={{ float: "right" }}
        >
          Create New Decision Maker
        </Button>
        <Radio.Group
          onChange={(e) => toggleColumns(e.target.value)}
          defaultValue="pii"
          buttonStyle="solid"
          style={{ float: "left" }}
        >
          <Radio.Button value="pii">PII</Radio.Button>
          <Radio.Button value="experiences">Experiences</Radio.Button>
          <Radio.Button value="hvcs">HVCs</Radio.Button>
          <Radio.Button value="elected_officials">
            Elected Officials
          </Radio.Button>
        </Radio.Group>

        <Space direction="vertical" size={15} style={{ width: "100%" }}>
          <Row>
            <Table
              columns={defaultColumns.concat(currentColumns)}
              dataSource={decisionMakersGrid}
              loading={isLoading}
              virtual
              scroll={{
                x: 1200,
                y: "60vh",
              }}
              pagination={{
                defaultPageSize: 50,
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "30", "50", "100"],
                showTotal: () => (
                  <>
                    {isUserInAllowedGroups(userGroups, [
                      ALL_GROUPS.TECH,
                      ALL_GROUPS.PRODUCT,
                      ALL_GROUPS.TARGETING_ASSOCIATES,
                    ]) && (
                      <CSVLink
                        filename={`decision_maker_grid_${new Date()
                          .toLocaleString()
                          .replace(/[.,/:/ /]/g, "_")}.csv`}
                        data={decisionMakersGrid}
                      >
                        <Button
                          type="primary"
                          icon={<SaveOutlined />}
                          style={{ marginRight: 10, width: "10rem" }}
                        >
                          Download
                        </Button>
                      </CSVLink>
                    )}
                  </>
                ),
              }}
            />
          </Row>
        </Space>
        <CreateDecisionMakerDrawer
          isVisible={creationDrawerIsVisible}
          setIsVisible={setCreationDrawerIsVisible}
          loadDecisionMakers={loadDecisionMakers}
        />
      </div>
    </Navbar>
  );
};

export default DecisionMakerList;
