import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store";

import { Col, Row, Typography, Spin, Button } from "antd";
import { TeamOutlined, PlusOutlined } from "@ant-design/icons";

import Navbar from "../Navbar";
import { getDecisionMaker } from "../../APIClient";
import { useParams } from "react-router-dom";
import DMSSearch from "../DMSSearch/DMSSearch";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import DecisionMakersTabs from "./DecisionMakerTabs";
import { CreateDecisionMakerDrawer } from "./DecisionMakerList/CreateDecisionMakerDrawer";
import { DecisionMakerContextProvider } from "./DecisionMakerContext";

export const DecisionMakers = () => {
  const { decisionMakerId: decisionMakerIdFromUrl } = useParams();

  // local state
  const [isDecisionMakerLoading, setIsDecisionMakerLoading] = useState(false);
  const [creationDrawerIsVisible, setCreationDrawerIsVisible] = useState(false);

  const history = useHistory();

  // redux state
  const decisionMaker = useSelector((state) => state.decisionMaker);

  const dispatch = useDispatch();
  const { setDecisionMakerAction } = bindActionCreators(
    actionCreators,
    dispatch
  );

  const fetchDecisionMaker = async (dmId) => {
    setIsDecisionMakerLoading(true);
    try {
      const resp = await getDecisionMaker(dmId);
      setDecisionMakerAction(resp);
    } catch (error) {
      alert(error);
    }
    setIsDecisionMakerLoading(false);
  };

  useEffect(() => {
    if (decisionMakerIdFromUrl) {
      fetchDecisionMaker(decisionMakerIdFromUrl);
    } else {
      dispatch({ type: "set-decision-maker-null" });
    }
    return () => {
      dispatch({ type: "set-decision-maker-null" });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decisionMakerIdFromUrl]);

  return (
    <Navbar>
      <DecisionMakerContextProvider>
        <Row style={{ flex: "1 1 auto", height: "100%" }}>
          {/* decision maker search column */}
          <Col span={6} style={{ backgroundColor: "#FAFCFF" }}>
            <Row
              justify="space-between"
              style={{ alignItems: "baseline", padding: "25px 24px" }}
            >
              <Col>
                <Typography.Title level={3}>
                  <TeamOutlined /> Decision Makers
                </Typography.Title>
              </Col>
              <Button
                style={{ width: "42px", height: "25px", padding: 0 }}
                onClick={() => setCreationDrawerIsVisible(true)}
              >
                <PlusOutlined style={{ fontSize: "16px" }} />
              </Button>
            </Row>
            <DMSSearch
              setDMSSearchResultCallback={(person) => {
                history.push(`/decision-makers/${person?.id}`);
              }}
            />
          </Col>

          {/* decision maker info column */}
          <Col style={{ backgroundColor: "white" }} span={18}>
            <Spin spinning={isDecisionMakerLoading}>
              {!!decisionMaker && (
                <>
                  <Row
                    justify="space-between"
                    style={{
                      alignItems: "baseline",
                      padding: "20px 24px 10px",
                    }}
                  >
                    <Col>
                      <Typography.Title level={3}>
                        {/* <UserOutlined />{" "} */}
                        {`Details for ${decisionMaker.first_name} ${decisionMaker.last_name}`}
                      </Typography.Title>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ margin: "20px 24px 0px 0px" }}>
                      <DecisionMakersTabs />
                    </Col>
                  </Row>
                </>
              )}
            </Spin>
          </Col>
        </Row>
        <CreateDecisionMakerDrawer
          isVisible={creationDrawerIsVisible}
          setIsVisible={setCreationDrawerIsVisible}
        />
      </DecisionMakerContextProvider>
    </Navbar>
  );
};
