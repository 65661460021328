import { Row, List, Button, Input, Form, Space } from "antd";
import React, { useEffect, useState } from "react";
import {
  getDecisionMakerNotes,
  createDecisionMakerNote,
} from "../../../APIClient";

import { useSelector } from "react-redux";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import { DecisionMakerNoteListItem } from "./DecisionMakerNoteListItem";

export const DecisionMakerNotes = () => {
  const [decisionMakerNotes, setDecisionMakerNotes] = useState();
  const [newNoteView, setNewNoteView] = useState(false);

  const [loading, setIsLoading] = useState(false);
  const { TextArea } = Input;
  const decisionMaker = useSelector((state) => state.decisionMaker);
  const user = useSelector((state) => state.user.email);
  const [noteInputForm] = useForm();
  const note = Form.useWatch("note", noteInputForm);

  const fetchDecisionMakerNotes = async () => {
    setIsLoading(true);
    try {
      const data = await getDecisionMakerNotes(decisionMaker.id);
      setDecisionMakerNotes(data.sort((a, b) => b.id - a.id));
    } catch (error) {
      alert(error);
    }

    setIsLoading(false);
  };

  const onSave = async () => {
    try {
      const noteValue = noteInputForm.getFieldsValue();
      const response = await createDecisionMakerNote(
        decisionMaker.id,
        user,
        noteValue.note
      );
      if (response.id) {
        setNewNoteView(false);
        noteInputForm.resetFields();
        fetchDecisionMakerNotes();
      }
      if (response.error) {
        alert(response.error);
      }
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    if (decisionMaker.id) {
      fetchDecisionMakerNotes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decisionMaker.id]);

  return (
    <Row className={"notes-list"}>
      <List
        size="large"
        header={
          <>
            <Row>
              <Button
                onClick={() => setNewNoteView(!newNoteView)}
                icon={
                  newNoteView ? <MinusCircleOutlined /> : <PlusCircleOutlined />
                }
                type="link"
              >
                {newNoteView ? "Hide" : "Add Note"}
              </Button>
            </Row>
            {newNoteView && (
              <Row>
                <Form
                  onFinish={onSave}
                  form={noteInputForm}
                  style={{ marginTop: 20 }}
                >
                  <Form.Item name="note">
                    <TextArea
                      maxLength={5000}
                      style={{ minHeight: 200, minWidth: "650px" }}
                    />
                  </Form.Item>

                  <Row justify="end">
                    <Space gap="large">
                      <Form.Item>
                        <Button onClick={() => setNewNoteView(!newNoteView)}>
                          Cancel
                        </Button>
                      </Form.Item>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          disabled={!note}
                        >
                          Save
                        </Button>
                      </Form.Item>
                    </Space>
                  </Row>
                </Form>
              </Row>
            )}
          </>
        }
        loading={loading}
        dataSource={decisionMakerNotes}
        renderItem={(item, index) => (
          <DecisionMakerNoteListItem
            item={item}
            fetchDecisionMakerNotes={fetchDecisionMakerNotes}
          />
        )}
      />
    </Row>
  );
};
