import React, { useState } from "react";
import { Col, DatePicker, Row, Table } from "antd";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { getColumnSearchProps } from "../../util/columnSearchPropFactory";
import { getProjectDecisionMakers, updateTargets } from "../../APIClient";
import { isTargetActive } from "./utils";

const filesTableColumns = [
  {
    title: "Segment Name",
    dataIndex: "file_name",
  },
  {
    title: "Segment Size",
    dataIndex: "distinct_row_count",
    render: (val) => val?.toLocaleString(),
  },
  {
    title: "Destination",
    dataIndex: "destination_name",
  },
  {
    title: "Ad Account",
    dataIndex: "advertising_account_id",
  },
];

export const ProjectDecisionMakers = () => {
  const { hubspotCompanyId, projectId } = useParams();

  const [tableData, setTableData] = useState([]);
  const [checkedRows, setCheckedRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchProjectDecisionMakers = async () => {
    setIsLoading(true);
    try {
      const response = await getProjectDecisionMakers(
        hubspotCompanyId,
        projectId
      );
      setTableData(response);
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    projectId && hubspotCompanyId && fetchProjectDecisionMakers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, hubspotCompanyId]);

  const columns = [
    {
      title: "Decision Maker",
      dataIndex: "full_name",
      ...getColumnSearchProps("full_name", "Decision Maker"),
    },
    {
      title: "Position",
      dataIndex: "decision_maker_type",
      ...getColumnSearchProps("decision_maker_type", "Position"),
    },
    {
      title: "State",
      dataIndex: "represented_state",
    },
    {
      title: "Party",
      dataIndex: "political_party",
    },
    {
      title: "Activation Date",
      dataIndex: "activation_date",
    },
    {
      title: "Expected Deactivation Date",
      dataIndex: "expected_deactivation_date",
    },
    {
      title: "Actual Deactivation Date",
      dataIndex: "deactivation_date",
    },
    {
      title: "Is Active",
      render: (row) => (isTargetActive(row) ? "Y" : "N"),
      sorter: (a, b) => isTargetActive(a) - isTargetActive(b),
      defaultSortOrder: "descend",
    },
    {
      title: "Segments",
      render: (text, record, index) =>
        record.dsp_audience_files.map((row) => (
          <p key={row.id}>{row.file_name}</p>
        )),
    },
  ];

  const checkIsDateDisabled = (current) => {
    const activateDates = checkedRows.map((row) =>
      moment(row.activation_date).unix()
    );
    const maxActivateDate = moment.unix(Math.max(...activateDates));

    return current <= maxActivateDate;
  };

  return (
    <Row gutter={[16, 16]}>
      <Row style={{ width: "100%" }}>
        <Col>
          <Row>
            <strong>
              Expected Deactivation for {checkedRows.length} Targets
            </strong>
          </Row>
          <Row>
            <DatePicker
              style={{ width: 250 }}
              value={null}
              placeholder="Set Expected Deactivation"
              disabled={!checkedRows.length}
              disabledDate={checkIsDateDisabled}
              onChange={async (date) => {
                try {
                  await updateTargets(projectId, {
                    new_values: checkedRows.map((tpl) => ({
                      id: tpl.id,
                      expected_deactivation_date: date.format("YYYY-MM-DD"),
                    })),
                  });
                  fetchProjectDecisionMakers();
                } catch (error) {
                  alert(error);
                }
              }}
            />
          </Row>
        </Col>
      </Row>
      <Row style={{ width: "100%" }}>
        <Table
          rowKey="id"
          loading={isLoading}
          style={{ width: "100%" }}
          columns={columns}
          dataSource={tableData}
          rowSelection={{
            selectedRowKeys: checkedRows.map((row) => row.id),
            onChange: (selectedRowKeys, selectedRows) => {
              setCheckedRows(selectedRows);
            },
            getCheckboxProps: (record) => ({
              disabled: !!record.deactivation_date,
            }),
          }}
          expandable={{
            expandedRowRender: (row) => (
              <Table
                rowKey="id"
                pagination={false}
                size="small"
                dataSource={row.dsp_audience_files.flatMap((dsp_audience) =>
                  dsp_audience.audience_destinations.length
                    ? dsp_audience.audience_destinations.map((destination) => ({
                        ...dsp_audience,
                        advertising_account_id:
                          destination.advertising_account_id,
                        destination_name: destination.name,
                      }))
                    : dsp_audience
                )}
                columns={filesTableColumns}
              />
            ),
            rowExpandable: (row) => row.dsp_audience_files.length > 0,
          }}
        />
      </Row>
    </Row>
  );
};
