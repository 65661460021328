import { useState } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal, Input, message } from "antd";
import { deleteDecisionMaker } from "../../APIClient";

export const DeleteDecisionMakerModal = ({
  setIsVisible,
  isVisible,
  decisionMaker,
  history,
}) => {
  const [typeDeleteText, setTypeDeleteText] = useState("");

  const deleteDecisionMakerAction = async () => {
    const status_code = await deleteDecisionMaker(decisionMaker?.id);

    if (status_code === 200) {
      message.success("Decision maker is deleted");
      history.push(`/decision-makers/`);
    }
  };
  const handleOk = () => {
    deleteDecisionMakerAction();
    setIsVisible(false);
    setTypeDeleteText("");
  };

  const handleCancel = () => {
    setIsVisible(false);
    setTypeDeleteText("");
  };

  return (
    <Modal
      visible={isVisible}
      okText="Yes"
      okType="danger"
      onCancel={handleCancel}
      onOk={handleOk}
      okButtonProps={{
        disabled: !(typeDeleteText === "DELETE") ? true : false,
      }}
      title={
        <>
          <ExclamationCircleOutlined
            style={{ marginRight: "8px", color: "#ff4d4f" }}
          />
          Are you sure?
        </>
      }
    >
      {`You are about to delete this decison maker, please type DELETE to confirm`}
      <p>
        <Input
          value={typeDeleteText}
          onChange={(e) => setTypeDeleteText(e.target.value)}
          style={{ marginTop: "20px", width: "200px" }}
          placeholder="Type DELETE to proceed"
        />
      </p>
    </Modal>
  );
};
