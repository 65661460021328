import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import {
  createDecisionMakerPhoneNumber,
  deleteDecisionMakerPhoneNumber,
  getDecisionMakerPhoneNumbers,
  getPhoneTypes,
  updateDecisionMakerPhoneNumber,
} from "../../APIClient";
import { checkFormChanged, showSuccessMessage } from "../../utils";
import { DecisionMakerContext } from "./DecisionMakerContext";

export const PhoneNumber = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [creatingNew, setCreatingNew] = useState(false);
  const [phoneNumbers, setPhoneNumbers] = useState([]);

  // redux state
  const decisionMaker = useSelector((state) => state.decisionMaker);

  const fetchPhoneNumbers = async () => {
    // turn on loading
    setIsLoading(true);
    try {
      const data = await getDecisionMakerPhoneNumbers(decisionMaker.id);
      setPhoneNumbers(data);
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (decisionMaker.id) {
      fetchPhoneNumbers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decisionMaker.id, decisionMaker.dcm_id]);

  return (
    <Spin spinning={isLoading}>
      <Space direction="vertical" style={{ width: "100%" }}>
        {creatingNew ? (
          <CreatePhoneNumber
            onCancel={() => setCreatingNew(false)}
            callback={() => {
              setCreatingNew(false);
              fetchPhoneNumbers();
            }}
            setIsLoading={setIsLoading}
          />
        ) : (
          <Row>
            <Button
              block
              type="link"
              onClick={() => setCreatingNew(true)}
              style={{ textAlign: "left" }}
              size="large"
            >
              <PlusOutlined /> Add another
            </Button>
          </Row>
        )}
        {phoneNumbers?.length > 0 && (
          <Divider plain orientation="left">
            Existing Phone Numbers
          </Divider>
        )}
        {phoneNumbers
          ?.sort((a, b) => b.id - a.id)
          ?.map((pn, i) => (
            <React.Fragment key={i}>
              <EditPhoneNumber
                existingRow={pn}
                callback={() => {
                  fetchPhoneNumbers();
                }}
                setIsLoading={setIsLoading}
              />
            </React.Fragment>
          ))}
      </Space>
    </Spin>
  );
};

const CreatePhoneNumber = ({ onCancel, callback, setIsLoading }) => {
  const [phoneNumber, setPhoneNumber] = useState();
  const [phoneType, setPhoneType] = useState();
  const [isFormValid, setIsFormValid] = useState(true);

  // redux state
  const decisionMaker = useSelector((state) => state.decisionMaker);
  const { setUpdateDecisionMakerQAStatus } = useContext(DecisionMakerContext);
  const resetForm = () => {
    setPhoneNumber();
    setPhoneType();
  };

  const onSave = async () => {
    setIsLoading(true);
    try {
      await createDecisionMakerPhoneNumber(decisionMaker.id, {
        phoneNumber,
        phoneType,
      });
      callback();
      setUpdateDecisionMakerQAStatus(true);
      showSuccessMessage("New phone number added.");
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    resetForm();
  }, [decisionMaker.id]);

  return (
    <Row>
      <Col span={16}>
        <PhoneNumberForm
          disabled={false}
          setIsFormValid={setIsFormValid}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          phoneType={phoneType}
          setPhoneType={setPhoneType}
        />
      </Col>
      <Col span={8}>
        <Space size={5}>
          <Button onClick={onCancel} size="middle" style={{ width: "75px" }}>
            Cancel
          </Button>
          <Button
            disabled={!isFormValid}
            onClick={onSave}
            type="primary"
            size="middle"
            style={{ width: "65px" }}
          >
            Save
          </Button>
        </Space>
      </Col>
    </Row>
  );
};

const EditPhoneNumber = ({ existingRow, callback, setIsLoading }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const [phoneType, setPhoneType] = useState();
  const [isFormValid, setIsFormValid] = useState(false);

  // redux state
  const decisionMaker = useSelector((state) => state.decisionMaker);
  const { setUpdateDecisionMakerQAStatus } = useContext(DecisionMakerContext);
  const resetForm = () => {
    setPhoneNumber(existingRow.phone_number);
    setPhoneType(existingRow.phone_type);
  };

  useEffect(() => {
    resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingRow]);

  const onSave = async () => {
    setIsLoading(true);
    try {
      await updateDecisionMakerPhoneNumber(decisionMaker.id, existingRow.id, {
        phoneNumber,
        phoneType,
      });
      callback();
      setUpdateDecisionMakerQAStatus(true);
      showSuccessMessage("Phone number updated.");
    } catch (error) {
      alert(error);
      resetForm();
    }
    setIsEditing(false);
    setIsLoading(false);
  };

  const onDelete = async () => {
    setIsLoading(true);
    try {
      await deleteDecisionMakerPhoneNumber(decisionMaker.id, existingRow.id);
      callback();
      setUpdateDecisionMakerQAStatus(true);
      showSuccessMessage("Phone number deleted.");
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  return (
    <Row>
      <Col span={16}>
        <PhoneNumberForm
          disabled={!isEditing}
          existingRow={existingRow}
          setIsFormValid={setIsFormValid}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          phoneType={phoneType}
          setPhoneType={setPhoneType}
        />
      </Col>
      <Col span={7}>
        {isEditing ? (
          <Space size={5}>
            <Button
              onClick={() => {
                setIsEditing(false);
                resetForm();
              }}
              size="middle"
              style={{ width: "75px" }}
            >
              Cancel
            </Button>
            <Button
              disabled={!isFormValid}
              onClick={onSave}
              type="primary"
              size="middle"
              style={{ width: "65px" }}
            >
              Save
            </Button>
          </Space>
        ) : (
          <Button
            onClick={() => setIsEditing(true)}
            size="middle"
            style={{ width: "140px" }}
          >
            Edit
          </Button>
        )}
      </Col>
      <Col span={1}>
        <Popconfirm
          title={`Delete Phone number?`}
          okText="Yes"
          onConfirm={onDelete}
        >
          <Button danger type="link" icon={<DeleteOutlined />} />
        </Popconfirm>
      </Col>
    </Row>
  );
};

const PhoneNumberForm = ({
  disabled,
  existingRow = {},
  setIsFormValid,
  phoneNumber,
  setPhoneNumber,
  phoneType,
  setPhoneType,
}) => {
  const [form] = useForm();

  const [phoneTypeOptions, setPhoneTypeOptions] = useState([]);

  const fetchPhoneTypeOptions = async () => {
    try {
      const resp = await getPhoneTypes();
      setPhoneTypeOptions(resp.map(([value, label]) => ({ value, label })));
    } catch (error) {
      alert(error);
    }
  };

  const validate = async () => {
    try {
      await form.validateFields().then((values) => {
        setIsFormValid(checkFormChanged(existingRow, values));
      });
    } catch (error) {
      setIsFormValid(false);
    }
  };
  // form validation
  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumber, phoneType]);

  useEffect(() => {
    if (phoneTypeOptions.length === 0) {
      fetchPhoneTypeOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form
      form={form}
      fields={[
        { name: ["phone_number"], value: phoneNumber },
        { name: ["phone_type"], value: phoneType },
      ]}
    >
      <Form.Item
        rules={[
          {
            required: true,
            pattern: new RegExp(/^[0-9]{10}$/),
            message: "Phone number must be a 10 digit number",
          },
        ]}
        name="phone_number"
      >
        <Input
          style={{ width: "200px" }}
          disabled={disabled}
          placeholder="Phone number"
          maxLength={10}
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
      </Form.Item>
      <Form.Item name="phone_type">
        <Select
          allowClear
          style={{ width: "150px" }}
          disabled={disabled}
          placeholder="Phone type"
          value={phoneType}
          onChange={(v) => setPhoneType(v)}
          options={phoneTypeOptions}
        />
      </Form.Item>
    </Form>
  );
};
