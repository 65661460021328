import React, { useState } from "react";
import { Button, Popconfirm, Popover, Table, Tag } from "antd";

import PIIprofile from "./PIIprofile";
import {
  CheckOutlined,
  CheckCircleOutlined,
  ReloadOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { getColumnSearchProps } from "../../util/columnSearchPropFactory";
import PIISearchDrawer from "./PIISearch";
import { createStafferMatchAttemptLog } from "../../APIClient";
import { formatTimestamp, sortColumnWithNulls } from "../../utils";

const StaffersGrid = ({ staffers, setStaffers, setRerender }) => {
  const [toggleDrawer, setToggleDrawer] = useState(false);
  const [currentStaffer, setCurrentStaffer] = useState(null);

  const returnValueOrNA = (val) => (val && val !== null ? val : "NA");
  const user_email = useSelector((state) => state.user.email);

  const updateMostRecentAttempt = async (
    record,
    event_type = "match_attempt"
  ) => {
    try {
      const resp = await createStafferMatchAttemptLog(
        record.source_id,
        user_email,
        event_type
      );
      setStaffers(
        staffers.map((staffer) => {
          if (staffer.source_id === record?.source_id) {
            if (event_type === "match_attempt") {
              staffer.most_recent_attempt = resp.event_timestamp;
              staffer.needs_qa = true;
            } else {
              staffer.most_recent_qa = resp.event_timestamp;
              staffer.needs_qa = false;
            }
          }
          return staffer;
        })
      );
      setRerender(true);
    } catch (error) {
      alert(error);
    }
  };

  const columns = [
    {
      title: "PII Profile",
      dataIndex: "p_id",
      fixed: "left",
      key: "p_id",
      filterSearch: true,
      render: (p_id, record) =>
        p_id ? (
          <Tag bordered="false" color="success">
            PII profile attached
          </Tag>
        ) : (
          <Tag
            color="blue"
            bordered="false"
            onMouseOver={(event) => {
              event.target.style.cursor = "pointer";
            }}
            onClick={() => {
              setToggleDrawer(true);
              setCurrentStaffer(record);
            }}
          >
            Attach PII profile {p_id}
          </Tag>
        ),
      filters: [
        {
          text: "pii profile attached",
          value: !null,
        },
        {
          text: "pii profile not attached",
          value: null,
        },
      ],
      filterMultiple: false,
      sorter: (a, b) => a?.p_id - b?.p_id,
      onFilter: (value, record) => {
        return value === null ? record.p_id === value : record.p_id !== null;
      },
    },
    {
      title: "Last Attempt",
      dataIndex: "most_recent_attempt",
      fixed: "left",
      key: "most_recent_attempt",
      render: (most_recent_attempt, record) =>
        most_recent_attempt ? (
          <Popover content={formatTimestamp(record.most_recent_attempt)}>
            <Popconfirm
              placement="bottom"
              title="Are you sure you want to update?"
              onConfirm={() => {
                updateMostRecentAttempt(record);
              }}
            >
              <Button type="link">
                <ReloadOutlined style={{ color: "#52c41a" }} />
              </Button>{" "}
            </Popconfirm>
          </Popover>
        ) : (
          <Popover content="Never Attempted">
            <Popconfirm
              placement="bottom"
              title="Are you sure you want to update?"
              onConfirm={() => {
                updateMostRecentAttempt(record);
              }}
            >
              <Button type="link">
                <ReloadOutlined style={{ color: "#1677ff" }} />
              </Button>{" "}
            </Popconfirm>
          </Popover>
        ),
      filters: [
        {
          text: "Attempted",
          value: !null,
        },
        {
          text: "Never Attempted",
          value: null,
        },
      ],
      filterMultiple: false,
      sorter: (a, b) =>
        new Date(a?.most_recent_attempt) - new Date(b?.most_recent_attempt),
      onFilter: (value, record) => {
        return value === null
          ? record.most_recent_attempt === value
          : record.most_recent_attempt !== null;
      },
    },
    {
      title: "Last QA",
      dataIndex: "",
      fixed: "left",
      render: (something, record) => {
        return (
          <Popover content={formatTimestamp(record?.most_recent_qa)}>
            <Popconfirm
              placement="bottom"
              title="Are you sure you want to update?"
              disabled={!record?.most_recent_attempt && !record?.p_id}
              onConfirm={() => {
                updateMostRecentAttempt(record, "qa");
              }}
            >
              <Button
                type="link"
                disabled={!record?.most_recent_attempt && !record?.p_id}
              >
                {record?.needs_qa ? (
                  <ExclamationCircleOutlined style={{ color: "red" }} />
                ) : (
                  <CheckCircleOutlined style={{ color: "#52c41a" }} />
                )}
              </Button>
            </Popconfirm>
          </Popover>
        );
      },
      filters: [
        {
          text: "Needs QA",
          value: true,
        },
        {
          text: "Doesn't need QA",
          value: false,
        },
      ],
      filterMultiple: false,
      sorter: (a, b) =>
        new Date(a?.most_recent_qa) - new Date(b?.most_recent_qa),
      onFilter: (value, record) => {
        return value === true
          ? record.needs_qa === true
          : record.needs_qa === false;
      },
    },

    {
      title: "In Office",
      dataIndex: "is_current",
      key: "is_current",
      render: (is_current) =>
        is_current && (
          <Tag icon={<CheckOutlined />} color="orange">
            In Office
          </Tag>
        ),
      filters: [
        {
          text: "In Office",
          value: true,
        },
        {
          text: "Not In Office",
          value: false,
        },
      ],
      filterMultiple: false,
      sorter: (a, b) => a?.is_current - b?.is_current,
      onFilter: (value, record) => {
        return record.is_current === value;
      },
    },
    {
      title: "Legislator",
      dataIndex: "legislator",
      key: "legislator",
      sorter: (a, b) => a?.legislator?.localeCompare(b?.legislator),
      ...getColumnSearchProps("legislator", "legislator"),
    },
    {
      title: "Legislator Chamber",
      dataIndex: "legislator_chamber",
      key: "legislator_chamber",
      filters: [
        {
          text: "senate",
          value: "senate",
        },
        {
          text: "state senate",
          value: "state senate",
        },
        {
          text: "house",
          value: "house",
        },
        {
          text: "state house",
          value: "state house",
        },
        {
          text: "NA",
          value: null,
        },
      ],
      onFilter: (value, record) => {
        return record.legislator_chamber === value;
      },
      render: (legislator_chamber) => returnValueOrNA(legislator_chamber),
      sorter: (a, b) =>
        a?.legislator_chamber?.localeCompare(b?.legislator_chamber),
    },
    {
      title: "Legislator State",
      dataIndex: "legislator_state",
      key: "legislator_state",
      render: (legislator_state) => legislator_state,
      sorter: (a, b) => a?.legislator_state?.localeCompare(b?.legislator_state),
    },
    {
      title: "Legislator District",
      dataIndex: "legislator_district",
      key: "legislator_district",
      render: (legislator_district) => returnValueOrNA(legislator_district),
    },

    {
      title: "Staffer First Name",
      dataIndex: "staffer_first_name",
      key: "staffer_first_name",
      sorter: (a, b) =>
        a.staffer_first_name.localeCompare(b.staffer_first_name),
      ...getColumnSearchProps("staffer_first_name", "staffer_first_name"),
    },
    {
      title: "Staffer Middle Initial",
      dataIndex: "staffer_middle_initial",
      key: "staffer_middle_initial",
      ...getColumnSearchProps(
        "staffer_middle_initial",
        "staffer_middle_initial"
      ),
    },
    {
      title: "Staffer Last Name",
      dataIndex: "staffer_last_name",
      key: "staffer_last_name",
      sorter: (a, b) => a.staffer_last_name.localeCompare(b.staffer_last_name),
      ...getColumnSearchProps("staffer_last_name", "staffer_last_name"),
    },
    {
      title: "Staffer DOB",
      dataIndex: "staffer_dob",
      key: "staffer_dob",
    },
    {
      title: "Staffer Title",
      dataIndex: "staffer_title",
      key: "staffer_title",
      sorter: (a, b) => sortColumnWithNulls(a.staffer_title, b.staffer_title),
      ...getColumnSearchProps("staffer_title", "staffer_title"),
    },
    {
      title: "Staffer Title Rank",
      dataIndex: "title_rank",
      key: "title_rank",
      sorter: (a, b) =>
        a.title_rank.toString().localeCompare(b.title_rank.toString()),
      ...getColumnSearchProps("title_rank", "staffer_title_rank"),
    },
    {
      title: "Staffer Start Year",
      dataIndex: "staffer_start_year",
      key: "staffer_start_year",
      render: (staffer_start_year) => returnValueOrNA(staffer_start_year),
      sorter: (a, b) =>
        sortColumnWithNulls(a.staffer_start_year, b.staffer_start_year),
    },
    {
      title: "Staffer End Year",
      dataIndex: "staffer_end_year",
      key: "staffer_end_year",
      render: (staffer_end_year) => returnValueOrNA(staffer_end_year),
      sorter: (a, b) =>
        sortColumnWithNulls(a.staffer_end_year, b.staffer_end_year),
    },
    {
      title: "Staffer Emails",
      dataIndex: "staffer_emails",
      key: "staffer_emails",
      render: (emails) =>
        emails?.length > 0 ? (
          emails.length === 1 ? (
            emails[0]
          ) : (
            <ul style={{ margin: 0, padding: 0 }}>
              {emails.map((e) => (
                <li
                  style={{ listStyleType: "none" }}
                  key={`email-${emails.indexOf(e)}`}
                >
                  {e}
                </li>
              ))}
            </ul>
          )
        ) : (
          "NA"
        ),
      sorter: (a, b) => (a.staffer_emails !== "NA" - b.staffer_emails) !== "NA",
    },
    {
      title: "Staffer Phone Numbers",
      dataIndex: "staffer_phone_numbers",
      key: "staffer_phone_numbers",
      render: (phone_numbers) =>
        phone_numbers?.length > 0 ? (
          phone_numbers?.length === 1 ? (
            phone_numbers[0]
          ) : (
            <ol style={{ margin: 0, padding: 0 }}>
              {phone_numbers.map((e) => (
                <li
                  style={{ listStyleType: "none" }}
                  key={`phone-${phone_numbers.indexOf(e)}`}
                >
                  {e}
                </li>
              ))}
            </ol>
          )
        ) : (
          "NA"
        ),
    },
  ];
  return (
    <>
      <Table
        pagination={{
          defaultPageSize: 50,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "30", "50", "100"],
        }}
        virtual
        scroll={{
          x: 2500,
          y: "60vh",
        }}
        loading={staffers.length < 1}
        columns={columns}
        expandable={{
          expandedRowRender: (record) => (
            <div
              style={{
                marginleft: 50,
                display: "flex",
                justifyContent: "left",
              }}
            >
              <PIIprofile
                p_id={record.p_id}
                setToggleDrawer={setToggleDrawer}
                currentStaffer={record}
                setCurrentStaffer={setCurrentStaffer}
                staffers={staffers}
                setStaffers={setStaffers}
                setRerender={setRerender}
                updateMostRecentAttempt={updateMostRecentAttempt}
              />
            </div>
          ),
          rowExpandable: (record) => record.p_id !== null,
        }}
        dataSource={staffers}
      />
      <PIISearchDrawer
        toggleDrawer={toggleDrawer}
        setToggleDrawer={setToggleDrawer}
        currentStaffer={currentStaffer}
        setCurrentStaffer={setCurrentStaffer}
        setStaffers={setStaffers}
        staffers={staffers}
        setRerender={setRerender}
      />
    </>
  );
};

export default StaffersGrid;
