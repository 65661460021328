import { CaretRightOutlined } from "@ant-design/icons";
import {
  AutoComplete,
  Button,
  Col,
  Collapse,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { getPoliticalParties, updateDecisionMaker } from "../../APIClient";
import { states, validDays, validYears } from "../../constants";
import { actionCreators } from "../../store";
import {
  checkFormChanged,
  makeAutocompleteOptions,
  showSuccessMessage,
} from "../../utils";
import { Address } from "./Address";
import { DecisionMakerDM } from "./DecisionMakerDCM";
import { DecisionMakerGeo } from "./DecisionMakerGeo";
import { Email } from "./Email";
import { HeaderWithInfoTip } from "./HeaderWithInfoTip";
import { PhoneNumber } from "./PhoneNumber";
import { Experiences } from "./Experiences";
import { EducationComponent } from "./Education";
import { UploadedDonationsData } from "./UploadDonations";
import { DonationsData } from "./Donations";
import HighValueConnections from "./HighValueConnections";
import { LegislatorsAndCandidatesData } from "./LegislatorsAndCandidates";
import DecisionMakerTaxonomy from "./DecisionMakerList/DecisionMakerTaxonomy";
import "./DecisionMaker.css";
import { UploadedDonationsDataV2 } from "./UploadDonationsV2";
import { DecisionMakerContext } from "./DecisionMakerContext";

export const DecisionMakerForm = () => {
  return (
    <Space direction="vertical" style={{ width: "100%", height: "100%" }}>
      <DecisionMakerDM />
      <Collapse
        accordion
        bordered={false}
        style={{ width: "100%", fontSize: "1.2em" }}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        <Collapse.Panel
          key={1}
          header={
            <HeaderWithInfoTip
              title="Personal Information"
              message="This data will be used in exports"
            />
          }
        >
          <PersonalInformationForm />
        </Collapse.Panel>
        <Collapse.Panel
          key={2}
          header={
            <HeaderWithInfoTip
              title="Address Information"
              message="This data will be used in exports"
            />
          }
        >
          <Address />
        </Collapse.Panel>
        <Collapse.Panel
          key={3}
          header={
            <HeaderWithInfoTip
              title="Email"
              message="This data will be used in exports"
            />
          }
        >
          <Email />
        </Collapse.Panel>
        <Collapse.Panel
          key={4}
          header={
            <HeaderWithInfoTip
              title="Phone Number"
              message="This data will be used in exports"
            />
          }
        >
          <PhoneNumber />
        </Collapse.Panel>
        <Collapse.Panel
          key={5}
          header={
            <HeaderWithInfoTip
              title="Experiences"
              message="This data will be used in exports"
            />
          }
        >
          <Experiences />
        </Collapse.Panel>
        <Collapse.Panel
          key={6}
          header={
            <HeaderWithInfoTip
              title="Education"
              message="This data will be used in exports"
            />
          }
        >
          <EducationComponent />
        </Collapse.Panel>

        <Collapse.Panel
          key={7}
          header={
            <HeaderWithInfoTip
              title="Linked Candidate Donors"
              message="This data will be used in exports"
            />
          }
        >
          <DonationsData />
        </Collapse.Panel>

        <Collapse.Panel
          key={8}
          header={
            <HeaderWithInfoTip
              title=" Upload Donors"
              message="This data will be used in exports"
            />
          }
        >
          <UploadedDonationsData />
        </Collapse.Panel>

        <Collapse.Panel
          key={9}
          header={
            <HeaderWithInfoTip
              title=" Upload Donors V2"
              message="This data will be used in exports"
            />
          }
        >
          <UploadedDonationsDataV2 />
        </Collapse.Panel>

        <Collapse.Panel
          key={10}
          header={
            <HeaderWithInfoTip
              title="High Value Connections"
              message="this data will be used in exports"
            />
          }
        >
          <HighValueConnections />
        </Collapse.Panel>
        <Collapse.Panel
          key={11}
          header={
            <HeaderWithInfoTip
              title="Linked Legislator and Campaign Staffer"
              message="This data will be used in exports"
            />
          }
        >
          <LegislatorsAndCandidatesData />
        </Collapse.Panel>
      </Collapse>
    </Space>
  );
};

const PersonalInformationForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [firstName, setFirstName] = useState();
  const [nickname, setNickname] = useState();
  const [lastName, setLastName] = useState();
  const [birthYear, setBirthYear] = useState();
  const [birthDay, setBirthDay] = useState();
  const [birthMonth, setBirthMonth] = useState();
  const [type, setType] = useState();
  const [electedOfficeStatus, setElectedOfficeStatus] = useState();
  const [party, setParty] = useState();
  const [representedCity, setRepresentedCity] = useState();
  const [representedState, setRepresentedState] = useState();
  const [congressionalDistrict, setCongressionalDistrict] = useState();
  const [representedStateUpperDistrict, setRepresentedStateUpperDistrict] =
    useState();
  const [representedStateLowerDistrict, setRepresentedStateLowerDistrict] =
    useState();
  const [representedCounty, setRepresentedCounty] = useState();
  const [politicalPartyOptions, setPoliticalPartyOptions] = useState([]);
  const [isPolitical, setIsPolitical] = useState();

  // redux state
  const decisionMaker = useSelector((state) => state.decisionMaker);
  const dispatch = useDispatch();
  const { setDecisionMakerAction } = bindActionCreators(
    actionCreators,
    dispatch
  );

  const { setUpdateDecisionMakerQAStatus } = useContext(DecisionMakerContext);

  const populateDecisionMakerGeo = () => {
    return decisionMaker?.congressional_district !== null
      ? "congressional district"
      : decisionMaker?.represented_state_upper_district !== null
      ? "state upper district"
      : decisionMaker?.represented_state_lower_district !== null
      ? "state lower district"
      : decisionMaker?.represented_county !== null
      ? "county"
      : "None";
  };

  const geoDisable = () =>
    decisionMakerGeo !== "None" &&
    [
      congressionalDistrict,
      representedStateUpperDistrict,
      representedStateLowerDistrict,
      representedCounty,
    ].every((val) => val < 1 || typeof representedState === "undefined");

  const [decisionMakerGeo, setDecisionMakerGeo] = useState(
    populateDecisionMakerGeo()
  );

  const fetchPoliticalPartyOptions = async () => {
    try {
      const resp = await getPoliticalParties();
      setPoliticalPartyOptions(
        resp.map(([value, label]) => ({ value, label }))
      );
    } catch (error) {
      alert(error);
    }
  };
  const resetForm = () => {
    setFirstName(decisionMaker?.first_name);
    setNickname(decisionMaker?.nickname);
    setLastName(decisionMaker?.last_name);
    setBirthYear(decisionMaker?.dob_year);
    setBirthDay(decisionMaker?.dob_day);
    setBirthMonth(decisionMaker?.dob_month);
    setType(decisionMaker?.decision_maker_type);
    setParty(decisionMaker?.political_party);
    setRepresentedCity(decisionMaker?.represented_city);
    setRepresentedState(decisionMaker?.represented_state);
    setCongressionalDistrict(decisionMaker?.congressional_district);
    setDecisionMakerGeo(populateDecisionMakerGeo());
    setRepresentedStateUpperDistrict(
      decisionMaker?.represented_state_upper_district
    );
    setRepresentedStateLowerDistrict(
      decisionMaker?.represented_state_lower_district
    );
    setRepresentedCounty(decisionMaker?.represented_county);
    setElectedOfficeStatus(decisionMaker?.elected_office_status);
  };

  const onClick = async () => {
    setIsLoading(true);
    try {
      // explicitly pass null or key will be dropped in request
      const resp = await updateDecisionMaker(decisionMaker.id, {
        first_name: firstName || null,
        nickname: nickname || null,
        last_name: lastName || null,
        dob_year: birthYear || null,
        dob_day: birthDay || null,
        dob_month: birthMonth || null,
        decision_maker_type: type || null,
        political_party: party || null,
        represented_city: representedCity || null,
        represented_state: representedState || null,
        congressional_district: congressionalDistrict || null,
        represented_state_upper_district: representedStateUpperDistrict || null,
        represented_state_lower_district: representedStateLowerDistrict || null,
        represented_county: representedCounty || null,
        elected_office_status: electedOfficeStatus,
      });
      setDecisionMakerAction(resp);
      setIsEditing(false);
      setUpdateDecisionMakerQAStatus(true);
      showSuccessMessage("Successfully updated decision maker.", 8);
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decisionMaker]);

  useEffect(() => {
    if (politicalPartyOptions.length === 0) {
      fetchPoliticalPartyOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Spin spinning={isLoading}>
      <Form layout="vertical">
        <Row gutter={5}>
          <Col span={12}>
            <DecisionMakerTaxonomy
              isEditing={isEditing}
              type={type}
              setType={setType}
              section="personal_info"
              setIsPolitical={setIsPolitical}
              electedOfficeStatus={electedOfficeStatus}
              setElectedOfficeStatus={setElectedOfficeStatus}
            />
          </Col>
        </Row>
        <Form.Item label="First name">
          <AutoComplete
            style={{ maxWidth: 350 }}
            allowClear
            disabled={!isEditing}
            onChange={(val) => setFirstName(val)}
            value={firstName}
            maxLength={100}
          />
        </Form.Item>
        <Form.Item label="Nickname">
          <AutoComplete
            style={{ maxWidth: 350 }}
            allowClear
            disabled={!isEditing}
            onChange={(val) => setNickname(val)}
            value={nickname}
            maxLength={100}
          />
        </Form.Item>
        <Form.Item label="Last name">
          <AutoComplete
            style={{ maxWidth: 350 }}
            allowClear
            disabled={!isEditing}
            onChange={(val) => setLastName(val)}
            value={lastName}
            maxLength={100}
          />
        </Form.Item>
        <Row>
          <Col span={16}>
            <Form.Item label="Date of Birth">
              <Select
                showSearch
                allowClear
                disabled={!isEditing}
                placeholder="Day"
                style={{ width: 75, marginRight: 10 }}
                onChange={(e) => setBirthDay(e)}
                value={birthDay}
              >
                {validDays.map((day) => (
                  <Select.Option value={day} key={day}>
                    {day}
                  </Select.Option>
                ))}
              </Select>
              <Select
                showSearch
                allowClear
                disabled={!isEditing}
                placeholder="Month"
                style={{ width: 150, marginRight: 10 }}
                onChange={(e) => setBirthMonth(e)}
                value={birthMonth}
                options={[
                  { label: "January", value: 1 },
                  { label: "February", value: 2 },
                  { label: "March", value: 3 },
                  { label: "April", value: 4 },
                  { label: "May", value: 5 },
                  { label: "June", value: 6 },
                  { label: "July", value: 7 },
                  { label: "August", value: 8 },
                  { label: "September", value: 9 },
                  { label: "October", value: 10 },
                  { label: "November", value: 11 },
                  { label: "December", value: 12 },
                ]}
              ></Select>
              <Select
                showSearch
                allowClear
                disabled={!isEditing}
                placeholder="Year"
                style={{ width: 85 }}
                onChange={(e) => setBirthYear(e)}
                value={birthYear}
              >
                {validYears.map((year) => (
                  <Select.Option value={year} key={year}>
                    {year}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Form.Item label="Represented city">
            <Input
              disabled={!isEditing || !isPolitical}
              value={representedCity}
              onChange={(e) => setRepresentedCity(e.target.value)}
            />
          </Form.Item>
        </Row>
        <Row>
          <Col span={8}>
            <Form.Item label="Represented state">
              <Select
                showSearch
                allowClear
                disabled={!isEditing || !isPolitical}
                style={{ width: 120 }}
                onChange={(e) => {
                  setRepresentedState(e);
                  setCongressionalDistrict(null);
                  setRepresentedStateUpperDistrict(null);
                  setRepresentedStateLowerDistrict(null);
                  setRepresentedCounty(null);
                }}
                value={representedState}
                options={makeAutocompleteOptions(states)}
              />
            </Form.Item>
            <DecisionMakerGeo
              isPolitical={isPolitical}
              isEditing={isEditing}
              representedState={representedState}
              decisionMakerGeo={decisionMakerGeo}
              setDecisionMakerGeo={setDecisionMakerGeo}
              representedStateUpperDistrict={representedStateUpperDistrict}
              setRepresentedStateUpperDistrict={
                setRepresentedStateUpperDistrict
              }
              representedStateLowerDistrict={representedStateLowerDistrict}
              setRepresentedStateLowerDistrict={
                setRepresentedStateLowerDistrict
              }
              representedCounty={representedCounty}
              setRepresentedCounty={setRepresentedCounty}
              congressionalDistrict={congressionalDistrict}
              setCongressionalDistrict={setCongressionalDistrict}
            />
          </Col>
        </Row>
        <Row>
          <Form.Item label="Party">
            <Radio.Group
              disabled={!isEditing || !isPolitical}
              options={politicalPartyOptions}
              onChange={(e) => setParty(e.target.value)}
              value={party}
              optionType="button"
              buttonStyle="solid"
            />
          </Form.Item>
        </Row>
        <Row justify="end">
          {isEditing ? (
            <Space size={5}>
              <Button
                onClick={() => {
                  setIsEditing(false);
                  resetForm();
                }}
                size="middle"
                style={{ width: "75px" }}
              >
                Cancel
              </Button>
              <Button
                onClick={onClick}
                type="primary"
                size="middle"
                style={{ width: "65px" }}
                disabled={
                  geoDisable() ||
                  !checkFormChanged(decisionMaker, {
                    first_name: firstName,
                    nickname: nickname,
                    last_name: lastName,
                    dob_year: birthYear,
                    dob_day: birthDay,
                    dob_month: birthMonth,
                    decision_maker_type: type,
                    political_party: party,
                    represented_city: representedCity,
                    represented_state: representedState,
                    congressional_district: congressionalDistrict,
                    represented_state_upper_district:
                      representedStateUpperDistrict,
                    represented_state_lower_district:
                      representedStateLowerDistrict,
                    represented_county: representedCounty,
                    elected_office_status: electedOfficeStatus,
                  })
                }
              >
                Save
              </Button>
            </Space>
          ) : (
            <Button
              onClick={() => setIsEditing(true)}
              size="middle"
              style={{ width: "140px" }}
            >
              Edit
            </Button>
          )}
        </Row>
      </Form>
    </Spin>
  );
};
