import React from "react";
import { Collapse } from "antd";
import { useSelector } from "react-redux";

const ReviewDrawer = ({ tpls, uniqueGroupNames }) => {
  const { Panel } = Collapse;
  const fullBreakdownResp = useSelector((state) => state.breakdown);
  const segmentBreakdown = fullBreakdownResp?.segment_breakdown || {};

  const onChange = (key) => {
    console.log(key);
  };
  return (
    <div style={{ margin: "2rem 0 3rem" }}>
      <Collapse defaultActiveKey={["1"]} onChange={onChange}>
        {uniqueGroupNames.map((group) => {
          const segmentTarget = segmentBreakdown[group]?.tpl_list || [];
          const groupTargets = tpls
            .filter((tpl) => segmentTarget.includes(tpl.id))
            .sort(function (a, b) {
              return a.name.localeCompare(b.name);
            });

          const segment_counts = segmentBreakdown[group]?.segment_count || 0;

          return (
            <Panel
              header={`${group} (${groupTargets.length}) (${segment_counts})`}
              key={`${uniqueGroupNames.indexOf(group)}`}
            >
              {groupTargets.map((tpl) => (
                <li key={tpls.indexOf(tpl)}>{tpl.name}</li>
              ))}
            </Panel>
          );
        })}
      </Collapse>
    </div>
  );
};

export default ReviewDrawer;
