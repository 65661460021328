import React from "react";
import { Card, Row, Col } from "antd";
import {
  CalendarOutlined,
  InfoCircleOutlined,
  CompassOutlined,
  InsertRowLeftOutlined,
} from "@ant-design/icons";

import "./DMSPersonCard.css";

const DMSPersonCard = ({
  index,
  person,
  linkToButtonCallback,
  header,
  footer,
}) => {
  const {
    first_name,
    last_name,
    dob_day,
    dob_month,
    dob_year,
    decision_maker_type,
    represented_state,
    most_recent_experience,
  } = person;

  let cart_style_class =
    index % 2 === 0 ? "person-card-even" : "person-card-odd";

  if (linkToButtonCallback) {
    cart_style_class = cart_style_class + " pointer-wise-card";
  }

  const capitalizeFirstLetter = (str) => {
    if (str !== undefined || str !== null) {
      let lowerCase = str.toLowerCase();
      return lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1);
    } else {
      return str;
    }
  };

  return (
    <Card
      title={`${first_name} ${last_name}`.toLocaleUpperCase()}
      bordered={false}
      style={{ width: "100%", fontSize: "14px", marginBottom: 10 }}
      className={cart_style_class}
      onClick={() => {
        linkToButtonCallback && linkToButtonCallback(person);
      }}
      extra={header && header()}
    >
      {dob_year && dob_year !== 0 && (
        <>
          <Row className="person-card-header">
            <Col
              style={{
                width: 30,
              }}
            >
              <CalendarOutlined />
            </Col>
            <Col>Date of Birth</Col>
          </Row>
          <Row className="person-card-content">
            <Col
              style={{
                width: 30,
              }}
            ></Col>
            <Col>
              {(dob_month ? dob_month + " / " : "00 / ") +
                (dob_day ? dob_day + " / " : "00 / ") +
                dob_year}
            </Col>
          </Row>
        </>
      )}
      {decision_maker_type && (
        <>
          <Row className="person-card-header">
            <Col
              style={{
                width: 30,
              }}
            >
              <InfoCircleOutlined />
            </Col>
            <Col>Decision Maker Type</Col>
          </Row>
          <Row className="person-card-content">
            <Col
              style={{
                width: 30,
              }}
            ></Col>
            <Col>
              {capitalizeFirstLetter(decision_maker_type.replaceAll("_", " "))}
            </Col>
          </Row>
        </>
      )}
      {represented_state && (
        <>
          <Row className="person-card-header">
            <Col
              style={{
                width: 30,
              }}
            >
              <CompassOutlined />
            </Col>
            <Col>Represented State</Col>
          </Row>
          <Row className="person-card-content">
            <Col
              style={{
                width: 30,
              }}
            ></Col>
            <Col>{represented_state}</Col>
          </Row>
        </>
      )}

      {most_recent_experience.company_name && (
        <>
          <Row className="person-card-header">
            <Col
              style={{
                width: 30,
              }}
            >
              <InsertRowLeftOutlined />
            </Col>
            <Col>Most Recent Experience</Col>
          </Row>
          <Row className="person-card-content">
            <Col
              style={{
                width: 30,
              }}
            ></Col>
            <Col>
              {capitalizeFirstLetter(most_recent_experience.company_name)}
            </Col>
          </Row>
          <Row className="person-card-content">
            <Col
              style={{
                width: 30,
              }}
            ></Col>
            <Col>
              {most_recent_experience.from_date
                ? most_recent_experience.from_date.replaceAll("-", " / ")
                : "None"}
              {" - "}
              {most_recent_experience.to_date
                ? most_recent_experience.to_date.replaceAll("-", " / ")
                : "None"}
            </Col>
          </Row>
        </>
      )}

      {footer && footer()}
    </Card>
  );
};

export default DMSPersonCard;
