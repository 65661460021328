import React from "react";
import ReactDOM from "react-dom";
import Provider from "./store/store";

import "./index.css";
import App from "./components/App/App";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";

const ignoreErrors = [
  "ResizeObserver loop limit exceeded",
  "ResizeObserver loop completed with undelivered notifications",
  "Authentication credentials were not provided.",
];

if (process.env.REACT_APP_IS_PRODUCTION === "True") {
  Sentry.init({
    dsn: "https://8b1c7fc6f4f0496aafb7e46bd391a6dd@o436969.ingest.sentry.io/5398866",
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 0.7,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    release: process.env.REACT_APP_CIRCLE_BUILD_NUM,
    sendDefaultPii: true,
    ignoreErrors,
  });
} else if (process.env.REACT_APP_IS_BETA === "True") {
  Sentry.init({
    dsn: "https://8512006c77e64f3c9b53efdbb9f6a762@o436969.ingest.sentry.io/4504260504059904",
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 0.7,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    release: process.env.REACT_APP_CIRCLE_BUILD_NUM,
    sendDefaultPii: true,
    ignoreErrors,
  });
}

ReactDOM.render(
  <Provider>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
