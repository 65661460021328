import { useState, useContext } from "react";
import { Select, Modal } from "antd";
import { CompanyGroupsContext } from "../CompanyGroupsPage";

const AddCompaniesToGroupModal = ({
  selectedCompanies,
  isVisible,
  setIsVisible,
  bulkAddCompanies,
}) => {
  const [canonicalCompany, setCanonicalCompany] = useState(null);
  const { selectedGroup } = useContext(CompanyGroupsContext);

  const addCanonicalToCompany = () => {
    try {
      let finalCompanies = selectedCompanies.map((company) =>
        company.company_id === canonicalCompany
          ? { ...company, canonical: true }
          : company
      );
      bulkAddCompanies(selectedGroup?.id, finalCompanies);
    } catch (error) {
      alert(error);
    }
  };

  return (
    <Modal
      visible={isVisible}
      title="Add Companies to Group"
      okText="Add Companies"
      onCancel={() => setIsVisible(false)}
      okButtonProps={{ disabled: !canonicalCompany }}
      onOk={addCanonicalToCompany}
    >
      <Select
        placeholder={"Choose Canonical Company"}
        options={selectedCompanies
          .sort((a, b) => b?.employee_count - a?.employee_count)
          .map((item) => ({
            value: item.company_id,
            label: `${item.company_name} (${item?.employee_count}) (${
              item?.city || "N/A"
            }, ${item?.state || "N/A"})`,
          }))}
        onChange={(e) => setCanonicalCompany(e)}
        style={{ margin: "20px 0", width: "80%" }}
      />
    </Modal>
  );
};

export default AddCompaniesToGroupModal;
