import { Button, Col, Form, Row, Typography, Spin } from "antd";
import React, { useState } from "react";
import { findPersonV2 } from "../../APIClient";
import AddressFormInputs from "./SearchForms/AddressForm";
import BirthDateInput from "./SearchForms/BirthDateInput";
import NameForm from "./SearchForms/NameForm";

const MPSSearchForm = ({
  dataCallback,
  searchForm,
  formResetCallback,
  setSearchTriggered,
  searchMethodItems,
  setSearchMethodItems,
}) => {
  const [btnDisabled, setBtndisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const formatNameParams = (names) => {
    return names?.map((name) => {
      const nameDicts = {};
      nameDicts["first_name"] = name?.first_name
        ? { [name.first_name_search_type]: name?.first_name?.trim() }
        : undefined;
      nameDicts["middle_name"] = name?.middle_name
        ? { [name.middle_name_search_type]: name?.middle_name?.trim() }
        : undefined;
      nameDicts["last_name"] = name?.last_name
        ? { [name.last_name_search_type]: name?.last_name?.trim() }
        : undefined;
      return nameDicts;
    });
  };

  const formatBirthdayParams = (birthdays) => {
    const validateSearchType = (birthday) => {
      if (
        birthday?.birthday_search_type === "between" &&
        birthday?.year_range
      ) {
        return {
          [birthday.birthday_search_type]: [
            birthday?.year_range[0].year(),
            birthday?.year_range[1].year(),
          ],
        };
      } else if (birthday?.year) {
        return { [birthday.birthday_search_type]: birthday?.year.year() };
      } else {
        return undefined;
      }
    };
    return birthdays?.map((birthday) => {
      const birthdayDicts = {};
      birthdayDicts["year"] = validateSearchType(birthday);
      birthdayDicts["month"] = birthday?.month
        ? birthday?.month?.month() + 1
        : undefined;
      birthdayDicts["day"] = birthday?.day ? birthday?.day : undefined;
      return birthdayDicts;
    });
  };

  const formatSearchParams = (searchParams) => {
    let cleaned_val = {};
    for (let entry of Object.entries(searchParams)) {
      if (
        typeof entry[1] !== undefined &&
        entry[1]?.length > 0 &&
        entry[0] !== "names"
      ) {
        cleaned_val[entry[0]] = entry[1].map((val) => {
          let tempObj = {};
          for (let entry of Object.entries(val)) {
            tempObj[entry[0]] =
              typeof entry[1] === "string"
                ? entry[1]?.toLowerCase()?.trim()
                : entry[1];
          }
          return tempObj;
        });
      }
    }
    return { ...searchParams, ...cleaned_val };
  };

  const onFinish = async (values) => {
    setSearchTriggered(true);
    setIsLoading(true);
    try {
      const searchParams = {
        ...values,
        names: formatNameParams(values.names),
        birthdays: formatBirthdayParams(values.birthdays),
      };
      const data = await findPersonV2(formatSearchParams(searchParams));
      dataCallback(data);
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const disableSearchBtn = (values) => {
    return (
      values
        ?.map((val) => Object.values(val || {}).flat())
        .flat()
        .filter((val) => val !== null && val !== undefined && val !== "")
        .length < 1
    );
  };

  const onChange = () => {
    const valid_names =
      formatNameParams(searchForm.getFieldValue("names")) || [];
    const valid_birthdays =
      formatBirthdayParams(searchForm.getFieldValue("birthdays")) || [];
    const valid_addresses = searchForm.getFieldValue("addresses") || [];
    setBtndisabled(
      disableSearchBtn([...valid_names, ...valid_birthdays, ...valid_addresses])
    );
  };

  const onReset = () => {
    formResetCallback();
    setBtndisabled(true);
    setSearchTriggered(false);
  };
  const textColor = "#434343";
  const { Title } = Typography;
  return (
    <>
      <Spin spinning={isLoading}>
        <Form
          form={searchForm}
          name="basic"
          onValuesChange={onChange}
          onFieldsChange={onChange}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Title style={{ color: textColor }} level={5}>
            Name
          </Title>
          <NameForm />
          <Title style={{ color: textColor }} level={5}>
            Birthdate
          </Title>
          <BirthDateInput
            searchMethodItems={searchMethodItems}
            setSearchMethodItems={setSearchMethodItems}
          />

          <Title style={{ color: textColor }} level={5}>
            Address
          </Title>
          <AddressFormInputs />
          <Row gutter={10}>
            <Col>
              <Form.Item>
                <Button htmlType="button" onClick={onReset}>
                  Reset
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button type="primary" htmlType="submit" disabled={btnDisabled}>
                  Search
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </>
  );
};
export default MPSSearchForm;
