import { Form, Input, Row, Select, Col } from "antd";
import React, { useEffect } from "react";
import { states } from "../../constants";
import { useForm } from "antd/lib/form/Form";
import { checkFormChanged } from "../../utils";

export const AddressForm = ({
  addressValues = {},
  disabled = false,
  setIsFormValid,
  address,
  setAddress,
  city,
  setCity,
  state,
  setState,
  zipcode,
  setZipcode,
  addr2,
  setAddr2,
}) => {
  const [form] = useForm();

  const validate = async () => {
    try {
      await form.validateFields().then((values) => {
        setIsFormValid(checkFormChanged(addressValues, values));
      });
    } catch (error) {
      setIsFormValid(false);
    }
  };
  // form validation
  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipcode, address, addr2, city, state]);

  return (
    <Form
      form={form}
      fields={[
        { name: ["zipcode"], value: zipcode },
        { name: ["street_address"], value: address },
        { name: ["addr2"], value: addr2 },
        { name: ["city"], value: city },
        { name: ["state"], value: state },
      ]}
    >
      <Row>
        <Form.Item name="street_address">
          <Input
            disabled={disabled}
            placeholder="Address 1"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </Form.Item>
      </Row>
      <Row>
        <Form.Item name="addr2">
          <Input
            disabled={disabled}
            placeholder="Address 2"
            value={addr2}
            onChange={(e) => setAddr2(e.target.value)}
          />
        </Form.Item>
      </Row>
      <Row gutter={10}>
        <Col>
          <Form.Item name="city">
            <Input
              disabled={disabled}
              placeholder="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name="state">
            <Select
              showSearch
              allowClear
              disabled={disabled}
              style={{ width: "100px" }}
              placeholder="State"
              value={state}
              onChange={(val) => setState(val)}
            >
              {states.map((state) => (
                <Select.Option key={state} value={state.toLocaleLowerCase()}>
                  {state}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Form.Item
          rules={[
            {
              required: false,
              pattern: new RegExp(/^[0-9]{5}$/),
              message: "Zip code must be a 5 digit number",
            },
          ]}
          name="zipcode"
        >
          <Input
            disabled={disabled}
            placeholder="Zipcode"
            maxLength={5}
            value={zipcode}
            onChange={(e) => setZipcode(e.target.value)}
          />
        </Form.Item>
      </Row>
    </Form>
  );
};
