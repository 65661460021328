import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { createAssociationsAudienceMembers } from "../../../APIClient";
import {
  showDangerMessage,
  showSuccessMessage,
  showWarningMessage,
} from "../../../utils";
import { EASSegmentDrawer } from "./EASSegmentDrawer";

export const AssociationsDrawer = ({
  isVisible,
  setIsVisible,
  refreshPage,
}) => {
  const { projectId } = useParams();
  const target = useSelector((state) => state.target);

  const createAudienceMembers = async (
    desiredNumber,
    selectedStates,
    isDCmetro
  ) => {
    try {
      const {
        audience_length: associationsFound,
        matched_count: associationsMatched,
      } = await createAssociationsAudienceMembers(
        target?.dm_id,
        desiredNumber,
        projectId,
        target.id,
        selectedStates,
        isDCmetro
      );
      if (associationsMatched === 0) {
        showDangerMessage(
          `Total of ${associationsFound} associations were found but we were unable to match any`
        );
      } else if (desiredNumber > associationsMatched) {
        showWarningMessage(
          `Total of ${associationsMatched} were found/matched and ${associationsFound} associations were added.`
        );
      } else {
        showSuccessMessage(
          `Total of ${associationsMatched} were found/matched and ${associationsFound} associations were added.`
        );
      }
    } catch (error) {
      alert(error);
    }
  };

  return (
    <EASSegmentDrawer
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      refreshPage={refreshPage}
      createAudienceMembers={createAudienceMembers}
      message={"Create Associations"}
    />
  );
};
