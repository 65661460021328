import React from "react";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";

import { Button, Table, Drawer, InputNumber, Spin } from "antd";
import { createSecondDegreeSegment } from "../../../APIClient";
import { showDangerMessage, showSuccessMessage } from "../../../utils";
import { useParams } from "react-router-dom";

export const SecondDegreeDrawer = ({
  isVisible,
  setIsVisible,
  refreshPage,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { projectId } = useParams();

  const target = useSelector((state) => state.target);

  const [selectedFirstDegreeSegments, setSelectedFirstDegreeSegments] =
    useState([]);

  const selectedTargetSegments = useSelector(
    (state) => state.selectedTargetSegments
  );

  const [desiredNumber, setDesiredNumber] = useState(100);

  const defaultSegmentSize = () => {
    const segmentSize = {};
    selectedTargetSegments.forEach((segments) => {
      segmentSize[segments["type_name"]] = 100;
    });

    return segmentSize;
  };

  const [selectedTargetSegmentsSize, setSelectedTargetSegmentsSize] = useState(
    defaultSegmentSize()
  );

  function DrawerDefaultMessage() {
    if (selectedTargetSegments.length === 0) {
      return <p>None of the first degree segments were added</p>;
    } else {
      return (
        <p>
          Select segments that should receive 2nd degree audience members and
          set the maximum number of audience members to create.
        </p>
      );
    }
  }

  const createAudienceMembers = async () => {
    setIsLoading(true);
    const secondaryDegreeRequest = [];
    selectedFirstDegreeSegments.forEach((segments) => {
      const request = {};
      request.segment_type = segments["type_name"];
      request.desired_size =
        selectedTargetSegmentsSize[segments["type_name"]] || 100;
      secondaryDegreeRequest.push(request);
    });
    try {
      const {
        audience_length: numberOfAudience,
        matched_count: numberOfAudienceMatched,
      } = await createSecondDegreeSegment(
        projectId,
        target.id,
        secondaryDegreeRequest
      );
      if (numberOfAudienceMatched === 0) {
        showDangerMessage(
          `Number of second degree found ${numberOfAudienceMatched}`
        );
      } else {
        showSuccessMessage(
          `Total of ${numberOfAudience} second degree were added.`
        );
      }
    } catch (error) {
      alert(error);
    }
    refreshPage();
    setIsLoading(false);
    setIsVisible(false);
  };

  useEffect(() => {
    if (projectId) {
      setDesiredNumber(100);
      setSelectedTargetSegmentsSize(defaultSegmentSize());
      setSelectedFirstDegreeSegments([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const setDesiredSize = (record, size) => {
    setSelectedTargetSegmentsSize({
      ...selectedTargetSegmentsSize,
      [record.type_name]: size,
    });
  };
  const ExistingSecondDegreeSegmentColumns = [
    {
      title: "Segment Type",
      dataIndex: "type_name",
      key: "type_name",
    },
    {
      title: "Desired Size",
      width: 150,
      key: "desired_size",
      render: (text, record) => {
        return (
          <InputNumber
            size="large"
            min={1}
            max={10000000}
            defaultValue={
              selectedTargetSegmentsSize[record?.type_name] || desiredNumber
            }
            onChange={(val) => {
              setDesiredSize(record, val);
            }}
          />
        );
      },
    },
  ];

  const rowSelection = {
    selections: selectedFirstDegreeSegments,
    selectedRowKeys: selectedFirstDegreeSegments.map(
      (segments) => segments["id"]
    ),
    onChange: (key, vals) => setSelectedFirstDegreeSegments(vals),
    type: "checkbox",
  };
  return (
    <Drawer
      title={`Create 2nd Degree`}
      onClose={() => setIsVisible(false)}
      visible={isVisible}
      width={800}
      bodyStyle={{ paddingBottom: 20 }}
    >
      <DrawerDefaultMessage />

      {selectedTargetSegments && selectedTargetSegments.length > 0 && (
        <Table
          style={{ paddingBottom: "15px" }}
          dataSource={selectedTargetSegments?.sort((a, b) =>
            a?.type_name.localeCompare(b?.type_name)
          )}
          columns={ExistingSecondDegreeSegmentColumns}
          pagination={false}
          rowSelection={rowSelection}
          rowKey="id"
        />
      )}
      <Spin spinning={isLoading}>
        <Button
          disabled={selectedFirstDegreeSegments?.length <= 0}
          onClick={() => createAudienceMembers()}
        >
          Create Second Degree
        </Button>
      </Spin>
    </Drawer>
  );
};
