export const segmentTypes = [
  "Friends and family",
  "Close connections",
  "Close associates",
  "Classmates",
  "Coworkers",
  "Elected officials",
  "Donor",
  "Staffers",
  "Party leaders",
  "Association members",
  "Media members",
  "Business leaders",
  "Chambers of commerce",
];
export const connectionTypes = [
  "Spouse",
  "Other family",
  "Staff",
  "Close association member",
  "Close coworker",
  "Top party leader",
  "Top media member",
  "Friend",
];

export const staffTitles = [
  "Chief of Staff",
  "Communications Director",
  "Deputy Chief of Staff",
  "District Director",
  "Legislative Director",
  "Press Secretary",
  "Senior Adviser",
  "State Director",
  "Mid-Level Staff",
];
