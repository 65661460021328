export const ALL_GROUPS = {
  EVERYONE: "Everyone",
  CLIENT_SERVICES: "client-services",
  AUDIENCE_CONSTRUCTION: "audience-construction",
  BIZOPS: "bizops",
  OKTA_ADMIN: "Okta Administrators",
  PRODUCT: "product",
  TECH: "tech",
  TARGETING_ASSOCIATES: "targeting-associates",
  BUSINESS_DEVELOPMENT_LEADS: "bd-leads",
};

export const account_permissions = {
  [ALL_GROUPS.PRODUCT]: {
    key: "Product",
    account_summary: true,
    active_projects: true,
    destinations: true,
    campaign: true,
    projects: true,
    decision_makers: true,
    enrichment: true,
    in_ex_lists: true,
    packages: true,
    unit_tracking: true,
  },
  [ALL_GROUPS.TECH]: {
    key: "Engineering",
    account_summary: true,
    active_projects: true,
    destinations: true,
    campaign: true,
    projects: true,
    decision_makers: true,
    enrichment: true,
    in_ex_lists: true,
    packages: true,
    unit_tracking: true,
  },
  [ALL_GROUPS.CLIENT_SERVICES]: {
    key: "Client Services",
    account_summary: true,
    active_projects: true,
    destinations: true,
    campaign: true,
    projects: false,
    decision_makers: false,
    enrichment: false,
    in_ex_lists: false,
    packages: false,
    unit_tracking: false,
  },

  [ALL_GROUPS.BIZOPS]: {
    key: "Business Operations",
    account_summary: true,
    active_projects: true,
    destinations: false,
    campaign: true,
    projects: false,
    decision_makers: false,
    enrichment: false,
    in_ex_lists: false,
    packages: false,
    unit_tracking: true,
  },
  [ALL_GROUPS.AUDIENCE_CONSTRUCTION]: {
    key: "Audience Constructions",
    account_summary: false,
    active_projects: false,
    destinations: false,
    campaign: false,
    projects: true,
    decision_makers: true,
    enrichment: true,
    in_ex_lists: true,
    packages: true,
    unit_tracking: false,
  },
  [ALL_GROUPS.TARGETING_ASSOCIATES]: {
    key: "Target Associates",
    account_summary: true,
    active_projects: false,
    destinations: false,
    campaign: true,
    projects: true,
    decision_makers: true,
    enrichment: true,
    in_ex_lists: true,
    packages: true,
    unit_tracking: true,
  },
  [ALL_GROUPS.BUSINESS_DEVELOPMENT_LEADS]: {
    key: "Business Development Leads",
    account_summary: true,
    active_projects: false,
    destinations: false,
    campaign: true,
    projects: false,
    decision_makers: false,
    enrichment: false,
    in_ex_lists: false,
    packages: false,
    unit_tracking: false,
  },
};

export const isProdTechUser = (userGroups) => {
  return isUserInAllowedGroups(userGroups, [
    ALL_GROUPS.PRODUCT,
    ALL_GROUPS.TECH,
  ]);
};

const intersection = (arr1, arr2) => {
  return arr1.filter((value) => arr2.includes(value));
};

export const isUserInAllowedGroups = (userGroups, allowedGroups) => {
  return (
    intersection(
      userGroups.map((u) => u.name),
      allowedGroups
    ).length > 0
  );
};

export const isUserAllowedToReachGivenPage = (userGroups, page) => {
  const teams = Object.keys(account_permissions);
  const user_groups = userGroups.map((item) => item.name);
  let all_permissions_for_all_user_groups = intersection(
    teams,
    user_groups
  ).map((item) => {
    return account_permissions[item][page];
  });

  return intersection(all_permissions_for_all_user_groups, [true]).length > 0;
};
