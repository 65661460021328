import React, { useEffect, useState } from "react";
import { Row, Col, Table, Button, Select } from "antd";
import { useHistory } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import { useDispatch, useSelector } from "react-redux";

import { getColumnSearchProps } from "../../util/columnSearchPropFactory";
import ProjectDetailsDrawer from "./ProjectDetailsDrawer";
import { getAccountProjects } from "../../APIClient";
import { ProjectDMsDrawer } from "./ProjectDMsDrawer";
import { getAccountReport } from "../../APIClient";
import { useParams } from "react-router-dom";
import { isTargetActive } from "./utils";
import { uniq } from "lodash";
import { ProjectFileNamesDrawer } from "./ProjectFileNamesDrawer";

const ListAccountProjects = () => {
  const { accountId: accountIdFromUrlParams } = useParams();
  const history = useHistory();

  const selectedHubspotCompanyId = useSelector(
    (state) => state.selectedHubspotCompanyId
  );

  const [accountProjects, setAccountProjects] = useState([]);
  const [isDMsDrawerVisible, setIsDMsDrawerVisible] = useState(false);
  const [isFileNamesDrawerVisible, setIsFileNamesDrawerVisible] =
    useState(false);
  const [selectedProjectForDMDrawer, setSelectedProjectForDrawer] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const fetchAccountProjects = async () => {
    setIsLoading(true);
    try {
      const resp = await getAccountProjects(selectedHubspotCompanyId);
      setAccountProjects(resp);
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!!selectedHubspotCompanyId) {
      fetchAccountProjects();
      history.push(`/account-projects/${selectedHubspotCompanyId}`);
    } else if (!!accountIdFromUrlParams) {
      dispatch({
        type: "set-selected-hubspot-company-id",
        payload: accountIdFromUrlParams,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHubspotCompanyId]);

  const allUniqueDecisionMakerNames = uniq(
    accountProjects.flatMap((p) =>
      p.decision_makers.map((dm) => dm?.dm_profile?.full_name)
    )
  )?.filter((name) => !!name);

  const allUniqueFileNames = uniq(accountProjects.flatMap((p) => p.file_names));

  const activeDmsInProject = (p) =>
    p?.decision_makers?.filter((dm) => isTargetActive(dm))?.length || 0;

  const columns = [
    {
      title: "Client Campaign Id",
      dataIndex: "hubspot_client_project_id",
    },
    {
      title: "Campaign Name",
      dataIndex: "campaign_name",
      ...getColumnSearchProps("campaign_name", "Campaign Name"),
      sorter: (a, b) => a.name?.localeCompare(b.name),
    },
    {
      title: "ABBA Project Id",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "ABBA Project Name",
      dataIndex: "name",
      ...getColumnSearchProps("name", "Name"),
      sorter: (a, b) => a.name?.localeCompare(b.name),
      render: (text, record) => (
        <Button
          type="link"
          onClick={() =>
            history.push(
              `/account-projects/${selectedHubspotCompanyId}/project/${record.id}`
            )
          }
        >
          {text}
        </Button>
      ),
    },
    {
      title: "Is DMT",
      dataIndex: "is_dmt",
      render: (text, record) => (record.is_dmt ? "Y" : "N"),
    },
    {
      title: "Active Decision Makers",
      width: 250,
      sorter: (a, b) => activeDmsInProject(a) - activeDmsInProject(b),
      defaultSortOrder: "descend",
      render: (row, record) => (
        <>
          {record.is_dmt === true ? (
            <Row justify="space-between">
              <Col>{activeDmsInProject(row)}</Col>
              <Col>
                <Button
                  onClick={() => {
                    setSelectedProjectForDrawer(row);
                    setIsDMsDrawerVisible(true);
                  }}
                >
                  Preview
                </Button>
              </Col>
            </Row>
          ) : (
            "N/A"
          )}
        </>
      ),
      ...getColumnSearchProps("", "Active Decision Makers"),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div
          style={{
            padding: 8,
            borderRadius: "5px",
          }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <Select
            showSearch
            allowClear
            style={{ width: 200 }}
            options={allUniqueDecisionMakerNames?.sort()?.map((name) => ({
              label: name,
              value: name,
            }))}
            placeholder={`Search Decision Makers`}
            value={selectedKeys[0]}
            autoFocus
            onChange={(value) => {
              setSelectedKeys(value ? [value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => confirm()}
          />
        </div>
      ),
      onFilter: (value, record) =>
        record.decision_makers
          .map((dm) => dm?.dm_profile?.full_name?.toLowerCase())
          .includes(value?.toLowerCase()),
    },
    {
      title: "Audience Segments",
      render: (row) => (
        <Row justify="space-between">
          <Col>{row.file_names?.length || 0}</Col>
          <Col>
            <Button
              onClick={() => {
                setSelectedProjectForDrawer(row);
                setIsFileNamesDrawerVisible(true);
              }}
              disabled={!row.file_names?.length}
            >
              Preview
            </Button>
          </Col>
        </Row>
      ),
      ...getColumnSearchProps("file_names", "Audience Segments"),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div
          style={{
            padding: 8,
            borderRadius: "5px",
          }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <Select
            showSearch
            allowClear
            style={{ width: 500 }}
            options={allUniqueFileNames?.sort()?.map((fileName) => ({
              label: fileName,
              value: fileName,
            }))}
            placeholder={`Search Audience Segments`}
            value={selectedKeys[0]}
            autoFocus
            onChange={(value) => {
              setSelectedKeys(value ? [value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => confirm()}
          />
        </div>
      ),
    },
    {
      title: "Combined File Total Audience Size",
      dataIndex: "combined_file_row_count",
      render: (val) => val?.toLocaleString(),
    },
    {
      title: "Combined File Unique Audience Size",
      dataIndex: "combined_file_distinct_row_count",
      render: (val) => val?.toLocaleString(),
    },
    {
      title: "Details",
      render: (row) => (
        <Button
          onClick={() => {
            dispatch({
              type: "set-is-open-project-details-drawer",
              payload: { isVisible: true, project_id: row.id },
            });
          }}
        >
          Edit Details
        </Button>
      ),
    },
  ];

  const onClickReport = async () => {
    try {
      await getAccountReport(selectedHubspotCompanyId);
    } catch (error) {
      alert(error);
    }
  };

  return (
    <Navbar contentClass="landing-page-content">
      <ProjectDMsDrawer
        project={selectedProjectForDMDrawer}
        isVisible={isDMsDrawerVisible}
        setIsVisible={setIsDMsDrawerVisible}
      />
      <ProjectFileNamesDrawer
        project={selectedProjectForDMDrawer}
        isVisible={isFileNamesDrawerVisible}
        setIsVisible={setIsFileNamesDrawerVisible}
      />
      <ProjectDetailsDrawer />

      <Row
        style={{
          marginBottom: 20,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button style={{ float: "right" }} onClick={() => onClickReport()}>
          Download Campaign Information
        </Button>
      </Row>
      <Row>
        {selectedHubspotCompanyId && (
          <Table
            loading={isLoading}
            style={{ width: "100%" }}
            columns={columns}
            dataSource={accountProjects}
            rowKey="id"
          />
        )}
      </Row>
    </Navbar>
  );
};

export default ListAccountProjects;
