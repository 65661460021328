import {
  AutoComplete,
  Button,
  Col,
  Descriptions,
  Drawer,
  Form,
  Row,
  Space,
  Spin,
  Steps,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import { createDecisionMaker, findDecisionMaker } from "../../../APIClient";
import { showSuccessMessage, titleCaseNames } from "../../../utils";
import DecisionMakerTaxonomy from "./DecisionMakerTaxonomy";

const DecisionMakerDetails = ({
  onContinue,
  type,
  setType,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  nickname,
  setNickname,
  title,
  setTitle,
  electedOfficeStatus,
  setElectedOfficeStatus,
  optionalDefaultName,
}) => {
  const isValid = type && firstName && lastName && electedOfficeStatus;

  return (
    <Form layout="vertical">
      <Row gutter={5}>
        <Col span={15}>
          <DecisionMakerTaxonomy
            setType={setType}
            type={type}
            electedOfficeStatus={electedOfficeStatus}
            setElectedOfficeStatus={setElectedOfficeStatus}
            section="create desicion_maker"
          />
        </Col>
      </Row>
      <Form.Item label="First name">
        <AutoComplete
          allowClear
          onChange={(val) => setFirstName(val)}
          value={firstName}
          defaultValue={titleCaseNames(optionalDefaultName?.first_name)}
        />
      </Form.Item>
      <Form.Item label="Last name">
        <AutoComplete
          allowClear
          onChange={(val) => setLastName(val)}
          value={lastName}
          defaultValue={titleCaseNames(optionalDefaultName?.last_name)}
        />
      </Form.Item>
      <Form.Item label="Nickname">
        <AutoComplete
          allowClear
          onChange={(val) => setNickname(val)}
          value={nickname}
        />
      </Form.Item>
      <Form.Item label="Title">
        <AutoComplete
          allowClear
          onChange={(val) => setTitle(val)}
          value={title}
        />
      </Form.Item>
      <Row justify="end">
        <Button
          disabled={!isValid}
          type="primary"
          onClick={() => {
            CheckDMExistence(firstName, lastName, type, onContinue);
          }}
        >
          Continue
        </Button>
      </Row>
    </Form>
  );
};

const Finalize = ({
  firstName,
  lastName,
  nickname,
  title,
  type,
  electedOfficeStatus,
  onBack,
  onFinish,
}) => {
  return (
    <Space
      direction="vertical"
      size="large"
      style={{ height: "100%", width: "100%", marginTop: 20 }}
    >
      <Descriptions
        bordered
        title={
          <span>
            <UserOutlined /> Decision Maker Details
          </span>
        }
      >
        <Descriptions.Item span={2} label="Name">
          <strong>{`${firstName} ${lastName}`}</strong>
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Nickname">
          {nickname}
        </Descriptions.Item>
        <Descriptions.Item span={1} label="Title">
          {title}
        </Descriptions.Item>
        <Descriptions.Item span={1} label="Type">
          {type}
        </Descriptions.Item>
        <Descriptions.Item span={1} label="Elected Office Status">
          {electedOfficeStatus}
        </Descriptions.Item>
      </Descriptions>
      <Row justify="space-between">
        <Button onClick={onBack}>Back</Button>
        <Button type="primary" onClick={onFinish}>
          Finish
        </Button>
      </Row>
    </Space>
  );
};

const CheckDMExistence = async (
  firstName,
  lastName,
  decisionMakerType,
  onContinue
) => {
  const firstNameClean = firstName.toLowerCase().trim();
  const lastNameClean = lastName.toLowerCase().trim();
  const resp = await findDecisionMaker(firstNameClean, lastNameClean);
  const exactMatches = resp.filter(
    (row) =>
      row.first_name.toLowerCase().trim() === firstNameClean &&
      row.last_name.toLowerCase().trim() === lastNameClean &&
      row.decision_maker_type.toLowerCase() === decisionMakerType.toLowerCase()
  );
  exactMatches.length !== 0
    ? alert(
        `We found ${exactMatches.length} record(s) for ${firstName} ${lastName} and type ${decisionMakerType}. Please make sure if decision maker exists in database before creating it by searching them on the left hand side.`
      )
    : onContinue();
};

export const CreateDecisionMakerDrawer = ({
  isVisible,
  setIsVisible,
  loadDecisionMakers = () => {},
  handleNewlyCreatedDecisionMaker = () => {},
  optionalDefaultName,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [type, setType] = useState();
  const [electedOfficeStatus, setElectedOfficeStatus] = useState(null);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [nickname, setNickname] = useState();
  const [title, setTitle] = useState();

  useEffect(() => {
    if (optionalDefaultName) {
      setFirstName(titleCaseNames(optionalDefaultName?.first_name));
      setLastName(titleCaseNames(optionalDefaultName?.last_name));
    }
  }, [optionalDefaultName]);

  const resetValues = () => {
    setCurrentStep(0);
    setType();
    setFirstName();
    setLastName();
    setNickname();
    setTitle();
    setElectedOfficeStatus(null);
  };

  const onFinish = async () => {
    setIsLoading(true);
    try {
      const resp = await createDecisionMaker(
        firstName,
        lastName,
        nickname,
        title,
        type,
        electedOfficeStatus
      );
      handleNewlyCreatedDecisionMaker(resp.id, firstName, lastName);
      setIsVisible(false);
      resetValues();
      if (resp.error) {
        alert(resp.error);
      }
      showSuccessMessage("New Decision Maker created");
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
    loadDecisionMakers();
  };

  const stepContent = [
    <DecisionMakerDetails
      onContinue={() => setCurrentStep(1)}
      type={type}
      setType={setType}
      firstName={firstName}
      setFirstName={setFirstName}
      lastName={lastName}
      setLastName={setLastName}
      nickname={nickname}
      setNickname={setNickname}
      title={title}
      setTitle={setTitle}
      electedOfficeStatus={electedOfficeStatus}
      setElectedOfficeStatus={setElectedOfficeStatus}
      optionalDefaultName={optionalDefaultName}
    />,
    <Finalize
      firstName={firstName}
      lastName={lastName}
      nickname={nickname}
      title={title}
      type={type}
      electedOfficeStatus={electedOfficeStatus}
      onBack={() => setCurrentStep(0)}
      onFinish={onFinish}
    />,
  ];
  return (
    <Drawer
      title="Create Decision Maker"
      onClose={() => setIsVisible(false)}
      visible={isVisible}
      width={800}
      bodyStyle={{ paddingBottom: 20 }}
      maskClosable={false}
    >
      <Space direction="vertical" style={{ width: "100%", height: "100%" }}>
        <Steps size="small" current={currentStep} status="process">
          <Steps.Step key="1" title="Details" />
          <Steps.Step key="2" title="Finalize" />
        </Steps>
        <Spin spinning={isLoading}>{stepContent[currentStep]}</Spin>
      </Space>
    </Drawer>
  );
};
