import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store";
import iconStyles from "./style/Icon.module.css";
import moment from "moment";
import {
  Badge,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Input,
  Modal,
  Popconfirm,
  Popover,
  Result,
  Row,
  Skeleton,
  Space,
  Tabs,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import {
  AimOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  ThunderboltOutlined,
  ToTopOutlined,
  LeftOutlined,
  WarningOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import CreateTargetDrawer from "../TargetDrawer";
import {
  deactivateTargetProjectLinks,
  deleteTpl,
  floodTargetProjectLink,
  reactivateTargetProjectLinks,
} from "../../APIClient";
import { showSuccessMessage } from "../../utils";
import CreatePackageDrawer, {
  EditPackageDrawer,
} from "../PackageDrawer/PackageDrawer";

const { confirm } = Modal;

export const TargetsColumn = ({ refreshPageInfo }) => {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const {
    getProjectAction,
    setTargetNull,
    getTargetAction,
    getAudienceCreationLogsAction,
    getSegmentsAction,
    getDSPExportFilesAction,
  } = bindActionCreators(actionCreators, dispatch);

  // redux state
  const target = useSelector((state) => state.target);
  const project = useSelector((state) => state.project);
  const isProjectLoading = useSelector((state) => state.isProjectLoading);
  const selectedTargetId = useSelector((state) => state.selectedTargetId);
  const targetOrPackage = useSelector((state) => state.targetPackageTabKey);
  const dspExportFiles = useSelector((state) => state.dspExportFiles);

  // local state
  const [targetSearchTerm, setTargetSearchTerm] = useState("");
  const [showPackageDrawer, setShowPackageDrawer] = useState(false);
  const [showTargetDrawer, setShowTargetDrawer] = useState(false);
  const [showEditPackageDrawer, setShowEditPackageDrawer] = useState(false);
  const [isEditPackageVisible, setIsEditPackageVisible] = useState(false);
  const [isCreateDMVisible, setIsCreateDMVisible] = useState(false);
  const [deactivate, setDeactivate] = useState(false);
  const [reactivate, setReactivate] = useState(false);
  const [selectedDeactivateTargetIds, setSelectedDeactivateTargetIds] =
    useState([]);
  const [selectedReactivateTargetIds, setSelectedReactivateTargetIds] =
    useState([]);
  const [deactivationDate, setDeactivationDate] = useState();
  useState(false);

  const history = useHistory();

  useEffect(() => {
    setSelectedTargetId("all");
    setTargetNull();
    dispatch({ type: "reset-target-package-tab-key" });
    fetchDSPExportFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    if (selectedTargetId === "all") {
      setTargetNull();
    } else if (selectedTargetId && !isNaN(selectedTargetId)) {
      // if id is a valid number fetch the associated target
      fetchTarget();
      fetchAudienceCreationLogs();
    }
    fetchAudienceSegments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTargetId]);

  const setSelectedTargetId = (payload) => {
    dispatch({ type: "set-selected-target-id", payload });
  };

  const fetchTarget = async () => {
    try {
      getTargetAction(selectedTargetId, projectId);
    } catch (error) {
      alert(error);
    }
  };

  const fetchProject = async () => {
    try {
      getProjectAction(projectId);
    } catch (error) {
      alert(error);
    }
  };

  const fetchAudienceCreationLogs = () => {
    if (!selectedTargetId === "all") {
      return;
    }
    try {
      getAudienceCreationLogsAction(projectId, selectedTargetId);
    } catch (error) {
      alert(error);
    }
  };

  const fetchAudienceSegments = async () => {
    try {
      getSegmentsAction(
        selectedTargetId === "all" ? undefined : selectedTargetId,
        projectId
      );
    } catch (error) {
      alert(error);
    }
  };

  const fetchDSPExportFiles = async () => {
    try {
      getDSPExportFilesAction(projectId);
    } catch (error) {
      alert(error);
    }
  };

  const deactivateSelectedTargets = async () => {
    try {
      await deactivateTargetProjectLinks(
        deactivationDate.format("DD-MM-YYYY"),
        selectedDeactivateTargetIds
      );
      setDeactivationDate();
      setSelectedDeactivateTargetIds([]);
      setDeactivate(false);
      fetchProject();
      dispatch({ type: "set-audience-breakdown-outdated" });
    } catch (error) {
      alert(error);
    }
  };

  const reactivateSelectedTargets = async () => {
    try {
      await reactivateTargetProjectLinks(selectedReactivateTargetIds);
      setSelectedReactivateTargetIds([]);
      setReactivate(false);
      fetchProject();
      dispatch({ type: "set-audience-breakdown-outdated" });
    } catch (error) {
      alert(error);
    }
  };

  const onFloodTargetProjectLink = async (targetId) => {
    try {
      await floodTargetProjectLink(targetId);
      showSuccessMessage("Files successfully flooded");
      await fetchDSPExportFiles();
    } catch (error) {
      alert(error);
    }
  };

  const onDeleteTarget = (tplId) => {
    confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content:
        "Deleting this Decision Maker will delete all audience members attached to this Decision Maker, are you sure you wish to continue?",
      okText: "Yes",
      okType: "danger",
      async onOk() {
        await deleteTpl(tplId, projectId);
        fetchProject();
        dispatch({ type: "set-audience-breakdown-outdated" });
        setSelectedTargetId("all");
      },
    });
  };

  function disabledDate(current) {
    const activateDates = activatedTargets
      .filter((t) => selectedDeactivateTargetIds.includes(t.id))
      .map((row) => moment(row.effective_date).unix());
    const maxActivateDate = moment.unix(Math.max(...activateDates));

    return (
      current.isBefore(moment().startOf("month").subtract(1, "month")) ||
      current.isSameOrBefore(maxActivateDate, "day")
    );
  }

  const matchingTargets = project?.target_project_links
    ?.filter((target) => !target?.package)
    ?.filter((t) => {
      const name = t.dm_profile?.full_name;
      return name?.toLowerCase()?.includes(targetSearchTerm);
    })
    .sort((a, b) => {
      const tplNameA = a.dm_profile?.full_name;
      const tplNameB = b.dm_profile?.full_name;
      return tplNameA.localeCompare(tplNameB);
    });

  const deactivatedTargets = matchingTargets?.filter(
    (target) => !target?.is_active
  );

  const activatedTargets = matchingTargets?.filter(
    (target) => target?.is_active
  );

  const allPackages = project?.target_project_links?.filter(
    (target) => target?.package
  );
  const matchingPackages = allPackages
    ?.filter((t) => {
      return t.package?.name?.toLowerCase().includes(targetSearchTerm);
    })
    .sort((a, b) => {
      return a.package?.name?.localeCompare(b.package.name);
    });

  return (
    <Col span={4} style={{ backgroundColor: "#FAFCFF" }}>
      <Row
        justify="space-between"
        style={{ alignItems: "baseline", padding: "20px 24px" }}
      >
        <Col>
          <Typography.Title level={5}>
            <AimOutlined /> Decision Makers
          </Typography.Title>
        </Col>
      </Row>
      {isProjectLoading ? (
        <Space
          direction="vertical"
          size="large"
          className="full-width-loading-skeleton"
          style={{
            padding: "0px 24px",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Skeleton.Input active style={{ height: "40px", width: "100%" }} />
          <Skeleton.Input active style={{ height: "40px", width: "100%" }} />
          <Skeleton.Input active style={{ height: "40px", width: "100%" }} />
          <Skeleton.Input active style={{ height: "40px", width: "100%" }} />
        </Space>
      ) : (
        <>
          <Input.Search
            allowClear
            placeholder="Decision Maker name"
            style={{ padding: "24px" }}
            onSearch={(val) => {
              setTargetSearchTerm(val.toLocaleLowerCase());
            }}
          />
          <Tabs
            tabPosition="left"
            moreIcon={null}
            tabBarStyle={{
              width: "100%",
              textAlign: "left",
              maxHeight: "80vh",
              overflowY: "auto",
            }}
            className="target-tabs"
            activeKey={selectedTargetId}
            onChange={(key) => setSelectedTargetId(key)}
          >
            <Tabs.TabPane
              tab={
                <Row justify="space-between" style={{ alignItems: "center" }}>
                  <Col>All</Col>
                  <Col>
                    <Tag style={{ margin: 0 }}>
                      {project?.target_project_links?.length}
                    </Tag>
                  </Col>
                </Row>
              }
              key="all"
            />
          </Tabs>

          <Tabs
            tabPosition="top"
            moreIcon={null}
            className="target-tabs"
            activeKey={targetOrPackage}
            onChange={(key) => {
              dispatch({ type: "set-target-package-tab-key", payload: key });
              setDeactivate(false);
            }}
          >
            <Tabs.TabPane
              key={"targets"}
              tab={
                <span style={{ padding: "0 10px" }}>
                  {`Decision Makers (${activatedTargets?.length || 0})`}
                </span>
              }
            />
            <Tabs.TabPane
              key={"packages"}
              tab={
                <span style={{ padding: "0 10px" }}>
                  {`Packages (${allPackages?.length || 0})`}
                </span>
              }
            />
          </Tabs>
          <Row
            style={{ padding: 5, paddingBottom: 25 }}
            gutter={[0, 16]}
            justify="space-between"
          >
            {deactivate ? (
              <>
                <Col>
                  <Button
                    icon={<LeftOutlined />}
                    onClick={() => setDeactivate(false)}
                  />
                </Col>
                <Col>
                  <Tooltip
                    title="Deactivation Date must occur after Usage Start Date and the
                             last day of the previous month, please reach out to Business
                             Operations team with questions."
                  >
                    <DatePicker
                      placeholder="Deactivation Date"
                      disabledDate={disabledDate}
                      showToday={false}
                      value={deactivationDate}
                      disabled={selectedDeactivateTargetIds.length === 0}
                      onChange={(date) => {
                        setDeactivationDate(date);
                      }}
                    />
                  </Tooltip>
                </Col>
                <Col>
                  <Popconfirm
                    icon={
                      <QuestionCircleOutlined style={{ color: "#d46b08" }} />
                    }
                    title={
                      <>
                        {deactivationDate?.isBefore(
                          moment().startOf("month").subtract(1, "day")
                        ) && (
                          <div style={{ padding: "5px" }}>
                            <Tag icon={<WarningOutlined />} color="warning">
                              Deactivation date is in previous
                              <br /> month which may impact previous billing
                              <br /> statements. Please confirm dates with
                              <br /> your Business Operations contact before
                              <br /> proceeding.
                            </Tag>
                          </div>
                        )}
                        <div>
                          Are you sure you want to Deactivate
                          <br />
                          <b>{selectedDeactivateTargetIds.length}</b> Decision
                          Makers on{" "}
                          <b>{deactivationDate?.format("MM/DD/YYYY")}</b>
                        </div>
                      </>
                    }
                    disabled={
                      selectedDeactivateTargetIds.length === 0 ||
                      !deactivationDate
                    }
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => {
                      deactivateSelectedTargets();
                    }}
                  >
                    <Button
                      disabled={
                        selectedDeactivateTargetIds.length === 0 ||
                        !deactivationDate
                      }
                      type="primary"
                      onClick={() => {}}
                    >
                      Submit
                    </Button>
                  </Popconfirm>
                </Col>
              </>
            ) : (
              <>
                <Col>
                  <Button
                    icon={<PlusOutlined />}
                    onClick={() =>
                      targetOrPackage === "targets"
                        ? setShowTargetDrawer(true)
                        : setShowPackageDrawer(true)
                    }
                  >
                    Add
                  </Button>
                </Col>
                <Col>
                  {targetOrPackage === "targets" && (
                    <Button
                      type="primary"
                      icon={<EditOutlined />}
                      disabled={!activatedTargets?.length}
                      onClick={() => {
                        setDeactivate(true);
                      }}
                    >
                      Deactivate
                    </Button>
                  )}
                </Col>
              </>
            )}
          </Row>
          <Tabs
            tabPosition="left"
            moreIcon={null}
            tabBarStyle={{
              width: "100%",
              textAlign: "left",
              maxHeight: "80vh",
              overflowY: "auto",
            }}
            className="target-tabs"
            activeKey={selectedTargetId}
            onChange={(key) => setSelectedTargetId(key)}
          >
            {targetOrPackage === "targets"
              ? activatedTargets?.map((t) => (
                  <Tabs.TabPane
                    tab={
                      <Row
                        justify="space-between"
                        style={{ alignItems: "center" }}
                      >
                        <Col span={1}>
                          {t?.dm_profile?.p_id ? <Badge color="blue" /> : null}
                        </Col>
                        <Col span={1}>
                          {deactivate && (
                            <Checkbox
                              checked={selectedDeactivateTargetIds.includes(
                                t.id
                              )}
                              disabled={!t.is_exported}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSelectedDeactivateTargetIds([
                                    ...selectedDeactivateTargetIds,
                                    t.id,
                                  ]);
                                } else {
                                  setSelectedDeactivateTargetIds(
                                    selectedDeactivateTargetIds.filter(
                                      (id) => id !== t.id
                                    )
                                  );
                                }
                              }}
                            />
                          )}
                        </Col>
                        {t?.is_pending && (
                          <Col span={1}>
                            <Tooltip title="Re-export Required">
                              <Tag style={{ margin: 0 }}>R</Tag>
                            </Tooltip>
                          </Col>
                        )}
                        <Col span={13} style={{ whiteSpace: "initial" }}>
                          {`${t?.dm_profile?.first_name} ${t?.dm_profile?.last_name}`}
                        </Col>
                        <Col span={2}>
                          {!deactivate && (
                            <Tooltip title="Edit Decision Maker">
                              <Link
                                to={`/decision-makers/${t?.dm_profile?.id}`}
                                target="_blank"
                              >
                                <EditOutlined className={iconStyles.editIcon} />
                              </Link>
                            </Tooltip>
                          )}
                        </Col>
                        <Col span={2}>
                          {!deactivate && (
                            <Button
                              type="text"
                              className="danger-link"
                              disabled={t?.is_exported}
                              icon={<DeleteOutlined />}
                              onClick={() => onDeleteTarget(t.id)}
                              style={{ padding: 0, border: 0, height: "20px" }}
                            />
                          )}
                        </Col>
                      </Row>
                    }
                    key={t?.id}
                  />
                ))
              : matchingPackages?.map((tpl) => (
                  <Tabs.TabPane
                    tab={
                      <Row
                        justify="space-between"
                        style={{ alignItems: "center" }}
                      >
                        <Col span={18} style={{ whiteSpace: "initial" }}>
                          {tpl?.package?.name}
                        </Col>
                        <Col span={2}>
                          <Tooltip title="Edit Packages">
                            <EditOutlined
                              className={iconStyles.editIcon}
                              onClick={() => setIsEditPackageVisible(true)}
                            />
                          </Tooltip>
                        </Col>
                        <Col span={2}>
                          <Tooltip title="Delete">
                            <DeleteOutlined
                              className="danger-link"
                              onClick={() => onDeleteTarget(tpl.id)}
                            />
                          </Tooltip>
                        </Col>
                      </Row>
                    }
                    key={tpl.id}
                  />
                ))}
          </Tabs>
          {targetOrPackage === "targets"
            ? project?.target_project_links?.filter((row) => !row.package)
                ?.length === 0 && (
                <Result
                  icon={<AimOutlined className="empty-results-icon" />}
                  subTitle={
                    <>
                      Click the &nbsp;
                      <PlusOutlined className="empty-results-button" />
                      &nbsp; in this section to add Targets
                    </>
                  }
                />
              )
            : project?.target_project_links?.filter((row) => row.package)
                ?.length === 0 && (
                <Result
                  icon={<AimOutlined className="empty-results-icon" />}
                  subTitle={
                    <>
                      Click the &nbsp;
                      <PlusOutlined className="empty-results-button" />
                      &nbsp; in this section to add Packages
                    </>
                  }
                />
              )}
          {/* DEACTIVATED AREA */}
          {targetOrPackage === "targets" && (
            <>
              <Divider />
              <Row
                style={{ padding: "0px 16px 8px 16px" }}
                justify="space-between"
                align="middle"
              >
                <Col span={10}>
                  Deactivated ({deactivatedTargets?.length || 0})
                </Col>
                {!reactivate ? (
                  <Col span={14} style={{ textAlign: "right" }}>
                    <Button
                      type="primary"
                      icon={<ToTopOutlined />}
                      disabled={!deactivatedTargets?.length}
                      onClick={() => {
                        setReactivate(true);
                      }}
                    >
                      Reactivate
                    </Button>
                  </Col>
                ) : (
                  <>
                    <Col>
                      <Button
                        icon={<LeftOutlined />}
                        onClick={() => setReactivate(false)}
                      />
                    </Col>
                    <Col>
                      <Popconfirm
                        icon={
                          <QuestionCircleOutlined
                            style={{ color: "#d46b08" }}
                          />
                        }
                        title={
                          <div>
                            Are you sure you want to Reactivate
                            <br />
                            <b>{selectedReactivateTargetIds.length}</b> Decision
                            Makers
                          </div>
                        }
                        disabled={selectedReactivateTargetIds.length === 0}
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => {
                          reactivateSelectedTargets();
                        }}
                      >
                        <Button
                          disabled={selectedReactivateTargetIds.length === 0}
                          type="primary"
                          onClick={() => {}}
                        >
                          Submit
                        </Button>
                      </Popconfirm>
                    </Col>
                  </>
                )}
              </Row>
              <Tabs
                tabPosition="left"
                moreIcon={null}
                tabBarStyle={{
                  width: "100%",
                  textAlign: "left",
                  maxHeight: "80vh",
                  overflowY: "auto",
                  marginTop: "10px",
                }}
                className="target-tabs"
              >
                {deactivatedTargets?.map((t) => {
                  const targetCanBeFlooded =
                    !!dspExportFiles[t.id] &&
                    dspExportFiles[t.id].filter((row) => !row.is_flooded)
                      .length > 0;
                  return (
                    <Tabs.TabPane
                      key={t?.id}
                      tab={
                        <Row
                          justify="space-between"
                          style={{ alignItems: "center" }}
                        >
                          <Col span={1}>
                            {t?.dm_profile?.p_id ? (
                              <Badge color="blue" />
                            ) : null}
                          </Col>
                          <Col span={2}>
                            {t?.is_pending && (
                              <Tooltip title="Deactivation Pending">
                                <Tag style={{ margin: 0 }}>P</Tag>
                              </Tooltip>
                            )}
                          </Col>
                          <Col span={1}>
                            {reactivate && (
                              <Checkbox
                                checked={selectedReactivateTargetIds.includes(
                                  t.id
                                )}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setSelectedReactivateTargetIds([
                                      ...selectedReactivateTargetIds,
                                      t.id,
                                    ]);
                                  } else {
                                    setSelectedReactivateTargetIds(
                                      selectedReactivateTargetIds.filter(
                                        (id) => id !== t.id
                                      )
                                    );
                                  }
                                }}
                              />
                            )}
                          </Col>
                          <Col span={14} style={{ whiteSpace: "initial" }}>
                            <Popover
                              placement="bottom"
                              title={"Files will be flooded"}
                              content={dspExportFiles[t?.id]
                                ?.filter((row) => !row.is_flooded)
                                ?.reduce(
                                  (myArrayOfStrings, myObject) =>
                                    myArrayOfStrings.concat(
                                      <div key={myObject["file_name"]}>
                                        {myObject["file_name"]}
                                      </div>
                                    ),
                                  []
                                )}
                            >
                              {`${t?.dm_profile?.first_name} ${t?.dm_profile?.last_name}`}
                            </Popover>
                          </Col>
                          <Col span={2}>
                            {!reactivate && (
                              <Tooltip
                                title={
                                  targetCanBeFlooded
                                    ? "Flood"
                                    : "No files to flood"
                                }
                              >
                                <Popconfirm
                                  title="Are you sure you wish to flood this decision maker?"
                                  placement="rightBottom"
                                  onConfirm={() =>
                                    onFloodTargetProjectLink(t.id)
                                  }
                                  disabled={!targetCanBeFlooded}
                                >
                                  <Button
                                    type="text"
                                    disabled={!targetCanBeFlooded}
                                    style={{
                                      padding: 0,
                                      border: 0,
                                      height: "20px",
                                    }}
                                    icon={
                                      <ThunderboltOutlined
                                        className={
                                          targetCanBeFlooded && "danger-link"
                                        }
                                      />
                                    }
                                  />
                                </Popconfirm>
                              </Tooltip>
                            )}
                          </Col>
                        </Row>
                      }
                    />
                  );
                })}
              </Tabs>
            </>
          )}
        </>
      )}
      {/* Package column drawers */}
      <CreatePackageDrawer
        projectId={projectId}
        isVisible={showPackageDrawer}
        setIsVisible={setShowPackageDrawer}
        refreshPackages={() => {
          fetchProject();
        }}
      />
      <EditPackageDrawer
        isVisible={showEditPackageDrawer}
        setIsVisible={setShowEditPackageDrawer}
        refreshPackages={() => {
          fetchProject();
        }}
      />

      {/* Target/Package drawers */}
      <CreateTargetDrawer
        isVisible={showTargetDrawer}
        setIsVisible={setShowTargetDrawer}
        setIsDMSModalVisible={setIsCreateDMVisible}
        graphVersion={project?.graph_version}
        refreshTargets={() => {
          fetchProject();
        }}
      />
      <Modal
        title="Edit Packages"
        visible={isEditPackageVisible}
        onOk={() => history.push(`/packages/${target.package}`)}
        onCancel={() => setIsEditPackageVisible(false)}
      >
        <p>You are leaving the project page, Do you want to continue? </p>
      </Modal>

      <Modal
        title="Create Decision Maker"
        visible={isCreateDMVisible}
        onOk={() => history.push(`/decision-makers/`)}
        onCancel={() => setIsCreateDMVisible(false)}
      >
        <p>You are leaving the project page, Do you want to continue?</p>
      </Modal>
    </Col>
  );
};
