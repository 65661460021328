import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Drawer, Spin, Space } from "antd";
import { useState } from "react";
import { createHVCSegment } from "../../../APIClient";
import { showSuccessMessage, showWarningMessage } from "../../../utils";

export const HVCDrawer = ({ isVisible, setIsVisible, refreshPage }) => {
  const [isLoading] = useState(false);
  const { projectId } = useParams();
  const target = useSelector((state) => state.target);
  const createAudienceMembers = async () => {
    try {
      const response = await createHVCSegment(
        target?.dm_id,
        parseInt(projectId),
        target.id
      );
      const counts = Object.keys(response).map(
        (key) => `${response[key].audience_length} ${key}`
      );
      if (counts.length === 0) {
        showWarningMessage("No HVCs found");
      } else {
        showSuccessMessage(`Total of ${counts.join(", ")} HVCs were added.`);
      }
    } catch (error) {
      alert(error);
    }
    refreshPage();
    setIsVisible(false);
  };

  return (
    <Drawer
      title={`High Value Connections (HVC)`}
      onClose={() => setIsVisible(false)}
      visible={isVisible}
      width={900}
      bodyStyle={{ paddingBottom: 20 }}
    >
      {" "}
      <Space direction="vertical" size="large">
        High Value Connections (HVC) specified in the project-level HVC
        configuration will be added to the target
        <Spin spinning={isLoading}>
          <Button onClick={() => createAudienceMembers()}>
            Create High Value Connections (HVC)
          </Button>
        </Spin>
      </Space>
    </Drawer>
  );
};
