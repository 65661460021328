import { Col, Drawer, Row, Empty, Card } from "antd";
import MPSSearchForm from "../MPSSearch/MPSSearchForm";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "antd/lib/form/Form";
import { SaveOutlined } from "@ant-design/icons";
import PersonCard from "../MPSSearch/PersonCard";
import {
  addPidToStaffer,
  updateStafferPid,
  createStafferMatchAttemptLog,
} from "../../APIClient";
import { showSuccessMessage } from "../../utils";

const PIISearchDrawer = ({
  toggleDrawer,
  setToggleDrawer,
  currentStaffer,
  setCurrentStaffer,
  setRerender,
  staffers,
  setStaffers,
}) => {
  const [form] = useForm();
  const [results, setResults] = useState([]);
  const [searchtriggered, setSearchTriggered] = useState(false);
  const [searchMethodItems, setSearchMethodItems] = useState({});
  const user_email = useSelector((state) => state.user.email);

  useEffect(() => {
    setResults([]);
  }, [currentStaffer?.id, form]);

  const resetForm = () => {
    form.resetFields();
    form.setFieldsValue({ addresses: [], birthdays: [], names: [] });
    setSearchMethodItems({});
    setResults([]);
  };

  const attachPIDtoStaffer = async (p_id) => {
    try {
      await addPidToStaffer(currentStaffer?.source_id, p_id);
      const resp = await createStafferMatchAttemptLog(
        currentStaffer?.source_id,
        user_email
      );
      setStaffers(
        staffers.map((staffer) => {
          if (staffer.source_id === currentStaffer?.source_id) {
            staffer.p_id = p_id;
            staffer.most_recent_attempt = resp.event_timestamp;
          }
          return staffer;
        })
      );
      setCurrentStaffer({
        ...currentStaffer,
        p_id,
        most_recent_attempt: resp.event_timestamp,
      });
      setRerender(true);
      showSuccessMessage("Pii profile attached successfully");
      resetForm();
      setToggleDrawer(false);
    } catch (error) {
      alert(error);
    }
  };

  const swapPidForStaffer = async (p_id) => {
    try {
      await updateStafferPid(currentStaffer?.source_id, p_id);
      const resp = await createStafferMatchAttemptLog(
        currentStaffer?.source_id,
        user_email
      );
      setStaffers(
        staffers.map((staffer) => {
          if (staffer.source_id === currentStaffer?.source_id) {
            staffer.p_id = p_id;
            staffer.most_recent_attempt = resp.event_timestamp;
          }
          return staffer;
        })
      );
      setCurrentStaffer({
        ...currentStaffer,
        p_id,
        most_recent_attempt: resp.event_timestamp,
      });
      setRerender(true);
      showSuccessMessage("Pii profile updated successfully");
      resetForm();
      setToggleDrawer(false);
    } catch (error) {
      alert(error);
    }
  };

  return (
    <Drawer
      forceRender
      title={`${
        currentStaffer?.p_id ? "Replace P_ID for" : "Attach P_ID to"
      } staffer ${currentStaffer?.staffer_first_name} ${
        currentStaffer?.staffer_last_name
      }`}
      placement="right"
      visible={toggleDrawer}
      width={800}
      onClose={() => {
        setCurrentStaffer(null);
        setToggleDrawer(false);
      }}
    >
      <>
        <Card>
          <MPSSearchForm
            dataCallback={setResults}
            searchForm={form}
            formResetCallback={resetForm}
            setSearchTriggered={setSearchTriggered}
            setSearchMethodItems={setSearchMethodItems}
            searchMethodItems={searchMethodItems}
          />
        </Card>

        {searchtriggered && (
          <div className="results-container">
            <Row>
              <Col span={12}>
                <h2>Search Results ({results ? results.length : 0})</h2>
              </Col>
            </Row>

            {!results || results.length === 0 ? (
              <Empty description="No results" />
            ) : (
              <>
                {results.map((row, index) => (
                  <PersonCard
                    index={index}
                    key={index}
                    person={row}
                    linkTo={
                      currentStaffer?.p_id === null
                        ? "Attach to Staffer"
                        : "Replace the current profile"
                    }
                    linkToButtonCallback={() =>
                      currentStaffer?.p_id === null
                        ? attachPIDtoStaffer(row.p_id)
                        : swapPidForStaffer(row.p_id)
                    }
                    icon={<SaveOutlined />}
                  />
                ))}
              </>
            )}
          </div>
        )}
      </>
    </Drawer>
  );
};

export default PIISearchDrawer;
