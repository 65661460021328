import { Form, Select } from "antd";

export const AccountSelectForm = ({
  clientId,
  clientNameOptions,
  onAccountChange,
}) => {
  return (
    <Form>
      <Form.Item>
        <Select
          showSearch
          style={{ minWidth: "265px" }}
          placeholder="Account name"
          value={clientId}
          onChange={(value) => {
            onAccountChange(value);
          }}
          filterOption={(input, option) =>
            (option?.label?.toLowerCase() ?? "").includes(input?.toLowerCase())
          }
          options={clientNameOptions
            .map((client) => ({
              label: client.name,
              value: client.id,
            }))
            .toSorted((a, b) => a?.label.localeCompare(b?.label))}
        />
      </Form.Item>
    </Form>
  );
};
