import Navbar from "../../Navbar";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getUsage, postReport } from "../../../APIClient";
import { Button, Space, DatePicker, Row, Col, Tabs } from "antd";
import { AggregatedDMsTable } from "./AggregatedDMsTable";
import { ExplodedDMsTable } from "./ExplodedDMsTable";

const UnitTracking = () => {
  const [tableData, setTableData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    onChangeDatePicker(selectedDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeDatePicker = async (selectedDate) => {
    setIsLoading(true);
    setSelectedDate(selectedDate);

    try {
      const result = await getUsage(selectedDate);
      setTableData(result);
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  const disableDate = (monthYear) => {
    return monthYear < moment("2/1/2023", "M/D/YYYY") || monthYear > Date.now();
  };

  const onClickReport = async () => {
    await postReport(selectedDate);
  };

  return (
    <Navbar contentClass="landing-page-content">
      <Space direction="vertical" size={15} style={{ width: "100%" }}>
        <Row>
          <Col span={12}>
            <Space direction="horizontal" align="start">
              <div style={{ height: 100, width: 200 }}>
                <h1>Monthly View</h1>
                <DatePicker
                  allowClear={false}
                  onChange={onChangeDatePicker}
                  picker="month"
                  defaultValue={selectedDate}
                  disabledDate={disableDate}
                />
              </div>
            </Space>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Button onClick={onClickReport}>Download Report</Button>
          </Col>
        </Row>
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="AU Charges" key="1">
            <AggregatedDMsTable tableData={tableData} isLoading={isLoading} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Project Targets" key="2">
            <Row>
              <ExplodedDMsTable
                changeUsageStartCallback={() =>
                  onChangeDatePicker(selectedDate)
                }
                tableData={tableData}
                isLoading={isLoading}
              />
            </Row>
          </Tabs.TabPane>
        </Tabs>
      </Space>
    </Navbar>
  );
};

export default UnitTracking;
