import React from "react";
import { Modal, Drawer } from "antd";
import { connectionTypes, staffTitles } from "./constants";
import ConnectionTypeForm from "./ConnectionTypeForm";

const ConnectionTypeComponent = ({
  setIsConnectionTypeCompOpen,
  decisionMaker,
  selectedDecisionMakerAsHVC,
  connectionTypeForm,
  isConnectionTypeCompOpen,
  attachHVCtoDecisionMaker,
  updateHVCForDecisionMaker,
  isDrawer = false,
}) => {
  const initialValues = {
    connection_type: selectedDecisionMakerAsHVC?.connection_type,
    is_current: selectedDecisionMakerAsHVC?.is_current,
    company: selectedDecisionMakerAsHVC?.company,
    web_url: selectedDecisionMakerAsHVC?.web_url,
    title: selectedDecisionMakerAsHVC?.title,
  };

  const DisplayMode = isDrawer ? Drawer : Modal;

  return (
    <DisplayMode
      title={
        "Adding " +
        selectedDecisionMakerAsHVC.first_name +
        " " +
        selectedDecisionMakerAsHVC.last_name +
        " as HVC to " +
        decisionMaker.first_name +
        " " +
        decisionMaker.last_name
      }
      visible={isConnectionTypeCompOpen}
      onCancel={() => {
        setIsConnectionTypeCompOpen(false);
        connectionTypeForm.setFieldsValue(initialValues);
      }}
      onClose={() => {
        setIsConnectionTypeCompOpen(false);
      }}
      footer={null}
      width={isDrawer ? 900 : 600}
      maskClosable={false}
    >
      {selectedDecisionMakerAsHVC && (
        <ConnectionTypeForm
          form={connectionTypeForm}
          connectionTypes={connectionTypes}
          staffTitles={staffTitles}
          attachHVCtoDecisionMakerFunc={attachHVCtoDecisionMaker}
          updateHVCForDecisionMakerFunc={updateHVCForDecisionMaker}
          selectedDecisionMakerAsHVC={selectedDecisionMakerAsHVC}
          initialValues={initialValues}
        />
      )}
    </DisplayMode>
  );
};

export default ConnectionTypeComponent;
