import { Popover, Table } from "antd";
import { Link } from "react-router-dom";
import {
  formatDecisionMakerNSBuildingStatus,
  formatDecisionMakerQAStatus,
} from "../QualityAssurance/DecisionMakerStatus";
import { daysSince, sortColumnWithNulls } from "../../../utils";
import { getColumnSearchProps } from "../../../util/columnSearchPropFactory";
import { selectDecisionMakerStatusFilters } from "./ResearchRequestsColumnProcessFunctions";

const ResearchRequestsQueueGrid = ({
  isLoading,
  queueGridData,
  isQA = false,
}) => {
  const queueGridCommonColumns = [
    {
      title: "Due Date",
      dataIndex: "due_date",
      sorter: (a, b) => new Date(a.due_date) - new Date(b.due_date),
    },
    {
      title: "DM id",
      dataIndex: "decision_maker_id",
      sorter: (a, b) => a.decision_maker_id - b.decision_maker_id,
      render: (_, record) => (
        <Link
          to={`/decision-makers/${record.decision_maker_id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {record.decision_maker_id}
        </Link>
      ),
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      sorter: (a, b) => a.first_name?.localeCompare(b.first_name),
      ...getColumnSearchProps("first_name", "First Name"),
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      sorter: (a, b) => a.last_name?.localeCompare(b.last_name),
      ...getColumnSearchProps("last_name", "Last Name"),
    },
    {
      title: "DM Type",
      dataIndex: "decision_maker_type",
      sorter: (a, b) =>
        a.decision_maker_type?.localeCompare(b.decision_maker_type),
      ...getColumnSearchProps("decision_maker_type", "Decision Maker Type"),
    },
    {
      title: "Organization",
      dataIndex: "company_name",
      sorter: (a, b) =>
        sortColumnWithNulls(a.decision_maker_type, b.decision_maker_type),
      ...getColumnSearchProps("company_name", "Organization"),
    },
    {
      title: "Title",
      dataIndex: "dm_title",
      sorter: (a, b) => sortColumnWithNulls(a.dm_title, b.dm_title),
      ...getColumnSearchProps("dm_title", "Title"),
    },
  ];

  const queueGridQAColumns = [
    {
      title: "QA",
      key: "qa",
      onFilter: (value, record) => record.qa_info.value === value,
      filters: selectDecisionMakerStatusFilters(),
      render: (record) => {
        if (record?.is_building || record?.is_failed) {
          return formatDecisionMakerNSBuildingStatus(
            record?.is_building,
            record?.is_failed
          );
        }
        let popoverInfo = record?.qa_info?.event_timestamp ? (
          <div>
            <p>
              <b>Date</b>: {record?.qa_info?.event_timestamp} (
              {daysSince(record?.qa_info?.event_timestamp)})
              <br />
              <b>User</b>: {record?.qa_info?.updated_by}
            </p>
          </div>
        ) : (
          `No QA`
        );
        return (
          <Popover title={`Last QA Information`} content={popoverInfo}>
            {formatDecisionMakerQAStatus(record?.qa_info?.value, false, false)}
          </Popover>
        );
      },
    },
    {
      title: "Review",
      key: "review",
      onFilter: (value, record) => record.review_info.value === value,
      filters: selectDecisionMakerStatusFilters(false),
      render: (record) => {
        if (record?.is_building || record?.is_failed) {
          return formatDecisionMakerNSBuildingStatus(
            record?.is_building,
            record?.is_failed
          );
        }
        let popoverInfo = record?.review_info?.event_timestamp ? (
          <div>
            <p>
              <b>Date</b>: {record?.review_info?.event_timestamp} (
              {daysSince(record?.review_info?.event_timestamp)})
              <br />
              <b>User</b>: {record?.review_info?.updated_by}
            </p>
          </div>
        ) : (
          `No Review`
        );
        return (
          <Popover title={`Last Review Information`} content={popoverInfo}>
            {formatDecisionMakerQAStatus(
              record?.review_info?.value,
              true,
              false
            )}
          </Popover>
        );
      },
    },
    {
      title: "Last User",
      key: "last_user",

      sorter: (a, b) =>
        sortColumnWithNulls(
          a.last_user_info?.updated_by,
          b.last_user_info?.updated_by
        ),
      ...getColumnSearchProps("last_user_info.updated_by", "User"),
      render: (record) => {
        let popoverInfo = record.last_user_info?.updated_by ? (
          <div>
            <p>
              <b>Action</b>: {record.last_user_info?.event_type} <br />
              <b>Date</b>: {record.last_user_info?.event_timestamp} (
              {daysSince(record.last_user_info?.event_timestamp)})
              <br />
              <b>User</b>: {record.last_user_info?.updated_by}
            </p>
          </div>
        ) : null;
        return record.last_user_info?.updated_by ? (
          <Popover title={`Last User Action`} content={popoverInfo}>
            {record.last_user_info?.updated_by?.replace("@applecart.co", "")}
          </Popover>
        ) : null;
      },
    },
  ];

  return (
    <Table
      columns={
        isQA
          ? [...queueGridCommonColumns, ...queueGridQAColumns]
          : queueGridCommonColumns
      }
      dataSource={queueGridData}
      loading={isLoading}
      scroll={{ x: "max-content" }}
    />
  );
};

export default ResearchRequestsQueueGrid;
