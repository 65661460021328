import React from "react";
import { Spin, Table, Tabs } from "antd";
import { ResponsiveBar } from "@nivo/bar";
import {
  isObjectEmpty,
  dedupeArray,
  roundNumberToNDecimals,
} from "../../../utils";
import { GeographyBreakdownTable } from "./GeographyBreakdownTable";

const AllTargetsBreakdown = ({
  audienceBreakdown,
  project,
  isBreakdownLoading,
  geographyBreakdown,
}) => {
  // map target ID to full target name
  const idToNameMapping = project
    ? project.target_project_links.reduce((obj, t) => {
        return { ...obj, [t.id]: t.dm_profile?.full_name };
      }, {})
    : {};
  const totalAudienceSize =
    (audienceBreakdown?.first_degree?.segment_total?.target_total || 0) +
      (audienceBreakdown?.second_degree?.segment_total?.target_total || 0) || 1; // avoid div-by-zero error by making total size 1

  // get all segments and targets
  const firstDegreeTargets = !audienceBreakdown?.first_degree
    ? []
    : Object.keys(audienceBreakdown.first_degree).filter(
        (item) => item !== "segment_total"
      );
  const secondDegreeTargets = !audienceBreakdown?.second_degree
    ? []
    : Object.keys(audienceBreakdown.second_degree).filter(
        (item) => item !== "segment_total"
      );
  const allTargetIDs = [
    ...dedupeArray([...firstDegreeTargets, ...secondDegreeTargets])
      .sort()
      .reverse(),
    "target_total_without_filtering",
    "segment_total",
  ];

  const firstDegreeSegments = !audienceBreakdown?.first_degree
    ? []
    : Object.keys(audienceBreakdown.first_degree.segment_total).filter(
        (item) => item !== "target_total"
      );
  const secondDegreeSegments = !audienceBreakdown?.second_degree
    ? []
    : Object.keys(audienceBreakdown.second_degree.segment_total).filter(
        (item) => item !== "target_total"
      );
  const allSegmentNames = [
    ...dedupeArray([...firstDegreeSegments, ...secondDegreeSegments])
      .sort()
      .reverse(),
    "target_total_without_filtering",
    "target_total",
  ];

  // segment breakdown data
  const segmentBreakdownGraphKeys = project
    ? project.target_project_links
        .map((t) => t.dm_profile?.full_name)
        .filter((item) => item !== undefined)
    : [];
  const segmentBreakdownGraphData = isObjectEmpty(audienceBreakdown)
    ? []
    : allSegmentNames
        .filter((segment) => segment !== "target_total_without_filtering")
        .map((segment) => {
          const firstDegreeTotal =
            audienceBreakdown?.first_degree?.segment_total?.[segment] || 0;
          const secondDegreeTotal =
            audienceBreakdown?.second_degree?.segment_total?.[segment] || 0;
          const totalAudienceMembers =
            firstDegreeTotal + secondDegreeTotal || 1;

          const countData = {
            segment: segment === "target_total" ? "All Segments" : segment,
          };
          allTargetIDs
            .filter((item) => item !== "segment_total")
            .forEach((key) => {
              const firstDegreeCount =
                audienceBreakdown?.first_degree?.[key]?.[segment] || 0;
              const secondDegreeCount =
                audienceBreakdown?.second_degree?.[key]?.[segment] || 0;
              countData[idToNameMapping[key]] = Math.trunc(
                ((firstDegreeCount + secondDegreeCount) /
                  totalAudienceMembers) *
                  100
              );
            });
          return countData;
        });
  const segmentBreakdownTableColumns = [
    {
      title: "Segment Name",
      dataIndex: "segment_name",
    },
    {
      title: "Audience Member Count",
      dataIndex: "audience_member_count",
    },
    {
      title: "Percent First Degree",
      dataIndex: "percent_first_degree",
      render: (val) => `${val}%`,
    },
    {
      title: "Percent of Project Audience",
      dataIndex: "percent_of_project_audience",
      render: (val) => `${val}%`,
    },
  ];

  const segmentBreakdownTableDataRaw = isObjectEmpty(audienceBreakdown)
    ? []
    : allSegmentNames
        .map((segment_name) => {
          const firstDegreeTotal =
            audienceBreakdown?.first_degree?.segment_total?.[segment_name] || 0;
          const secondDegreeTotal =
            audienceBreakdown?.second_degree?.segment_total?.[segment_name] ||
            0;
          const segmentTotal = firstDegreeTotal + secondDegreeTotal;

          const number_of_audience_members_without_filtering =
            audienceBreakdown?.number_of_audience_members_without_filtering;

          if (segment_name === "target_total_without_filtering") {
            return {
              segment_name: "All Segments without Filters",
              audience_member_count:
                number_of_audience_members_without_filtering,
              percent_first_degree: "100.0",
              percent_of_project_audience: "100.0",
            };
          } else {
            return {
              segment_name:
                segment_name === "target_total" ? "All Segments" : segment_name,
              audience_member_count: segmentTotal,
              percent_first_degree: roundNumberToNDecimals(
                (firstDegreeTotal / (segmentTotal || 1)) * 100,
                1
              ).toFixed(1),
              percent_of_project_audience: roundNumberToNDecimals(
                (segmentTotal / (totalAudienceSize || 1)) * 100,
                1
              ).toFixed(1),
            };
          }
        })
        .sort()
        .reverse();

  // rearrange the data
  const segmentBreakdownTableData = segmentBreakdownTableDataRaw;

  // target breakdown data
  const targetBreakdownGraphKeys = allSegmentNames
    .filter((item) => item !== "target_total")
    .filter((item) => item !== "target_total_without_filtering");

  const targetBreakdownGraphData = isObjectEmpty(audienceBreakdown)
    ? []
    : allTargetIDs
        .filter((segment) => segment !== "target_total_without_filtering")
        .map((id) => {
          const firstDegree = audienceBreakdown?.first_degree?.[id];
          const secondDegree = audienceBreakdown?.second_degree?.[id];
          const totalAudienceMembers =
            (firstDegree?.target_total || 0) +
              (secondDegree?.target_total || 0) || 1;

          const countData = {
            target:
              id === "segment_total"
                ? "All Decision Makers"
                : idToNameMapping[id],
          };

          targetBreakdownGraphKeys.forEach((key) => {
            countData[key] = Math.trunc(
              (((firstDegree?.[key] || 0) + (secondDegree?.[key] || 0)) /
                totalAudienceMembers) *
                100
            );
          });
          return countData;
        })
        .sort();

  const targetBreakdownTableColumns = [
    {
      title: "Decision Maker Name",
      dataIndex: "target",
    },
    {
      title: "Audience Member Count",
      dataIndex: "audience_member_count",
    },
    {
      title: "Percent First Degree",
      dataIndex: "percent_first_degree",
      render: (val) => `${val}%`,
    },
    {
      title: "Percent of Project Audience",
      dataIndex: "percent_of_project_audience",
      render: (val) => `${val}%`,
    },
  ];

  const targetBreakdownTableDataRaw = isObjectEmpty(audienceBreakdown)
    ? []
    : allTargetIDs
        .map((id) => {
          const f = audienceBreakdown?.first_degree?.[id]?.target_total || 0;
          const s = audienceBreakdown?.second_degree?.[id]?.target_total || 0;
          const total = f + s;
          const number_of_audience_members_without_filtering =
            audienceBreakdown?.number_of_audience_members_without_filtering;

          if (id === "target_total_without_filtering") {
            return {
              target: "All Decision Makers without Filters",
              audience_member_count:
                number_of_audience_members_without_filtering,
              percent_first_degree: "100.0",
              percent_of_project_audience: "100.0",
            };
          } else {
            return {
              target:
                id === "segment_total"
                  ? "All Decision Makers"
                  : [idToNameMapping[id]],
              audience_member_count: total,
              percent_first_degree: roundNumberToNDecimals(
                (f / (total || 1)) * 100,
                1
              ).toFixed(1),
              percent_of_project_audience: roundNumberToNDecimals(
                ((f + s) / (totalAudienceSize || 1)) * 100,
                1
              ).toFixed(1),
            };
          }
        })
        .sort()
        .reverse();

  // rearrange the data
  const targetBreakdownTableData = targetBreakdownTableDataRaw;

  const targetChartHeight = {
    height: `${String(
      Math.max(
        targetBreakdownGraphData.length * 50,
        segmentBreakdownGraphData.length * 30
      )
    )}px`,
  };
  const segmentChartHeight = {
    height: `${String(
      Math.max(
        segmentBreakdownGraphData.length * 50,
        targetBreakdownGraphData.length * 30
      )
    )}px`,
  };

  const responsiveBarConfig = {
    padding: 0.3,
    layout: "horizontal",
    valueScale: { type: "linear" },
    indexScale: { type: "band", round: true },
    borderRadius: 6,
    borderColor: { from: "color", modifiers: [["darker", 1.6]] },
    axisTop: null,
    axisRight: null,
    axisBottom: null,
    axisLeft: { tickPadding: 10, tickSize: 0 },
    label: (d) => `${d.value}%`,
    labelSkipWidth: 12,
    labelSkipHeight: 12,
    labelTextColor: { from: "color", modifiers: [["darker", 5]] },
    legends: [
      {
        dataFrom: "keys",
        anchor: "top-right",
        direction: "column",
        justify: false,
        translateX: 100,
        translateY: 20,
        itemWidth: 75,
        itemHeight: 30,
        itemDirection: "left-to-right",
        itemOpacity: 0.85,
        symbolSize: 20,
        effects: [
          {
            on: "hover",
            style: { itemOpacity: 1 },
          },
        ],
      },
    ],
  };

  return (
    <Tabs
      type="card"
      style={{ paddingLeft: "10px" }}
      className="all-targets-breakdown-tabs"
    >
      <Tabs.TabPane tab="Segment" key="1">
        <Spin spinning={isBreakdownLoading}>
          <div style={segmentChartHeight}>
            <ResponsiveBar
              data={segmentBreakdownGraphData}
              keys={segmentBreakdownGraphKeys}
              indexBy="segment"
              colors={{ scheme: "set3" }}
              margin={{ top: 0, right: 150, bottom: 0, left: 100 }}
              {...responsiveBarConfig}
            />
          </div>
        </Spin>
        <Table
          bordered
          className="breakdown-table"
          style={{ padding: "20px", paddingTop: "50px" }}
          pagination={false}
          loading={isBreakdownLoading}
          size="small"
          rowKey="segment_name"
          rowClassName={(record, index) =>
            index === 0 ? "table-row-highlighted" : "table-row-light"
          }
          columns={segmentBreakdownTableColumns}
          dataSource={segmentBreakdownTableData.sort(
            (a, b) => b.audience_member_count - a.audience_member_count
          )}
        />
      </Tabs.TabPane>

      <Tabs.TabPane tab="Decision Maker" key="2">
        <Spin spinning={isBreakdownLoading}>
          <div style={targetChartHeight}>
            <ResponsiveBar
              data={targetBreakdownGraphData}
              keys={targetBreakdownGraphKeys}
              indexBy="target"
              colors={{ scheme: "set3" }}
              margin={{ top: 0, right: 150, bottom: 0, left: 150 }}
              {...responsiveBarConfig}
            />
          </div>
        </Spin>
        <Table
          bordered
          className="breakdown-table"
          style={{ padding: "20px", paddingTop: "50px" }}
          pagination={false}
          loading={isBreakdownLoading}
          size="small"
          rowKey="target"
          rowClassName={(record, index) =>
            index === 0 ? "table-row-highlighted" : "table-row-light"
          }
          columns={targetBreakdownTableColumns}
          dataSource={targetBreakdownTableData.sort(
            (a, b) => b.audience_member_count - a.audience_member_count
          )}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Geography" key="3">
        <GeographyBreakdownTable
          showAllTargetsRow
          geographyBreakdown={geographyBreakdown}
          isBreakdownLoading={isBreakdownLoading}
          project={project}
        />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default AllTargetsBreakdown;
