import React from "react";
import { useState } from "react";
import {
  Button,
  Table,
  Drawer,
  InputNumber,
  Spin,
  Form,
  Select,
  Checkbox,
} from "antd";
import {
  showDangerMessage,
  showSuccessMessage,
  showWarningMessage,
} from "../../../utils";
import { states } from "../../../constants";
import { dedupeArray, makeAutocompleteOptions } from "../../../utils";
import { useSelector } from "react-redux";
import { createLocalActivistsAudienceMembers } from "../../../APIClient";
import { useParams } from "react-router-dom";
import { StateDependentSelectionRow } from "../../SearchFormItems/StateDependentSelectionRow";
import { CongDistsByState } from "../../Project/constants/cong_dist";

export const LocalActivistsDrawer = ({
  isVisible,
  setIsVisible,
  refreshPage,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedActivistsSegments, setSelectedActivistsSegments] = useState(
    []
  );
  const [congDistStates, setCongDistStates] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedCongressionalDistricts, setSelectedCongressionalDistricts] =
    useState([]);
  const [isDCMetro, setIsDCMetro] = useState(false);
  const [desiredNumber, setDesiredNumber] = useState(100);

  const { projectId } = useParams();
  const target = useSelector((state) => state.target);
  const projectInfo = {
    project_id: projectId,
    dm_id: target?.dm_id,
    target_id: target?.id,
  };

  const defaultActivistsSegmentNames = [
    "Republican activists",
    "Democratic activists",
  ];

  const formatActivistsSegmentNames = (segments) => {
    return segments.map((segment, index) => {
      return {
        id: index,
        type_name: segment,
      };
    });
  };

  const selectedDefaultActivistsSegments = formatActivistsSegmentNames(
    defaultActivistsSegmentNames
  );

  const defaultSegmentSize = () => {
    const segmentSize = {};
    selectedDefaultActivistsSegments.forEach((segments) => {
      segmentSize[segments["type_name"]] = 100;
    });
    return segmentSize;
  };

  const [
    selectedDefaultActivistsSegmentsSize,
    setSelectedDefaultActivistsSegmentsSize,
  ] = useState(defaultSegmentSize());

  const activistRefresh = () => {
    setCongDistStates([]);
    setSelectedCongressionalDistricts([]);
    setIsDCMetro(false);
  };

  const createAudienceMembers = async () => {
    setIsLoading(true);

    const activistSegmentsRequest = [];
    selectedActivistsSegments.forEach((segments) => {
      const request = {};
      request.segment_type = segments["type_name"];
      request.desired_size =
        selectedDefaultActivistsSegmentsSize[segments["type_name"]];
      activistSegmentsRequest.push(request);
    });
    try {
      const { audience_length: audienceSize, matched_count: matchedCount } =
        await createLocalActivistsAudienceMembers(
          projectInfo,
          dedupeArray(selectedStates.map((v) => v.toLowerCase())),
          activistSegmentsRequest,
          dedupeArray(
            selectedCongressionalDistricts.map((v) => v.toLowerCase())
          ),
          isDCMetro
        );
      if (matchedCount === 0) {
        showDangerMessage(
          `Total of ${audienceSize} audience members were found but we were unable to match any`
        );
      } else if (desiredNumber > matchedCount) {
        showWarningMessage(
          `Total of ${matchedCount} were found/matched and ${audienceSize} audience members were added.`
        );
      } else {
        showSuccessMessage(
          `Total of ${matchedCount} were found/matched and ${audienceSize} audience members were added.`
        );
      }
    } catch (error) {
      alert(error);
    }

    refreshPage();
    setDesiredNumber(100);
    setSelectedDefaultActivistsSegmentsSize(defaultSegmentSize());
    setSelectedActivistsSegments([]);
    setSelectedStates([]);
    setIsLoading(false);
    setIsVisible(false);
    activistRefresh();
  };

  const setDesiredSize = (record, size) => {
    setDesiredNumber(size);
    selectedDefaultActivistsSegmentsSize[record["type_name"]] = size;
  };

  const existingActivistsSegmentsColumns = [
    {
      title: "Segment Type",
      dataIndex: "type_name",
      key: "type_name",
    },
    {
      title: "Desired Size",
      width: 150,
      key: "desired_size",
      render: (text, record) => (
        <InputNumber
          size="large"
          min={1}
          max={10000000}
          defaultValue={desiredNumber}
          value={selectedDefaultActivistsSegmentsSize[record["type_name"]]}
          onChange={(val) => setDesiredSize(record, val)}
        />
      ),
    },
  ];

  const rowSelection = {
    selections: selectedActivistsSegments,
    selectedRowKeys: selectedActivistsSegments.map(
      (segments) => segments["id"]
    ),
    onChange: (key, vals) => setSelectedActivistsSegments(vals),
    type: "checkbox",
  };

  return (
    <Drawer
      title={`Create Activists Segments`}
      onClose={() => setIsVisible(false)}
      visible={isVisible}
      width={800}
      bodyStyle={{ paddingBottom: 20 }}
    >
      <p>
        First choose the location(s) you want to add audience members from. Then
        select the parties you want to include and set the number of audience
        members to create.
      </p>

      <Spin spinning={isLoading}>
        <Form layout="vertical" style={{ paddingBottom: "10px" }}>
          <Form.Item label="States">
            <Select
              showSearch
              allowClear
              mode="multiple"
              placeholder="Select states"
              style={{ width: 350 }}
              onChange={(e) => setSelectedStates(e)}
              value={selectedStates}
              options={makeAutocompleteOptions(states)}
            />
          </Form.Item>
          <Form.Item label="Congressional District">
            <StateDependentSelectionRow
              selectedValues={selectedCongressionalDistricts}
              setSelectedValues={setSelectedCongressionalDistricts}
              selectedStates={congDistStates}
              setSelectedStates={setCongDistStates}
              valuesByState={CongDistsByState}
            />
          </Form.Item>
          <Form.Item>
            <Checkbox
              checked={isDCMetro}
              onChange={(e) => {
                setIsDCMetro(e.target.checked);
              }}
            >
              Include DC Metro Area (DMV Area)
            </Checkbox>
          </Form.Item>
        </Form>
      </Spin>
      <Table
        style={{ paddingBottom: "15px" }}
        dataSource={selectedDefaultActivistsSegments}
        columns={existingActivistsSegmentsColumns}
        pagination={false}
        rowSelection={rowSelection}
        rowKey="id"
      />
      <Spin spinning={isLoading}>
        <Button
          onClick={() => createAudienceMembers()}
          disabled={
            !(selectedActivistsSegments?.length > 0) ||
            !(
              selectedStates?.length > 0 ||
              selectedCongressionalDistricts?.length > 0
            )
          }
        >
          Create Activists Segments
        </Button>
      </Spin>
    </Drawer>
  );
};
