import React, { useEffect } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import Navbar from "../Navbar";
import Project from "../Project";
import ProjectList from "../ProjectList";
import StaffersPage from "../Staffers/StaffersPage";
import { Packages } from "../Packages";
import { InExListsPage } from "../InExLists/InExListsPage";
import { DecisionMakers } from "../DecisionMakers";
import UnitTracking from "../Admin";
import Permissions from "../Permissions";
import Account from "../Account";
import DecisionMakerList from "../DecisionMakers/DecisionMakerList";
import Dashboard from "../AccountSummaryDashboard";
import Destination from "../Destination";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store";
import SingleAccountProject from "../AccountProjects/SingleAccountProject";
import ListAccountProjects from "../AccountProjects/ListAccountProjects";
import ListAccountCampaigns from "../AccountCampaigns/ListAccountCampaigns";
import { AccountMembership } from "../Account/AccountMembership";
import { handlePostLoginRedirect } from "../../util/postLoginRedirect";
import { CompanyGroupsPage } from "../CompanyGroups/CompanyGroupsPage";
import CreateEditCampaign from "../AccountCampaigns/CreateEditCampaign";
import { isProdTechUser } from "../../util/feature_flags/users";

import ResearchRequests from "../DecisionMakers/DecisionMakerResearchRequests/ResearchRequests";

const AuthedContainer = () => {
  const ProjectListPage = () => (
    <Navbar contentClass="landing-page-content">
      <ProjectList />
    </Navbar>
  );

  const dispatch = useDispatch();
  const { updateUserGroupsAction } = bindActionCreators(
    actionCreators,
    dispatch
  );
  const userGroups = useSelector((state) => state.userGroups);

  function gtag() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(arguments);
  }

  const configureGtag = () => {
    if (
      process.env.REACT_APP_IS_BETA === "True" ||
      process.env.REACT_APP_IS_PRODUCTION === "True"
    ) {
      gtag("js", new Date());
      gtag("config", "G-FQZMVHZRRY");
    }
  };

  const setIsProdTechUser = () => {
    if (
      process.env.REACT_APP_IS_BETA === "True" ||
      process.env.REACT_APP_IS_PRODUCTION === "True"
    ) {
      gtag("set", "user_properties", {
        is_prod_tech_user: Number(isProdTechUser(userGroups)),
      });
    }
  };

  useEffect(() => {
    updateUserGroupsAction();
    const interval = setInterval(() => {
      updateUserGroupsAction();
    }, 5 * 60 * 1000);

    configureGtag();
    handlePostLoginRedirect();
    return () => {
      // clean up on dismount
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userGroups?.length && userGroups?.length > 0) {
      setIsProdTechUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userGroups]);

  return (
    <BrowserRouter>
      <Route exact path="/" component={ProjectListPage} />
      <Route exact path="/account" component={Account} />
      <Route
        exact
        path="/account-dashboard/:accountId?"
        component={Dashboard}
      />
      <Route
        exact
        path="/account-projects/:hubspotCompanyId/project/:projectId"
        component={SingleAccountProject}
      />
      <Route
        exact
        path="/account-projects/:accountId?"
        component={ListAccountProjects}
      />
      <Route
        exact
        path="/account-campaigns/:hubspotCompanyId?"
        component={ListAccountCampaigns}
      />
      <Route
        exact
        path="/account-campaigns/:hubspotCompanyId/campaign"
        component={CreateEditCampaign}
      />
      <Route
        exact
        path="/account-campaigns/:hubspotCompanyId/campaign/:campaignId"
        component={CreateEditCampaign}
      />
      <Route exact path="/admin" component={UnitTracking} />
      <Route
        exact
        path="/decision-makers/:decisionMakerId?"
        component={DecisionMakers}
      />
      <Route exact path="/decision-makers-grid" component={DecisionMakerList} />
      <Route
        exact
        path="/decision-makers-grid/project/:projectId?"
        component={DecisionMakerList}
      />
      <Route exact path="/destination/:accountId?" component={Destination} />
      <Route exact path="/inex-lists/:inExListId?" component={InExListsPage} />
      <Route exact path="/packages" component={Packages} />
      <Route exact path="/packages/:packageId" component={Packages} />
      <Route exact path="/permissions" component={Permissions} />
      <Route exact path="/projects/:projectId" component={Project} />
      <Route exact path="/projects" component={ProjectListPage} />
      <Route exact path="/staffers" component={StaffersPage} />
      <Route
        exact
        path="/company-groups/:groupId?"
        component={CompanyGroupsPage}
      />
      <Route exact path="/account-membership" component={AccountMembership} />
      <Route exact path="/research-requests" component={ResearchRequests} />
    </BrowserRouter>
  );
};

export default AuthedContainer;
