import { useEffect, useState } from "react";
import { Modal } from "antd";
import { getPii } from "../../APIClient";
import PersonCard from "../MPSSearch/PersonCard";

export const MPSProfileReviewModal = ({
  personID,
  isMPSProfileReviewModalVisible,
  setIsMPSProfileReviewModalVisible,
}) => {
  const [profileData, setProfileData] = useState({});

  const retrieveMPSProfilePII = async (id) => {
    try {
      const response = await getPii([id]);
      let resp_formatted = await response.map((el) => {
        return {
          first_name: el.first_name,
          middle_name: el.middle_name,
          last_name: el.last_name,
          phones: el.phones,
          emails: el.emails,
          addresses: el.addresses,
          year: el.year,
          month: el.month,
          day: el.day,
          p_id: el.p_id,
        };
      });
      setProfileData(resp_formatted[0]);
      if (response.error) {
        alert(response.error);
      }
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    if (personID) {
      retrieveMPSProfilePII(personID);
    }
  }, [personID]);

  return (
    <>
      <Modal
        visible={isMPSProfileReviewModalVisible}
        cancelButtonProps={{ style: { display: "none" } }}
        onOk={() => setIsMPSProfileReviewModalVisible(false)}
        onCancel={() => setIsMPSProfileReviewModalVisible(false)}
      >
        <PersonCard
          key={`key-${personID}`}
          index={0}
          person={profileData}
          hoverDisabled={true}
        />
      </Modal>
    </>
  );
};
