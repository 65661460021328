const LOCAL_STORAGE_KEY = "postLoginRedirect";

export const setPostLoginRedirect = () => {
  localStorage.setItem(
    LOCAL_STORAGE_KEY,
    JSON.stringify({
      redirect: window.location.pathname,
      timestamp: String(new Date()),
    })
  );
};

export const handlePostLoginRedirect = () => {
  const redirectObj = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
  if (redirectObj && redirectObj.redirect && redirectObj.timestamp) {
    if (new Date() - new Date(redirectObj.timestamp) < 1000 * 60 * 5) {
      window.location.href = redirectObj.redirect;
    }
  }
  localStorage.removeItem(LOCAL_STORAGE_KEY);
};
