import { useContext } from "react";
import {
  Button,
  Popconfirm,
  Typography,
  Row,
  Tag,
  Col,
  Space,
  Popover,
} from "antd";
import { ReloadOutlined, SyncOutlined } from "@ant-design/icons";
import { HeaderWithInfoTip } from "../HeaderWithInfoTip";
import {
  formatDecisionMakerQAStatus,
  formatDecisionMakerNSBuildingStatus,
} from "./DecisionMakerStatus";
import { DecisionMakerContext } from "../DecisionMakerContext";
import { daysSince } from "../../../utils";

export const QualityAssuranceCard = ({
  isDMNetworkBuilding,
  isDMNetworkFailed,
  isLoading,
  onClick,
}) => {
  const { Title } = Typography;

  const { isDecisionMakerQAStatusLoading, qualityAssuranceInfo } =
    useContext(DecisionMakerContext);
  return (
    <>
      <Row style={{ marginLeft: 0 }}>
        <HeaderWithInfoTip
          title={
            <h3 style={{ marginBottom: 0, marginLeft: 0 }}>Most Recent QA</h3>
          }
          message={"Most recent quality assurance timestamp and status"}
        />
      </Row>

      <Row style={{ width: "100%", margin: "20px 0px 30px" }}>
        <Tag
          style={{
            padding: "5px 20px",
            width: "600px",
            borderRadius: "3px",
          }}
        >
          <Row justify="space-between" style={{ alignItems: "center" }}>
            <Col span={9}>
              <Row>
                {((isLoading || isDecisionMakerQAStatusLoading) && (
                  <SyncOutlined />
                )) || (
                  <Space size="large">
                    {qualityAssuranceInfo.event_timestamp && (
                      <Popover
                        content={`Last QA was done ${daysSince(
                          qualityAssuranceInfo.event_timestamp
                        )} by ${qualityAssuranceInfo?.updated_by}`}
                      >
                        <Title level={5} style={{ margin: 0 }}>
                          {qualityAssuranceInfo.event_timestamp}
                        </Title>
                      </Popover>
                    )}
                    {formatDecisionMakerNSBuildingStatus(
                      isDMNetworkBuilding,
                      isDMNetworkFailed
                    ) ||
                      formatDecisionMakerQAStatus(
                        qualityAssuranceInfo?.qa_status
                      )}
                  </Space>
                )}
              </Row>
            </Col>

            <Col span={3} style={{ textAlign: "center" }}>
              <Popconfirm
                title="Are you sure you want to reset most recent QA timestamp?"
                onConfirm={onClick}
                disabled={isDMNetworkBuilding}
              >
                <Button
                  type="link"
                  style={{ paddingLeft: 0, float: "right" }}
                  disabled={isDMNetworkBuilding}
                >
                  Update <ReloadOutlined />
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        </Tag>
      </Row>
    </>
  );
};
