import {
  Button,
  Col,
  Divider,
  Input,
  Row,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import { useState } from "react";
import {
  CloseOutlined,
  EditOutlined,
  PlusCircleOutlined,
  SaveOutlined,
} from "@ant-design/icons";

export const DecisionMakerTitle = ({
  isLoading,
  decisionMaker,
  dmTitle,
  setDmTitle,
  onSave,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const onEdit = () => {
    setIsEditing(true);
  };
  const onCancel = () => {
    setIsEditing(false);
    setDmTitle(decisionMaker.dm_title);
  };
  if (isLoading) {
    return (
      <>
        <Divider orientation="left" plain orientationMargin="0">
          Title
        </Divider>
        <Spin size="small" />
      </>
    );
  }
  return (
    <>
      <Divider orientation="left" plain orientationMargin="0">
        Title
      </Divider>
      <div style={{ width: 600 }}>
        {isEditing ? (
          <Row justify="space-between">
            <Col>
              <Input
                type="text"
                maxLength={128}
                value={dmTitle}
                allowClear
                onChange={(e) => setDmTitle(e.target.value || null)}
                style={{ width: 500 }}
              />
            </Col>
            <Col>
              <Tooltip title="Save">
                <Button
                  type="link"
                  disabled={dmTitle === decisionMaker.dm_title}
                  onClick={() => {
                    onSave();
                    setIsEditing(false);
                  }}
                  style={{ padding: "5px", fontSize: "16px" }}
                >
                  {dmTitle === decisionMaker.dm_title ? (
                    <SaveOutlined style={{ color: "#595959" }} />
                  ) : (
                    <SaveOutlined style={{ color: "#389e0d" }} />
                  )}
                </Button>
              </Tooltip>
              <Tooltip title="Cancel">
                <Button
                  type="link"
                  onClick={onCancel}
                  style={{ padding: "5px", fontSize: "16px" }}
                >
                  <CloseOutlined style={{ color: "#f5222d" }} />
                </Button>
              </Tooltip>
            </Col>
          </Row>
        ) : dmTitle ? (
          <>
            <Row justify={"space-between"}>
              <Col>
                <Typography.Title level={4} style={{ marginBottom: 0 }}>
                  {dmTitle}
                </Typography.Title>
              </Col>
              <Col>
                <Tooltip title="Edit">
                  <Button
                    type="link"
                    style={{
                      color: "#1677ff",
                      padding: "5px",
                      fontSize: "16px",
                    }}
                    onClick={onEdit}
                  >
                    <EditOutlined />
                  </Button>
                </Tooltip>
              </Col>
            </Row>
          </>
        ) : (
          <Button
            type="link"
            onClick={onEdit}
            icon={<PlusCircleOutlined />}
            style={{ marginLeft: 0 }}
          >
            Add Title
          </Button>
        )}
      </div>
    </>
  );
};
