import Navbar from "../Navbar";
import moment from "moment";
import { Table, Space, Row, Select } from "antd";
import { patchClientStatus, getAccountsByDealStageType } from "../../APIClient";
import React, { useEffect, useState } from "react";
import { getColumnSearchProps } from "../../util/columnSearchPropFactory";

const { Option } = Select;
const status_values = ["ACTIVE", "INACTIVE"];

const StatusSelect = ({ record }) => {
  const [status, setStatus] = useState(record.status);

  const onAccountStatusChange = async (hubspot_company_id, value) => {
    try {
      await patchClientStatus(hubspot_company_id, value);
      setStatus(value);
    } catch (error) {
      alert(error);
    }
  };

  return (
    <Select
      defaultValue={record.status}
      style={{ width: "100%" }}
      onChange={(item) => {
        onAccountStatusChange(record.hs_object_id, item);
      }}
      value={status}
    >
      {status_values.map((item, id) => {
        let key = `${record.hs_object_id}_${id}`;
        return (
          <Option key={key} value={item}>
            {item}
          </Option>
        );
      })}
    </Select>
  );
};

const Account = () => {
  const [accountData, setAccountData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const onRefreshTable = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  useEffect(() => {
    retrieveAllAccounts("Deal Won");
  }, []);

  const company_table_columns = [
    {
      title: "ID",
      dataIndex: "hs_object_id",
      sorter: (a, b) => a.hs_object_id.localeCompare(b.hs_object_id),
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      ...getColumnSearchProps("name", "Name"),
    },
    {
      title: "Created Date",
      dataIndex: "createdate",
      sorter: (a, b) => moment(a.createdate) - moment(b.createdate),
      render: (text, record, index) =>
        moment(record.createdate).format("MM-DD-YYYY"),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (select, record) => <StatusSelect record={record} />,
    },
    {
      title: "Type",
      dataIndex: "type",
      sorter: (a, b) => a.type?.localeCompare(b.type),
    },
    {
      title: "Billing Type",
      dataIndex: "billing_type",
      sorter: (a, b) => a.billing_type?.localeCompare(b.billing_type),
    },
  ];

  const retrieveAllAccounts = async (dealStage) => {
    try {
      setIsLoading(true);
      const accountData = await getAccountsByDealStageType(dealStage);
      setAccountData(accountData);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      alert(error);
    }
  };

  return (
    <Navbar contentClass="landing-page-content">
      <Space direction="vertical" size={15} style={{ width: "100%" }}>
        <Row>
          <Table
            style={{ width: "100%" }}
            columns={company_table_columns}
            dataSource={accountData}
            onChange={onRefreshTable}
            loading={isLoading}
          />
        </Row>
      </Space>
    </Navbar>
  );
};

export default Account;
