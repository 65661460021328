import React, { useState } from "react";
import { Form, Row, Col, Checkbox, Select } from "antd";
import { DynamicRow } from "../../DynamicRow/DynamicRow";
import "./MethodSearchForm.css";
import { BirthdaySearchMethodSelector } from "./BirthdaySearchMethodSelector";

const BirthDateRow = ({ searchMethodItems, setSearchMethodItems }) => {
  const searchTypeOptionsMapping = [
    { value: "exact", label: "Exact" },
    { value: "between", label: "Between" },
    { value: "no_later_than", label: "No later than" },
    { value: "no_earlier_than", label: "No earlier than" },
  ];

  const onMethodChange = (field, e) => {
    setSearchMethodItems({ ...searchMethodItems, [field.fieldKey]: e });
  };

  const makeBirthRow = (field, index) => {
    return (
      <>
        <Col>
          <Form.Item
            {...field}
            name={[field.name, "birthday_search_type"]}
            key={[field.name, "birthday_search_type", index]}
            initialValue="exact"
          >
            <Select
              className="method-search-mode-options"
              dropdownStyle={{ minWidth: "120px" }}
              options={searchTypeOptionsMapping}
              onChange={(e) => onMethodChange(field, e)}
            />
          </Form.Item>
        </Col>
        <BirthdaySearchMethodSelector
          field={field}
          index={index}
          type={searchMethodItems[field.fieldKey]}
        />
      </>
    );
  };
  return (
    <Form.List name="birthdays">
      {(fields, { add, remove }) => (
        <DynamicRow
          fields={fields}
          add={add}
          remove={remove}
          makeRow={makeBirthRow}
        />
      )}
    </Form.List>
  );
};

const BirthDateInput = ({ searchMethodItems, setSearchMethodItems }) => {
  const [includeNullDOB, setIncludeNullDOB] = useState(false);

  return (
    <Row
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
      gutter={15}
    >
      <Col>
        <Form.Item name="include_null_dob" valuePropName="checked">
          <Checkbox
            checked={includeNullDOB}
            onChange={() => {
              setIncludeNullDOB(!includeNullDOB);
            }}
          >
            Include Null DOB
          </Checkbox>
        </Form.Item>
      </Col>

      <BirthDateRow
        searchMethodItems={searchMethodItems}
        setSearchMethodItems={setSearchMethodItems}
      />
    </Row>
  );
};

export default BirthDateInput;
