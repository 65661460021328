import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Drawer, Form, Input, Spin } from "antd";
import { getProject, updateProject } from "../../APIClient";

const ProjectDetailsDrawer = () => {
  // redux state

  const isVisible = useSelector(
    (state) => state.isOpenProjectDetailsDrawer.isVisible
  );
  const projectId = useSelector(
    (state) => state.isOpenProjectDetailsDrawer.project_id
  );

  const dispatch = useDispatch();

  // local state
  const [form] = Form.useForm();
  const [projectDetails, setProjectDetails] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const { TextArea } = Input;
  const loadProjectDetail = async () => {
    setIsLoading(true);
    try {
      const resp = await getProject(projectId);
      setProjectDetails(resp.project_details);
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (projectId) {
      loadProjectDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const handleChange = (event) => {
    event.target.value
      ? setProjectDetails(event.target.value)
      : setProjectDetails("");
  };

  const onSubmit = async () => {
    const params = {
      project_details: projectDetails,
    };
    updateProject(projectId, params);
    setProjectDetails("");
    dispatch({
      type: "set-is-open-project-details-drawer",
      payload: { isVisible: false, project_id: null },
    });
  };

  const closeDrawer = () => {
    setProjectDetails("");
    dispatch({
      type: "set-is-open-project-details-drawer",
      payload: { isVisible: false, project_id: null },
    });
  };

  return (
    <Drawer
      title={"Project Details"}
      width={400}
      onClose={() => {
        closeDrawer();
      }}
      visible={isVisible}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div style={{ textAlign: "right" }}>
          <Button onClick={() => closeDrawer()} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <Button
            onClick={onSubmit}
            type="primary"
            htmlType="submit"
            disabled={isLoading}
          >
            Save
          </Button>
        </div>
      }
    >
      <Spin spinning={isLoading}>
        <Form layout="vertical" form={form}>
          <Form.Item label="Project Details">
            <TextArea
              style={{ height: 200 }}
              onChange={handleChange}
              value={projectDetails}
            />
          </Form.Item>
        </Form>
      </Spin>
    </Drawer>
  );
};

export default ProjectDetailsDrawer;
