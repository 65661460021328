import { bindActionCreators } from "redux";
import { actionCreators } from "../../store";
import {
  CheckCircleTwoTone,
  SyncOutlined,
  WarningTwoTone,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { Table, Tooltip } from "antd";
import { titleCase } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useEffect, useState } from "react";

const CurrentExports = ({ getS3DataUploadLogs }) => {
  const [intervalId, setIntervalId] = useState();

  const s3DataUploadLogs = useSelector((state) => state.s3DataUploadLogs);
  const project = useSelector((state) => state.project);

  const dispatch = useDispatch();
  const { getDSPExportFilesAction } = bindActionCreators(
    actionCreators,
    dispatch
  );

  const uploadTaskIsRunning = s3DataUploadLogs.some(
    (r) => r.status !== "COMPLETED"
  );

  const fetchDSPExportFiles = async () => {
    try {
      getDSPExportFilesAction(project.id);
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    let interval;
    if (project && uploadTaskIsRunning) {
      interval = setInterval(() => {
        getS3DataUploadLogs();
        fetchDSPExportFiles();
      }, 10 * 1000);
      setIntervalId(interval);
    } else {
      // use the local state to properly clean up on task completion, local var won't be set
      setIntervalId();
      clearInterval(intervalId);
    }
    return () => {
      // use the local var to properly clean up on dismount, local state won't be updated
      setIntervalId();
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, uploadTaskIsRunning]);

  const audienceExportColumns = [
    {
      render: (row) => {
        if (row.status !== "COMPLETED") {
          return <SyncOutlined spin />;
        } else if (row.result_state === "SUCCESS") {
          return <CheckCircleTwoTone twoToneColor="#52c41a" />;
        } else if (row.result_state === "FAILED") {
          return <WarningTwoTone twoToneColor="red" />;
        }
      },
    },
    {
      title: () => {
        return (
          <span>
            Started
            <Tooltip
              title={`Local Timezone: ${
                Intl.DateTimeFormat().resolvedOptions().timeZone
              }`}
            >
              <InfoCircleOutlined style={{ marginLeft: 10 }} />
            </Tooltip>
          </span>
        );
      },
      dataIndex: "created_on",
      render: (val) => {
        return moment
          .utc(val)
          .utcOffset(moment().utcOffset())
          .format("MM-DD-YYYY hh:mm A")
          .toString();
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 250,
    },
    {
      title: "Result",
      dataIndex: "result_state",
      width: 200,
    },
    {
      title: "S3 Link",
      dataIndex: "url",
      width: 150,
      render: (val) =>
        val && (
          <a href={val} target="_blank" rel="noreferrer">
            Download Data
          </a>
        ),
    },
  ];

  const exportFileColumns = [
    {
      title: "File Name",
      dataIndex: "file_name",
      render: (val, row) => (
        <a href={row.s3_location} target="_blank" rel="noreferrer">
          {val}
        </a>
      ),
    },
    {
      title: "Folder Name",
      dataIndex: "folder_name",
      render: (val, row) =>
        row.key_name.replace(row.file_name, "").split("/")[1],
    },
    {
      title: "Total Row Count",
      dataIndex: "row_count",
      render: (val) => val?.toLocaleString(),
    },
    {
      title: "Distinct Row Count",
      dataIndex: "distinct_row_count",
      render: (val) => val?.toLocaleString(),
    },
    {
      title: "Flooded",
      dataIndex: "is_flooded",
      render: (val) => titleCase(String(val)),
    },
  ];
  return (
    <>
      <Table
        columns={audienceExportColumns}
        dataSource={s3DataUploadLogs}
        rowKey="id"
        virtual
        scroll={{ x: "max-content", y: "60vh" }}
        expandable={{
          expandedRowRender: (row) => (
            <Table
              rowKey="id"
              pagination={false}
              size="small"
              dataSource={row.dsp_export_files}
              columns={exportFileColumns}
            />
          ),
          rowExpandable: (row) => row.dsp_export_files.length > 0,
        }}
      />
    </>
  );
};

export default CurrentExports;
