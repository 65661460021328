import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import reducers from "./reducers";

export const store = createStore(reducers, {}, applyMiddleware(thunk));

export const createStoreWithInitialState = (initialState) =>
  createStore(reducers, initialState, applyMiddleware(thunk));

const AppProvider = ({ children }) => (
  <Provider store={store}>{children}</Provider>
);

export default AppProvider;
