import Navbar from "../Navbar";
import React from "react";
import { Table, Space, Checkbox } from "antd";
import { account_permissions } from "../../util/feature_flags/users";

const Permissions = () => {
  const account_permissions_columns = [
    {
      title: "Team",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Account Summary",
      dataIndex: "account_summary",
      key: "account_summary",
      render: (value, row) => <Checkbox defaultChecked={value} disabled />,
    },
    {
      title: "Active Projects",
      dataIndex: "active_projects",
      key: "active_projects",
      render: (value, row) => <Checkbox defaultChecked={value} disabled />,
    },
    {
      title: "Active Projects",
      dataIndex: "destinations",
      key: "destinations",
      render: (value, row) => <Checkbox defaultChecked={value} disabled />,
    },
    {
      title: "Campaigns",
      dataIndex: "campaign",
      key: "campaign",
      render: (value, row) => <Checkbox defaultChecked={value} disabled />,
    },
  ];

  const decision_maker_permissions_columns = [
    {
      title: "Team",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Projects",
      dataIndex: "projects",
      key: "projects",
      render: (value, row) => <Checkbox defaultChecked={value} disabled />,
    },
    {
      title: "Decision Makers",
      dataIndex: "decision_makers",
      key: "decision_makers",
      render: (value, row) => <Checkbox defaultChecked={value} disabled />,
    },
    {
      title: "Staffers",
      dataIndex: "staffers",
      key: "staffers",
      render: (value, row) => <Checkbox defaultChecked={value} disabled />,
    },
    {
      title: "InEx Lists",
      dataIndex: "in_ex_lists",
      key: "in_ex_lists",
      render: (value, row) => <Checkbox defaultChecked={value} disabled />,
    },
    {
      title: "Packages",
      dataIndex: "packages",
      key: "packages",
      render: (value, row) => <Checkbox defaultChecked={value} disabled />,
    },
  ];

  const unit_tracking_permissions_columns = [
    {
      title: "Team",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Unit Tracking",
      dataIndex: "unit_tracking",
      key: "unit_tracking",
      render: (value, row) => <Checkbox defaultChecked={value} disabled />,
    },
  ];

  const account_permissions_list = Object.entries(account_permissions).map(
    ([key, value]) => value
  );

  return (
    <Navbar contentClass="landing-page-content">
      <Space direction="vertical" size={15} style={{ width: "100%" }}>
        <Table
          dataSource={account_permissions_list}
          columns={account_permissions_columns}
          pagination={false}
        />
        <Table
          dataSource={account_permissions_list}
          columns={decision_maker_permissions_columns}
          pagination={false}
        />

        <Table
          dataSource={account_permissions_list}
          columns={unit_tracking_permissions_columns}
          pagination={false}
        />
      </Space>
    </Navbar>
  );
};

export default Permissions;
