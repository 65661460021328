import { titleCase } from "../../utils";

export const formatSaffersForGrid = (staffers) => {
  return staffers
    ?.map(
      ({
        id,
        source_id,
        p_id,
        legislator_first_name,
        legislator_last_name,
        district,
        first_name,
        middle_initial,
        last_name,
        chamber,
        state,
        phone_numbers,
        emails,
        start_year,
        end_year,
        title,
        date_of_birth,
        is_current,
        title_rank,
        most_recent_attempt,
        needs_qa,
        most_recent_qa,
      }) => {
        return {
          key: id,
          source_id: source_id,
          p_id,
          legislator: `${titleCase(legislator_first_name)} ${titleCase(
            legislator_last_name
          )}`,
          staffer_first_name: titleCase(first_name),
          staffer_middle_initial: middle_initial?.toUpperCase() || "NA",
          staffer_last_name: titleCase(last_name),
          legislator_chamber: chamber,
          legislator_state: state?.toUpperCase(),
          legislator_district: district,
          staffer_start_year: start_year,
          staffer_end_year: end_year,
          staffer_emails: emails,
          staffer_phone_numbers: phone_numbers,
          staffer_title: title,
          staffer_dob: date_of_birth ? date_of_birth : "NA",
          is_current,
          title_rank: title_rank ? title_rank : "NA",
          most_recent_attempt: most_recent_attempt,
          needs_qa,
          most_recent_qa,
        };
      }
    )
    .sort((a, b) => a.staffer_first_name.localeCompare(b.staffer_first_name))
    .sort((a, b) => a.legislator.localeCompare(b.legislator));
};
