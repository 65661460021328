import { Table, Input, Button } from "antd";
import { PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { useState } from "react";
import ReviewDrawer from "./ReviewDrawer";

import { getAudienceBreakdown, updateTargets } from "../../APIClient";
import { useDispatch, useSelector } from "react-redux";
import { showSuccessMessage } from "../../utils";

import { merge, keyBy } from "lodash";
const COMBINED = "COMBINED";

const SegmentationUI = ({
  tpls,
  setTpls,
  uniqueGroupNames,
  setRefreshTpls,
}) => {
  const [newGroupName, setNewGroupName] = useState("");
  const [newGroupTplId, setNewGroupTplId] = useState(null);
  const [toggleBreakdown, setToggleBreakdown] = useState(false);

  const getTpl = (tplId) => tpls.filter((tpl) => tpl["id"] === tplId)[0];
  const [selectedRowKeys, setSelectedRowKeys] = useState(
    tpls.filter((tpl) => tpl["selected_for_export"]).map((tpl) => tpl["id"])
  );
  const toggleGroupSelection = (tplId, groupName) => {
    if (isGroupSelected(tplId, groupName)) {
      removeGroupFromTarget(tplId, groupName);
    } else {
      addGroupToTarget(tplId, groupName);
    }
  };

  const isGroupSelected = (tplId, groupName) => {
    return getTpl(tplId)["groups"].includes(groupName);
  };

  const dispatch = useDispatch();
  const updateTpls = (updatedTpl) => {
    const newTpls = tpls.map((tpl) => {
      return tpl.id === updatedTpl.id ? updatedTpl : tpl;
    });
    setTpls(newTpls);
  };

  const validateGroupName = (group) =>
    group
      .trim()
      .toUpperCase()
      .replace(/[^0-9a-z_]/gi, "");

  const addGroupToTarget = (tplId, groupName) => {
    const group = validateGroupName(groupName);
    if (group !== "") {
      const tpl = getTpl(tplId);
      tpl["groups"] = tpl["groups"].concat(group);
      updateTpls(tpl);
    }
  };

  const removeGroupFromTarget = (tplId, groupName) => {
    const tpl = getTpl(tplId);
    tpl["groups"] = tpl["groups"].filter((group) => group !== groupName);
    updateTpls(tpls);
  };

  const keyHandler = (e) => {
    if (e.key === "Escape") {
      setNewGroupTplId(null);
      e.stopPropagation();
    } else if (e.key === "Enter") {
      addGroupToTarget(newGroupTplId, newGroupName);
      setNewGroupName("");
      setNewGroupTplId(null);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => setSelectedRowKeys(selectedRowKeys),
  };

  const updateTargetSelectedForExport = () => {
    const newTpls = tpls.map((tpl) => {
      tpl["selected_for_export"] = isTargetSelectedForExport(tpl.id);
      return tpl;
    });
    setTpls(newTpls);
    setRefreshTpls(true);
  };

  const groupingColumns = [
    {
      title: "Decision Maker",
      dataIndex: "name",
      fixed: "left",
    },
    {
      title: "Groups",
      render: (row) => {
        return (
          <>
            {uniqueGroupNames
              .filter((group) => group !== COMBINED)
              .map((group) => (
                <Button
                  key={`g_${uniqueGroupNames.indexOf(group)}`}
                  type={isGroupSelected(row["id"], group) ? "primary" : ""}
                  style={{ margin: 5 }}
                  onClick={(e) => toggleGroupSelection(row["id"], group)}
                >
                  {group}
                </Button>
              ))}
          </>
        );
      },
    },
    {
      render: (row) => {
        return (
          <>
            <Button
              type="link"
              className="add-row-button"
              hidden={row["id"] === newGroupTplId}
              onClick={() => setNewGroupTplId(row["id"])}
            >
              <PlusOutlined /> New Group
            </Button>
            <Input
              placeholder="CUSTOM"
              value={newGroupName}
              hidden={row["id"] !== newGroupTplId}
              autoFocus={true}
              style={{ textTransform: "uppercase" }}
              onKeyDown={keyHandler}
              onChange={(e) => setNewGroupName(e.target.value)}
            />
          </>
        );
      },
    },
  ];

  const projectId = useSelector((state) => state.project?.id);

  const reFetchAudienceBreakdown = async () => {
    try {
      const resp = await getAudienceBreakdown(projectId);
      dispatch({
        type: "get-breakdown",
        payload: resp,
      });
    } catch (error) {
      alert(error);
    }
  };

  const isTargetSelectedForExport = (id) => {
    return selectedRowKeys.includes(id);
  };
  const onSave = async () => {
    try {
      const res = await updateTargets(projectId, {
        new_values: tpls.map((tpl) => {
          return {
            id: tpl.id,
            groups: tpl.groups,
            selected_for_export: isTargetSelectedForExport(tpl.id),
          };
        }),
      });
      const merged = merge(
        keyBy(res, "id"),
        keyBy(
          tpls.map(({ groups, ...tpl }) => tpl),
          "id"
        )
      );
      setTpls(Object.values(merged));
      updateTargetSelectedForExport();
      await reFetchAudienceBreakdown();
      showSuccessMessage("Decision Makers Segments updated Successfully!");
    } catch (error) {
      alert(error);
    }
  };

  return (
    <>
      <Table
        columns={groupingColumns}
        rowSelection={rowSelection}
        virtual
        scroll={{ x: "max-content", y: "60vh" }}
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "30", "50", "100"],
          showTotal: () => (
            <>
              <Button
                type="primary"
                icon={<SaveOutlined />}
                style={{ marginRight: 10, width: "10rem" }}
                onClick={onSave}
              >
                Save
              </Button>
              <Button onClick={() => setToggleBreakdown(!toggleBreakdown)}>
                {!toggleBreakdown ? "Show" : "Close"} Breakdown
              </Button>
            </>
          ),
        }}
        dataSource={tpls.sort(function (a, b) {
          return a.name.localeCompare(b.name);
        })}
        key="id"
      />
      {toggleBreakdown && (
        <ReviewDrawer
          tpls={tpls}
          uniqueGroupNames={[COMBINED, ...uniqueGroupNames]}
        />
      )}
    </>
  );
};

export default SegmentationUI;
