import {
  List,
  Typography,
  Row,
  Form,
  Space,
  Button,
  Input,
  Col,
  Popconfirm,
  Tooltip,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  SaveOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { formatTimestamp } from "../../../utils";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  deleteDecisionMakerNote,
  updateDecisionMakerNote,
} from "../../../APIClient";
export const DecisionMakerNoteListItem = ({
  item,
  fetchDecisionMakerNotes,
}) => {
  const [noteEditForm] = useForm();
  const [isEditable, setIsEditable] = useState(false);
  const decisionMaker = useSelector((state) => state.decisionMaker);
  const { TextArea } = Input;
  const note = Form.useWatch("note", noteEditForm);

  const onSave = async (id) => {
    try {
      const noteValue = noteEditForm.getFieldsValue();
      const response = await updateDecisionMakerNote(
        id,
        decisionMaker.id,

        noteValue.note
      );
      if (response.id) {
        setIsEditable(false);
        noteEditForm.resetFields();
        fetchDecisionMakerNotes();
      }
      if (response.error) {
        alert(response.error);
      }
    } catch (error) {
      alert(error);
    }
  };

  const onDelete = async (id) => {
    try {
      const response = await deleteDecisionMakerNote(decisionMaker.id, id);
      if (response) {
        fetchDecisionMakerNotes();
      }
      if (response.error) {
        alert(response.error);
      }
    } catch (error) {
      alert(error);
    }
  };

  const EditButtons = ({ item }) => {
    return (
      <Space gap="large">
        <Tooltip title="Cancel">
          <Button
            style={{ padding: "3px" }}
            type="link"
            onClick={() => {
              setIsEditable(false);
              noteEditForm.setFieldsValue({ note: item.note });
            }}
          >
            <CloseOutlined />
          </Button>
        </Tooltip>

        <Tooltip title="Save">
          <Button
            style={{ padding: "3px" }}
            type="link"
            onClick={() => onSave(item.id)}
            disabled={!note || note === item.note}
          >
            {!note || note === item.note ? (
              <SaveOutlined style={{ color: "#595959" }} />
            ) : (
              <SaveOutlined style={{ color: "#389e0d" }} />
            )}
          </Button>
        </Tooltip>
      </Space>
    );
  };

  const SaveButtons = ({ item }) => {
    return (
      <Space size="small">
        <Tooltip title="Edit">
          <Button
            type="link"
            onClick={() => {
              setIsEditable(!isEditable);
              noteEditForm.setFieldsValue({ note: item.note });
            }}
            style={{ padding: "3px" }}
          >
            <EditOutlined style={{ color: "#1677ff" }} />
          </Button>
        </Tooltip>
        <Popconfirm
          placement="bottom"
          title="Are you sure you want to delete the note?"
          onConfirm={() => {
            onDelete(item.id);
          }}
        >
          <Tooltip title="Delete">
            <Button type="link" style={{ padding: "3px" }}>
              <DeleteOutlined style={{ color: "#f5222d" }} />
            </Button>
          </Tooltip>
        </Popconfirm>
      </Space>
    );
  };

  return (
    <List.Item
      style={{
        minHeight: 100,
        marginTop: 25,
        flexDirection: "column",
        alignItems: "start",
      }}
    >
      {isEditable ? (
        <Row>
          <Form
            form={noteEditForm}
            initialValues={{ note: item.note }}
            style={{ paddingBottom: 0 }}
          >
            <Form.Item name="note">
              <TextArea
                maxLength={5000}
                style={{ minWidth: "650px", padding: 0, marginTop: 0 }}
                autoSize
              />
            </Form.Item>
            <Row justify="end"></Row>
          </Form>
        </Row>
      ) : (
        <Typography.Paragraph style={{ whiteSpace: "pre-line" }}>
          {item?.note}
        </Typography.Paragraph>
      )}
      <List.Item.Meta
        style={{
          minWidth: "850px",
          paddingTop: 0,
        }}
        description={
          <Row align="middle">
            <Space size="large" style={{ fontSize: "12px" }}>
              <Col>
                {`${item?.created_by} | created ${formatTimestamp(
                  item?.created_on
                )}` +
                  (item.updated_on
                    ? ` | edited ${formatTimestamp(item?.updated_on)}`
                    : ``)}
              </Col>
              <Col>
                {isEditable ? (
                  <EditButtons item={item} />
                ) : (
                  <SaveButtons item={item} />
                )}
              </Col>
            </Space>
          </Row>
        }
      />
    </List.Item>
  );
};
