import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { get } from "lodash";

export const getColumnSearchProps = (dataIndex, title) => {
  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div
        style={{
          padding: 8,
          borderRadius: "5px",
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          allowClear={true}
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          autoFocus
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({ closeDropdown: false });
          }}
          onPressEnter={() => confirm()}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          backgroundColor: filtered ? "#1890ff" : undefined,
          color: filtered ? "whitesmoke" : undefined,
          fontSize: "15px",
          borderRadius: "100px",
          padding: "4px",
        }}
      />
    ),
    onFilter: (value, record) =>
      get(record, dataIndex)
        ?.toString()
        ?.toLowerCase()
        ?.includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => {}, 100);
      }
    },
  };
};
