import { useContext, useState } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal, Input } from "antd";
import { deleteCompanyGroup } from "../../../APIClient";
import { showSuccessMessage } from "../../../utils";
import { CompanyGroupsContext } from "../CompanyGroupsPage";

export const DeleteGroupModal = ({ setIsVisible, isVisible }) => {
  const [typeDeleteText, setTypeDeleteText] = useState("");
  const { selectedGroup, configureGroup } = useContext(CompanyGroupsContext);

  const deleteGroup = async () => {
    try {
      const response = await deleteCompanyGroup(selectedGroup.id);
      if (response) {
        configureGroup();
        showSuccessMessage("Successfully deleted");
      }
      if (response.error) {
        alert(response.error);
      }
    } catch (error) {
      alert(error);
    }
  };

  const handleOk = () => {
    deleteGroup(selectedGroup.id);
    setIsVisible(false);
    setTypeDeleteText("");
  };

  const handleCancel = () => {
    setIsVisible(false);
    setTypeDeleteText("");
  };

  return (
    <Modal
      visible={isVisible}
      okText="Yes"
      okType="danger"
      onCancel={handleCancel}
      onOk={handleOk}
      okButtonProps={{
        disabled: !(typeDeleteText === "DELETE") ? true : false,
      }}
      title={
        <>
          <ExclamationCircleOutlined
            style={{ marginRight: "8px", color: "#ff4d4f" }}
          />
          Are you sure?
        </>
      }
    >
      {`You are about to delete this group, please type DELETE to confirm`}
      <p>
        <Input
          value={typeDeleteText}
          onChange={(e) => setTypeDeleteText(e.target.value)}
          style={{ marginTop: "20px", width: "200px" }}
          placeholder="Type DELETE to proceed"
        />
      </p>
    </Modal>
  );
};
