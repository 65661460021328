import { useContext } from "react";
import { Select } from "antd";
import { CompanyGroupsContext } from "./CompanyGroupsPage";
import { debounce } from "lodash";
import { getCompanyGroups } from "../../APIClient";

export const GroupSelector = ({ textAlign = "left" }) => {
  const {
    selectedGroup,
    groupNames,
    setGroupNames,
    configureGroup,
    setIsLoading,
    isLoading,
  } = useContext(CompanyGroupsContext);

  const callFindEASGroupsDebounced = debounce(async (val) => {
    setIsLoading(true);
    try {
      const data = await getCompanyGroups(val);
      setGroupNames(data);
    } catch (e) {
      alert(e);
    }
    setIsLoading(false);
  }, 500);

  const onSearch = async (val) => {
    if (val && val.length >= 2) {
      callFindEASGroupsDebounced(val);
    }
  };

  const onChange = (value) => {
    if (value) {
      let val = JSON.parse(value);
      configureGroup(val.id);
    }
  };

  return (
    <Select
      showSearch
      allowClear
      onClear={() => configureGroup()}
      placeholder="Choose a Group"
      value={selectedGroup?.name}
      onSearch={onSearch}
      loading={isLoading}
      filterOption={(input, option) =>
        (option?.label?.toLowerCase() ?? "").includes(input?.toLowerCase())
      }
      style={{ minWidth: 200, maxWidth: 300, textAlign: textAlign }}
      options={groupNames.map((item) => ({
        value: JSON.stringify(item),
        label: item.name,
      }))}
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? "")
          .toLowerCase()
          .localeCompare((optionB?.label ?? "").toLowerCase())
      }
      onChange={onChange}
    />
  );
};
