import { formatTimestamp } from "../../../utils";
import {
  determineComprehensiveReviewStatus,
  determineMostRecentEvent,
} from "../QualityAssurance/DecisionMakerStatus";

export const processQaInformation = (record) => {
  let qaInfo = {};
  if (record?.is_building) {
    qaInfo.value = "building";
    qaInfo.updated_by = null;
    qaInfo.event_timestamp = null;
    return qaInfo;
  }
  if (record?.is_failed) {
    qaInfo.value = "failed";
    qaInfo.updated_by = null;
    qaInfo.event_timestamp = null;
    return qaInfo;
  }
  let qaStatus =
    record?.qa_status === "outdated" ? "edited" : record?.qa_status;
  qaInfo.value = record?.qa_events?.qa?.is_expired ? "expired" : qaStatus;
  qaInfo.updated_by = record?.qa_events?.qa?.updated_by;
  qaInfo.event_timestamp = formatTimestamp(
    record?.qa_events?.qa?.event_timestamp
  );
  return qaInfo;
};

export const processReviewInformation = (record) => {
  let reviewInfo = {};
  if (record?.is_building) {
    reviewInfo.value = "building";
    reviewInfo.updated_by = null;
    reviewInfo.event_timestamp = null;
    return reviewInfo;
  }
  if (record?.is_failed) {
    reviewInfo.value = "failed";
    reviewInfo.updated_by = null;
    reviewInfo.event_timestamp = null;
    return reviewInfo;
  }
  let info = determineComprehensiveReviewStatus(record?.qa_events);
  reviewInfo.value = info?.review_status;
  reviewInfo.updated_by = info?.updated_by;
  reviewInfo.event_timestamp = info?.event_timestamp;
  return reviewInfo;
};

export const processLastUserInformation = (record) => {
  let lastUsertInfo = {};
  let lastEvent = determineMostRecentEvent(record?.qa_events);
  lastUsertInfo.event_type = lastEvent?.type?.toUpperCase();
  lastUsertInfo.event_timestamp = formatTimestamp(lastEvent?.event_timestamp);
  lastUsertInfo.updated_by = lastEvent?.updated_by;
  return lastUsertInfo;
};

export const selectDecisionMakerStatusFilters = (isQA = true) =>
  [
    {
      text: isQA ? "No QA" : "No Review",
      value: "no_qa",
    },
    {
      text: "Edited",
      value: "edited",
    },
    {
      text: "Building",
      value: "building",
    },
    {
      text: "Build failed",
      value: "failed",
    },
    {
      text: "Expired",
      value: "expired",
    },
  ].sort((a, b) => b.text.localeCompare(a.text));
