import { InfoCircleOutlined } from "@ant-design/icons";
import { Row, Tooltip } from "antd";
import React from "react";

export const HeaderWithInfoTip = ({ title, message }) => {
  return (
    <Row align="middle">
      {title}&nbsp;&nbsp;
      <Tooltip title={message}>
        <InfoCircleOutlined />
      </Tooltip>
    </Row>
  );
};
