import { Button, Tabs, Typography } from "antd";
import Navbar from "../../Navbar";
import styles from "./ResearchRequests.module.css";
import { useEffect, useState } from "react";
import {
  getDMNetworkBuildingStatus,
  getEASCompanies,
  getResearchRequests,
} from "../../../APIClient";
import ResearchRequestsQueueGrid from "./ResearchRequestsQueueGrid";
import {
  processLastUserInformation,
  processQaInformation,
  processReviewInformation,
} from "./ResearchRequestsColumnProcessFunctions";
import { useSelector } from "react-redux";
import {
  ALL_GROUPS,
  isUserInAllowedGroups,
} from "../../../util/feature_flags/users";
import RequestsAdminGrid from "./RequestsAdminGrid";

const ResearchRequests = () => {
  const userGroups = useSelector((state) => state.userGroups);
  const { Title } = Typography;
  const [researchQueueGridData, setResearchQueueGridData] = useState([]);
  const [qaQueueGridData, setQAQueueGridData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchResearchRequests = async () => {
    setIsLoading(true);
    try {
      const researchRequestData = await getResearchRequests();
      if (researchRequestData.length > 0) {
        const organizationData = await getEASCompanies([
          ...new Set(
            researchRequestData
              .map((item) => item.company_id)
              .filter((company_id) => company_id)
          ),
        ]);

        const networkStatusData = await getDMNetworkBuildingStatus(
          researchRequestData.map((item) => item.decision_maker_id)
        );

        const combinedData = researchRequestData.map((researchRequestItem) => ({
          key: researchRequestItem.id,
          ...researchRequestItem,
          ...organizationData.find(
            (organizationItem) =>
              organizationItem.company_id === researchRequestItem.company_id
          ),
          ...networkStatusData.find(
            (networkStatusItem) =>
              networkStatusItem.dm_id === researchRequestItem.decision_maker_id
          ),
        }));
        const combinedProcessedData = combinedData.map((item) => ({
          ...item,
          qa_info: processQaInformation(item),
          review_info: processReviewInformation(item),
          last_user_info: processLastUserInformation(item),
        }));

        setResearchQueueGridData(
          combinedProcessedData.filter((item) => item.is_researched === false)
        );
        setQAQueueGridData(
          combinedProcessedData.filter((item) => item.is_researched === true)
        );
      } else {
        setResearchQueueGridData();
        setQAQueueGridData();
      }
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchResearchRequests();
  }, []);

  const tabContent = [
    {
      key: "request",
      label: "Requests Admin",
      children: <RequestsAdminGrid />,
    },
    {
      key: "research",
      label: "Research",
      children: (
        <ResearchRequestsQueueGrid
          isLoading={isLoading}
          queueGridData={researchQueueGridData}
        />
      ),
    },
    {
      key: "qa",
      label: "QA",
      children: (
        <ResearchRequestsQueueGrid
          isLoading={isLoading}
          queueGridData={qaQueueGridData}
          isQA={true}
        />
      ),
    },
  ];

  const filterNewRequest = (value) => {
    return isUserInAllowedGroups(userGroups, [
      ALL_GROUPS.TECH,
      ALL_GROUPS.PRODUCT,
      ALL_GROUPS.TARGETING_ASSOCIATES,
    ])
      ? true
      : value.key !== "request";
  };

  return (
    <Navbar>
      <div className={styles.container}>
        <Title level={3} style={{ marginBottom: 40 }}>
          Decision Maker Research Requests
        </Title>
        <Tabs
          size="large"
          type="card"
          defaultActiveKey="research"
          tabBarExtraContent={
            <Button onClick={fetchResearchRequests}>Refresh</Button>
          }
        >
          {tabContent
            .filter(filterNewRequest)
            .map(({ key, label, children }) => (
              <Tabs.TabPane tab={label} key={key}>
                {children}
              </Tabs.TabPane>
            ))}
        </Tabs>
      </div>
    </Navbar>
  );
};

export default ResearchRequests;
