import React, { useEffect, useState } from "react";
import { Row, Col, Form, Select, Button, Input, Spin } from "antd";
import { findEASCompanies } from "../../../APIClient";
import { debounce } from "lodash";
import { checkFormChanged } from "../../../utils";

const ConnectionTypeForm = ({
  attachHVCtoDecisionMakerFunc,
  updateHVCForDecisionMakerFunc,
  form,
  connectionTypes,
  staffTitles,
  selectedDecisionMakerAsHVC,
  initialValues,
}) => {
  const { Option } = Select;

  const connectionTypeValue = Form.useWatch(["connection_type"], form);
  const isCurrentValue = Form.useWatch(["is_current"], form);
  const urlValue = Form.useWatch(["web_url"], form);
  const companyValue = Form.useWatch(["company"], form);
  const titleValue = Form.useWatch(["title"], form);

  const isHereForUpdate = updateHVCForDecisionMakerFunc ? true : false;

  const [companies, setCompanies] = useState([]);
  const [loading, setloading] = useState(false);

  const callFindEASCompaniesDebounced = debounce(async (val) => {
    setloading(true);
    try {
      const data = await findEASCompanies(val);
      setCompanies(data);
    } catch (e) {
      alert(e);
    }
    setloading(false);
  }, 1000);

  const onSearch = async (val) => {
    if (val && val.length > 2) {
      callFindEASCompaniesDebounced(val);
    }
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDecisionMakerAsHVC]);

  return (
    <>
      {selectedDecisionMakerAsHVC && (
        <Form
          layout="vertical"
          form={form}
          onFinish={(values) => {
            isHereForUpdate
              ? updateHVCForDecisionMakerFunc(
                  values.connection_type,
                  values.is_current,
                  values.company,
                  values.web_url,
                  values.title
                )
              : attachHVCtoDecisionMakerFunc(
                  values.connection_type,
                  values.is_current,
                  values.company,
                  values.web_url,
                  values.title
                );
          }}
        >
          <Row>
            <Col>
              <Form.Item name="connection_type" label="Connection Type">
                <Select
                  placeholder="Connection type"
                  style={{ width: "300px" }}
                >
                  {connectionTypes.map((connection) => (
                    <Select.Option
                      key={`connection-${connectionTypes.indexOf(connection)}`}
                      value={connection}
                    >
                      {connection}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Form.Item name="is_current" label="Is Current?">
              <Select
                style={{ width: "200px" }}
                placeholder="Current"
                options={[
                  { value: true, label: "Current" },
                  { value: false, label: "Former" },
                ]}
              ></Select>
            </Form.Item>
          </Row>
          <Row>
            <Form.Item name="title" label="Connection Title">
              {connectionTypeValue === "Staff" ? (
                <Select
                  placeholder="Select Connection Title"
                  style={{ width: "300px" }}
                >
                  {staffTitles.map((title) => (
                    <Select.Option
                      key={`title-${staffTitles.indexOf(title)}`}
                      value={title}
                    >
                      {title}
                    </Select.Option>
                  ))}
                </Select>
              ) : (
                <Input
                  placeholder="Enter Connection Title"
                  style={{ width: "300px" }}
                  maxLength={120}
                />
              )}
            </Form.Item>
          </Row>
          <Row>
            <Form.Item name="company" label="Company">
              <Select
                showSearch
                style={{ width: "400px" }}
                placeholder="Select a Company"
                optionFilterProp="children"
                filterOption={false}
                loading={loading}
                notFoundContent={null}
                onSearch={onSearch}
                allowClear
              >
                {loading ? (
                  <Option
                    disabled={true}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <Spin tip="Loading results" />
                  </Option>
                ) : (
                  <>
                    {companies?.length > 0 && (
                      <Option
                        key="disabled result"
                        disabled={true}
                        style={{ fontWeight: "bold" }}
                      >
                        {companies?.length} results found
                      </Option>
                    )}
                    {companies
                      ?.sort((a, b) =>
                        a.employee_count > b.employee_count ? -1 : 1
                      )
                      .map((c) => (
                        <Option key={c.company_id} value={c.company_name}>
                          {c.company_name} ({c.employee_count?.toLocaleString()}
                          )
                        </Option>
                      ))}
                  </>
                )}
              </Select>
            </Form.Item>
          </Row>
          <Row>
            <Form.Item
              name="web_url"
              label="URL"
              style={{ width: "400px" }}
              rules={[
                { type: "url", warningOnly: true },
                { max: 1024, message: "Link is too long" },
              ]}
              maxLength={1024}
            >
              <Input placeholder="Enter Web URL" />
            </Form.Item>
          </Row>
          <Row>
            <Col>
              <Form.Item style={{ marginTop: 10 }}>
                {!isHereForUpdate && (
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={
                      !connectionTypeValue ||
                      urlValue?.length > 1024 ||
                      titleValue?.length > 120 ||
                      !(
                        (connectionTypeValue === "Staff" &&
                          staffTitles?.includes(titleValue)) ||
                        connectionTypeValue !== "Staff"
                      )
                    }
                  >
                    Attach
                  </Button>
                )}
                {isHereForUpdate && (
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={
                      !checkFormChanged(selectedDecisionMakerAsHVC, {
                        title: titleValue,
                        is_current: isCurrentValue,
                        web_url: urlValue,
                        company: companyValue,
                        connection_type: connectionTypeValue,
                      }) ||
                      urlValue?.length > 1024 ||
                      titleValue?.length > 120 ||
                      !(
                        (connectionTypeValue === "Staff" &&
                          staffTitles?.includes(titleValue)) ||
                        connectionTypeValue !== "Staff"
                      )
                    }
                  >
                    Update
                  </Button>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export default ConnectionTypeForm;
