import { numberToNDigitString } from "../util";

export const congDistOptionsWithState = (state) => {
  if (MaxCongDistNumberByState[state] === 1) {
    return [];
  }
  return [...Array(MaxCongDistNumberByState[state]).keys()].map(
    (i) => `${state}-${numberToNDigitString(i + 1)}`
  );
};

export const congDistrictsFromState = (state) => {
  if (!state) {
    return [];
  }
  return [...Array(MaxCongDistNumberByState[state]).keys()].map((i) =>
    numberToNDigitString(i + 1)
  );
};

export const getStateFromCongDist = (congDist) => {
  const m = congDist.match(/^([A-z]{2})-([0-9]{2})$/);
  if (!m) {
    throw new Error(`Badly formatted congressional district: ${congDist}`);
  }
  return m[1];
};

export const MaxCongDistNumberByState = {
  AK: 1,
  AL: 7,
  AR: 4,
  AZ: 9,
  CA: 52,
  CO: 8,
  CT: 5,
  DC: 1,
  DE: 1,
  FL: 28,
  GA: 14,
  HI: 2,
  IA: 4,
  ID: 2,
  IL: 17,
  IN: 9,
  KS: 4,
  KY: 6,
  LA: 6,
  MA: 9,
  MD: 8,
  ME: 2,
  MI: 13,
  MN: 8,
  MO: 8,
  MS: 4,
  MT: 2,
  NC: 14,
  ND: 1,
  NE: 3,
  NH: 2,
  NJ: 12,
  NM: 3,
  NV: 4,
  NY: 26,
  OH: 15,
  OK: 5,
  OR: 6,
  PA: 17,
  RI: 2,
  SC: 7,
  SD: 1,
  TN: 9,
  TX: 38,
  UT: 4,
  VA: 11,
  VT: 1,
  WA: 10,
  WI: 8,
  WV: 2,
  WY: 1,
};

function reducer(acc, [state, maxCongDist]) {
  acc[state] = [...Array(maxCongDist).keys()].map((i) =>
    numberToNDigitString(i + 1)
  );
  return acc;
}

export const CongDistsByState = Object.entries(MaxCongDistNumberByState).reduce(
  reducer,
  {}
);
