import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  Pagination,
  Badge,
  Typography,
  Space,
  Radio,
  Input,
  Divider,
} from "antd";

import { EditOutlined } from "@ant-design/icons";

import { useHistory } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import { useDispatch, useSelector } from "react-redux";

import { getAccountCampaigns } from "../../APIClient";
import { CampaignDMsDrawer } from "./CampaignDMsDrawer";
import { useParams } from "react-router-dom";
import {
  ALL_GROUPS,
  isUserInAllowedGroups,
} from "../../util/feature_flags/users";

const ListAccountCampaigns = () => {
  const userGroups = useSelector((state) => state.userGroups);
  console.log("my userGroups", userGroups);

  const { hubspotCompanyId: hubspotCompanyIdFromUrlParams } = useParams();
  const history = useHistory();

  const selectedHubspotCompanyId = useSelector(
    (state) => state.selectedHubspotCompanyId
  );

  const { Search } = Input;
  const { Text } = Typography;

  const [accountCampaigns, setAccountCampaigns] = useState([]);
  const [ascending, setAscending] = useState("asc");
  const [accountCampaignstoShow, setAccountCampaignstoShow] = useState([]);
  const [page, setPage] = useState(1);
  const [isDMsDrawerVisible, setIsDMsDrawerVisible] = useState(false);
  const [selectedCampaignForDMDrawer, setSelectedCampaignForDrawer] =
    useState();

  const dispatch = useDispatch();

  const fetchAccountCampaigns = async () => {
    try {
      let response = await getAccountCampaigns(selectedHubspotCompanyId);
      response = response.map((item) => {
        return { ...item, backofcard: false };
      });
      setAccountCampaigns(response);
      setAccountCampaignstoShow(response);
    } catch (error) {
      alert(error);
    }
  };

  const sortList = (lst) => {
    if (ascending === "asc") {
      return lst.sort((a, b) => a.name?.localeCompare(b.name));
    } else {
      return lst.sort((a, b) => b.name?.localeCompare(a.name));
    }
  };

  useEffect(() => {
    if (!!selectedHubspotCompanyId) {
      fetchAccountCampaigns();
      history.push(`/account-campaigns/${selectedHubspotCompanyId}`);
    } else if (!!hubspotCompanyIdFromUrlParams) {
      dispatch({
        type: "set-selected-hubspot-company-id",
        payload: hubspotCompanyIdFromUrlParams,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHubspotCompanyId]);

  const cardTitleStyle = {
    fontSize: "0.8vw",
    fontStyle: "bold",
    color: "#8D8D8D",
    fontWeight: "600",
    lineHeight: "20px",
    marginBottom: "5px",
  };

  const campaignTitleStyleFront = {
    fontSize: "1.5vw",
    fontStyle: "normal",
    color: "white",
    fontWeight: "800",
    lineHeight: "74px",
    backgroundImage:
      "linear-gradient(180deg, rgb(28, 102, 226, 0), rgb(28, 102, 226, 0.8))",
    position: "absolute",
    bottom: "0px",
    left: "2px",
    right: "0px",
    paddingLeft: "20px",
  };

  const campaignTitleStyle = {
    fontSize: "1.4vw",
    fontStyle: "normal",
    color: "blue",
    fontWeight: "700",
    lineHeight: "20px",
    marginBottom: "60px",
  };

  const defaultPageSize = 9;

  const cardContentStyle = {
    fontSize: "0.8vw",
    fontStyle: "normal",
    color: "black",
    fontWeight: "700",
    lineHeight: "20px",
    marginLeft: "10px",
  };

  const cardCampaignStatusStyle = {
    fontSize: "0.8vw",
    fontStyle: "normal",
    color: "black",
    fontWeight: "700",
    lineHeight: "20px",
  };

  const activeDmsInCampaign = (p) =>
    p?.decision_makers?.filter((dm) => dm.active)?.length || 0;

  const applyHoverEffect = (itemId) => {
    setAccountCampaignstoShow(
      accountCampaignstoShow.map((item) =>
        item.id === itemId
          ? { ...item, backOfCard: true }
          : { ...item, backOfCard: false }
      )
    );
  };

  return (
    <Navbar contentClass="landing-page-content">
      <CampaignDMsDrawer
        project={selectedCampaignForDMDrawer}
        isVisible={isDMsDrawerVisible}
        setIsVisible={setIsDMsDrawerVisible}
      />
      <Row
        style={{
          marginBottom: "1%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Col span={16}>
          <Search
            placeholder="Search Campaign"
            onChange={(v) => {
              let searchValue = v.target.value;
              console.log(searchValue);
              if (searchValue === "") {
                setAccountCampaignstoShow(accountCampaigns);
              } else {
                console.log(
                  accountCampaigns?.filter((item) =>
                    item.name
                      .toLowerCase()
                      .startsWith(searchValue.toLowerCase())
                  )
                );
                setAccountCampaignstoShow(
                  accountCampaigns?.filter((item) =>
                    item.name
                      ?.toLowerCase()
                      .startsWith(searchValue.toLowerCase())
                  )
                );
              }
            }}
            style={{ marginLeft: "10px" }}
            enterButton
          />
        </Col>
        <Col span={1}></Col>
        <Col span={4}>
          <Radio.Group
            value={ascending}
            onChange={(v) => {
              setAscending(v.target.value);
            }}
          >
            <Radio.Button value="asc">Ascending</Radio.Button>
            <Radio.Button value="desc">Descending</Radio.Button>
          </Radio.Group>
        </Col>

        <Col span={3}>
          <>
            {!isUserInAllowedGroups(userGroups, [
              ALL_GROUPS.BUSINESS_DEVELOPMENT_LEADS,
            ]) && (
              <Button
                type="primary"
                disabled={!selectedHubspotCompanyId}
                onClick={() => {
                  history.push(
                    `/account-campaigns/${selectedHubspotCompanyId}/campaign`
                  );
                }}
                style={{ marginRight: "20px", float: "right" }}
              >
                New Campaign
              </Button>
            )}
          </>
        </Col>
      </Row>
      <Row width="100%">
        {selectedHubspotCompanyId && (
          <>
            {accountCampaignstoShow &&
              accountCampaignstoShow.length > 0 &&
              sortList(accountCampaignstoShow)
                .slice((page - 1) * defaultPageSize, page * defaultPageSize)
                .map((item) => {
                  return (
                    <>
                      <Col
                        span={8}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Card
                          style={{
                            margin: "5%",
                            minHeight: "260px",
                            minWidth: "320px",
                            marginLeft: "8px",
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                            backgroundRepeat: "no-repeat",
                            backgroundImage: item.backOfCard
                              ? null
                              : "url(/cover.png)",
                          }}
                          hoverable={true}
                          item={item}
                          key={item.id}
                          backOfCard={item.backOfCard}
                          onMouseEnter={(v) => {
                            applyHoverEffect(item.id);
                          }}
                          onMouseLeave={(v) => {
                            applyHoverEffect(null);
                          }}
                        >
                          {item.backOfCard ? (
                            <>
                              <Text type="secondary" style={campaignTitleStyle}>
                                {item.name}
                                <>
                                  {!isUserInAllowedGroups(userGroups, [
                                    ALL_GROUPS.BUSINESS_DEVELOPMENT_LEADS,
                                  ]) && (
                                    <EditOutlined
                                      style={{
                                        float: "right",
                                        color: "gray",
                                      }}
                                      onClick={() =>
                                        history.push(
                                          `/account-campaigns/${selectedHubspotCompanyId}/campaign/${item.id}`
                                        )
                                      }
                                    />
                                  )}
                                </>
                              </Text>

                              <Row
                                style={{
                                  marginTop: "8%",
                                  minHeight: "100px",
                                }}
                                span={11}
                              >
                                <Row style={{ width: "100%" }}>
                                  <Col span={16}>
                                    <Text
                                      type="secondary"
                                      style={cardTitleStyle}
                                    >
                                      Number Of Projects :
                                    </Text>
                                  </Col>
                                  <Col span={8}>
                                    <Text
                                      type="secondary"
                                      style={cardContentStyle}
                                    >
                                      {item.projects
                                        ? item.projects?.length
                                        : 0}
                                    </Text>
                                  </Col>
                                </Row>

                                <Row style={{ width: "100%" }}>
                                  <Col span={16}>
                                    <Text
                                      type="secondary"
                                      style={cardTitleStyle}
                                    >
                                      Decision Makers :
                                    </Text>
                                  </Col>
                                  <Col span={8}>
                                    <Text
                                      type="secondary"
                                      style={cardContentStyle}
                                      onClick={() => {
                                        setSelectedCampaignForDrawer(item);
                                        setIsDMsDrawerVisible(true);
                                      }}
                                      underline
                                    >
                                      {activeDmsInCampaign(item)}
                                    </Text>
                                  </Col>
                                </Row>

                                <Row style={{ width: "100%" }}>
                                  <Col span={16}>
                                    <Text
                                      type="secondary"
                                      style={cardTitleStyle}
                                    >
                                      Created :
                                    </Text>
                                  </Col>
                                  <Col span={8}>
                                    <Text
                                      type="secondary"
                                      style={cardContentStyle}
                                    >
                                      {item.created_on.split(" ")[0]}
                                    </Text>
                                  </Col>
                                </Row>
                              </Row>

                              {/* <Col span={11}>
                                  <Row>
                                    <Text
                                      type="secondary"
                                      style={cardTitleStyle}
                                    >
                                      Number Of Projects :
                                    </Text>
                                  </Row>
                                  <Row>
                                    <Text
                                      type="secondary"
                                      style={cardTitleStyle}
                                    >
                                      Decision Makers :
                                    </Text>
                                  </Row>
                                  <Row>
                                    <Text
                                      type="secondary"
                                      style={cardTitleStyle}
                                    >
                                      Created :
                                    </Text>
                                  </Row>
                                </Col> */}
                              {/* <Col span={1}></Col> */}
                              {/* <Col span={11}>
                                  <Row>
                                    <Text
                                      type="secondary"
                                      style={cardContentStyle}
                                    >
                                      {item.projects
                                        ? item.projects?.length
                                        : 0}
                                    </Text>
                                  </Row>
                                  <Row>
                                    <Text
                                      type="secondary"
                                      style={cardContentStyle}
                                      onClick={() => {
                                        setSelectedCampaignForDrawer(item);
                                        setIsDMsDrawerVisible(true);
                                      }}
                                      underline
                                    >
                                      {activeDmsInCampaign(item)}
                                    </Text>
                                  </Row>
                                  <Row>
                                    <Text
                                      type="secondary"
                                      style={cardContentStyle}
                                    >
                                      {item.created_on.split(" ")[0]}
                                    </Text>
                                  </Row>
                                </Col>
                              </Row> */}

                              <Row
                                style={{
                                  height: "10px",
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                }}
                                span={2}
                              >
                                <Divider
                                  type="horizontal"
                                  style={{
                                    height: "100%",
                                    width: "100%",
                                    margin: "1px",
                                  }}
                                />
                              </Row>
                              <Row
                                span={11}
                                style={{
                                  marginBottom: "0",
                                }}
                              >
                                <Col span={11} align="center">
                                  <>
                                    <Badge status="success" />
                                    <Text
                                      type="secondary"
                                      style={cardCampaignStatusStyle}
                                    >
                                      Active
                                    </Text>
                                  </>
                                </Col>
                                <Col
                                  span={1}
                                  style={{ height: "25", position: "center" }}
                                >
                                  <Divider
                                    type="vertical"
                                    style={{ height: "100%", width: "10px" }}
                                  />
                                </Col>
                                <Col span={11} align="center">
                                  <>ID : {item.id}</>
                                </Col>
                              </Row>
                            </>
                          ) : (
                            <>
                              <Text
                                type="secondary"
                                style={campaignTitleStyleFront}
                              >
                                {item.name} <Space span={8} />
                              </Text>
                            </>
                          )}
                        </Card>
                      </Col>
                    </>
                  );
                })}

            {accountCampaignstoShow && accountCampaignstoShow.length === 0 && (
              <>
                <Col
                  span={24}
                  align="center"
                  style={{ marginTop: "4%", fontSize: "20px" }}
                >
                  {" "}
                  No records found
                </Col>
              </>
            )}
          </>
        )}
      </Row>
      <Row width="100%" align="middle" justify="center">
        {selectedHubspotCompanyId && (
          <>
            {accountCampaignstoShow && accountCampaignstoShow.length > 0 && (
              <Pagination
                style={{
                  marginTop: "20px",
                  float: "right",
                }}
                defaultCurrent={1}
                defaultPageSize={defaultPageSize}
                onChange={(pageNumber, pageSize) => {
                  setPage(pageNumber);
                }}
                total={accountCampaigns?.length}
              />
            )}
          </>
        )}
      </Row>
    </Navbar>
  );
};

export default ListAccountCampaigns;
