import React, { useEffect, useState } from "react";
import { Button, Form, Input, Row, Select, Space, Typography } from "antd";
import { updatePackage } from "../../api/packages";
import { getDecisionMakerTaxonomy } from "../../APIClient";

const { Title } = Typography;

export const PackageForm = ({
  packge,
  isNew,
  setIsCreatingPackage,
  searchPackages,
  addPackage,
}) => {
  const [name, setName] = useState(isNew ? "" : packge?.name);
  const [politicalParties, setPoliticalParties] = useState([]);
  const [decisionMakerTypeOptions, setDecisionMakerTypeOptions] = useState([]);
  const [decisionMakerTypes, setDecisionMakerTypes] = useState([]);
  const [distribution, setDistribution] = useState("match_state");

  useEffect(() => {
    if (packge) {
      setName(packge?.name);
      setPoliticalParties(packge.political_parties);
      setDecisionMakerTypes(packge.decision_maker_types);
      setDistribution(packge.distribution_rule);
    }
  }, [packge]);

  useEffect(() => {
    if (isNew) {
      setName("");
      setDistribution("match_state");
      setPoliticalParties([]);
      setDecisionMakerTypes([]);
    }
  }, [isNew]);

  const POLITICAL_PARTIES = [
    { value: "DEMOCRAT", label: "DEMOCRAT" },
    { value: "REPUBLICAN", label: "REPUBLICAN" },
    { value: "INDEPENDENT", label: "INDEPENDENT" },
    { value: "OTHER", label: "OTHER" },
  ];

  const updateDecisionMakerTypeOptions = async () => {
    const data = await getDecisionMakerTaxonomy();
    const non_parent_ids = data
      .map((item) => item.id)
      .filter((item) => !data.map((item) => item.parent_id).includes(item));
    setDecisionMakerTypeOptions(
      data
        .filter((item) => non_parent_ids.includes(item.id))
        .sort((a, b) => a.name?.localeCompare(b.name))
        .map((item) => ({ value: item.name, label: item.name }))
    );
  };

  const onSave = async () => {
    const values = {
      political_parties: politicalParties,
      decision_maker_types: decisionMakerTypes,
      distribution_rule: distribution,
      name: name,
    };
    if (isNew) {
      try {
        addPackage(values);
      } catch (e) {
        alert(`Error creating package '${name}'`);
      }
    } else {
      try {
        const returnValue = updatePackage(packge.id, values);
        if (returnValue === true) {
          alert(`Package '${name}' updated successfully`);
        }
      } catch (e) {
        alert(`Error updating package '${packge.name}'`);
      }
    }
    setIsCreatingPackage(false);
    searchPackages(name);
  };

  useEffect(() => {
    updateDecisionMakerTypeOptions();
  }, [packge]);

  return (
    <Form style={{ marginLeft: 20 }}>
      <Row>
        <Form.Item>
          <Title level={5}>Package Name</Title>
          <Input value={name} onChange={(e) => setName(e.target.value)} />
        </Form.Item>
      </Row>
      <Row>
        <Form.Item>
          <Title level={5}>Target's Political Party</Title>
          <Select
            showSearch
            allowClear
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            mode="multiple"
            value={politicalParties}
            onChange={(value) => setPoliticalParties(value)}
            options={POLITICAL_PARTIES}
            style={{ width: 240 }}
          />
        </Form.Item>
      </Row>
      <Row>
        <Form.Item>
          <Title level={5}>Decision-Maker Type</Title>
          <Select
            showSearch
            allowClear
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            mode="multiple"
            value={decisionMakerTypes}
            onChange={(value) => setDecisionMakerTypes(value)}
            options={decisionMakerTypeOptions}
            style={{ width: 240 }}
          />
        </Form.Item>
      </Row>
      <Row>
        <Form.Item>
          <Title level={5}>Distribution Rules</Title>
          <Button
            type={distribution === "match_state" ? "primary" : ""}
            onClick={() => setDistribution("match_state")}
          >
            Match State (+ Congressional District)
          </Button>
          <Button
            type={distribution === "nationwide" ? "primary" : ""}
            style={{ margin: 5 }}
            onClick={() => setDistribution("nationwide")}
          >
            Nationwide
          </Button>
        </Form.Item>
      </Row>
      <Row>
        <Space size={5}>
          <Button
            onClick={onSave}
            type="primary"
            size="middle"
            style={{ width: "65px" }}
          >
            Save
          </Button>
        </Space>
      </Row>
    </Form>
  );
};
