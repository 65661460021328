import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { createClassmateAudienceMembers } from "../../../APIClient";
import {
  showDangerMessage,
  showSuccessMessage,
  showWarningMessage,
} from "../../../utils";
import { EASSegmentDrawer } from "./EASSegmentDrawer";

export const ClassmatesDrawer = ({ isVisible, setIsVisible, refreshPage }) => {
  const { projectId } = useParams();
  const target = useSelector((state) => state.target);

  const createAudienceMembers = async (
    desiredNumber,
    selectedStates,
    isDCmetro
  ) => {
    try {
      const {
        audience_length: numberOfClassmates,
        matched_count: numberOfClassmatesMatched,
      } = await createClassmateAudienceMembers(
        target?.dm_id,
        desiredNumber,
        projectId,
        target.id,
        selectedStates,
        isDCmetro
      );

      if (numberOfClassmates === 0) {
        showDangerMessage(
          `Total of ${numberOfClassmates} classmates were found but we were unable to match any`
        );
      } else if (desiredNumber > numberOfClassmates) {
        showWarningMessage(
          `Total of ${numberOfClassmatesMatched} were found/matched and ${numberOfClassmates} classmates were added.`
        );
      } else {
        showSuccessMessage(
          `Total of ${numberOfClassmatesMatched} were found/matched and ${numberOfClassmates} classmates were added.`
        );
      }
    } catch (error) {
      alert(error);
    }
  };

  return (
    <EASSegmentDrawer
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      refreshPage={refreshPage}
      createAudienceMembers={createAudienceMembers}
      message={"Create Classmates"}
    />
  );
};
