import { Form, Row, Col, Select, Space, DatePicker } from "antd";
import { range } from "lodash";
import moment from "moment";
export const BirthdaySearchMethodSelector = ({ field, index, type }) => {
  const validDays = range(1, 32);
  const { RangePicker } = DatePicker;

  function disabledDate(current) {
    return current && (current < moment("1900") || current > moment());
  }

  if (type === "between")
    return (
      <Row>
        <Space>
          <Col>
            <Form.Item
              {...field}
              name={[field.name, "year_range"]}
              key={[field.name, "year_range", index]}
            >
              <RangePicker
                picker="year"
                placeholder={["Year", "Year"]}
                disabledDate={disabledDate}
                placement={"bottomLeft"}
                style={{ width: 150 }}
              />
            </Form.Item>
          </Col>
        </Space>
      </Row>
    );

  if (["no_earlier_than", "no_later_than"].includes(type)) {
    return (
      <Col>
        <Form.Item
          {...field}
          name={[field.name, "year"]}
          key={[field.name, "year", index]}
        >
          <DatePicker
            picker="year"
            placeholder={"Year"}
            disabledDate={disabledDate}
            placement={"bottomLeft"}
            style={{ width: 90 }}
          />
        </Form.Item>
      </Col>
    );
  } else
    return (
      <Row>
        <Space>
          <Col>
            <Form.Item
              {...field}
              name={[field.name, "year"]}
              key={[field.name, "year", index]}
            >
              <DatePicker
                picker="year"
                placeholder={"Year"}
                disabledDate={disabledDate}
                placement={"bottomLeft"}
                style={{ width: 90 }}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              {...field}
              name={[field.name, "month"]}
              key={[field.name, "month", index]}
            >
              <DatePicker
                picker="month"
                format="M"
                placeholder={"Month"}
                placement={"bottomLeft"}
                style={{ width: 90 }}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              {...field}
              name={[field.name, "day"]}
              key={[field.name, "day", index]}
            >
              <Select
                showSearch
                allowClear
                style={{ minWidth: 90 }}
                placeholder="Day"
              >
                {validDays.map((day) => (
                  <Select.Option key={`month-${day}`} value={day}>
                    {day}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Space>
      </Row>
    );
};
